import Cookies from "js-cookie"
import ReactGA from "react-ga4"

interface IParameters {
  item_id?: string
  item_nameCategory?: string
  item_nameCategorySub?: string
  item_brandName?: string
  item_productName?: string
  item_description?: string
  item_price?: string
  item_savings?: string
  item_location?: string
  item_message1?: string
  item_message2?: string
  banner_position?: string
  imageUrl?: string
  link_sendTo?: string
  text_click?: any
  item_expirationDate?: string
  expired?: string
  navCoupon_close?: string

  // StoreLocator Parameters
  storeLocator_zipCode?: string
  storeLocator_setAsMyStore?: string
  storeLocator_applyOnline?: string
  navStoreLocator_directions?: string
  navStoreLocator_weeklyAds?: string
  location?: string
  section?: string
  User_LoyaltyId?: string
  User_AppDownload_Click?: string
  User_Flyer_View?: string
  User_Coupon_Clip?: string
  search_term?: string
}

export enum EventName {
  banner_click = "banner_click",
  chat_open = "chat_open",
  login_click = "login_click",
  navTop_click = "navTop_click",
  navStoreLocator = "navStoreLocator",
  storeLocator_setAsMyStore = "storeLocator_setAsMyStore",
  storeLocator_weeklyAdClick = "storeLocator_weeklyAdClick",
  product_view = "product_view",
  reward_addReward = "reward_addReward",
  navFooter_click = "navFooter_click",
  coupon_view = "coupon_view",
  password_forgotPassword = "password_forgotPassword",
  signup_click = "signup_click",
  coupon_termsAndCond = "coupon_termsAndCond",
  coupon_clip = "coupon_clip",
  signout_click = "signout_click",
  storeLocator_directionsClick = "storeLocator_directionsClick",
  storeLocator_mapClick = "storeLocator_mapClick",
  storeLocator_listClick = "storeLocator_listClick",
  navTopB_click = "navTopB_click",
  navTopMenu_click = "navTopMenu_click",
  navPage_backToTopClick = "navPage_backToTopClick",
  storeLocator_storeCardClick = "storeLocator_storeCardClick",
  navProdCat_click = "navProdCat_click",
  navPage_viewAllClick = "navPage_viewAllClick",
  navRewards_closeMyBarcodeModal = "navRewards_closeMyBarcodeModal",
  navRewards_showMyBarcodeClick = "navRewards_showMyBarcodeClick",
  navStoreLocator_closeModal = "navStoreLocator_closeModal",
  navCoupon_closeModal = "navCoupon_closeModal",
  navSort_click = "navSort_click",
  navPage_arrowClick = "navPage_arrowClick",
  profile_click = "profile_click",
  navFilter_clearAll = "navFilter_clearAll",
  navFilter_click = "navFilter_click",
  navFilter_unclick = "navFilter_unclick",
  flyer_view = "flyer_view",
  navStoreLocator_findAStoreClick = "navStoreLocator_findAStoreClick",
  account_settings = "account_settings",
  my_coupons = "my_coupons",
  all_coupons = "all_coupons",
  my_rewards = "my_rewards",
  view_store_details = "view_store_details",
  maxx_savings = "maxx_savings",
  storeLocator_searchPlaces = "storeLocator_searchPlaces",
  storeLocator_geocodeLocation = "storeLocator_geocodeLocation",
  // Add more event names here...
}

interface IEvent {
  eventName: EventName
  parameters?: IParameters
}

function removeBlankValueKeys(message: any) {
  const newMessage: any = {}
  for (const key in message) {
    const value = message[key]
    newMessage[key] =
      value === null || value === "" || value === undefined
        ? "undefined"
        : value
  }
  return newMessage
}

export const gaLogEvent = (eventProps: IEvent) => {
  const cookieValue = Cookies.get("cmapi_cookie_privacy")
  /**
   * cookieValue = 2 means Functional Cookies are set to true
   */
  if (cookieValue && cookieValue.includes("2")) {
    const eventProprObject = removeBlankValueKeys(eventProps.parameters)
    const localProperties = {
      User_ID: (Cookies.get("authToken") as string)
        ? localStorage.getItem("userId")
        : "undefined",
      User_Login: (Cookies.get("authToken") as string) ? "true" : "false",
      User_StoreName: localStorage.getItem("storeName") || "undefined",
      User_RewardPoints: localStorage.getItem("loyaltyPoints") || "undefined",
      User_LoyaltyId: (Cookies.get("authToken") as string)
        ? localStorage.getItem("cardId")
        : "undefined",
    }
    ReactGA.event(eventProps.eventName, {
      ...eventProprObject,
      ...localProperties,
    })
  }
}
