import { ShoppingListItemType } from "components/shoppingList/types"
import { swiftly } from "../../client-data-bom"
import { DecoratedStore } from "../../components/stores/utils"
import { GQLError } from "../../utils/GraphqlError"
import { actionTypes, initialState, PageAttributesType } from "../AppContext"

export const openSnackbar = ({
  message,
  errors,
  variant,
  alert,
  actionButton,
  close,
}: {
  message?: string
  errors?: GQLError[]
  variant?: string
  alert?: { color?: string; variant?: string }
  actionButton?: boolean
  close?: boolean
}) => {
  return {
    type: actionTypes.OPEN_SNACKBAR,
    payload: {
      message: message || initialState.snackbar.message,
      variant: variant || initialState.snackbar.variant,
      errors: errors || initialState.snackbar.errors,
      alert: {
        color: alert?.color || initialState.snackbar.alert.color,
        variant: alert?.variant || initialState.snackbar.alert.variant,
      },
      actionButton: actionButton || initialState.snackbar.actionButton,
      close: close || initialState.snackbar.close,
    },
  }
}

export const closeSnackbar = () => {
  return {
    type: actionTypes.CLOSE_SNACKBAR,
  }
}

export const setStore = (store: DecoratedStore) => {
  localStorage.setItem("store", store.id)
  localStorage.setItem("storeName", store.name)
  localStorage.setItem("storeObject", JSON.stringify(store))
  return {
    type: actionTypes.SET_STORE,
    payload: {
      store,
    },
  }
}

export const flashAlert = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  show: (payload: any) => {
    return {
      type: actionTypes.SHOW_FLASH_ALERT,
      payload,
    }
  },
  hide: () => {
    return {
      type: actionTypes.HIDE_FLASH_ALERT,
    }
  },
}

export const setOpenPayPalModal = () => {
  return {
    type: actionTypes.SET_OPEN_PAYPAL_MODAL,
    payload: true,
  }
}

export const setClosePayPalModal = () => {
  return {
    type: actionTypes.SET_CLOSE_PAYPAL_MODAL,
    payload: false,
  }
}

export const setOpenCouponId = (
  couponId: string,
  isOpenCouponRebate = false,
  isClippedCoupon = false
) => {
  return {
    type: actionTypes.SET_OPEN_COUPON_ID,
    payload: {
      couponId,
      isOpenCouponRebate,
      isClippedCoupon: isClippedCoupon,
    },
  }
}

export const clearOpenCouponId = () => {
  return {
    type: actionTypes.CLEAR_OPEN_COUPON_ID,
    payload: undefined,
  }
}

export const setOpenProductId = (payload: string) => {
  return {
    type: actionTypes.SET_OPEN_PRODUCT_ID,
    payload: payload,
  }
}

export const clearOpenProductId = () => {
  return {
    type: actionTypes.CLEAR_OPEN_PRODUCT_ID,
    payload: undefined,
  }
}

export const openLoginRequestModal = () => {
  return {
    type: actionTypes.OPEN_COUPON_ID_LOGIN_REQUEST_MODAL,
  }
}

export const closeLoginRequestModal = () => {
  return {
    type: actionTypes.CLOSE_COUPON_ID_LOGIN_REQUEST_MODAL,
    payload: undefined,
  }
}

export const setSelectedCouponCategoryId = (categoryId: string) => {
  return {
    type: actionTypes.SET_SELECTED_COUPON_CATEGORY_ID,
    payload: categoryId,
  }
}

export const setReFetchedCoupons = (
  refetchedCoupons: swiftly.offers.SwiftlyJsSwiftlyOffer[]
) => {
  return {
    type: actionTypes.SET_REFETCHED_COUPON,
    payload: refetchedCoupons,
  }
}

export const resetPageAttributes = () => {
  return {
    type: actionTypes.RESET_PAGE_ATTRIBUTES,
  }
}

export const setPageAttributes = (
  pageAttributes: Partial<PageAttributesType>
) => {
  return {
    type: actionTypes.SET_PAGE_ATTRIBUTES,
    payload: pageAttributes,
  }
}

export const setCategoriesList = (categoryList: any) => {
  return {
    type: actionTypes.SET_CATEGORY_LIST,
    payload: categoryList,
  }
}

export const setBreadcrumbs = (payload: any) => {
  return {
    type: actionTypes.SET_BREADCRUMBS,
    payload: payload,
  }
}

export const setCarousalList = (payload: any) => {
  return {
    type: actionTypes.SET_CAROUSAL_LIST,
    payload: payload,
  }
}

export const openShoppingListDrawer = (payload: string | null) => {
  return {
    type: actionTypes.OPEN_SHOPPING_LIST_DRAWER,
    payload,
  }
}

export const closeShoppingListDrawer = () => {
  return {
    type: actionTypes.CLOSE_SHOPPING_LIST_DRAWER,
  }
}

export const setShoppingListMetaData = (payload: any) => {
  return {
    type: actionTypes.SET_SHOPPING_LIST_META_DATA,
    payload,
  }
}

export const setShoppingListItems = (payload: any) => {
  return {
    type: actionTypes.SET_SHOPPING_LIST_ITEMS,
    payload,
  }
}

export const resetShoppingListItems = () => {
  return {
    type: actionTypes.RESET_SHOPPING_LIST_ITEMS,
  }
}

export const addShoppingListItem = (payload: ShoppingListItemType) => {
  return {
    type: actionTypes.ADD_SHOPPING_LIST_ITEM,
    payload,
  }
}

export const setShoppingListLoader = (payload: boolean) => {
  return {
    type: actionTypes.SET_SHOPPING_LIST_LOADER,
    payload,
  }
}

export const updateShoppingListItem = (payload: any) => {
  return {
    type: actionTypes.UPDATE_SHOPPING_LIST_ITEM,
    payload,
  }
}

export const deleteShoppingListItem = (payload: any) => {
  return {
    type: actionTypes.DELETE_SHOPPING_LIST_ITEM,
    payload,
  }
}

export const uncheckAllShoppingListItems = () => {
  return {
    type: actionTypes.UNCHECK_ALL_SHOPPING_LIST_ITEMS,
  }
}

export const setEligibiitiesList = (eligibilityList: any) => {
  return {
    type: actionTypes.SET_ELIGIBILITES_LIST,
    payload: eligibilityList,
  }
}
