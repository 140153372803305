import React from "react"
import { Box } from "@mui/material"
import RewardCard, { RewardCardProps } from "./RewardCard"

export interface RewardGridProps {
  rewards: RewardCardProps[]
  title?: string
  onClick?: (rewardId: string) => void
  onRedeem?: (rewardId: string) => Promise<void>
  isMobile: boolean
}

// same with ProductList style
const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 180px)",
    alignItems: "stretch",
  },
  title: {
    color: "#232323",
    fontWeight: "700",
    lineHeight: 2,
    fontSize: 18,
    my: 2,
    mx: 1,
  },
}

const RewardGrid = ({
  rewards,
  title,
  onClick,
  onRedeem,
  isMobile,
}: RewardGridProps) => {
  return (
    <Box>
      {title && <Box sx={{ ...styles.title }}>{title}</Box>}
      <Box gap={isMobile ? 2 : 3} sx={styles.container} justifyContent="center">
        {rewards?.map((reward, index) => {
          if (onClick) reward.onClick = onClick
          if (onRedeem) reward.onRedeem = onRedeem
          return <RewardCard key={`${reward.rewardId}-${index}`} {...reward} />
        })}
      </Box>
    </Box>
  )
}

export default RewardGrid
