import * as React from "react"
import { SVGProps } from "react"

const AddToList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.76601 19.4181H3.30332C1.49008 19.4181 0.584473 18.5027 0.584473 16.6609V3.33544C0.584473 1.50294 1.49008 0.578247 3.30332 0.578247H12.6368C14.452 0.578247 15.3671 1.50294 15.3671 3.33544V11.1284C14.9226 10.9101 14.4452 10.7545 13.9461 10.6737V3.35795C13.9461 2.48013 13.4735 1.99183 12.5682 1.99183H3.38152C2.46671 1.99183 1.99782 2.48013 1.99782 3.35795V16.6384C1.99782 17.5162 2.46671 18.0045 3.38152 18.0045H7.96542C8.15558 18.5187 8.42877 18.9951 8.76601 19.4181ZM3.87992 5.18724H11.9669C12.263 5.18724 12.5076 4.9397 12.5076 4.63776C12.5076 4.33794 12.263 4.08102 11.9669 4.08102H3.87992C3.58193 4.08102 3.33927 4.33794 3.33927 4.63776C3.33927 4.9397 3.58193 5.18724 3.87992 5.18724ZM3.87992 7.86254H11.9669C12.2721 7.86254 12.5076 7.62414 12.5076 7.31498C12.5076 7.01323 12.2721 6.77671 11.9669 6.77671H3.87992C3.57278 6.77671 3.33927 7.01323 3.33927 7.31498C3.33927 7.62414 3.57278 7.86254 3.87992 7.86254ZM3.87992 10.668H11.9669C12.263 10.668 12.5076 10.4223 12.5076 10.1204C12.5076 9.82049 12.2721 9.58209 11.9669 9.58209H3.87992C3.57278 9.58209 3.33927 9.82049 3.33927 10.1204C3.33927 10.4223 3.58193 10.668 3.87992 10.668ZM12.9547 20.4484C15.373 20.4484 17.4154 18.4245 17.4154 15.9858C17.4154 13.5396 15.4009 11.525 12.9547 11.525C10.5085 11.525 8.49389 13.5415 8.49389 15.9858C8.49389 18.4432 10.5085 20.4484 12.9547 20.4484ZM12.9547 18.7855C12.63 18.7855 12.4133 18.5653 12.4133 18.246V16.5271H10.6963C10.3772 16.5271 10.1642 16.3107 10.1642 15.9858C10.1642 15.6629 10.3772 15.4463 10.6963 15.4463H12.4133V13.7293C12.4133 13.4103 12.63 13.1973 12.9547 13.1973C13.2796 13.1973 13.4942 13.4103 13.4942 13.7293V15.4463H15.2112C15.5323 15.4463 15.7526 15.6629 15.7526 15.9858C15.7526 16.3107 15.5323 16.5271 15.2112 16.5271H13.4942V18.246C13.4942 18.5653 13.2796 18.7855 12.9547 18.7855Z" />
  </svg>
)
export default AddToList
