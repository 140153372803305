export default function getTimeZone(): string {
  if (typeof window === "undefined") {
    return "America/Los_Angeles"
  }

  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (_) {
    return "America/Los_Angeles"
  }
}
