import { SVGProps } from "react"
const SvgDropPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="87"
    height="113"
    viewBox="0 0 87 113"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.375 0C19.794 0 0.625 18.8277 0.625 41.9886C0.625 72.049 35.6759 103.599 43.306 112.141C43.346 112.186 43.404 112.186 43.4439 112.141C51.0737 103.569 86.125 72.0491 86.125 41.9886C86.125 18.8575 66.956 0 43.375 0ZM43.375 65.7175C30.048 65.7175 19.2159 55.0783 19.2159 41.9886C19.2159 28.8989 30.048 18.2598 43.375 18.2598C56.7021 18.2598 67.5341 28.8989 67.5341 41.9886C67.5341 55.0783 56.7021 65.7175 43.375 65.7175Z"
      fill="#D2D2D2"
    />
  </svg>
)
export default SvgDropPinIcon
