import getAuthTokenFromCookie from "./getAuthTokenFromCookie"

type AuthHeaderType =
  | {
      Authorization: string
    }
  | object

function getSwiftyAuthHeader(): AuthHeaderType {
  const authToken = getAuthTokenFromCookie()
  return authToken?.access_token
    ? { Authorization: `Bearer ${authToken.access_token}` }
    : {}
}

export default getSwiftyAuthHeader
