import { TopBarType } from "swiftlykit/dist/esm/components/couponCard/CouponCard"
import { swiftly } from "../client-data-bom"
import { off } from "process"
import { Rebate } from "services/CouponsApi"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sortOffers,
}

// returns sorted offers
export function sortOffers(
  offers: Array<swiftly.offers.SwiftlyJsSwiftlyOffer>,
  dimensionId: string,
  categoryId?: string
): Array<swiftly.offers.SwiftlyJsSwiftlyOffer> {
  const offersCopy = [...offers]
  offersCopy.sort(
    (
      offerA: swiftly.offers.SwiftlyJsSwiftlyOffer,
      offerB: swiftly.offers.SwiftlyJsSwiftlyOffer
    ) => {
      const offerASortDimension =
        findOfferSortDimension(
          offerA,
          dimensionId,
          categoryId
        )?.rank?.toString() || Number.MAX_SAFE_INTEGER
      const offerBSortDimension =
        findOfferSortDimension(
          offerB,
          dimensionId,
          categoryId
        )?.rank?.toString() || Number.MAX_SAFE_INTEGER
      return offerASortDimension - offerBSortDimension
    }
  )
  return offersCopy
}

export function findOfferSortDimension(
  offer: swiftly.offers.SwiftlyJsSwiftlyOffer,
  dimensionId: string,
  categoryId?: string
): swiftly.offers.SwiftlyJsSortDimension | null | undefined {
  const categoryIdDefaulted = categoryId || "*"
  return offer.sortGroups
    .find((sortGroup: swiftly.offers.SwiftlyJsSortGroup) => {
      return sortGroup.groupId === categoryIdDefaulted
    })
    ?.dimensions?.find((dimension: swiftly.offers.SwiftlyJsSortDimension) => {
      return dimension.dimensionId === dimensionId
    })
}

enum allOfferTypes {
  DECORATOR_TYPE_LOYALTY = "Loyalty",
  DECORATOR_TYPE_FEATURED = "Featured",
  DECORATOR_TYPE_MULTI_REDEMPTION = "MultipleRedemptionsAllowed",
  DECORATOR_TYPE_APP_ONLY = "AppOnly",
  DECORATOR_TYPE_EXPIRING = "Expiring",
  DECORATOR_TYPE_EXPIRED = "Expired",
  DECORATOR_TYPE_NEW = "New",
  DECORATOR_TYPE_REDEEMED = "Redeemed",
  DECORATOR_TYPE_EXCLUSIVE = "Exclusive",
  DECORATOR_TYPE_UNKNOWN = "Unknown",
}

export const findBestDecoration = (
  offer: swiftly.offers.SwiftlyJsSwiftlyOffer
) => {
  const topPriorityDecorator = offer?.decorators?.reduce(
    (
      acc: swiftly.offers.SwiftlyJsOfferDecorator | null,
      curr: swiftly.offers.SwiftlyJsOfferDecorator
    ) => {
      if (curr.priority < (acc?.priority || Number.MAX_SAFE_INTEGER)) {
        return curr
      }
      return acc
    },
    null
  )
  switch (topPriorityDecorator?.type) {
    case allOfferTypes.DECORATOR_TYPE_NEW:
      return "new"
    case allOfferTypes.DECORATOR_TYPE_FEATURED:
      return "featured"
    case allOfferTypes.DECORATOR_TYPE_EXPIRING:
      return "expires_soon"
    default:
      return "empty"
  }
}

export const findBestDecorationRebates = (offer: Rebate) => {
  switch (offer?.decorator) {
    case allOfferTypes.DECORATOR_TYPE_NEW:
      return "new"
    case allOfferTypes.DECORATOR_TYPE_FEATURED:
      return "featured"
    case allOfferTypes.DECORATOR_TYPE_EXPIRING:
      return "expires_soon"
    default:
      return "empty"
  }
}

export const findOfferState = (offer: swiftly.offers.SwiftlyJsSwiftlyOffer) => {
  if (offer.shopperOffer?.claimed) return "clipped"
  if (offer.shopperOffer?.redeemed) return "redeemed"
  if (new Date() > new Date(offer.expirationDateStr)) return "expired"
  return "active"
}

export const findOfferStateRebate = (offer: Rebate) => {
  if (offer.status === "Available") return "active"
  if (offer.status === "Clipped") return "clipped"
  if (offer.status === "Redeemed") return "redeemed"
}
