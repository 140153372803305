import {
  CartIcon,
  ChevronRightIcon,
  CloseIcon,
  CouponsIcon,
  DepartmentsIcon,
  LevelBackIcon,
  MadeToOrderIcon,
  RewardsIcon,
  SignOutIcon,
  StoreIcon,
  UserIcon,
  ZapIcon,
} from "@/icons"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import { AppContext } from "contexts/AppContext"
import { useAuth } from "contexts/AuthContext"
import { useRouter } from "next/router"
import React, { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import generateConfig from "../../configs/config"

const config = generateConfig()

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}))

const Drawer = styled(MuiDrawer)(() => ({
  "& .MuiDrawer-paper": {
    width: "90%",
  },
}))

const ListItemIcon = styled(MuiListItemIcon)(() => ({
  "&.MuiListItemIcon-root": {
    display: "flex",
    justifyContent: "center",
  },
}))

const DashedDivider = styled(Divider)(() => ({
  "&.MuiDivider-root": {
    borderStyle: "dashed",
  },
}))

export type NavBarChildType = {
  label: JSX.Element | string
  icon?: JSX.Element
  href?: string
  onClick?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any
}

export type NavBarItemType = {
  label: JSX.Element | string
  icon?: JSX.Element
  endIcon?: JSX.Element
  isExternal?: boolean
  href?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any
  children?: NavBarChildType[][]
}

export type SideNavBarProps = {
  open: boolean
  onClose: () => void
  setIsDepartmentsVisible: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void
}

export default function SideNavBar({
  open,
  onClose,
  setIsDepartmentsVisible,
}: SideNavBarProps) {
  const theme = useTheme()
  const router = useRouter()
  const containerRef = useRef(null)
  const { t } = useTranslation()
  const { isAuthenticated, user, logout, initialState } = useAuth()
  const [selectedNavItem, setSelectedNavItem] = useState<NavBarItemType | null>(
    null
  )

  const {
    state: { currentStore },
  } = useContext(AppContext)

  const navItemGroups: NavBarItemType[][] = [
    // Group 1
    [
      {
        label: t("navBarMenu.departments"),
        onClick: () => {
          setIsDepartmentsVisible(true)
        },
        icon: (
          <DepartmentsIcon
            color={theme.palette.ctaPrimary.main}
            fontSize="1.5rem"
          />
        ),
      },
      {
        label: t("navBarMenu.coupons"),
        href: "/coupons",
        icon: (
          <CouponsIcon
            color={theme.palette.ctaPrimary.main}
            fontSize="1.5rem"
          />
        ),
      },
      {
        label: t("navBarMenu.shopNow"),
        href: `${config.shopNow}`,
        icon: <CartIcon fontSize="1.5rem" />,
        props: {
          "aria-label": t("navBarMenu.shopNowAriaLabel"),
        },
      },
    ],
    // Group 2
    [
      {
        label: t("navBarMenu.weeklyAd"),
        href: "/flyers" + (currentStore ? `/${currentStore.number}` : ""),
        icon: (
          <ZapIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
        ),
        props: {
          "data-cy": "weekly-ad",
        },
      },
      {
        label: t("navBarMenu.locations"),
        href: "/stores",
        icon: (
          <StoreIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
        ),
      },
    ],
  ]

  if (initialState?.envVariables?.configBanner === "foodmaxx") {
    navItemGroups[1].push({
      label: t("navBarMenu.maxxSavings"),
      href: "/maxx-savings",
      icon: (
        <RewardsIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
      ),
    })
  } else {
    if (config?.madeToOrder) {
      navItemGroups[0].push(
        {
          label: t("navBarMenu.madeToOrder"),
          href: config?.madeToOrder || "",
          icon: <MadeToOrderIcon fontSize="1.5rem" />,
          props: {
            "aria-label": t("navBarMenu.madeToOrderAriaLabel"),
            role: "link",
          },
        }
      )
    }
    navItemGroups[1].push({
      label: t("navBarMenu.myRewards"),
      href: isAuthenticated ? "/my-rewards" : "/rewards-login",
      icon: (
        <RewardsIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
      ),
    })
  }

  if (config.enableShoppingList) {
    navItemGroups[1].push({
      label: t("navBarMenu.shoppingList"),
      href: "/shopping-list",
      icon: (
        <PlaylistAddCheckIcon sx={{ color: theme.palette.ctaPrimary.main }} />
      ),
    })
  }

  // Set auth nav option dynamically
  if (isAuthenticated) {
    const rewards =
      initialState?.envVariables?.configBanner === "foodmaxx"
        ? {
            label: t("navBarMenu.maxxSavings"),
            icon: (
              <RewardsIcon
                color={theme.palette.ctaPrimary.main}
                fontSize="1.5rem"
              />
            ),
            href: "/maxx-savings",
            onclick: () => {
              gaLogEvent({
                eventName: EventName.maxx_savings,
                parameters: {
                  item_location: window.location.pathname,
                },
              })
            },
            props: {
              "data-cy": "my-rewards",
            },
          }
        : {
            label: t("navBarMenu.myRewards"),
            icon: (
              <RewardsIcon
                color={theme.palette.ctaPrimary.main}
                fontSize="1.5rem"
              />
            ),
            href: "/my-rewards",
            onclick: () => {
              gaLogEvent({
                eventName: EventName.my_rewards,
                parameters: {
                  item_location: window.location.pathname,
                },
              })
            },
            props: {
              "data-cy": "my-rewards",
            },
          }
    const navItem = {
      label: user?.firstName || "",
      icon: (
        <UserIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
      ),
      // Nested children navigation
      children: [
        [
          rewards,
          {
            label: t("navBarMenu.myCoupons"),
            icon: (
              <CouponsIcon
                color={theme.palette.ctaPrimary.main}
                fontSize="1.5rem"
              />
            ),
            href: "/accounts/coupons",
            onclick: () => {
              gaLogEvent({
                eventName: EventName.my_coupons,
                parameters: {
                  item_location: window.location.pathname,
                },
              })
            },
            props: {
              "data-cy": "my-coupons",
            },
          },
          {
            label: t("navBarMenu.accountSettings"),
            icon: (
              <UserIcon
                color={theme.palette.ctaPrimary.main}
                fontSize="1.5rem"
              />
            ),
            href: "/accounts",
            props: {
              "data-cy": "accounts",
            },
          },
        ],
        [
          {
            label: t("navBarMenu.signOut"),
            icon: (
              <SignOutIcon
                color={theme.palette.ctaPrimary.main}
                fontSize="1.5rem"
              />
            ),
            onClick: () => {
              setSelectedNavItem(null)
              onClose
              gaLogEvent({
                eventName: EventName.signout_click,
                parameters: {
                  item_location: window.location.pathname,
                },
              })
              logout({
                message: t("couponsMessage.loggedOut"),
                redirectLocation: "/login",
              })
            },
            props: {
              "data-cy": "accounts",
            },
          },
        ],
      ],
    }
    navItemGroups.unshift([navItem])
  } else {
    const navItem = {
      desktopPosition: "right",
      label: t("navBarMenu.signIn"),
      icon: (
        <UserIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
      ),
      href: "/login",
    }
    navItemGroups.unshift([navItem])
  }
  const isTablet = useMediaQuery("(min-width:767px) and (max-width:1279px)")
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      ref={containerRef}
      sx={{
        " .MuiPaper-root": {
          width: isTablet ? "335px" : "90%",
        },
      }}
    >
      <DrawerHeader
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "bgPrimary.main",
          zIndex: 9,
          minHeight: "56px !important",
          borderBottom: `1px solid ${theme.palette.dividerColor?.main}`,
        }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            onClick={onClose}
            endIcon={<CloseIcon color={theme.palette.ctaPrimary.main} />}
            color="ctaPrimary"
            aria-label={t("navBarMenu.closeMenu")}
          >
            {t("navBarMenu.closeMenu")}
          </Button>
        </Box>
      </DrawerHeader>
      <List sx={{ overflowX: "hidden", p: 0 }}>
        {/* Default first page to render parent level nav items */}
        {!selectedNavItem && (
          <Slide
            direction="right"
            mountOnEnter
            unmountOnExit
            in={!selectedNavItem}
            container={containerRef.current}
          >
            <Stack px={0}>
              {navItemGroups?.map((navItems, i) => (
                <React.Fragment key={i}>
                  {navItems?.map(
                    (
                      {
                        label,
                        icon,
                        href,
                        onClick,
                        props,
                        children,
                      }: NavBarItemType,
                      i: number
                    ) => (
                      <ListItem key={i} disablePadding>
                        <ListItemButton
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            const target = e.target as HTMLButtonElement
                            if (target.textContent === t("navBarMenu.signIn")) {
                              gaLogEvent({
                                eventName: EventName.login_click,
                                parameters: {
                                  text_click: target.textContent,
                                  item_location: window.location.pathname,
                                },
                              })
                              localStorage.setItem(
                                "from",
                                window.location.pathname
                              )
                            } else {
                              gaLogEvent({
                                eventName: isAuthenticated
                                  ? EventName.profile_click
                                  : EventName.navTop_click,
                                parameters: {
                                  text_click: isAuthenticated
                                    ? "Profile"
                                    : target.textContent,
                                  link_sendTo: href,
                                  item_location: "Menu",
                                },
                              })
                            }

                            e.preventDefault()
                            if (href) {
                              if (href === config?.shopNow || href === config?.madeToOrder) {
                                window.open(href, "_blank")
                              } else {
                                router.push({ pathname: href })
                              }
                              onClose()
                            } else if (
                              onClick &&
                              typeof onClick === "function"
                            ) {
                              onClick(e)
                              onClose()
                            } else if (children) {
                              e.stopPropagation()
                              setSelectedNavItem(navItems[i])
                            }
                          }}
                          {...props}
                          sx={{ p: 2 }}
                        >
                          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                            {icon || null}
                          </ListItemIcon>
                          <ListItemText sx={{ my: 0 }}>
                            <Typography variant="label">{label}</Typography>
                          </ListItemText>
                          {children && (
                            <ChevronRightIcon
                              color={theme.palette.ctaPrimary.main}
                              fontSize="1.5rem"
                            />
                          )}
                        </ListItemButton>
                      </ListItem>
                    )
                  )}
                  {i !== navItems.length && (
                    <DashedDivider
                      sx={{
                        borderStyle: "dashed",
                        borderWidth: "inherit",
                        borderBottomWidth: "1px",
                        mx: 2,
                        my: 1,
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Stack>
          </Slide>
        )}

        {/* Slide in second page to show children of the selected parent nav item  */}
        {selectedNavItem && (
          <Slide
            direction="left"
            mountOnEnter
            unmountOnExit
            in={!!selectedNavItem}
            container={containerRef.current}
          >
            <Stack px={0}>
              {/* First nav item for back navigation */}
              <Stack direction="row" alignItems="center">
                <IconButton
                  onClick={() => {
                    setSelectedNavItem(null)
                  }}
                  sx={{ mx: 1 }}
                  aria-label={t("ariaLabels.back")}
                >
                  <LevelBackIcon color={theme.palette.ctaPrimary.main} />
                </IconButton>
                <Divider
                  sx={{
                    height: 30,
                    margin: "auto 0",
                    borderLeft: `1px solid ${theme.palette.dividerColor?.main}`,
                  }}
                />
                <ListItem disablePadding>
                  <ListItemButton sx={{ py: 2 }}>
                    <ListItemText sx={{ my: 0 }}>
                      <Typography variant="label" color="textSecondary">
                        {selectedNavItem?.label}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Stack>
              <DashedDivider
                sx={{
                  borderStyle: "dashed",
                  borderWidth: "inherit",
                  borderBottomWidth: "1px",
                  mx: 2,
                  my: 1,
                }}
              />

              {/* Render all children nav items for selected parent nav item */}
              {selectedNavItem?.children?.map(
                (navItems: NavBarChildType[], i) => (
                  <React.Fragment key={i}>
                    {navItems?.map(
                      (
                        { label, icon, href, onClick, props }: NavBarItemType,
                        i: number
                      ) => (
                        <ListItem key={i} disablePadding>
                          <ListItemButton
                            onClick={(
                              e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              const target = e.target as HTMLButtonElement
                              if (
                                target.textContent === t("navBarMenu.signIn")
                              ) {
                                gaLogEvent({
                                  eventName: EventName.login_click,
                                  parameters: {
                                    text_click: target.textContent,
                                    item_location: window.location.pathname,
                                  },
                                })
                              } else {
                                gaLogEvent({
                                  eventName: EventName.navTop_click,
                                  parameters: {
                                    text_click: target.textContent,
                                    link_sendTo: href,
                                    item_location: "Menu",
                                  },
                                })
                              }
                              e.preventDefault()
                              if (href) {
                                router.push({ pathname: href })
                              } else if (
                                onClick &&
                                typeof onClick === "function"
                              ) {
                                onClick(e)
                              }
                              onClose()
                            }}
                            {...props}
                            sx={{ py: 2 }}
                          >
                            {icon ? (
                              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                                {icon}
                              </ListItemIcon>
                            ) : null}
                            <ListItemText sx={{ my: 0 }}>
                              <Typography variant="label">{label}</Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      )
                    )}
                    {i !== navItems.length && (
                      <DashedDivider
                        sx={{
                          borderStyle: "dashed",
                          borderWidth: "inherit",
                          borderBottomWidth: "1px",
                          mx: 2,
                          my: 1,
                        }}
                      />
                    )}
                  </React.Fragment>
                )
              )}
            </Stack>
          </Slide>
        )}
      </List>
    </Drawer>
  )
}
