import * as React from "react"
import { SVGProps } from "react"
const SvgLevelBackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.4 8H17c.6 0 1-.4 1-1s-.4-1-1-1H7c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1s1-.4 1-1V9.4l8.3 8.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L9.4 8Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLevelBackIcon
