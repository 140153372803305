import { CacheProvider, css, Global } from "@emotion/react"
import { ThemeProvider } from "@mui/material"
import { Open_Sans } from "@next/font/google"
import { Hydrate, QueryClientProvider } from "@tanstack/react-query"
import { SwiftlykitThemeProvider, getTheme } from "swiftlykit"
import { getTheme as savemartTheme } from "../themes/saveMart"
import { getTheme as luckysupermarketsTheme } from "../themes/luckysupermarkets"
import { getTheme as foodmaxxTheme } from "../themes/foodmaxx"
import generateConfig from "../configs/config"
import { queryClient } from "../utils/queryClient"
import AppContextProvider from "./AppContext"
import AuthProvider from "./AuthContext"
import createEmotionCache from "../utils/createEmotionCache"
import "../i18n/i18n"
import React from "react"
// Emotion
export const clientSideEmotionCache = createEmotionCache()
// Font from google
const openSans = Open_Sans({ subsets: ["latin"] })

// GlobalCSS
export const globalCSS = css`
  body {
    font-family: ${openSans.style.fontFamily};
    color: #241e1e;
  }

  #__next {
    width: 100%;
  }

  #nprogress {
    z-index: 9999;
  }
`
const config = generateConfig()
let appTheme

switch (config.configBanner) {
  case "luckysupermarkets":
    appTheme = luckysupermarketsTheme(openSans.style.fontFamily)
    break
  case "foodmaxx":
    appTheme = foodmaxxTheme(openSans.style.fontFamily)
    break
  case "savemart":
    appTheme = savemartTheme(openSans.style.fontFamily)
    break
  default:
    // need a real default theme and put it here...sprys??
    appTheme = savemartTheme(openSans.style.fontFamily)
}

const swiftlykitTheme = getTheme(openSans.style.fontFamily)
/**
 * This Will wrap the component with Application dependent Providers, apply application theme and client's Emotion Styles Caching
 * @param {{
 *  pageProps?: {dehydratedState:any},
 *  emotionCache?: import("@emotion/react").EmotionCache,
 *  redirectAuthenticatedTo?: string,
 *  redirectUnauthenticatedTo?: string,
 *  requiresAuth?: boolean,
 * }}
 */
export default function Wrapper({
  children,
  pageProps,
  emotionCache = clientSideEmotionCache,
  redirectAuthenticatedTo,
  redirectUnauthenticatedTo,
  requiresAuth,
}) {
  return (
    <>
      <Global styles={globalCSS} />
      <CacheProvider value={emotionCache}>
        <SwiftlykitThemeProvider theme={swiftlykitTheme}>
          <ThemeProvider theme={appTheme}>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps?.dehydratedState}>
                <AppContextProvider>
                  <AuthProvider
                    redirectUnauthenticatedTo={redirectUnauthenticatedTo}
                    requiresAuth={requiresAuth}
                    redirectAuthenticatedTo={redirectAuthenticatedTo}
                  >
                    {children}
                  </AuthProvider>
                </AppContextProvider>
              </Hydrate>
            </QueryClientProvider>
          </ThemeProvider>
        </SwiftlykitThemeProvider>
      </CacheProvider>
    </>
  )
}
