import * as React from "react"
import { SVGProps } from "react"
const SvgStorePinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.5C5 5.364 8.363 2 12.5 2 16.637 2 20 5.369 20 9.5c0 4.657-4.623 9.509-6.725 11.715-.287.301-.527.553-.702.749a.096.096 0 0 1-.146 0c-.172-.192-.408-.44-.69-.735C9.641 19.032 5 14.166 5 9.501Zm7.5 3.955c2.07 0 3.75-1.71 3.75-3.818 0-2.11-1.68-3.819-3.75-3.819-2.071 0-3.75 1.71-3.75 3.819 0 2.108 1.679 3.818 3.75 3.818Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgStorePinIcon
