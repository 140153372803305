import { SVGProps } from "react"
const TrophyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path
      opacity="0.4"
      d="M36.5 40.9999H34.5V40.4999C34.5 38.2999 32.7 36.4999 30.5 36.4999H26V31.9199C25.5 31.9799 25 31.9999 24.5 31.9999C24 31.9999 23.5 31.9799 23 31.9199V36.4999H18.5C16.3 36.4999 14.5 38.2999 14.5 40.4999V40.9999H12.5C11.68 40.9999 11 41.6799 11 42.4999C11 43.3199 11.68 43.9999 12.5 43.9999H36.5C37.32 43.9999 38 43.3199 38 42.4999C38 41.6799 37.32 40.9999 36.5 40.9999Z"
    />
    <path
      opacity="0.4"
      d="M11.5401 23.28C10.2201 22.78 9.06006 21.96 8.14006 21.04C6.28006 18.98 5.06006 16.52 5.06006 13.64C5.06006 10.76 7.32006 8.5 10.2001 8.5H11.3201C10.8001 9.56 10.5001 10.74 10.5001 12V18C10.5001 19.88 10.8601 21.66 11.5401 23.28Z"
    />
    <path
      opacity="0.4"
      d="M43.94 13.64C43.94 16.52 42.72 18.98 40.86 21.04C39.94 21.96 38.78 22.78 37.46 23.28C38.14 21.66 38.5 19.88 38.5 18V12C38.5 10.74 38.2 9.56 37.68 8.5H38.8C41.68 8.5 43.94 10.76 43.94 13.64Z"
    />
    <path d="M30.5 4H18.5C14.08 4 10.5 7.58 10.5 12V18C10.5 25.74 16.76 32 24.5 32C32.24 32 38.5 25.74 38.5 18V12C38.5 7.58 34.92 4 30.5 4ZM30.18 16.9L28.94 18.42C28.74 18.64 28.6 19.08 28.62 19.38L28.74 21.34C28.82 22.54 27.96 23.16 26.84 22.72L25.02 22C24.74 21.9 24.26 21.9 23.98 22L22.16 22.72C21.04 23.16 20.18 22.54 20.26 21.34L20.38 19.38C20.4 19.08 20.26 18.64 20.06 18.42L18.82 16.9C18.04 15.98 18.38 14.96 19.54 14.66L21.44 14.18C21.74 14.1 22.1 13.82 22.26 13.56L23.32 11.92C23.98 10.9 25.02 10.9 25.68 11.92L26.74 13.56C26.9 13.82 27.26 14.1 27.56 14.18L29.46 14.66C30.62 14.96 30.96 15.98 30.18 16.9Z" />
    <path
      opacity="0.4"
      d="M30.18 16.8993L28.94 18.4193C28.74 18.6393 28.6 19.0793 28.62 19.3793L28.74 21.3393C28.82 22.5393 27.96 23.1593 26.84 22.7193L25.02 21.9993C24.74 21.8993 24.26 21.8993 23.98 21.9993L22.16 22.7193C21.04 23.1593 20.18 22.5393 20.26 21.3393L20.38 19.3793C20.4 19.0793 20.26 18.6393 20.06 18.4193L18.82 16.8993C18.04 15.9793 18.38 14.9593 19.54 14.6593L21.44 14.1793C21.74 14.0993 22.1 13.8193 22.26 13.5593L23.32 11.9193C23.98 10.8993 25.02 10.8993 25.68 11.9193L26.74 13.5593C26.9 13.8193 27.26 14.0993 27.56 14.1793L29.46 14.6593C30.62 14.9593 30.96 15.9793 30.18 16.8993Z"
    />
  </svg>
)

export default TrophyIcon
