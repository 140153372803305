import React from "react"
import {
  Button,
  ButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { gaLogEvent, EventName } from "utils/googleAnalyticsEvents"
import { storeList } from "mocks/handlers/storeList"

interface IStoreToggleSwitchProps {
  handleChange: (value: "1" | "2") => void
  value: "1" | "2"
  isModal?: boolean
  currentStore?: any
}

const StoreToggleSwitch = ({
  handleChange,
  value,
  isModal,
  currentStore,
}: IStoreToggleSwitchProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery("(max-width:767px)")
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1199px)")
  return (
    <ButtonGroup
      sx={{
        width: "100%",
        border: `1px solid ${theme.palette.dividerColor?.main}`,
        borderRadius: 2,
        fontWeight: 500,
        padding: "0.25rem 0.25rem",
        fontSize: "0.8125rem",
        lineHeight: "1.25rem",
        textTransform: "none",
        backgroundColor: "#EAE8E3",
        my: isMobile ? 2 : isTablet ? 0 : isModal ? 0 : 2,
        ml: isMobile ? 0 : isTablet ? 2 : isModal ? 2 : 0,
      }}
    >
      <Button
        onClick={() => {
          handleChange("1")
          gaLogEvent({
            eventName: EventName.storeLocator_listClick,
            parameters: {
              item_location: isModal ? "Modal" : "Page",
              item_message1: currentStore && currentStore.name,
              item_message2: currentStore && currentStore.address,
            },
          })
        }}
        sx={{
          width: "100%",
          backgroundColor:
            value === "2"
              ? "unset"
              : `${theme.palette.bgPrimary?.main} !important`,
          border: "none !important",
          borderRadius: "8px !important",
          fontWeight: 500,
          fontSize: "0.8125rem",
          lineHeight: "1.25rem",
          textTransform: "none",
          padding: "0.5rem 1rem",
          outline: "none",
          color: "textPrimary.main",
        }}
        data-cy="list"
        aria-label={t("stores.list")}
      >
        <Typography color={theme.palette.textPrimary.main}>
          {t("stores.list")}
        </Typography>
      </Button>
      <Button
        onClick={() => {
          handleChange("2")
          gaLogEvent({
            eventName: EventName.storeLocator_mapClick,
            parameters: {
              item_location: isModal ? "Modal" : "Page",
              item_message1: currentStore && currentStore.name,
              item_message2: currentStore && currentStore.address,
            },
          })
        }}
        sx={{
          width: "100%",
          backgroundColor:
            value === "1"
              ? "unset"
              : `${theme.palette.bgPrimary?.main} !important`,
          border: "none !important",
          borderRadius: "8px !important",
          fontWeight: 500,
          fontSize: "0.8125rem",
          lineHeight: "1.25rem",
          textTransform: "none",
          padding: "0.5rem 1rem",
          color: "textPrimary.main",
        }}
        data-cy="map"
        aria-label={t("stores.map")}
      >
        <Typography color={theme.palette.textPrimary.main}>
          {t("stores.map")}
        </Typography>
      </Button>
    </ButtonGroup>
  )
  // : (
  //   <Button
  //     onClick={() => {
  //       handleChange(value === "2" ? "1" : "2");
  //     }}
  //     sx={{
  //       display: "flex",
  //       alignItems: "center",
  //       fontWeight: 500,
  //       fontSize: "1rem",
  //       lineHeight: "1.5rem",
  //       color: `${theme.palette.ctaPrimary.main}`,
  //       margin: 0,
  //       marginTop: 3,
  //       marginBottom: 2,
  //       cursor: "pointer",
  //       p: 0,
  //     }}
  //   >
  //     <Typography color={theme.palette.ctaPrimary.main}>
  //       {value === "1" ? "VIEW ON MAP" : "VIEW ON LIST"}
  //     </Typography>
  //   </Button>
  // );
}

export default StoreToggleSwitch
