import { SvgFilledLocationIcon, SvgLocationIcon } from "@/icons"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import axios from "axios"
import { usePreviousRoute } from "components/usePreviousRoute"
import generateConfig from "configs/config"
import { useRouter } from "next/router"
import React, { FormEventHandler, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { faroPushErrors } from "utils/faroGrafanaLogs"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { ZodError, z } from "zod"
import { useAuth } from "../../contexts/AuthContext"
import styless from "../../styles/component.module.scss"
import { TextInput } from "../common/textInput/TextInput"

const generateCon = generateConfig()

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const birthMonth = months.map((month, index) => ({
  key: (index + 1).toString().padStart(2, "0"),
  value: month,
}))

export const birthDay = new Array(31).fill(0).map((a, index) => ({
  key: (index + 1).toString().padStart(2, "0"),
  value: (index + 1).toString().padStart(2, "0"),
}))

export const registerZod = z.object({
  first_name: z
    .string()
    .min(1, { message: "Required field" })
    .regex(/^[a-zA-Z- ]*$/, {
      message:
        "Name must start with a letter and contain letters, spaces and dashes only.",
    }),
  last_name: z
    .string()
    .min(1, { message: "Required field" })
    .regex(/^[a-zA-Z- ]*$/, {
      message:
        "Name must start with a letter and contain letters, spaces and dashes only.",
    }),
  email: z.string().min(1, { message: "Required field" }).email({
    message: "Email address is not in correct format, please check your input.",
  }),
  phone: z
    .string()
    .min(1, { message: "Required field" })
    .min(14, { message: "Not a valid phone number." })
    .trim(),
  zipcode: z
    .string()
    .min(1, { message: "Required field" })
    .max(5, { message: "Zipcode should be 5 digits" }),
  password: z
    .string()
    .min(1, { message: "Required field" })
    .regex(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()\[\]_+={}[\];:?\/>.<])[A-Za-z\d~!@#$%^&*()\[\]_+={}[\];:?\/>.<]{6,12}$/,
      {
        message:
          "Must be between 6 and 12 characters and must contain at least 1 character from each of the following: Characters (a-z) (A-Z), Numbers (0-9), Special characters (!, #, $, @, etc.)",
      }
    ),
})

export const styles = {
  title: {
    // marginBottom: "16px",
    color: "textPrimary.main",
  },
  pageMsgText: {
    fontSize: 14,
    fontWeight: 400,
  },
  pageTitle: {
    fontWeight: 600,
    color: "textPrimary.main",
  },
  pageSubtitle: {
    color: grey[400],
    fontSize: 16,
  },
  card: {
    borderRadius: 2,
    padding: 3,
    outline: "1px solid",
    outlineColor: grey[300],
    flex: 1,
  },
  cardMobile: {
    width: "100%",
    padding: 2,
  },
  textInput: {
    borderRadius: 40,
    ".MuiOutlinedInput-root": {
      borderRadius: 2,
      fontSize: 14,
      ".MuiInputBase-input": {
        p: 2,
      },
    },
  },
  showBtn: {
    padding: 0,
    fontSize: 14,
    color: "ctaPrimary.main",
    textDecoration: "underline",
    ":hover": {
      backgroundColor: "unset",
    },
  },
  inputLabel: {
    marginTop: 2,
    marginLeft: 2,
    fontWeight: 600,
    fontSize: "13px !important",
  },
  button: {
    width: "100%",
    padding: 2,
    color: "bgPrimary.main",
    fontSize: 15,
    lineHeight: "16px",
    backgroundColor: "ctaPrimary.main",
    marginTop: 2,
    cursor: "pointer",
  },
  setPassGetCodeButton: {
    width: "100%",
    padding: "8px 16px",
    fontSize: 15,
    lineHeight: "24px",
    cursor: "pointer",
    fontWeight: 500,
    marginTop: 2,
  },
  setPassSaveButton: {
    width: "100%",
    padding: "8px 16px",
    fontSize: 15,
    lineHeight: "24px",
    cursor: "pointer",
    fontWeight: 500,
    marginTop: 2,
    backgroundColor: "ctaPrimary.main",
    color: "bgPrimary.main",
  },
  finishDisablebutton: {
    width: "100%",
    padding: 2,
    color: "#060606",
    fontSize: 15,
    lineHeight: "16px",
    backgroundColor: "#0000000A",
    border: "unset",
    ":hover": {
      backgroundColor: "#0000000A",
      color: "#060606",
      boxShadow: "none",
      border: "unset",
    },
  },
  buttonC: {
    marginTop: 4,
  },
  finishB: {
    marginTop: 0,
  },
  outlinedButton: {
    padding: 2,
    width: "100%",
    lineHeight: "16px",
    border: "2px solid",
    borderColor: "ctaPrimary.main",
    borderRadius: 2,
    backgroundColor: "transparent",
    color: "ctaPrimary.main",
    fontWeight: 500,
    fontSize: 15,
    boxShadow: "none",
    marginTop: 2,
    ":hover": {
      backgroundColor: "ctaPrimary.main",
      color: "bgPrimary.main",
      boxShadow: "none",
      border: "2px solid",
      borderColor: "ctaPrimary.main",
    },
  },
  disableBtn: {
    padding: 2,
    width: "100%",
    lineHeight: "16px",
    borderRadius: 2,
    border: "2px solid",
    borderColor: "#5B5653",
    color: "#5B5653",
    fontWeight: 500,
    fontSize: 15,
    boxShadow: "none",
    ":hover": {
      boxShadow: "none",
      backgroundColor: "bgPrimary.main",
      border: "2px solid",
      borderColor: "#5B5653",
      color: "#5B5653",
    },
  },
  formlabel: {
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 0,
    color: "ctaPrimary.main",
    alignItems: "flex-start",
    ".MuiCheckbox-root": {
      padding: 0,
      marginRight: 1,
    },
    ".MuiTypography-root": {
      fontSize: 14,
    },
  },
  signInButton: {
    marginTop: 2,
    flex: 1,
  },
  links: {
    display: "flex",
    justifyContent: "end",
    marginTop: 3,
    gap: 1,
    width: "fit-content",
    border: "none",
    backgroundColor: "transparent",
    alignSelf: "end",
  },
  underlinedlink: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    borderBottom: "1px solid",
    borderColor: "primaryLabel.main",
    color: "primaryLabel.main",
    cursor: "pointer",
  },
  accountText: {
    whiteSpace: "pre-wrap",
    marginBottom: 2,
    color: "primaryLabel.main",
  },
  linkSent: {
    marginTop: 2,
    marginLeft: 1,
  },
  dialogContent: {
    ".MuiPaper-root": {
      padding: 3,
      borderRadius: 3,
      margin: 2,
      width: "100%",
      maxWidth: 360,
    },
  },
  dialogContentChange: {
    ".MuiPaper-root": {
      padding: 3,
      borderRadius: 3,
      margin: 2,
      width: "100%",
      maxWidth: 480,
    },
  },
  signUpMsg: {
    fontSize: 14,
    marginTop: 2,
    lineHeight: "20px",
  },
}
interface RegisterFormType {
  first_name: {
    value: string
    error?: string
  }
  last_name: {
    value: string
    error?: string
  }
  email: {
    value: string
    error?: string
  }
  phone: {
    value: string
    error?: string
  }
  password: {
    value: string
    error?: string
  }
  confirmPassword: {
    value: string
    error?: string
  }
  rememberMe: {
    value: boolean
    error?: string
  }
  zipcode: {
    value: string
    error?: string
  }
  birthday?: {
    value: Date | null
    error?: string
  }
  employeeId: {
    value: string
    error?: string
  }
  birthDay: {
    value: string
    error?: string
  }
  birthMonth: {
    value: string
    error?: string
  }
  config_banner: {
    value: string
    error: string
  }
  op: {
    value: string
    error: string
  }
  preferred_store: {
    value: string
    error: string
  }
  is_mobile: {
    value: string
    error: string
  }
  is_email_optin: {
    value: string
    error: string
  }
}

interface RegisterProps {
  requiredZipCode?: boolean
  requiredBirthday?: boolean
  onSubmit: (data: z.infer<typeof registerZod>) => void
}

const Register = ({
  requiredZipCode,
  requiredBirthday,
  onSubmit,
}: RegisterProps) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [emailSubscribed, setEmailSubscribed] = useState(true)
  const [open, setOpen] = useState(false)
  const [showEmployee, setShowEmployee] = useState(false)
  const [isMobilePhone, setIsMobilePhone] = useState(false)
  const [step, setStep] = useState(0)
  const [values, setValues] = useState<any>("")
  const {
    updateIsFromRegister,
    updateRegisterInfo,
    getRegisterInfo,
    swiftlyToken,
    initialState,
  } = useAuth()

  // useRefs for focus adjustments
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const zipcodeRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const employeeIdRef = useRef<HTMLInputElement>(null)
  const birthMonthRef = useRef<HTMLInputElement>(null)
  const birthDayRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!localStorage.hasOwnProperty("step")) {
      localStorage.setItem("step", "0")
    }
  }, [])
  const [profileFormData, setProfileFormData] =
    React.useState<RegisterFormType>({
      first_name: {
        value: "",
        error: "",
      },
      last_name: {
        value: "",
        error: "",
      },
      email: {
        value: "",
        error: "",
      },
      phone: {
        value: values,
        error: "",
      },
      password: {
        value: "",
        error: "",
      },
      confirmPassword: {
        value: "",
        error: "",
      },
      rememberMe: {
        value: false,
        error: "",
      },
      zipcode: {
        value: "",
        error: "",
      },
      employeeId: {
        value: "",
        error: "",
      },
      birthMonth: {
        value: "",
        error: "",
      },
      birthDay: {
        value: "",
        error: "",
      },
      config_banner: {
        value: `${initialState?.envVariables?.configBanner}`,
        error: "",
      },
      op: {
        value: "submit",
        error: "",
      },
      preferred_store: {
        value: "",
        error: "",
      },
      is_mobile: {
        value: "",
        error: "",
      },
      is_email_optin: {
        value: "",
        error: "",
      },
    })

  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(767))
  const isDesktop = useMediaQuery(theme.breakpoints.up(768))
  const isTablet = !isMobile && !isDesktop
  const router = useRouter()
  const [isEmployeeExist, setIsEmployeeExist] = useState<boolean>(false)
  const [emailSubscribedCheck, setEmailSubscribedCheck] = useState<any>({
    isEmailSubscribed: false,
    emailSubscribedErroMessage: "",
    checkingIsEmployee: false,
  })
  const [phoneExistCheck, setphoneExistCheck] = useState<any>({
    isPhoneSubscribed: false,
    phoneSubscribedErroMessage: "",
    checkingIsEmployee: false,
  })

  let stepValue

  if (typeof window !== "undefined" && window.localStorage) {
    stepValue = localStorage.getItem("step")
  }

  const [isEmployeeExistErrorMsg, setIsEmployeeExistErrorMsg] = useState<
    string | null
  >("")
  const previousRoute = usePreviousRoute()

  const normalizeInput = (value: any, previousValue: any) => {
    if (!value) return value

    const currentValue = value.replace(/[^\d]/g, "")
    const cvLength = currentValue.length

    if (!previousValue || value.length >= previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    }
  }

  const handleChange = ({ target: { value } }: any) => {
    setValues((prev: any) => normalizeInput(value, prev))
  }

  React.useEffect(() => {
    const getPreviousUrl = localStorage.getItem("getPreviousUrl")
    if (getPreviousUrl == "/stores" || getPreviousUrl == "/register") {
      setValues((prev: any) =>
        normalizeInput(getRegisterInfo?.phone, prev?.phone?.value)
      )
      setProfileFormData((prev) => ({
        ...prev,
        first_name: {
          ...prev.first_name,
          value: getRegisterInfo?.first_name,
          error: "",
        },
        last_name: {
          ...prev.last_name,
          value: getRegisterInfo?.last_name,
          error: "",
        },
        email: {
          ...prev.email,
          value: getRegisterInfo?.email,
          error: "",
        },
        phone: {
          ...prev.phone,
          value: getRegisterInfo?.phone,
          error: "",
        },
        is_mobile: {
          ...prev.is_mobile,
          value: getRegisterInfo?.is_mobile,
          error: "",
        },
        zipcode: {
          ...prev.zipcode,
          value: getRegisterInfo?.zipcode,
          error: "",
        },
        password: {
          ...prev.zipcode,
          value: getRegisterInfo?.password,
          error: "",
        },
        is_email_optin: {
          ...prev.is_email_optin,
          value: getRegisterInfo?.is_email_optin,
          error: "",
        },
        employeeId: {
          ...prev.employeeId,
          value: getRegisterInfo?.employeeId,
          error: "",
        },
        preferred_store: {
          ...prev.preferred_store,
          value: getRegisterInfo?.preferred_store,
          error: "",
        },
      }))
    }

    if (getRegisterInfo?.is_mobile === "1") {
      setIsMobilePhone(true)
    } else {
      setIsMobilePhone(false)
    }
    if (getRegisterInfo?.is_email_optin === "0") {
      setEmailSubscribed(false)
    } else {
      setEmailSubscribed(true)
    }
  }, [])

  useEffect(() => {
    setProfileFormData((prev) => ({
      ...prev,
      phone: {
        ...prev.phone,
        value: values,
        error: "",
      },
    }))
  }, [values])

  React.useEffect(() => {
    if (
      profileFormData.employeeId.value &&
      profileFormData.birthMonth.value &&
      profileFormData.birthDay.value
    ) {
      const request = {
        employee_id: profileFormData.employeeId.value,
        date_of_birth: `${profileFormData.birthMonth.value}-${profileFormData.birthDay.value}`,
      }
      try {
        setEmailSubscribedCheck((prevState: any) => ({
          ...prevState,
          checkingIsEmployee: true,
        }))
        const sToken = `${swiftlyToken?.access_token}`
        axios
          .post(
            "/api/isEmployee",
            { data: request },
            {
              headers: {
                Authorization: sToken,
              },
            }
          )
          .then(async (result: any) => {
            if (
              result?.data?.status_code === "100" ||
              result?.data?.status_code === "1"
            ) {
              setIsEmployeeExist(true)
              setIsEmployeeExistErrorMsg(result?.data?.user_message)
            } else {
              setIsEmployeeExist(false)
              setIsEmployeeExistErrorMsg("")
            }
            setEmailSubscribedCheck((prevState: any) => ({
              ...prevState,
              checkingIsEmployee: false,
            }))
          })
      } catch (error: any) {
        setEmailSubscribedCheck((prevState: any) => ({
          ...prevState,
          checkingIsEmployee: false,
        }))
        setIsEmployeeExist(true)
        setIsEmployeeExistErrorMsg("Failed to connect")
        console.error("ISEMPLOYEE ERROR", error)
      }
    }
  }, [
    profileFormData.employeeId.value,
    profileFormData.birthMonth.value,
    profileFormData.birthDay.value,
    swiftlyToken?.access_token,
  ])

  React.useEffect(() => {
    if (showEmployee) {
      updateRegisterInfo({
        is_email_optin: emailSubscribed ? "1" : "0",
        is_mobile: isMobilePhone ? "1" : "0",
        first_name: profileFormData.first_name.value,
        last_name: profileFormData.last_name.value,
        phone: profileFormData.phone.value?.replace(/[^0-9]+/g, ""),
        email: profileFormData.email.value,
        zipcode: profileFormData.zipcode.value,
        password: profileFormData.password.value,
        preferred_store: profileFormData.preferred_store.value,
        config_banner: `${initialState?.envVariables?.configBanner}`,
        employee_id: profileFormData.employeeId.value,
        employee_birth_month: profileFormData.birthMonth.value,
        employee_birth_date: profileFormData.birthDay.value,
      })
    } else {
      updateRegisterInfo({
        is_email_optin: emailSubscribed ? "1" : "0",
        is_mobile: isMobilePhone ? "1" : "0",
        first_name: profileFormData.first_name.value,
        last_name: profileFormData.last_name.value,
        phone: profileFormData.phone.value?.replace(/[^0-9]+/g, ""),
        email: profileFormData.email.value,
        zipcode: profileFormData.zipcode.value,
        password: profileFormData.password.value,
        preferred_store: profileFormData.preferred_store.value,
        config_banner: `${initialState?.envVariables?.configBanner}`,
      })
    }
  }, [profileFormData, previousRoute])

  const validateField = async <Key extends keyof RegisterFormType>(
    field: Key
  ): Promise<boolean> => {
    const request = {
      key: field,
      value: profileFormData[field]?.value,
    }

    try {
      const result = await axios.post(
        `${generateCon.registerBaseUrl}/v1-loyalty-signup/validate`,
        request
      )

      if (result.data.return_code === "1") {
        setProfileFormData((prev) => ({
          ...prev,
          [field]: {
            value: profileFormData[field]?.value,
            error: result.data.user_message,
          },
        }))
      }

      return true
    } catch (error: any) {
      faroPushErrors(
        error?.response?.data ? error?.response?.data : "Failed to connect"
      )

      setProfileFormData((prev) => ({
        ...prev,
        [field]: {
          value: profileFormData[field]?.value,
          error: error?.response?.data
            ? error?.response?.data
            : "Failed to connect",
        },
      }))

      return false
    }
  }

  const loyaltyExistCheck = async () => {
    const request = {
      email: profileFormData.email.value,
      phone: profileFormData.phone.value?.replace(/[^0-9]+/g, ""),
    }

    try {
      const result = await axios.post(
        `${generateCon.registerBaseUrl}/v1-loyalty-signup/loyalty-exists`,
        request
      )

      return loyaltyErrorCheck(result)
    } catch (err: any) {
      setphoneExistCheck((prevState: any) => ({
        ...prevState,
        isPhoneSubscribed: true,
        phoneSubscribedErroMessage: err?.response?.data
          ? err?.response?.data
          : "Failed to connect",
      }))
      setEmailSubscribedCheck((prevState: any) => ({
        ...prevState,
        isEmailSubscribed: true,
      }))
      setProfileFormData((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          value: profileFormData.email.value,
          error: err?.response?.data
            ? err?.response?.data
            : "Failed to connect",
        },
      }))

      return false
    }
  }

  const loyaltyErrorCheck = (result: any) => {
    const statusCode = result?.data?.status_code
    const returnCode = result?.data?.return_code

    if (returnCode === "1") {
      setphoneExistCheck((prevState: any) => ({
        ...prevState,
        isPhoneSubscribed: true,
        phoneSubscribedErroMessage: result.data.user_message,
      }))
      setEmailSubscribedCheck((prevState: any) => ({
        ...prevState,
        isEmailSubscribed: true,
        emailSubscribedErroMessage: result.data.user_message,
      }))
      return true
    } else {
      switch (statusCode) {
        case "101":
          setphoneExistCheck((prevState: any) => ({
            ...prevState,
            isPhoneSubscribed: true,
            phoneSubscribedErroMessage: result.data.user_message,
          }))
          return true
        case "102":
          setEmailSubscribedCheck((prevState: any) => ({
            ...prevState,
            isEmailSubscribed: true,
          }))
          setProfileFormData((prev) => ({
            ...prev,
            email: {
              ...prev.email,
              value: profileFormData.email.value,
              error: result.data.user_message,
            },
          }))
          return true
        case "100":
          setphoneExistCheck((prevState: any) => ({
            ...prevState,
            isPhoneSubscribed: true,
            phoneSubscribedErroMessage: result.data.user_message,
          }))
          setEmailSubscribedCheck((prevState: any) => ({
            ...prevState,
            isEmailSubscribed: true,
          }))
          setProfileFormData((prev) => ({
            ...prev,
            email: {
              ...prev.email,
              value: profileFormData.email.value,
              error: result.data.user_message,
            },
          }))
          return true
        default:
          setphoneExistCheck((prevState: any) => ({
            ...prevState,
            isPhoneSubscribed: false,
            phoneSubscribedErroMessage: "",
          }))
          setEmailSubscribedCheck((prevState: any) => ({
            ...prevState,
            isEmailSubscribed: false,
          }))
          return false
      }
    }
  }

  const handleCancelModel = () => {
    setOpen(!open)
  }

  const handleSelectStore = (e: any) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      updateIsFromRegister(true)
      router.push("/stores")
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    })
  }, [step === 1])

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault()
      updateIsFromRegister(false)
      handleCancelModel()
    }
  }

  const onFormSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    registerZod
      .parseAsync({
        is_email_optin: emailSubscribed,
        first_name: profileFormData.first_name.value,
        last_name: profileFormData.last_name.value,
        phone: profileFormData.phone.value,
        zipcode: profileFormData.zipcode.value,
        email: profileFormData.email.value,
        password: profileFormData.password.value,
        employee_id: profileFormData.employeeId.value,
        employee_birth_month: profileFormData.birthMonth.value,
        employee_birth_date: profileFormData.birthDay.value,
        config_banner: `${initialState?.envVariables?.configBanner}`,
        op: "submit",
        preferred_store: profileFormData.preferred_store.value,
        is_mobile: isMobilePhone,
        isEmployee: showEmployee,
      })
      .then(async (a) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof RegisterFormType] = {
              ...prev[key as keyof RegisterFormType],
              error: undefined,
            }
            return acc
          }, {} as RegisterFormType)
        })
        for (const aKey in a) {
          if (a[aKey as keyof typeof a] === undefined)
            delete a[aKey as keyof typeof a]
        }
        const checkFields: (keyof RegisterFormType)[] = [
          "first_name",
          "last_name",
          "email",
          "password",
        ]
        const validations = checkFields.map(async (field) => {
          return await validateField(field as keyof RegisterFormType)
        })
        const isValid = (await Promise.all(validations)).every(Boolean)
        if (!isValid) {
          window.scrollTo({
            top: document.getElementById("email")?.offsetTop,
            behavior: "smooth",
          })
          // exit early
          return
        }
        const loyaltyCheckResult = await loyaltyExistCheck()

        if (loyaltyCheckResult) {
          window.scrollTo({
            top: document.getElementById("email")?.offsetTop,
            behavior: "smooth",
          })
          // exit early
          return
        }
        if (step === 0) {
          const jsonValue = showEmployee ?
            {
                is_email_optin: emailSubscribed ? "1" : "0",
                is_mobile: isMobilePhone ? "1" : "0",
                first_name: profileFormData.first_name.value,
                last_name: profileFormData.last_name.value,
                phone: (profileFormData.phone.value || '').replace(/\D/g,''),
                email: profileFormData.email.value,
                zipcode: profileFormData.zipcode.value,
                password: profileFormData.password.value,
                preferred_store: profileFormData.preferred_store.value,
                config_banner: `${initialState?.envVariables?.configBanner}`,
                employee_id: profileFormData.employeeId.value,
                employee_birth_month: profileFormData.birthMonth.value,
                employee_birth_date: profileFormData.birthDay.value,
            } : {
             is_email_optin: emailSubscribed ? "1" : "0",
             is_mobile: isMobilePhone ? "1" : "0",
             first_name: profileFormData.first_name.value,
             last_name: profileFormData.last_name.value,
             phone: (profileFormData.phone.value || '').replace(/\D/g,''),
             email: profileFormData.email.value,
             zipcode: profileFormData.zipcode.value,
             password: profileFormData.password.value,
             preferred_store: profileFormData.preferred_store.value,
             config_banner: `${initialState?.envVariables?.configBanner}`,
           }
          updateRegisterInfo(jsonValue)
          if (
            !showEmployee &&
            !emailSubscribedCheck.isEmailSubscribed &&
            !phoneExistCheck.isPhoneSubscribed
          ) {
            setStep(1)
            localStorage.setItem("step", "1")
            localStorage.setItem("userInfo", JSON.stringify(jsonValue))
          } else if (
            showEmployee &&
            isEmployeeExistErrorMsg == "" &&
            profileFormData.employeeId.value &&
            profileFormData.birthMonth.value &&
            profileFormData.birthDay.value &&
            !emailSubscribedCheck.isEmailSubscribed &&
            !phoneExistCheck.isPhoneSubscribed &&
            !emailSubscribedCheck.checkingIsEmployee
          ) {
            setStep(1)
            localStorage.setItem("step", "1")
            localStorage.setItem("userInfo", JSON.stringify(jsonValue))
          } else {
            setIsEmployeeExist(true)
            setIsEmployeeExistErrorMsg("This field is required.")
            setProfileFormData((prev) => ({
              ...prev,
              email: {
                ...prev.email,
                value: profileFormData.email.value,
                error:
                  profileFormData.email.error ??
                  emailSubscribedCheck.emailSubscribedErroMessage,
              },
            }))
            window.scrollTo({
              top: document.getElementById("email")?.offsetTop,
              behavior: "smooth",
            })
          }
        } else {
          onSubmit(a)
        }
      })
      .catch((err: ZodError) => {
        if (err && err.errors.length) {
          const errorPath = err.errors[0]?.path[0]
          switch (errorPath) {
            case "first_name":
              firstNameRef?.current?.focus()
              break
            case "last_name":
              lastNameRef?.current?.focus()
              break
            case "email":
              emailRef?.current?.focus()
              break
            case "phone":
              phoneRef?.current?.focus()
              break
            case "zipcode":
              zipcodeRef?.current?.focus()
              break
            case "password":
              passwordRef?.current?.focus()
              break
            case "employeeId":
              employeeIdRef?.current?.focus()
              break
            case "birthMonth":
              birthMonthRef?.current?.focus()
              break
            case "birthDay":
              birthDayRef?.current?.focus()
              break
            // Add more cases for other fields if needed
            default:
              break
          }
        }

        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof RegisterFormType] = {
              ...prev[key as keyof RegisterFormType],
              error: err.issues.find((issue) => issue.path[0] === key)?.message,
            }
            return acc
          }, {} as RegisterFormType)
        })

        window.scrollTo({
          top: document.getElementById(err.errors[0].path[0].toString())
            ?.offsetTop,
          behavior: "smooth",
        })
      })
  }
  const handleBlur = async (field: keyof RegisterFormType) => {
    if (field !== "phone") {
      const result = await validateField(field)

      if (!result) {
        console.log(`Validation error for field ${field}`)
        return
      }
    }

    // if we blurred the phone or email, we now need to check if we can do the loyalty check
    if (
      (field === "phone" || field === "email") &&
      !profileFormData.email.error &&
      !phoneExistCheck.phoneSubscribedErroMessage &&
      !profileFormData.phone.error &&
      profileFormData.email?.value?.length > 0 &&
      profileFormData.phone?.value?.length > 0
    ) {
      const loyaltyCheckResult = await loyaltyExistCheck()

      if (loyaltyCheckResult) {
        window.scrollTo({
          top: document.getElementById("email")?.offsetTop,
          behavior: "smooth",
        })
        // exit early
        return
      }
    }
  }

  return (
    <div aria-live="polite">
      {stepValue === "0" ? (
        <>
          <Box
            mt={3}
            sx={{ display: "flex", justifyContent: "center" }}
            className={styless.formSect}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // ...(isMobile ? styles.cardMobile : {}),
              }}
            >
              <Typography
                variant="h1"
                fontSize={isMobile ? "20px" : "24px"}
                fontWeight={600}
                sx={styles.pageTitle}
              >
                {t("register.pageTitle")}
              </Typography>
              <Box
                width={isMobile ? "100%" : 600}
                marginTop={3}
                sx={{
                  backgroundColor: `${theme.palette.bgPrimary.main}`,
                  ...(isMobile
                    ? { ...styles.card, ...styles.cardMobile }
                    : { ...styles.card }),
                }}
              >
                <form onSubmit={onFormSubmit}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h2"
                      fontSize="20px"
                      fontWeight={700}
                      sx={styles.title}
                    >
                      {t("register.title")}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ ...styles.formlabel, marginBottom: "18px" }}
                    >
                      {t("register.pageSubtitle")}
                    </Typography>
                    <div id="first_name">
                      <TextInput
                        data-cy="register-first-name"
                        aria-required={true}
                        autoComplete="off"
                        label={t("register.first_name")}
                        value={profileFormData.first_name.value}
                        sx={styles.textInput}
                        labelSx={styles.inputLabel}
                        onChange={(e) => {
                          setProfileFormData((prev) => ({
                            ...prev,
                            first_name: {
                              ...prev.first_name,
                              value: e.target.value,
                              error: "",
                            },
                          }))
                        }}
                        onBlur={() =>
                          profileFormData.first_name.value.length &&
                          handleBlur("first_name")
                        }
                        error={!!profileFormData.first_name.error}
                        helperText={profileFormData.first_name.error}
                        inputProps={{
                          maxLength: 50,
                          ref: firstNameRef,
                        }}
                      />
                    </div>
                    <div id="last_name">
                      <TextInput
                        data-cy="register-last-name"
                        label={t("register.last_name")}
                        autoComplete="off"
                        aria-required={true}
                        value={profileFormData.last_name.value}
                        sx={styles.textInput}
                        labelSx={styles.inputLabel}
                        onChange={(e) => {
                          setProfileFormData((prev) => ({
                            ...prev,
                            last_name: {
                              ...prev.last_name,
                              value: e.target.value,
                              error: "",
                            },
                          }))
                        }}
                        onBlur={() =>
                          profileFormData.last_name.value.length &&
                          handleBlur("last_name")
                        }
                        error={!!profileFormData.last_name.error}
                        helperText={profileFormData.last_name.error}
                        inputProps={{
                          maxLength: 50,
                          ref: lastNameRef,
                        }}
                      />
                    </div>
                    <div id="email">
                      <TextInput
                        data-cy="register-email"
                        label={t("register.email")}
                        aria-required={true}
                        value={profileFormData.email.value}
                        sx={styles.textInput}
                        labelSx={styles.inputLabel}
                        autoComplete="off"
                        onChange={(e) => {
                          setProfileFormData((prev) => ({
                            ...prev,
                            email: {
                              ...prev.email,
                              value: e.target.value,
                              error: "",
                            },
                          }))
                          setEmailSubscribedCheck((prev: any) => ({
                            ...prev,
                            isEmailSubscribed: false,
                            emailSubscribedErroMessage: "",
                          }))
                        }}
                        onBlur={() =>
                          profileFormData.email.value.length &&
                          handleBlur("email")
                        }
                        error={
                          !!profileFormData.email.error ??
                          !!emailSubscribedCheck.isEmailSubscribed
                        }
                        helperText={
                          profileFormData.email.error ??
                          emailSubscribedCheck.emailSubscribedErroMessage
                        }
                        inputProps={{
                          maxLength: 50,
                          ref: emailRef,
                        }}
                      />
                    </div>
                    <div id="phone">
                      <TextInput
                        data-cy="register-phone"
                        label={t("register.phone")}
                        autoComplete="off"
                        aria-required={true}
                        value={values}
                        sx={styles.textInput}
                        labelSx={styles.inputLabel}
                        onChange={(e) => {
                          setProfileFormData((prev) => ({
                            ...prev,
                            phone: {
                              ...prev.phone,
                              error: "",
                            },
                          }))
                          setphoneExistCheck((prev: any) => ({
                            ...prev,
                            isPhoneSubscribed: false,
                            phoneSubscribedErroMessage: "",
                          }))
                          handleChange(e)
                        }}
                        onBlur={() =>
                          profileFormData.phone.value.length &&
                          handleBlur("phone")
                        }
                        error={
                          !!profileFormData.phone.error ||
                          !!phoneExistCheck.isPhoneSubscribed
                        }
                        helperText={
                          profileFormData.phone.error ||
                          phoneExistCheck.phoneSubscribedErroMessage
                        }
                        inputProps={{
                          maxLength: 14,
                          ref: phoneRef,
                        }}
                      />
                    </div>
                    <FormControlLabel
                      sx={{
                        ...styles.formlabel,
                        marginTop: "-8px",
                        marginBottom: "22px",
                      }}
                      control={
                        <Checkbox
                          name="mobile"
                          color="ctaPrimary"
                          size="small"
                          tabIndex={0}
                          onClick={(e: any) =>
                            setIsMobilePhone(e.target.checked)
                          }
                        />
                      }
                      role="button"
                      label={t("register.isMobile")}
                    />

                    {/* {requiredZipCode && ( */}
                    <div id="zipcode">
                      <TextInput
                        data-cy="register-zipcode"
                        label={t("register.zipcode")}
                        autoComplete="off"
                        aria-required={true}
                        value={profileFormData.zipcode?.value}
                        sx={styles.textInput}
                        labelSx={styles.inputLabel}
                        onChange={(e) => {
                          setProfileFormData((prev) => ({
                            ...prev,
                            zipcode: {
                              ...prev.zipcode,
                              value: e.target.value.replace(/[^0-9]/g, ""),
                              error: "",
                            },
                          }))
                        }}
                        error={!!profileFormData.zipcode?.error}
                        helperText={profileFormData.zipcode?.error}
                        inputProps={{
                          maxLength: 5,
                          ref: zipcodeRef,
                        }}
                      />
                    </div>
                    {/* )} */}
                    <div id="password">
                      <TextInput
                        data-cy="register-password"
                        label={t("register.password")}
                        autoComplete="off"
                        aria-required={true}
                        value={profileFormData.password.value}
                        sx={styles.textInput}
                        labelSx={styles.inputLabel}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={styles.showBtn}
                                aria-label={
                                  showPassword
                                    ? t("ariaLabels.hide")
                                    : t("ariaLabels.show")
                                }
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                {showPassword ? "Hide" : "Show"}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setProfileFormData((prev) => ({
                            ...prev,
                            password: {
                              ...prev.password,
                              value: e.target.value,
                              error: "",
                            },
                          }))
                        }}
                        onBlur={() =>
                          profileFormData.password.value.length &&
                          handleBlur("password")
                        }
                        error={!!profileFormData.password.error}
                        helperText={profileFormData.password.error}
                        inputProps={{
                          maxLength: 50,
                          ref: passwordRef,
                        }}
                      />
                    </div>
                    {requiredBirthday && (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h6"
                          sx={{ ...styles.inputLabel, marginTop: 2 }}
                        >
                          {t("register.birthday")}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            renderInput={(params) => {
                              const modifiedParams = {
                                ...params,
                                sx: {
                                  ...params.sx,
                                  ...styles.textInput,
                                  marginTop: 1,
                                },
                                error: !!profileFormData.birthday?.error,
                                helperText: profileFormData.birthday?.error,
                              }
                              return <TextField {...modifiedParams} />
                            }}
                            value={profileFormData.birthday?.value}
                            onChange={(newValue) => {
                              setProfileFormData((prev) => ({
                                ...prev,
                                birthday: {
                                  ...prev.birthday,
                                  value: new Date((newValue as Date) || null),
                                },
                              }))
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    )}
                    <Stack
                      sx={{ fontSize: 13, marginTop: "-16px" }}
                      ml={2}
                      color="textSecondary.main"
                    >
                      <Typography>{t("register.passwordMust")}</Typography>
                      <ul className={styless.listItems}>
                        <li>{t("register.letters")}</li>
                        <li>{t("register.numbers")}</li>
                        <li>{t("register.specialCharacters")}</li>
                      </ul>
                    </Stack>
                    <Stack>
                      <div>
                        <FormControlLabel
                          sx={{ ...styles.formlabel, marginTop: "16px" }}
                          role="button"
                          control={
                            <Checkbox
                              name="subscribeViaEmail"
                              color="ctaPrimary"
                              size="small"
                              tabIndex={0}
                              defaultChecked={emailSubscribed}
                              onClick={(e: any) =>
                                setEmailSubscribed(e.target.checked)
                              }
                            />
                          }
                          label={t("register.subscribeViaEmail")}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          sx={styles.formlabel}
                          role="button"
                          control={
                            <Checkbox
                              name="employee"
                              tabIndex={0}
                              color="ctaPrimary"
                              size="small"
                              onClick={(e: any) =>
                                setShowEmployee(e.target.checked)
                              }
                            />
                          }
                          label={t("register.employee")}
                        />
                      </div>
                    </Stack>

                    {showEmployee ? (
                      <>
                        <TextInput
                          label={t("register.employeeId")}
                          value={profileFormData.employeeId.value}
                          sx={styles.textInput}
                          autoComplete="off"
                          labelSx={styles.inputLabel}
                          onChange={(e) => {
                            setProfileFormData((prev) => ({
                              ...prev,
                              employeeId: {
                                ...prev.employeeId,
                                value: e.target.value.replace(/[^0-9]/g, ""),
                              },
                            }))
                          }}
                          error={isEmployeeExist}
                          helperText={isEmployeeExistErrorMsg}
                          inputProps={{
                            maxLength: 50,
                            ref: employeeIdRef,
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            flexDirection:
                              isMobile || isTablet ? "column" : "Row",
                          }}
                        >
                          <TextInput
                            className={styless.selectInput}
                            select
                            label={t("register.birthMonth")}
                            autoComplete="off"
                            placeholder={t("register.selectMonth")}
                            sx={styles.textInput}
                            labelSx={styles.inputLabel}
                            fullWidth
                            value={
                              profileFormData.birthMonth.value != ""
                                ? profileFormData.birthMonth.value
                                : null
                            }
                            onChange={(e: any) => {
                              const ele = e
                              setProfileFormData((prev) => ({
                                ...prev,
                                birthMonth: {
                                  ...prev.birthMonth,
                                  value: ele.target.value,
                                },
                              }))
                            }}
                            error={!!profileFormData.birthMonth.error}
                            helperText={profileFormData.birthMonth.error}
                            inputProps={{
                              ref: birthMonthRef,
                            }}
                          >
                            {birthMonth?.map((e: any) => (
                              <MenuItem key={e.key} value={e.key}>
                                {e.value}
                              </MenuItem>
                            ))}
                          </TextInput>

                          <TextInput
                            className={styless.selectInput}
                            select
                            label={t("register.birthDay")}
                            autoComplete="off"
                            sx={styles.textInput}
                            placeholder={t("register.selectDay")}
                            labelSx={styles.inputLabel}
                            fullWidth
                            value={
                              profileFormData.birthDay.value != ""
                                ? profileFormData.birthDay.value
                                : null
                            }
                            onChange={(e: any) => {
                              const ele = e
                              setProfileFormData((prev) => ({
                                ...prev,
                                birthDay: {
                                  ...prev.birthDay,
                                  value: ele.target.value,
                                },
                              }))
                            }}
                            error={!!profileFormData.birthDay.error}
                            helperText={profileFormData.birthDay.error}
                            inputProps={{
                              ref: birthDayRef,
                            }}
                          >
                            {birthDay?.map((e: any) => (
                              <MenuItem key={e.key} value={e.key}>
                                {e.value}
                              </MenuItem>
                            ))}
                          </TextInput>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                    <Button
                      data-cy="register-btn-continue"
                      variant="contained"
                      color="ctaPrimary"
                      sx={{ ...styles.button, ...styles.buttonC }}
                      type="submit"
                      aria-label={t("register.continue")}
                    >
                      {t("register.continue")}
                    </Button>

                    <Typography
                      variant="paragraph"
                      textAlign="center"
                      sx={styles.signUpMsg}
                    >
                      {t("register.iAgree")}
                      <span>
                        {" "}
                        <Link
                          href="/legal/privacy-policy"
                          sx={{
                            color: "ctaPrimary.main",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                          target="_blank"
                          aria-label={`${t("ariaLabels.open")} ${
                            process.env.NEXT_PUBLIC_SITE_DOMAIN
                          }/legal/privacy-policy ${t("ariaLabels.inNewTab")}`}
                        >
                          {t("register.privacyPolicy")}
                        </Link>{" "}
                      </span>
                      <span> {t("register.and")} </span>
                      <span>
                        {" "}
                        <Link
                          href="/legal/terms-and-conditions"
                          sx={{
                            color: "ctaPrimary.main",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                          target="_blank"
                          aria-label={`${t("ariaLabels.open")} ${
                            process.env.NEXT_PUBLIC_SITE_DOMAIN
                          }/legal/terms-and-conditions ${t(
                            "ariaLabels.inNewTab"
                          )}`}
                        >
                          {t("register.termsConditions")}
                        </Link>{" "}
                      </span>
                    </Typography>
                  </FormControl>
                </form>
              </Box>
              <Divider
                sx={{
                  borderStyle: "dashed",
                  marginTop: 3,
                  display: isMobile ? "block" : "none",
                }}
              />

              <Box
                width={isMobile ? "100%" : 600}
                marginTop={3}
                sx={{
                  backgroundColor: `${theme.palette.bgPrimary.main}`,
                  ...(isMobile
                    ? { ...styles.card, ...styles.cardMobile }
                    : { ...styles.card }),
                }}
              >
                <Typography
                  variant="h2"
                  fontSize="18px"
                  fontWeight={600}
                  mb={1}
                >
                  {t("register.login")}
                </Typography>
                <Button
                  aria-label={t("login.title")}
                  variant="contained"
                  color="ctaSuccess"
                  sx={styles.outlinedButton}
                  type="submit"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    const target = e.target as HTMLButtonElement
                    gaLogEvent({
                      eventName: EventName.login_click,
                      parameters: {
                        text_click: target.textContent,
                        item_location: window.location.pathname,
                      },
                    })
                    router.push("/login")
                  }}
                >
                  {t("login.title")}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            mt={3}
            sx={{ display: "flex", justifyContent: "center" }}
            className={styless.formSect}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h1"
                fontSize="24px"
                fontWeight={600}
                sx={styles.pageTitle}
              >
                {t("register.pageTitle")}
              </Typography>

              <Box
                width={isMobile ? "100%" : 480}
                marginTop={3}
                sx={{
                  backgroundColor: `${theme.palette.bgPrimary.main}`,
                  ...(isMobile
                    ? { ...styles.card, ...styles.cardMobile }
                    : { ...styles.card }),
                }}
              >
                <form onSubmit={onFormSubmit}>
                  <FormControl
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h4" sx={styles.title}>
                      {t("register.lastStep")}
                    </Typography>

                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={7}
                      mb={7}
                    >
                      <SvgLocationIcon width="100%" />
                      <SvgLocationIcon width="100%" />
                      <SvgLocationIcon width="100%" />
                      <SvgFilledLocationIcon width="100%" />
                      <SvgLocationIcon width="100%" />
                      <SvgLocationIcon width="100%" />
                      <SvgLocationIcon width="100%" />
                    </Box>
                    <Typography
                      variant="paragraph"
                      sx={{
                        ...styles.title,
                        marginBottom: 1,
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {t("register.lastStepMsg")}
                    </Typography>
                    <Link
                      data-cy="register-select-store"
                      aria-label={t("register.selectStore")}
                      sx={styles.button}
                      onClick={handleSelectStore}
                      onKeyDown={handleSelectStore}
                      tabIndex={0}
                      role="button"
                    >
                      {t("register.selectStore")}
                    </Link>
                    <Typography
                      variant="body1"
                      color="#5A5A5A"
                      sx={{
                        ...styles.formlabel,
                        marginTop: 3,
                        marginBottom: 2,
                      }}
                    >
                      {t("register.or")}
                    </Typography>
                    <Button
                      variant="text"
                      tabIndex={0}
                      role="button"
                      // color="ctaPrimary.main"
                      sx={styles.formlabel}
                      className={styless.cursor}
                      onKeyDown={handleKeyDown}
                      onClick={async () => {
                        updateIsFromRegister(false)
                        handleCancelModel()
                      }}
                    >
                      {t("register.cancelSignUp")}
                    </Button>

                    {/* CancelSignUpModal */}
                    <Dialog
                      aria-labelledby="cancelSignUp"
                      open={open}
                      sx={styles.dialogContent}
                    >
                      <Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography
                            variant="h4"
                            sx={styles.title}
                            id="cancelSignUp"
                          >
                            {t("register.cancelSignUpQue")}
                          </Typography>
                        </Stack>
                      </Stack>
                      <DialogContent
                        sx={{
                          marginTop: 1,
                        }}
                      >
                        <Box>
                          <Typography variant="body" sx={styles.pageMsgText}>
                            {t("register.cancelSignUpMsg")}
                          </Typography>
                          <form onSubmit={onFormSubmit}>
                            <FormControl
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="ctaPrimary"
                                sx={{ ...styles.button, marginTop: 3 }}
                                // type="submit"
                                onClick={handleCancelModel}
                                aria-label={t("register.continueSignUp")}
                              >
                                {t("register.continueSignUp")}
                              </Button>
                              <Link
                                textAlign="center"
                                mt={3}
                                role="button"
                                color="ctaFailed.main"
                                className={styless.cursor}
                                onClick={() => {
                                  router.push("/login")
                                }}
                                tabIndex={0}
                                onKeyDown={(event: any) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault()
                                    router.push("/login")
                                  }
                                }}
                              >
                                {t("register.yesCancelSignUp")}
                              </Link>
                            </FormControl>
                          </form>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </FormControl>
                </form>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </div>
  )
}

export default Register
