import axios from "axios"
import { com, swiftly } from "../client-data-bom"
import refreshApiSetupInitWithNewToken from "../utils/refreshApiSetupInitWithNewToken"
import getSwiftyAuthHeader from "../utils/getSwiftyAuthHeader"
import generateConfig from "configs/config"

const config = generateConfig()
// Host that connects to DB
export const HOST_NAME = config.rebatesHostName

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  claimCoupon,
  fetchCoupons,
  fetchRebateListWithState,
  fetchRebateListCustomerWithState,
  fetchRebateList,
  fetchRebateListCustomer,
  fetchCouponsById,
  fetchRebateDetails,
  fetchRebateDetailsCustomer,
  fetchClaimedAndRedeemedOffers,
  fetchClippedData,
  fetchRedeemedData,
  fetchWallet,
}

// TODO(add pagination)
export async function fetchClaimedAndRedeemedOffers(): Promise<swiftly.offers.SwiftlyJsSwiftlyClaimedAndRedeemedOffers> {
  await refreshApiSetupInitWithNewToken()
  return await swiftly.offers.fetchClaimedAndRedeemedOffers(
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    (_: com.swiftly.feature.offers.data.PageableOfferListRequestBuilder) => {}
  )
}

// TODO(add pagination)
export async function fetchCoupons(): Promise<
  Array<swiftly.offers.SwiftlyJsSwiftlyOffer>
> {
  await refreshApiSetupInitWithNewToken()
  return await swiftly.offers.fetchRankedOffers(
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    (_: com.swiftly.feature.offers.data.OfferListRequestBuilder) => {}
  )
}

export async function claimCoupon(
  offerId: string,
  additionalOfferIds: Array<string>
): Promise<swiftly.offers.SwiftlyJsSwiftlyMultipleOfferClaimResult> {
  await refreshApiSetupInitWithNewToken()
  return await swiftly.offers.claimOffers(offerId, additionalOfferIds)
}

export async function fetchCouponsById(
  id: string,
  isClipped?: boolean
): Promise<swiftly.offers.SwiftlyJsSwiftlyOffer | null> {
  await refreshApiSetupInitWithNewToken()
  if (isClipped) {
    return (
      (
        await swiftly.offers.fetchClaimedAndRedeemedOffers(
          // prettier-ignore
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
          (_: com.swiftly.feature.offers.data.PageableOfferListRequestBuilder) => {}
        )
      ).claimedOffers?.find(
        (currentOffer: swiftly.offers.SwiftlyJsSwiftlyOffer) => {
          return currentOffer.id === id
        }
      ) || null
    )
  }
  return (
    (
      await swiftly.offers.fetchRankedOffers(
        // prettier-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        (_: com.swiftly.feature.offers.data.OfferListRequestBuilder) => {}
      )
    ).find((currentOffer: swiftly.offers.SwiftlyJsSwiftlyOffer) => {
      return currentOffer.id === id
    }) || null
  )
}

export interface Rebate {
  rebateId: string
  brand: string
  category: string
  shortDescription: string
  longDescription: string
  termsAndConditions: string
  imageThumbnailUrl: string
  imageDetailsUrl: string
  valueInCents: number
  valueDisplay: string
  startDate: string
  endDate: string
  expirationDate: string
  featured: boolean
  status: string
  decorator: string
  maximumRedemptions: number
}

// for the `rebates/clipped/{tenant}/customer/{customer}`
export interface IRebate {
  rebateClippedId: string
  rebateId: string
  brand: string
  valueDisplay: string
  title: string
  shortDescription: string
  longDescription: string
  value: string
  imageThumbnailUrl: string
  imageDetailsUrl: string
  startDate: string
  endDate: string
  expirationDate: string
  featured: boolean
  redemptionsRemaining: number
  status: string
}

export async function fetchRebateListWithState(
  tenantId: string,
  state: string
): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<{
      activatedRebates: Rebate[]
      tenant: string
      usState: string
    }>(`${HOST_NAME}/rebates/active/${tenantId}/state/${state}`, {
      headers: getSwiftyAuthHeader(),
    })
  ).data
}

export async function fetchRebateListCustomerWithState(
  tenantId: string,
  state: string,
  customerId: string
): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<{
      activatedRebates: Rebate[]
      tenant: string
      usState: string
    }>(
      `${HOST_NAME}/rebates/active/${tenantId}/state/${state}/customer/${customerId}`,
      { headers: getSwiftyAuthHeader() }
    )
  ).data
}

export async function fetchRebateList(tenantId: string): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<{
      activatedRebates: Rebate[]
      tenant: string
      usState: string
    }>(`${HOST_NAME}/rebates/active/${tenantId}`, {
      headers: getSwiftyAuthHeader(),
    })
  ).data
}

export async function fetchRebateListCustomer(
  tenantId: string,
  customerId: string
): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<{
      activatedRebates: Rebate[]
      tenant: string
      usState: string
    }>(`${HOST_NAME}/rebates/active/${tenantId}/customer/${customerId}`, {
      headers: getSwiftyAuthHeader(),
    })
  ).data
}

export async function fetchRebateDetails(
  tenantId: string,
  id: string
): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<Rebate>(`${HOST_NAME}/rebates/active/${tenantId}/${id}`, {
      headers: getSwiftyAuthHeader(),
    })
  ).data
}

export async function fetchRebateDetailsCustomer(
  tenantId: string,
  id: string,
  customerId: string
): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<Rebate>(
      `${HOST_NAME}/rebates/active/${tenantId}/${id}/${customerId}`,
      { headers: getSwiftyAuthHeader() }
    )
  ).data
}

export async function fetchClippedData(
  tenant: string,
  id: string
): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<{
      rebatesClipped: IRebate[]
      tenant: string
    }>(`${HOST_NAME}/rebates/clipped/${tenant}/customer/${id}`, {
      headers: getSwiftyAuthHeader(),
    })
  ).data
}

export async function fetchRedeemedData(
  tenant: string,
  id: string
): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<{
      rebatesClipped: IRebate[]
      tenant: string
    }>(`${HOST_NAME}/rebates/clipped/${tenant}/customer/${id}/redeemed`, {
      headers: getSwiftyAuthHeader(),
    })
  ).data
}

export async function fetchWallet(tenant: string, id: string): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  return (
    await axios.get<{
      cashbackDisplay: string
      cashbackInCents: number
      expiringSoonDisplay: string
      expiringSoonInCents: number
      expiringSoon: [
        {
          cashbackAmount: string
          expirationDate: string
        }
      ]
    }>(`${HOST_NAME}/rebates/wallet/${tenant}/${id}`, {
      headers: getSwiftyAuthHeader(),
    })
  ).data
}
