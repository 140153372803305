import { createTheme, PaletteColor, ThemeOptions } from "@mui/material/styles"
import { Theme } from "@mui/material/styles/createTheme"
import { theme as defaultTheme } from "swiftlykit"

const { breakpoints, palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string): PaletteColor =>
  augmentColor({ color: { main: mainColor } })

// Colors
const bgPrimary = createColor("#FFFFFF")
const bgSecondary = createColor("#FAFAFA")
const ctaConfirm = createColor("#3170B7")
const ctaPrimary = createColor("#3170B7")
const accents = createColor("#005E8F")
const ctaPrimaryHover = createColor("#173E81")
const ctaPrimaryText = createColor("#FEFEFE")
const ctaPrimaryMuted = createColor("#E989C5")
const ctaPrimaryGhost = createColor("#04663014")
const ctaSecondary = createColor("#BC5727")
const ctaSecondaryText = createColor("#57781B")
const ctaSecondaryMuted = createColor("#F7FBF6")
const ctaSecondaryGhost = createColor("#E6EFF6")
const ctaSuccess = createColor("#005E1B")
const ctaFailure = createColor("#ff2500")
const textPrimary = createColor("#241e1e")
const textLink = createColor("#AC4717")
const textSecondary = createColor("#5B5653")
const textMuted = createColor("#B7B8B9")
const border = createColor("#D8DBD9")
const infoPositive = createColor("#33B007")
const infoNegative = createColor("#E84A4A")
const surfaceLow = createColor("#FDF8ED")
const surfaceHigh = createColor("#FEFCF6")
const subnavBg = createColor("#F4F4DC")
const topinfoBg = createColor("#F7F8F9")
const modalBg = createColor("#FEFAF3")
const footerBg = createColor("#D5FFC9")
const dividerColor = createColor("#E3E7E2")
const tabsButton = createColor("#A9A59A")
const accountHeader = createColor("#F8F9F8")
const couponLight = createColor("#658D1B")
const couponNew = defaultTheme.palette.couponNew
const couponFeatures = defaultTheme.palette.couponFeatures
const couponExpireSoon = defaultTheme.palette.couponExpireSoon
const couponBorder = defaultTheme.palette.couponBorder
const couponValue = createColor("#BC5727")
const rewardAvatars = createColor("#F5F8FF")
const ctaFailed = createColor("#AC002D")
const lightPrimary = createColor("#3170B714")
const footerDivider = createColor("#ffffff3d")
const luckyFooter = createColor("#EAE8E3")
const placeholder = createColor("#5B5653")
const bgForgotPasswordMessage = createColor("#3170b714")
const bgSetPaswordMessage = createColor("#3170b714")
const successSurface = createColor("#EFF4F9")
// const openSans = Open_Sans({ subsets: ["latin"] });
//
// // Fonts
// const fontFamily = openSans.style.fontFamily;
// Fonts
const fontFamily = "'Open Sans', sans-serif"

const themeDetails = (fontFamily: string): ThemeOptions => {
  return {
    palette: {
      bgForgotPasswordMessage,
      bgSetPaswordMessage,
      bgPrimary,
      bgSecondary,
      border,
      accents,
      ctaConfirm,
      ctaPrimary,
      ctaPrimaryHover,
      ctaPrimaryMuted,
      ctaPrimaryGhost,
      ctaSecondary,
      ctaSecondaryMuted,
      ctaSecondaryGhost,
      ctaSuccess,
      ctaPrimaryText,
      ctaSecondaryText,
      infoPositive,
      infoNegative,
      surfaceLow,
      surfaceHigh,
      textPrimary,
      textLink,
      textSecondary,
      textMuted,
      ctaFailure,
      subnavBg,
      topinfoBg,
      modalBg,
      footerBg,
      dividerColor,
      tabsButton,
      accountHeader,
      couponLight,
      couponNew,
      couponFeatures,
      couponExpireSoon,
      couponBorder,
      couponValue,
      rewardAvatars,
      ctaFailed,
      lightPrimary,
      footerDivider,
      luckyFooter,
      placeholder,
      successSurface,
    },
    typography: {
      body: {
        fontFamily,
        fontSize: "15px",
        fontWeight: 500,
        color: textPrimary.main,
        lineHeight: "24px",
      },
      caption: {
        fontFamily,
        fontSize: "13px",
        fontWeight: 500,
        color: textPrimary.main,
        lineHeight: "16px",
        [breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
      info: {
        fontFamily,
        fontSize: "13px",
        fontWeight: 500,
        color: textPrimary.main,
        lineHeight: "24px",
        [breakpoints.down("md")]: {
          lineHeight: "20px",
        },
      },
      body1: {
        fontFamily,
        fontSize: "14px",
        fontWeight: 500,
        color: textPrimary.main,
      },
      fontFamily,
      h1: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
        [breakpoints.down("md")]: {
          lineHeight: "32px",
          fontSize: 28,
        },
      },
      h2: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "32px",
        [breakpoints.down("md")]: {
          lineHeight: "28px",
          fontSize: 24,
          fontWeight: 700,
        },
      },
      h3: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "28px",
        [breakpoints.down("md")]: {
          lineHeight: "24px",
          fontSize: 22,
          fontWeight: 600,
        },
      },
      h4: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        [breakpoints.down("md")]: {
          lineHeight: "24px",
          fontSize: 20,
        },
      },
      h5: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
        [breakpoints.down("md")]: {
          lineHeight: "24px",
          fontSize: 18,
        },
      },
      h6: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",
        [breakpoints.down("md")]: {
          lineHeight: "20px",
          fontSize: 16,
        },
      },
      label: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "15px",
        fontWeight: 500,
        lineHeight: "24px",
        [breakpoints.down("md")]: {
          fontWeight: 600,
        },
      },
      paragraph: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "28px",
        [breakpoints.down("md")]: {
          lineHeight: "24px",
        },
      },
      textLink: {
        color: textPrimary.main,
        fontFamily,
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        textDecoration: `${textLink.main} !important`,
      },
      underlinedLink: {
        fontFamily,
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        textDecorationColor: `${textPrimary.main} !important`,
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              border: `2px solid ${ctaPrimary.main}`,
              borderRadius: 8,
              backgroundColor: "transparent",
              color: `${ctaPrimary.main}`,
              fontWeight: 500,
              fontSize: "13px",
              boxShadow: "none",
              ":hover": {
                backgroundColor: `${ctaPrimaryHover.main}`,
                color: `${bgPrimary.main}`,
                boxShadow: "none",
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            textTransform: "none",
          },

          containedSecondary: {
            // backgroundColor: create,
            ":hover": {
              backgroundColor: "rgba(192, 46, 136, 0.08)",
              boxShadow: "none",
            },
            color: "rgba(192, 46, 136)",
            backgroundColor: "rgba(192, 46, 136, 0.08)",
            borderRadius: "8px",
            boxShadow: "none",
            height: "40px",
            fontSize: "15px",
          },
          textSecondary: {
            // backgroundColor: create,
            ":hover": {
              boxShadow: "none",
            },
            color: "rgba(192, 46, 136)",
            borderRadius: "8px",
            boxShadow: "none",
            height: "40px",
            fontSize: "15px",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderWidth: 1,
            borderColor: `${dividerColor.main}`,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: `${bgPrimary.main}`,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body: "p",
            info: "p",
            label: "p",
            paragraph: "p",
            body1: "p",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: `1px solid ${dividerColor?.main}`,
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
            borderRadius: "0.5rem",
          },
        },
      },
    },
  }
}

const theme: Theme = createTheme(themeDetails(fontFamily))

/**
 * Use this if you need to override the default font using next.js font optimization
 * @param fontFamilyOverride
 */
export const getTheme = (fontFamilyOverride: string): Theme => {
  const theme: Theme = createTheme(
    themeDetails(fontFamilyOverride ?? fontFamily)
  )
  return theme
}
export default theme
