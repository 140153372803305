import { com, swiftly } from "../../client-data-bom"
import React, { ReactElement, useContext, useState } from "react"
import Box from "@mui/material/Box"
import { fireClick, fireImpression, fireAdView } from "services/AdsApi"
import styles from "../../styles/component.module.scss"
import SwipeableTextMobileStepper from "../ui/SwipeableTextMobileStepper"
import generateConfig from "../../configs/config"
import isMobileBrowser from "../../utils/isMobileBrowser"
import { gaLogEvent, EventName } from "utils/googleAnalyticsEvents"
import { AppContext } from "contexts/AppContext"

const config = generateConfig()

export function getImage(
  ad: swiftly.ads.SwiftlyJsImageAd,
  devicePixelRatio?: number
): swiftly.types.SwiftlyJsSwiftlyImageInfo | null | undefined {
  if (!ad || !ad.image) {
    return null
  }
  let pixelRatio
  if (!devicePixelRatio && typeof window !== "undefined") {
    pixelRatio = getPixelDensity(window.devicePixelRatio)
  } else if (devicePixelRatio && typeof window !== "undefined") {
    pixelRatio = getPixelDensity(devicePixelRatio)
  } else {
    pixelRatio = "ThreeX"
  }
  const deviceClass = isMobileBrowser()
    ? com.swiftly.framework.types.SwiftlyDeviceClass.PHONE
    : com.swiftly.framework.types.SwiftlyDeviceClass.DESKTOP
  const image = swiftly.types
    .bestFitImageOfInfoList(
      // prettier-ignore
      // @ts-ignore
      ad.image,
      com.swiftly.framework.types.SwiftlyImageDensity.valueOf(pixelRatio),
      deviceClass
    )
    ?.reify(swiftly.ads.JsSwiftlyAdsReificationContext)
  return image
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function findAd(currentAd: any, adRef: string): any {
  if (currentAd.resolveAd) {
    return currentAd.resolveAd(adRef)
  }

  // this shouldn't need to be called any more
  return (
    currentAd.containedAds.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (containedAd: any) => containedAd.ad.adId === adRef
    ) || {}
  )
}

function handleAds(
  currentAd: swiftly.ads.SwiftlyJsResolvableAdWrapper | undefined | null,
  userId: string,
  devicePixelRatio?: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overrideSx?: any,
  showEmptyAdHint = false
) {
  if (currentAd == null) {
    return <></>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let castedAd: any | null = null

  // doing some hacky things to support both serverSide and clientSide
  switch (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (currentAd.ad as any)?.constructorName ||
    currentAd?.ad?.constructor.name
  ) {
    case "SwiftlyJsCarouselCollectionAdValue":
      castedAd = currentAd.ad as swiftly.ads.SwiftlyJsCarouselCollectionAd
      const carouselElements = castedAd.placements.map(
        (ad: swiftly.ads.SwiftlyJsCarouselAdPlacement, index: number) => {
          return (
            <div key={`slider-${castedAd.adId}-${index}`}>
              {ad && ad.adRef
                ? handleAds(
                    findAd(currentAd, ad.adRef),
                    userId,
                    devicePixelRatio,
                    {
                      width: "100%",
                    }
                  )
                : ""}
            </div>
          )
        }
      )
      return (
        <SwipeableTextMobileStepper>
          {carouselElements}
        </SwipeableTextMobileStepper>
      )
    case "SwiftlyJsImageAdValue":
      castedAd = currentAd.ad as swiftly.ads.SwiftlyJsImageAd

      const image =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (currentAd as any)?.serverBestFit ||
        getImage(castedAd, devicePixelRatio)
      if (!image) {
        return <div />
      }

      const impressionId = castedAd.adTelemetry?.adInstanceId

      const userIdWithDefault = userId || ""

      const adAction = castedAd.image.action

      const { altText } = castedAd.image
      const { url } = image

      const borderRadius = castedAd.isRounded ? 2 : 0
      return (
        <Box
          tabIndex={0}
          component="img"
          className={styles.bannerTopSection}
          sx={{
            borderRadius: 3,
            display: "block",
            overflow: "hidden",
            maxWidth: "100%",
            height: "100%",
            // maxHeight: "380px",
            ...overrideSx,
          }}
          onLoad={(e: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const gaId =
              typeof window !== "undefined" && (window as any).gaGlobal?.vid

            fireImpression(gaId, userIdWithDefault, impressionId)
            fireAdView(gaId, userIdWithDefault, impressionId, e.currentTarget)
          }}
          onClick={async () => {
            gaLogEvent({
              eventName: EventName.banner_click,
              parameters: {
                item_id: castedAd.adId,
                item_description: "",
                banner_position:
                  JSON.parse(
                    localStorage.getItem("bannerListArray") ?? ""
                  )?.findIndex((item: any) => item === castedAd.adId) + 1,
                imageUrl: url,
                link_sendTo: adAction.target,
                text_click: altText && altText,
              },
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const gaId =
              typeof window !== "undefined" && (window as any).gaGlobal?.vid
            await fireClick(gaId, userId, impressionId)
            if (adAction?.actionType?.name === "NAVIGATE_URL") {
              window.location.href = adAction.target
            }
          }}
          src={url}
          alt={altText}
          data-aiid={impressionId}
          // Conditionally add role attribute
          {...(adAction?.actionType?.name === "NAVIGATE_URL"
            ? { role: "link", "aria-label": `${altText} home` }
            : undefined)}
        />
      )
    default:
      return <></>
  }
}

export function getPixelDensity(pixelDensity: number): string {
  if (pixelDensity > 3) {
    return "FourX"
  } else if (pixelDensity > 2) {
    return "ThreeX"
  } else if (pixelDensity > 1.5) {
    return "TwoX"
  } else if (pixelDensity > 1) {
    return "OnePointFiveX"
  }
  return "OneX"
}

export default function Ads({
  adWrapper,
  devicePixelRatio,
  userId,
  overrideSx,
  showEmptyAdHint,
}: {
  adWrapper: swiftly.ads.SwiftlyJsResolvableAdWrapper | undefined | null
  devicePixelRatio?: number
  userId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overrideSx?: any
  showEmptyAdHint?: boolean
}): ReactElement | null {
  if (adWrapper?.ad) {
    return (
      <>
        {handleAds(
          adWrapper,
          userId || "",
          devicePixelRatio,
          overrideSx,
          showEmptyAdHint
        )}
      </>
    )
  }
  return null
}
