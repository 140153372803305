export default function Content({ store, theme, t }) {
  return ` <div>
      <div
        style="
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;
          gap: 10px;
        "
      >
        <div>
          <p style="color: color:${
            theme.palette.textPrimary.light
          }; font-size: 16px; margin: 0 0 6px 0; font-weight: 600;">${
    store.name
  }
            <span style ="color: #B1B1B1">${store.distance}
            </span>
          </p> 
          <p style="display:flex; align-items: center; font-size: 15px; font-weight: 500; margin: 0; color:${
            theme.palette.textPrimary.light
          }">
            <svg width="12" height="15" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right:8px">
              <path d="M6 0.5C2.94416 0.5 0.5 3.09492 0.5 6.23835C0.5 8.22956 1.55379 10.2116 2.67202 11.7957C3.55369 13.0448 4.52503 14.1113 5.135 14.781C5.30184 14.9641 5.44165 15.1176 5.54519 15.2371C5.78574 15.5145 6.215 15.5142 6.45512 15.2364C6.56009 15.115 6.70257 14.9584 6.87284 14.7713C7.48301 14.1006 8.45014 13.0376 9.32777 11.7947C10.4461 10.2109 11.5 8.22971 11.5 6.23835C11.5 3.09889 9.05606 0.5 6 0.5Z" fill="${
                theme.palette.ctaPrimary.main
              }" stroke="white"/>
              <ellipse cx="6" cy="6.33335" rx="2.5" ry="2.66667" fill="white"/>
            </svg>
            ${store.address}
          </p>
        
          <p style="font-size: 13px; font-weight: 500; margin: 8px 0 20px; color: ${
            theme.palette.textPrimary.main
          }">
            <span style="color:${
              store.status.text === "Open Now"
                ? `${theme.palette.ctaSuccess.main}`
                : `${theme.palette.ctaSecondary.main}`
            }"> 
            ${store.status.text === "Open Now" ? "Open" : "Closed"}</span> • ${
    store.timings
  }
          </p>
        </div>
        <div onClick="window.navigateToStore('${store.id}', '${store.name}', '${
    store.number
  }', '${store.city}')"
        style="cursor: pointer;"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.8999 17.2C9.2999 17.2 8.8999 16.8 8.8999 16.2C8.8999 15.9 8.9999 15.7 9.1999 15.5L12.6999 12L9.1999 8.49999C8.7999 8.09999 8.7999 7.49999 9.1999 7.09999C9.5999 6.69999 10.1999 6.69999 10.5999 7.09999L14.7999 11.3C15.1999 11.7 15.1999 12.3 14.7999 12.7L10.5999 16.9C10.3999 17.1 10.0999 17.2 9.8999 17.2Z" fill="${
              theme.palette.ctaPrimary.main
            }"/>
          </svg>
        </div>
      </div>

      <button
        onClick="window.setCurrentStore('${store.id}');
        window.navigateToflyer('${store.number.toString()}')"

        id="setStoreBtn"
        aria-label=${
          store.isCurrentStore ? t("stores.myStore") : t("stores.setAsMyStore")
        }
        data-cy="btn-${store.id}"
        style="
          border: 1px solid ${theme.palette.ctaPrimary.main};
          border-radius: 8px;
          padding: 8px;
          font-size: 13px;
          font-weight: 500;
          width: 100%;
          color: ${theme.palette.ctaPrimary.main};
          background: transparent;
          cursor: pointer;
        "
      >
        ${store.isCurrentStore ? t("stores.myStore") : t("stores.setAsMyStore")}
      </button>
    </div>`
}

// const getTime = (store) => {
//   const day = new Date().getDay();
//   const timeObject = store?.primaryDetails?.hours[day]?.n1w_1?.[0];

//   const openTime = formatTime(
//     `${timeObject.t1w_1.z13_1._hour}:${timeObject.t1w_1.z13_1._minute}:${timeObject.t1w_1.z13_1._second}`
//   );

//   const closeTime = formatTime(
//     `${timeObject.s1w_1.z13_1._hour}:${timeObject.s1w_1.z13_1._minute}:${timeObject.s1w_1.z13_1._second}`
//   );

//   return {
//     openT: openTime,
//     closeT: closeTime,
//   };
// };

// function formatTime(timeString) {
//   const [hourString, minuteString] = timeString.split(":");
//   const minute = +minuteString % 10 === 0 ? `0${minuteString}` : minuteString;
//   const hour = +hourString % 24;
//   return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
// }
