import * as React from "react"
import { SVGProps } from "react"
import { useTheme } from "@mui/material/styles"

const SvgFilledLocationIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      width="48"
      height="56"
      viewBox="0 0 48 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.987 18.1999C44.2136 5.85325 33.4403 0.333252 24.0003 0.333252C24.0003 0.333252 24.0003 0.333252 23.9736 0.333252C14.5603 0.333252 3.81364 5.85325 1.01364 18.1733C-2.13302 31.9333 6.29364 43.5866 13.9203 50.9466C16.747 53.6666 20.3736 55.0266 24.0003 55.0266C27.627 55.0266 31.2536 53.6666 34.0536 50.9466C41.6803 43.5866 50.107 31.9599 46.987 18.1999ZM32.747 20.4133L22.0803 31.0799C21.6803 31.4799 21.1736 31.6666 20.667 31.6666C20.1603 31.6666 19.6536 31.4799 19.2536 31.0799L15.2536 27.0799C14.4803 26.3066 14.4803 25.0266 15.2536 24.2533C16.027 23.4799 17.307 23.4799 18.0803 24.2533L20.667 26.8399L29.9203 17.5866C30.6936 16.8133 31.9736 16.8133 32.747 17.5866C33.5203 18.3599 33.5203 19.6399 32.747 20.4133Z"
        fill={theme.palette.ctaPrimary.main}
      />
    </svg>
  )
}
export default SvgFilledLocationIcon
