import { ProductSummary } from "generated/graphql"
import { ItemStates, ShoppingListItemType } from "./types"

export const MAX_ITEMS_COUNT = 300

/**
 * Check if the item is already added to the shopping list
 * @param {string} itemId
 * @returns {boolean}
 */
export function isItemPresentInShoppingList(
  shoppingListItems: ShoppingListItemType[],
  itemId: string
): boolean {
  return Boolean(
    shoppingListItems?.find(
      (p: ShoppingListItemType) => p?.attributes?.productId === itemId
    )
  )
}

/**
 * Generate a new shopping list item by using provided item
 * @param item
 * @returns
 */
export function generateNewShoppingListItem(
  item: ProductSummary
): ShoppingListItemType {
  return {
    key: Math.random().toString(16).slice(2),
    title: item.title || "",
    attributes: {
      productId: item.id,
    },
    productAttributes: {
      brand: item.brand || "",
      imageUrl: item.images[0]?.image?.uri || "",
      basePrice: item.webPrice?.success?.basePrice || "",
      promoPrice: item.webPrice?.success?.promoPrice,
    },
    status: ItemStates.ACTIVE,
  }
}
