import ImageIcon from "@mui/icons-material/Image"
import { Button } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import { SxProps, Theme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { format } from "date-fns"
import Loader from "components/loader/Loader"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Reward } from "../../../generated/graphql"
import getPixelDensity from "../../../utils/getPixelDensity"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { useAppContext } from "contexts/AppContext"
import { openSnackbar } from "contexts/actions/actions"

export interface RewardCardProps extends Reward {
  sx?: {
    card?: SxProps<Theme>
    description?: SxProps<Theme>
  }
  pointBalance: number
  expiryDateTime?: string
  onRedeem?: (rewardId: string) => Promise<void>
  onClick?: (rewardId: string) => void
}

const styles = {
  card: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "grey.300",
    borderRadius: "8px",
    backgroundColor: "white",
    width: 180,
    alignContent: "center",
    position: "relative" as const,
    cursor: "pointer",
  },
  closeContainer: {
    position: "absolute" as const,
    right: 0,
    cursor: "pointer",
    padding: 1,
  },
  media: {
    display: "grid",
    backgroundSize: "cover",
    height: 132,
    alignContent: "center",
    justifyContent: "center",
  },
  emptyMedia: {
    backgroundColor: "grey.300",
  },
  content: {
    p: 1,
    ":last-child": {
      pb: 1,
    },
    textAlign: "center",
  },
  points: {
    mt: 1,
    fontSize: 16,
    color: "ctaPrimary.light",
    lineHeight: 2,
    textAlign: "center",
    borderRadius: 2,
    display: "block",
  },
  pointsAway: {
    mt: 1,
    fontWeight: "bold",
    fontSize: 13,
    textAlign: "center",
    borderRadius: 2,
    padding: 1,
    width: "100%",
    border: "none",
    height: 32,
    color: "#8D887F !important",
    backgroundColor: "#F4F3F0 !important",
  },
  redeem: {
    mt: 1,
    fontWeight: "bold",
    fontSize: 13,
    color: "bgPrimary.main",
    backgroundColor: "ctaPrimary.main",
    textAlign: "center",
    borderRadius: 2,
    padding: 1,
    width: "100%",
    border: "none",
    height: 32,
  },
  description: {
    textAlign: "center",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    lineHeight: "normal",
  },
}

const RewardCard = ({
  brand,
  images,
  displayName,
  pointCost,
  description,
  sx,
  rewardId,
  pointBalance,
  expiryDateTime,
  onRedeem,
  onClick,
}: RewardCardProps) => {
  const cardCustomStyle: SxProps<Theme> = sx?.card
    ? { ...styles.card, ...sx.card }
    : { ...styles.card }
  const [imageUrl, setImageUrl] = React.useState<string>("")
  const [isRedeemInProgress, setIsRedeemInProgress] = React.useState(false)
  useEffect(() => {
    if (!images.length) setImageUrl("")
    else {
      const pixelRatio = getPixelDensity()
      const targetImage = images.find(
        (image) => image.imageDensity === pixelRatio
      )
      if (targetImage) setImageUrl(targetImage.url)
      else setImageUrl(images[0].url)
    }
  }, [images])
  const { t } = useTranslation()
  const { dispatch } = useAppContext()

  const viewRewardDetails = () => {
    if (onClick) onClick(rewardId)
  }

  const canRedeem = pointCost <= pointBalance

  const redeemReward: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(
      openSnackbar({
        variant: "alert",
        message: t("rewards.rewardsAddedSuccessfully"),
        alert: {
          color: "success",
        },
      })
    )
    if (canRedeem) {
      if (onRedeem) {
        setIsRedeemInProgress(true)
        onRedeem(rewardId).then((_res: any) => {
          setIsRedeemInProgress(false)
          gaLogEvent({
            eventName: EventName.reward_addReward,
            parameters: {
              item_id: rewardId,
              item_price: displayName,
              item_savings: description,
              item_message1: pointCost.toString() + " points",
              item_message2:
                (pointCost - pointBalance).toString() + " points away",
            },
          })
        })
      }
    }
  }

  return (
    <Card
      sx={cardCustomStyle}
      elevation={0}
      aria-label={t("ariaLabels.productCard")}
      onClick={viewRewardDetails}
      tabIndex={0}
    >
      {imageUrl ? (
        <CardMedia
          role="presentation"
          aria-hidden="true"
          component="img"
          image={imageUrl}
          sx={styles.media}
          alt={displayName ?? brand}
        />
      ) : (
        <CardMedia sx={{ ...styles.media, ...styles.emptyMedia }}>
          <ImageIcon fontSize="large" />
        </CardMedia>
      )}
      <CardContent sx={styles.content}>
        <Typography gutterBottom noWrap variant="body1" fontWeight="700">
          {displayName || brand}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          color="textPrimary.main"
          sx={{ ...styles.description }}
          my={1}
        >
          {description}
        </Typography>
        {!expiryDateTime ? (
          <>
            <Typography variant="h6" sx={styles.points}>
              {t("rewardsCard.points", { points: pointCost })}
            </Typography>
            <Button
              variant="contained"
              color="ctaPrimary"
              sx={canRedeem ? styles.redeem : styles.pointsAway}
              disabled={!canRedeem}
              onClick={(e) => {
                localStorage.setItem("activeElement", rewardId), redeemReward(e)
              }}
              id={rewardId}
              aria-label={
                canRedeem
                  ? `${t("rewardsCard.redeem")}`
                  : `${t("ariaLabel.rewardButton", {
                      buttonText: pointCost - pointBalance,
                      rewardTitle: t("rewardsCard.pointsAway"),
                      defaultValue: `${pointCost - pointBalance} for ${t(
                        "rewardsCard.pointsAway"
                      )}`,
                    })})}`
              }
              tabIndex={canRedeem ? 0 : -1}
            >
              {isRedeemInProgress ? (
                <Loader height="100%" size={20} color="inherit" />
              ) : canRedeem ? (
                t("rewardsCard.redeem")
              ) : (
                t("rewardsCard.pointsAway", {
                  points: pointCost - pointBalance,
                })
              )}
            </Button>
          </>
        ) : (
          <Typography>
            {t("rewardsCard.exp")}{" "}
            {format(new Date(expiryDateTime), "dd MMM yyyy")}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default RewardCard
