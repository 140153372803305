import { CouponsIcon, RewardsIcon, UserIcon } from "@/icons"
import { Logout, PlaylistAddCheck } from "@mui/icons-material"
import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import generateConfig from "configs/config"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { useAuth } from "../../contexts/AuthContext"

export const styles = {
  line: {
    borderBottom: "1px dotted",
    borderColor: "#E3E7E2",
  },
  stack: {
    width: 360,
    border: "1px solid",
    backgroundColor: "bgPrimary.main",
    borderColor: "#E3E7E2",
    borderRadius: 4,
  },
  box: {
    padding: 0,
  },
  button: {
    color: grey[900],
    width: "100%",
    justifyContent: "left",
    display: "flex",
    paddingLeft: 1,
    py: "14px",
    pl: 3,
    borderRadius: 0,
    ":hover": {
      backgroundColor: "lightPrimary.main",
    },
    fontSize: "14px",
    fontWeight: 500,
  },
  active: {
    backgroundColor: "lightPrimary.main",
    borderRight: `2px solid`,
    borderColor: "ctaPrimary.main",
    color: "ctaPrimary.main",
  },
  buttonTypography: {
    marginTop: 1,
    marginBottom: 1,
  },
  itemTitleTypography: {
    p: 3,
    pb: 2,
    fontWeight: 600,
  },
  icon: {
    marginRight: 1,
  },
  svgIcon: {
    height: "22px",
    width: "22px",
    marginRight: 10,
  },
}

export const Line = () => {
  return <Divider sx={{ borderStyle: "dashed", mt: 1 }} />
}

interface SideBarProps {
  isDesktop: boolean
  isTablet: boolean
}

const SideBar = ({ isTablet }: SideBarProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { logout, initialState } = useAuth()
  const config = generateConfig()
  const [logoutUrl, setLogoutUrl] = useState(true)
  const menuItems = [
    {
      title: t("profile.myItems"),
      buttons: config.enableRewards
        ? [
            {
              icon: <RewardsIcon style={styles.svgIcon} />,
              text: t("profile.myRewards"),
              route: "/my-rewards",
            },
            {
              icon: <CouponsIcon style={styles.svgIcon} />,
              text: t("profile.myCoupons"),
              route: "/accounts/coupons",
            },
          ]
        : [
            {
              icon: <CouponsIcon style={styles.svgIcon} />,
              text: t("profile.myCoupons"),
              route: "/accounts/coupons",
            },
          ],
    },
    {
      title: t("profile.myProfile"),
      buttons: [
        {
          icon: <UserIcon style={styles.svgIcon} />,
          text: t("profile.accountSettings"),
          route: "/accounts/details",
        },
      ],
    },
  ]

  if (config.enableShoppingList) {
    menuItems[0].buttons.splice(2, 0, {
      icon: <PlaylistAddCheck style={styles.svgIcon} />,
      text: t("navBarMenu.shoppingList"),
      route: "/shopping-list",
    })
  }

  if (initialState?.envVariables?.configBanner === "foodmaxx") {
    menuItems[0].buttons.splice(0, 1)
  }

  const { user } = useAuth()

  const logOutUser = () => {
    // setLogoutUrl(true)
    logout({
      message: "You have been logged out",
      redirectLocation: "/login",
    })
  }

  return (
    <Stack
      sx={{
        ...styles.stack,
        width: "100%",
        minWidth: 282,
        maxWidth: 282,
        height: "fit-content",
        overflow: "hidden",
      }}
    >
      <Box sx={{ ...styles.box, px: 3, py: 2, pb: 1 }}>
        <Typography variant="h1" sx={{ fontSize: "18px", fontWeight: 600 }}>
          {t("profile.hey", {
            firstName: user?.firstName || "N/A",
          })}
        </Typography>
        {/* TODO - make dynamic? */}
        {/* <Typography color="textSecondary.main" fontWeight={400} variant="body1">
          {t("profile.memberSince")}
        </Typography> */}
      </Box>
      {menuItems.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Line />
            <Box sx={styles.box}>
              {item.title && (
                <Typography
                  variant="h2"
                  sx={{ ...styles.itemTitleTypography, fontSize: "14px" }}
                >
                  {item.title}
                </Typography>
              )}
              <div aria-live="polite" id="live-region">
                {item.buttons.map((button, index) => {
                  return (
                    <Box key={index}>
                      <Button
                        sx={
                          router.pathname === button.route
                            ? { ...styles.button, ...styles.active }
                            : styles.button
                        }
                        color="ctaPrimary"
                        onClick={() =>
                          router
                            .push(button.route)
                            .then((r) =>
                              router.push(button.route).then((r) => r)
                            )
                        }
                        aria-label={
                          router.pathname === button.route
                            ? `${button.text}, ${t("ariaLabels.active")}`
                            : button.text
                        }
                      >
                        {button.icon}
                        {button.text}
                      </Button>
                    </Box>
                  )
                })}
              </div>
            </Box>
          </React.Fragment>
        )
      })}
      <Divider sx={{ borderStyle: "dashed", mt: 1 }} />
      <Box sx={styles.box}>
        <Button
          sx={styles.button}
          color="ctaPrimary"
          onClick={() => {
            logOutUser()
            gaLogEvent({
              eventName: EventName.signout_click,
              parameters: {
                item_location: window.location.pathname,
              },
            })
          }}
          aria-label={t("profile.signOut")}
        >
          <Logout sx={styles.icon} aria-hidden="true" role="presentation" />
          <Typography sx={styles.buttonTypography}>
            {t("profile.signOut")}
          </Typography>
        </Button>
      </Box>
    </Stack>
  )
}
export default SideBar
