import generateConfig from "configs/config"

const config = generateConfig()
// Host that connects to DB
export const HOST_NAME = config.rebatesHostName

export const getWalletURL = (tenant: string, customerId: string) =>
  `${HOST_NAME}/rebates/wallet/${tenant}/${customerId}`

export const getCashoutURL = (tenant: string, customer: string) =>
  `${HOST_NAME}/cashout/${tenant}/customer/${customer}`

export const getClippedURL = (tenant: string, customer: string) =>
  `${HOST_NAME}/rebates/clipped/${tenant}/customer/${customer}`

export const getRedeemedURL = (tenant: string, customer: string) =>
  `${HOST_NAME}/rebates/clipped/${tenant}/customer/${customer}/redeemed`
