import MyLocation from "@mui/icons-material/MyLocation"
import {
  Grid,
  IconButton,
  ListItem,
  Paper,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import parse from "autosuggest-highlight/parse"
import { useRouter } from "next/router"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import styles from "../../styles/component.module.scss"
import { LatLng } from "./utils"
import generateConfig from "../../configs/config"
import { useDebounce } from "utils/useDebounce"
import { faroPushErrors } from "utils/faroGrafanaLogs"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"

const config = generateConfig()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomPopper = function (props: any) {
  const { zIndex } = useTheme()
  return props?.open ? (
    <Paper
      {...props}
      style={{
        width: "100%",
        position: "relative",
        zIndex: zIndex.mobileStepper,
      }}
    />
  ) : null
}

export type SearchPlaceInputProps = {
  panTo: ({ lat, lng }: LatLng) => void
  addToUrlParams?: boolean
  isModal?: boolean
  sx?: SxProps
  handleInputClear: () => void
}

type MainTextMatchedSubstrings = {
  offset: number
  length: number
}

type StructuredFormatting = {
  main_text: string
  secondary_text: string
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[]
}

type PlaceType = {
  description: string
  structured_formatting: StructuredFormatting
}

// something broken in the request options setup for google maps here does not seem to be working
// export const SearchPlaceInput: React.FC<SearchPlaceInputProps> = ({
//   panTo,
//   addToUrlParams = false,
//   sx,
//   handleInputClear,
// }: SearchPlaceInputProps) => {
//   const {
//     ready,
//     suggestions: { status, data },
//     setValue,
//   } = usePlacesAutocomplete({
//     requestOptions: {
//       componentRestrictions: { country: config.countriesBound },
//     },
//   });

export const SearchPlaceInput: React.FC<SearchPlaceInputProps> = ({
  panTo,
  addToUrlParams = false,
  sx,
  handleInputClear,
  isModal,
}: SearchPlaceInputProps) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: config.countriesBound },
    },
  })

  const [selectedValue, setSelectedValue] = useState<PlaceType | null>(null)
  const [searchText, setSearchText] = useState<string>("")
  const [options, setOptions] = useState<readonly PlaceType[]>([])
  const [touched, setTouched] = useState(false)
  const { palette } = useTheme()
  const { t } = useTranslation()
  const router = useRouter()

  const debouncedText = useDebounce(searchText, 600)

  useEffect(() => {
    setValue(debouncedText)
    gaLogEvent({
      eventName: EventName.storeLocator_searchPlaces,
      parameters: {
        search_term: debouncedText,
        item_location: isModal ? "modal" : "store locator page",
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText])

  const handleSearchInputOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (event: any, newValue: PlaceType | null) => {
      delete router.query.lat
      delete router.query.lng

      setOptions(newValue ? [newValue, ...options] : options)
      if (addToUrlParams && newValue !== null) {
        router.query.locationSearch = encodeURIComponent(
          JSON.stringify(newValue)
        )
        router.push(router)
      }
      setSelectedValue(newValue)

      if (!newValue) {
        return
      }
      try {
        const results = await getGeocode({
          address: newValue?.description,
        })
        gaLogEvent({
          eventName: EventName.storeLocator_geocodeLocation,
          parameters: {
            search_term: debouncedText,
            item_location: isModal ? "modal" : "store locator page",
          },
        })
        const { lat, lng } = getLatLng(results[0])
        panTo({ lat: lat, lng: lng })
      } catch (error: any) {
        faroPushErrors(JSON.stringify(error?.response?.data))
      }
    },
    [addToUrlParams, options, router, panTo]
  )

  useEffect(() => {
    if (addToUrlParams && router.query.locationSearch) {
      const defaultSelectedLocation = decodeURIComponent(
        router.query.locationSearch as string
      )
      const place = JSON.parse(defaultSelectedLocation) as PlaceType
      setSelectedValue(place)
      ;(async () => {
        try {
          if (router.query.length && router.query.lat && router.query.lng) {
            panTo({
              lat: Number(router.query.lat),
              lng: Number(router.query.lng),
            })
          } else {
            const results = await getGeocode({
              address: place?.description,
            })
            const { lat, lng } = getLatLng(results[0])
            panTo({ lat: lat, lng: lng })
          }
        } catch (error: any) {
          faroPushErrors(JSON.stringify(error?.response?.data))
        }
      })()
    }
    // Run it only on the first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isOptionEqualToValue = (a: any, b: any) => {
    return a?.description === b?.description
  }

  useEffect(() => {
    if (!selectedValue && touched) {
      handleInputClear()
    }
    const newOptions: PlaceType[] =
      status === "OK"
        ? // eslint-disable-next-line camelcase
          data.map(({ description, structured_formatting }) =>
            // eslint-disable-next-line camelcase
            ({
              description,
              structured_formatting,
            })
          )
        : []
    setOptions(newOptions)
  }, [data, selectedValue, status, handleInputClear, touched])

  const getMyGeocodeFromBrowser = () => {
    setSelectedValue(null)
    delete router.query.locationSearch
    navigator.geolocation.getCurrentPosition((position) => {
      const latLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      router.push("/stores", {
        query: latLng,
      })
      panTo(latLng)
    })
  }

  /*--------search list UI----------*/
  const classes = {
    popper: styles.customPopper,
    listbox: styles.customPopperListbox,
    options: styles.customPopperListboxOptions,
  }
  return (
    <Stack direction="row" flexDirection="column" width="100%">
      <Autocomplete
        classes={classes}
        fullWidth
        id="place-search"
        data-cy="place-search"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        noOptionsText={
          searchText && !options.length
            ? t("stores.noOptionsTextWhenIncorrectInput")
            : t("stores.noOptionsText")
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        clearOnEscape
        onFocus={() => {
          setTouched(true)
        }}
        clearOnBlur
        value={selectedValue}
        disabled={!ready}
        onChange={handleSearchInputOnChange}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.length === 0) {
            setSelectedValue(null)
          }
          setSearchText(newInputValue)
        }}
        PopperComponent={CustomPopper}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            sx={{
              "& input::placeholder": {
                color: `${palette.placeholder}`,
              },
            }}
            placeholder={t("stores.storeSearchInputPlaceholder")}
            className={styles.storeSearchInput}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <IconButton
                  onClick={getMyGeocodeFromBrowser}
                  className={styles.iconLocate}
                  sx={{
                    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.16)`,
                    color: `${palette.ctaPrimary?.main}`,
                    backgroundColor: "lightPrimary.main",
                    borderRadius: 2,
                    marginRight: 1,
                  }}
                  aria-label={t("ariaLabels.useMylocation")}
                >
                  <MyLocation
                    sx={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings
          const parts = parse(
            option.structured_formatting.main_text,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          )

          return (
            <>
              <ListItem
                {...props}
                className={styles.innerSearchbody}
                sx={{
                  px: 2,
                  py: 1,
                  width: "100%",
                  borderRadius: 2,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f9f8f7",
                  },
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs>
                    {parts.map((part, index) => {
                      return (
                        <span
                          key={index}
                          aria-label={`${config.siteName} ${part.text} of ${options.length}`}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            color: `${
                              part.highlight
                                ? palette.ctaConfirm?.main ??
                                  palette.ctaPrimary.main
                                : palette.textPrimary
                            }`,
                          }}
                        >
                          {part.text}
                        </span>
                      )
                    })}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </>
          )
        }}
      />
    </Stack>
  )
}
