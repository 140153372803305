import React, {
  Children,
  cloneElement,
  isValidElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Box } from "@mui/material"
import { createCustomEqual } from "fast-equals"
import Content from "./Content"
import { useDecorateStore } from "./utils"
import { useTheme } from "@emotion/react"
import { AppContext } from "../../contexts/AppContext"
import { setStore } from "../../contexts/actions/actions"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import { fetchData } from "utils/fetcher"
import { StoreDetailsDocument, useStoreDetailsQuery } from "generated/graphql"
import { queryClient } from "utils/queryClient"
import CurrentLocationIcon from "../../assets/currentLocationIcon.svg"
import SelectedStoreIconSavemart from "../../assets/MapMarkerSelectedSavemart.svg"
import StorePinIconSavemart from "../../assets/MapMarkerSavemart.svg"
import SelectedStoreIconLucky from "../../assets/MapMarkerSelectedLucky.svg"
import StorePinIconLucky from "../../assets/MapMarkerLucky.svg"
import SelectedStoreIconFoodmaxx from "../../assets/MapMarkerSelectedFoodmaxx.svg"
import StorePinIconFoodmaxx from "../../assets/MapMarkerFoodmaxx.svg"
import generateConfig from "../../configs/config"
import { useAuth } from "../../contexts/AuthContext"
import { gaLogEvent, EventName } from "../../utils/googleAnalyticsEvents"

const Marker = (options) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions(options)
      google.maps.event.clearListeners(marker, "click")
      marker.addListener("click", () => {
        options && options.onClick && options.onClick(marker)
      })
    }
  }, [marker, options])

  return null
}

export default function StoresMap({
  stores,
  position,
  isMobile,
  isModal,
  closeModal,
  currentLocation,
}) {
  const [infoMarker, setInfoMarker] = useState()
  const [markers, setMarkers] = useState([])
  const { decorateStore } = useDecorateStore(null, position)
  const theme = useTheme()
  const router = useRouter()
  const { dispatch, state } = useContext(AppContext)
  const { t } = useTranslation()
  const { handleStoreRoute, updateRegisterInfo, getRegisterInfo } = useAuth()
  const config = generateConfig()
  let SelectedStoreIcon
  let StorePinIcon
  switch (config.configBanner) {
    case "savemart":
      SelectedStoreIcon = SelectedStoreIconSavemart
      StorePinIcon = StorePinIconSavemart
      break
    case "luckysupermarkets":
      SelectedStoreIcon = SelectedStoreIconLucky
      StorePinIcon = StorePinIconLucky
      break
    case "foodmaxx":
      SelectedStoreIcon = SelectedStoreIconFoodmaxx
      StorePinIcon = StorePinIconFoodmaxx
      break
    default:
      SelectedStoreIcon = SelectedStoreIconSavemart
      StorePinIcon = StorePinIconSavemart
  }

  useEffect(() => {
    window.setCurrentStore = async (storeId) => {
      //as you can pass only string in the store,call api to get store information and then pass to the setStore function
      const storeDetail = { storeId }

      /**
       * @type {import("generated/graphql").StoreDetailsQuery}
       */
      const data = await queryClient.fetchQuery(
        useStoreDetailsQuery.getKey(storeDetail),
        fetchData(StoreDetailsDocument, storeDetail)
      )

      const store = decorateStore(data.store)
      dispatch(setStore(store))
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...getRegisterInfo,
          preferred_store: store?.number.toString(),
        })
      )
      const userData = localStorage.getItem("userInfo")
      updateRegisterInfo(JSON.parse(userData))
    }
    window.navigateToStore = (storeid, name, number, city) => {
      closeModal?.()
      handleStoreRoute(storeid, name, number, city)
    }

    window.navigateToflyer = (storeid) => {
      router.push(`/flyers/${storeid.toString()}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, decorateStore, dispatch, router, position])

  useEffect(() => {
    setMarkers(
      stores?.map((store) => {
        return {
          ...decorateStore(store),
          position: {
            lat: store.latitude,
            lng: store.longitude,
          },
          alt: `${store.name} marker`,
          title: store.name,
          // remove this line to use default icon
          icon:
            store.id === state.currentStore?.id
              ? SelectedStoreIcon.src
              : StorePinIcon.src,
        }
      })
    )
  }, [stores, state.currentStore?.id, decorateStore, position])

  const onClick = (store) => async (marker) => {
    infoMarker && infoMarker.close && infoMarker.close()
    const infoWindow = new google.maps.InfoWindow({
      content: Content({
        store,
        theme,
        t,
      }),
    })
    infoWindow.open(marker.get("map"), marker)
    setInfoMarker(infoWindow)
    gaLogEvent({
      eventName: EventName.navStoreLocator_mapClick,
      parameters: {
        item_message1: store?.name,
        item_location: "Modal",
        item_message2: store?.address,
      },
    })
  }

  return (
    <Map
      marker={position}
      isModal={isModal}
      isMobile={isMobile}
      markers={markers}
      currentLocation={currentLocation}
    >
      {markers.map((marker) => {
        return (
          <Marker
            key={marker.id + state.currentStore?.id}
            position={marker.position}
            title={marker.title}
            icon={
              config.useDefaultMapsIcon === "false"
                ? {
                    url: marker.icon,
                    scaledSize: new google.maps.Size(50, 50),
                  }
                : undefined
            }
            onClick={onClick(marker)}
          />
        )
      })}
      {currentLocation && (
        <Marker
          position={{
            lat: currentLocation.latitude,
            lng: currentLocation.longitude,
          }}
          title={t("currentLocation")}
          icon={{
            url: CurrentLocationIcon.src,
            scaledSize: new google.maps.Size(40, 40),
            anchor: new google.maps.Point(20, 20),
          }}
        />
      )}
    </Map>
  )
}

function Map(props) {
  const {
    onClick,
    onIdle,
    children,
    isModal,
    markers,
    currentLocation,
    ...options
  } = props
  const ref = useRef(null)
  const [map, setMap] = useState()

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  useEffect(() => {
    if (map) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      )

      if (onClick) {
        map.addListener("click", onClick)
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle])

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current))
    } else if (ref.current && map) {
      // https://stackoverflow.com/questions/15719951/auto-center-map-with-multiple-markers-in-google-maps-api-v3
      const bounds = new google.maps.LatLngBounds()
      if (markers.length) {
        markers.forEach((marker) => {
          bounds.extend(
            new google.maps.LatLng(marker.latitude, marker.longitude)
          )
        })
        if (currentLocation) {
          bounds.extend(
            new google.maps.LatLng(
              currentLocation.latitude,
              currentLocation.longitude
            )
          )
        }
        map.fitBounds(bounds)
      }
    }
  }, [ref, map, markers, currentLocation])

  return (
    <>
      <Box
        ref={ref}
        id="map"
        sx={{
          height: "100%",
          minHeight: isModal ? "360px" : "55vh",
          width: "100%",
          borderRadius: "12px",
        }}
      />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return cloneElement(child, { map })
        }
      })}
    </>
  )
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (a instanceof google.maps.LatLng || b instanceof google.maps.LatLng) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
  }

  // TODO extend to other types

  // use fast-equals for other objects
  return deepEqual(a, b)
})

function useDeepCompareEffectForMaps(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

function useDeepCompareMemoize(value) {
  const ref = useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
