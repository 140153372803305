import * as React from "react"
import { SVGProps } from "react"

const SvgCarrotColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.30072 0H14.042V11.4965L11.6713 14.4335L9.30072 11.4965V0Z"
      fill="#0AAD0A"
    />
    <path
      d="M16.2133 5.24559L13.8426 8.59174L11.6713 14.4344C14.1154 12.8575 19.0853 9.63019 19.3286 9.46236C19.6328 9.25257 22.9895 7.1337 20.3776 4.58476C18.4307 3.12462 16.7902 4.41693 16.2133 5.24559Z"
      fill="#0AAD0A"
    />
    <path
      d="M7.15268 5.24559L9.5233 8.59174L11.6713 14.4344C9.22728 12.8575 4.28066 9.63019 4.03731 9.46236C3.73311 9.25257 0.376481 7.1337 2.98836 4.58476C4.93521 3.12462 6.57576 4.41693 7.15268 5.24559Z"
      fill="#0AAD0A"
    />
    <path
      d="M20.1048 24H11.7132H3.32166C3.32166 19.3654 7.07869 15.6084 11.7132 15.6084C16.3478 15.6084 20.1048 19.3654 20.1048 24Z"
      fill="#FF7009"
    />
  </svg>
)

export default SvgCarrotColorIcon
