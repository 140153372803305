import { CloseIcon, TuneIcon } from "@/icons"
import {
  Button,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { SortDimensionId } from "generated/graphql"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import CouponCategories from "./CouponCategories"
import { SortDimensionMap } from "./CouponsSortByMenu"
import { CouponCategoriesProps } from "./types"
import { useAuth } from "contexts/AuthContext"

export type MobileRefineProps = {
  couponsLength: number
  sortBy: SortDimensionId
  coupanType: string
  onSortChange: (val: SortDimensionId) => void
  couponsId?:string
} & CouponCategoriesProps

export default function CouponsMobileRefine({
  coupanType,
  couponsLength,
  sortBy,
  categoryList,
  totalCount,
  couponsId,
  onSortChange,
}: MobileRefineProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const { showSubNav, updateSaubNavBar } = useAuth()

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        width="inherit"
        px={2}
        pr="10px"
        py="2px"
        sx={{
          backgroundColor: "#F9F8F7",
          borderRadius: 2,
          height: 40,
        }}
        mb={2}
      >
        <Typography color="textSecondary">
          {t("common.results", { count: couponsLength })}
        </Typography>
        {coupanType === "0" && (
          <Button
            aria-label={t("couponsRefine.refine")}
            startIcon={<TuneIcon color={theme.palette.ctaPrimary.main} />}
            color="textPrimary"
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
              updateSaubNavBar(false)
            }}
          >
            {t("couponsRefine.refine")}
          </Button>
        )}
      </Stack>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          alignSelf="stretch"
          width="inherit"
          p={2}
          minWidth="300px"
          py={1}
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "bgPrimary.main",
            zIndex: 9,
            borderBottom: `1px solid ${theme.palette.dividerColor?.main}`,
          }}
        >
          <Typography variant="h5">{t("couponsRefine.refine")}</Typography>
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              setOpen(false)
              updateSaubNavBar(true)
            }}
            aria-label={t("ariaLabels.closeButton")}
          >
            <CloseIcon color={theme.palette.ctaPrimary.main} />
          </IconButton>
        </Stack>
        <Stack p={2}>
          {/* <Typography variant="h6" mb={2}>
            {t("common.sortBy")}
          </Typography>
          <FormControl sx={{ mb: 3 }}>
            <RadioGroup
              aria-labelledby="coupons-sort-by-menu"
              name="coupons-sort-by-menu"
              value={sortBy}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onSortChange(
                  (e.target as HTMLInputElement).value as SortDimensionId
                );
              }}
              sx={{ pl: "16px" }}
            >
              {Object.entries(SortDimensionMap).map(([sortId, value]) => (
                <FormControlLabel
                  key={sortId}
                  control={<Radio color="ctaPrimary" size="small" />}
                  label={value as string}
                  value={sortId}
                />
              ))}
            </RadioGroup>
          </FormControl> */}
          {coupanType === "0" ? (
            <CouponCategories
              categoryList={categoryList}
              totalCount={totalCount}
              onClick={() => setOpen(false)}
              couponsId={couponsId}
            />
          ) : (
            ""
          )}
          {/* <CouponCategories
            categoryList={categoryList}
            totalCount={totalCount}
            onClick={() => setOpen(false)}
          /> */}
        </Stack>
      </Drawer>
    </>
  )
}
