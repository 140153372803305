import {
  ChevronUpIcon,
  ExternalLink,
  FacebookIcon,
  InstagramIcon,
  MinusIcon,
  PlusIcon,
} from "@/icons"
import styled from "@emotion/styled"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import axios from "axios"
import { useAuth } from "contexts/AuthContext"
import { openSnackbar } from "contexts/actions/actions"
import Image from "next/image"
import Link from "next/link"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { GitRef } from "swiftlykit"
import { faroPushErrors } from "utils/faroGrafanaLogs"
import generateConfig from "../../configs/config"
import { AppContext } from "../../contexts/AppContext"
import Apple from "../../public/images/Apple.svg"
import Google from "../../public/images/Google.svg"
import styles from "../../styles/component.module.scss"
import { EventName, gaLogEvent } from "../../utils/googleAnalyticsEvents"

const config = generateConfig()

const StyledLink = styled((props: any) => (
  <ListItem>
    <Link
      passHref
      rel="noopener noreferrer"
      role="link"
      {...props}
      onClick={() => {
        gaLogEvent({
          eventName: EventName.navFooter_click,
          parameters: {
            ...(Array.isArray(props.children) ||
            typeof props.children === "string"
              ? {
                  text_click: Array.isArray(props.children)
                    ? props.children[0]
                    : props.children,
                }
              : props.href === config?.facebookUrl
              ? { text_click: "Facebook" }
              : props.href === config?.instagramUrl
              ? { text_click: "Instagram" }
              : props.href === config?.appStoreLink
              ? { text_click: "App Store" }
              : props.href === config?.playStoreLink
              ? { text_click: "Google Play" }
              : null),
            item_message1: props.section,
            link_sendTo: props.href,
            User_AppDownload_Click:
              props.href === config.appStoreLink ||
              props.href === config.playStoreLink
                ? "true"
                : "false",
          },
        })
      }}
    />
  </ListItem>
))(({ theme }) => ({
  margin: "0.5rem 0rem",
  color:
    config?.configBanner === "luckysupermarkets"
      ? `${theme.palette.textPrimary?.main}`
      : `${theme.palette.bgPrimary?.main}`,
}))

export default function BottomNavBar() {
  const theme = useTheme()
  const isMobile = useMediaQuery("(max-width:767px)")
  const isTablet = useMediaQuery("(max-width:1199px)")
  const { t } = useTranslation()

  const [accExpanded, setAccExpanded] = useState<string | boolean>(false)
  const [hideButton, setHideButton] = useState(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setAccExpanded(isExpanded ? panel : false)
    }

  const { isAuthenticated } = useAuth()
  const states = useContext(AppContext)
  const {
    state: { currentStore },
  } = useContext(AppContext)

  const { dispatch } = useContext(AppContext)

  const footerBg =
    config?.configBanner === "luckysupermarkets"
      ? `${theme.palette.footerBg?.main}`
      : `${theme.palette.ctaPrimary?.main}`
  const textPrimary =
    config?.configBanner === "luckysupermarkets"
      ? `${theme.palette.textPrimary?.main}`
      : `${theme.palette.bgPrimary?.main}`
  const borderColor =
    config?.configBanner === "luckysupermarkets"
      ? `${theme.palette.ctaPrimary?.main}`
      : `${theme.palette.bgPrimary?.main}`
  const footerBorder =
    config?.configBanner === "luckysupermarkets"
      ? `${theme.palette.luckyFooter?.main}`
      : `${theme.palette.footerDivider?.main}`

  let width
  switch (config?.configBanner) {
    case "luckysupermarkets":
      width = 230
      break
    case "foodmaxx":
      width = 172
      break
    case "savemart":
      width = 194
      break
    default:
      width = 194
  }

  React.useEffect(() => {
    const handleScroll = () => {
      const pageHeight = document.documentElement.scrollHeight
      const viewportHeight = window.innerHeight

      if (pageHeight > 1.5 * viewportHeight) {
        setHideButton(true)
      } else {
        setHideButton(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleBackToTopClick = () => {
    // Navigate to #main-content
    const mainContent = document.getElementById("main-content")
    if (mainContent) {
      mainContent.focus()
      mainContent.scrollIntoView({
        behavior: "smooth",
      })
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
    gaLogEvent({
      eventName: EventName.navPage_backToTopClick,
      parameters: {
        item_nameCategory:
          states.state.breadcrumbs &&
          states.state.breadcrumbs[2]?.name &&
          states.state.breadcrumbs[2]?.name,
        item_nameCategorySub:
          states.state.breadcrumbs &&
          states.state.breadcrumbs[3] &&
          states.state.breadcrumbs[3]?.name,
      },
    })
  }

  const flyerUrlParams = currentStore ? `/${currentStore.number}` : ""
  const flyerUrl = `/flyers${flyerUrlParams}`

  const quickLink = (
    <Stack className={styles.footer_Links}>
      <List sx={{ p: 0 }}>
        <StyledLink
          href={config?.shopNow || ""}
          target="_blank"
          section="quickLink"
          aria-label={`${t("ariaLabels.open")} ${t("bottomNavBar.shop")} ${t(
            "ariaLabels.inNewTab"
          )}`}
        >
          {t("bottomNavBar.shop")}
          <ExternalLink className={styles.external_link} />
        </StyledLink>
        {config?.giftCardLink && (
          <StyledLink
            href={config?.giftCardLink || ""}
            target="_blank"
            section="quickLink"
            aria-label={`${t("ariaLabels.open")} ${t("bottomNavBar.shop")} ${t(
              "ariaLabels.inNewTab"
            )}`}
          >
            {t("bottomNavBar.giftCards")}
            <ExternalLink className={styles.external_link} />
          </StyledLink>
        )}
        <StyledLink href={flyerUrl} section="quickLink">
          {t("bottomNavBar.weekly")}
        </StyledLink>
        <StyledLink href="/consumer-information" section="quickLink">
          {t("bottomNavBar.consumerInformation")}
        </StyledLink>
        {config?.configBanner === "foodmaxx" && (
          <StyledLink href="/maxx-savings" section="quickLink">
            {t("bottomNavBar.maxxSavings")}
          </StyledLink>
        )}
        <StyledLink href="/contact-us" section="quickLink">
          {t("contactUs.title")}
        </StyledLink>
        <StyledLink href="/stores" section="quickLink">
          {t("bottomNavBar.location")}
        </StyledLink>
        {config?.quickLinks?.map((item: any, index: number) => (
          <StyledLink href={item.url} section="quickLink" key={index}>
            {item.title}
          </StyledLink>
        ))}
        {config?.enableRewards && (
          <StyledLink
            href={isAuthenticated ? "/my-rewards" : "/rewards-login"}
            section="quickLink"
          >
            {t("bottomNavBar.rewards")}
          </StyledLink>
        )}
        {config?.enableShoppingList && (
          <StyledLink href="/shopping-list" section="quickLink">
            {t("bottomNavBar.shoppingList")}
          </StyledLink>
        )}
        {config?.configBanner !== "foodmaxx" && (
          <StyledLink
            href={config?.madeToOrder || ""}
            target="_blank"
            section="quickLink"
            aria-label={`${t("ariaLabels.open")} ${t(
              "bottomNavBar.madeToOrder"
            )} ${t("ariaLabels.inNewTab")}`}
          >
            {t("bottomNavBar.madeToOrder")}
            <ExternalLink className={styles.external_link} />
          </StyledLink>
        )}
        <StyledLink href="/faq">{t("bottomNavBar.faq")}</StyledLink>
        <Box id="teconsent" p={2} sx={{ color: textPrimary }}></Box>
      </List>
    </Stack>
  )

  const policies = (
    <Stack className={styles.footer_Links}>
      <List sx={{ p: 0 }}>
        <StyledLink href="/ad-choices" section="policies">
          {t("bottomNavBar.choices")}
        </StyledLink>
        <StyledLink href="/california-privacy-rights" section="policies">
          {t("bottomNavBar.DoNotSellMyInfo")}
        </StyledLink>
        <StyledLink
          href={
            config?.configBanner === "foodmaxx"
              ? "/prop-65-warning"
              : "/californias-proposition-65-warnings"
          }
          section="policies"
        >
          {t("bottomNavBar.propositionWarning")}
        </StyledLink>
        <StyledLink
          href={
            config?.configBanner === "foodmaxx"
              ? "/legal/supply-chain-transparency"
              : "/supply-chain-transparency"
          }
          section="policies"
        >
          {t("bottomNavBar.supplyChain")}
        </StyledLink>
        <StyledLink href="/cookies-and-ads-policy" section="policies">
          {t("bottomNavBar.cookiesPolicy")}
        </StyledLink>
        <StyledLink href="/accessibility-statement" section="policies">
          {t("bottomNavBar.accessibility")}
        </StyledLink>
        <StyledLink
          href={
            config?.configBanner === "foodmaxx"
              ? "/legal/privacy-policy"
              : "/privacy-policy"
          }
          section="policies"
        >
          {t("bottomNavBar.privacyPolicy")}
        </StyledLink>
        <StyledLink
          href={
            config?.configBanner === "foodmaxx"
              ? "/legal/terms-and-conditions"
              : "/terms-use"
          }
          section="policies"
        >
          {t("bottomNavBar.terms")}
        </StyledLink>
        {config?.configBanner !== "foodmaxx" && (
          <StyledLink
            href={
              config?.configBanner === "savemart"
                ? "/save-mart-rewards-terms-and-conditions"
                : "/lucky-rewards-terms-and-conditions"
            }
            section="policies"
          >
            {t("bottomNavBar.RewardsFAQ")}
          </StyledLink>
        )}
      </List>
    </Stack>
  )

  const companyInfo = (
    <Stack className={styles.footer_Links}>
      <List sx={{ p: 0 }}>
        <StyledLink
          href={config?.aboutUsUrl || ""}
          target="_blank"
          section="companyInfo"
          aria-label={`${t("ariaLabels.open")} ${t("bottomNavBar.aboutUs")} ${t(
            "ariaLabels.inNewTab"
          )}`}
        >
          {t("bottomNavBar.aboutUs")}
          <ExternalLink className={styles.external_link} />
        </StyledLink>
        <StyledLink
          href={config?.newReleasesUrl || ""}
          target="_blank"
          section="companyInfo"
          aria-label={`${t("ariaLabels.open")} ${t(
            "bottomNavBar.pressRelease"
          )} ${t("ariaLabels.inNewTab")}`}
        >
          {t("bottomNavBar.pressRelease")}
          <ExternalLink className={styles.external_link} />
        </StyledLink>
        <StyledLink
          href={config?.supplierPortalUrl || ""}
          target="_blank"
          section="companyInfo"
          aria-label={`${t("ariaLabels.open")} ${t(
            "bottomNavBar.supplierPortal"
          )} ${t("ariaLabels.inNewTab")}`}
        >
          {t("bottomNavBar.supplierPortal")}
          <ExternalLink className={styles.external_link} />
        </StyledLink>
        <StyledLink href="/recall-information" section="companyInfo">
          {t("bottomNavBar.recallInformation")}
        </StyledLink>
        <StyledLink
          href="https://www.thesavemartcompanies.com/community/"
          section="companyInfo"
          target="_blank"
        >
          {t("bottomNavBar.community")}
          <ExternalLink className={styles.external_link} />
        </StyledLink>
        <StyledLink href="/careers" section="companyInfo">
          {t("bottomNavBar.careers")}
        </StyledLink>
      </List>
    </Stack>
  )

  return (
    <>
      <Container maxWidth="xl">
        <Box display="flex" justifyContent="end" mb={3}>
          {isMobile ? (
            <>
              <IconButton
                size="small"
                sx={{ padding: 1, backgroundColor: "lightPrimary.main" }}
                onClick={handleBackToTopClick}
                href="#main-content"
                aria-label={t("bottomNavBar.backToTop")}
              >
                <ChevronUpIcon style={{ width: 24, height: 24 }} />
              </IconButton>
            </>
          ) : (
            <>
              {hideButton && (
                <Button
                  aria-label={t("bottomNavBar.backToTop")}
                  size="small"
                  onClick={handleBackToTopClick}
                  href="#main-content"
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    color: "textPrimary.main",
                    backgroundColor: "lightPrimary.main",
                    "&:hover": { backgroundColor: "lightPrimary.main" },
                  }}
                >
                  <ChevronUpIcon style={{ width: 24, height: 24 }} />
                  {t("bottomNavBar.backToTop")}
                  <ChevronUpIcon style={{ width: 24, height: 24 }} />
                </Button>
              )}
            </>
          )}
        </Box>
      </Container>
      <Box
        id="footer"
        component="footer"
        sx={{
          backgroundColor: footerBg,
        }}
        className={styles.footer_Wrapper}
      >
        <Container maxWidth="xl">
          {isMobile ? (
            <>
              <Stack className={styles.footer_box}>
                <Grid container spacing={3} direction="column-reverse">
                  <Grid item xs={isTablet ? 12 : 6}>
                    <Stack
                      className={`${styles.footer_Left} ${styles.marginLeft} ${styles.mobileLeft}`}
                    >
                      <Stack
                        className={`${styles.footer_mobileResponsiveblock} ${styles.iSection_justify}`}
                        sx={{ borderBottom: `1px dashed ${footerBorder}` }}
                      >
                        <Image
                          className={styles.footer_logo}
                          src={config?.siteLogoLight}
                          alt={config?.siteName}
                          width={width}
                          height={50}
                          tabIndex={0}
                          priority
                        />
                        <Stack
                          direction="row"
                          className={styles.footer_socialIcons}
                        >
                          <StyledLink
                            href={config?.facebookUrl || ""}
                            target="_blank"
                            section="Social Handles"
                            aria-label={`${t("ariaLabels.open")} ${t(
                              "bottomNavBar.facebook"
                            )} ${t("ariaLabels.inNewTab")}`}
                          >
                            <Stack
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <FacebookIcon
                                color={borderColor}
                                style={{ width: 24, height: 24 }}
                              />
                            </Stack>
                          </StyledLink>
                          <StyledLink
                            href={config?.instagramUrl || ""}
                            target="_blank"
                            section="Social Handles"
                            aria-hidden
                          >
                            <Stack
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <InstagramIcon
                                color={borderColor}
                                style={{ width: 24, height: 24 }}
                              />
                            </Stack>
                          </StyledLink>
                        </Stack>
                      </Stack>
                      <Box className={styles.DownloadApp}>
                        <Typography
                          variant="h6"
                          sx={{ color: textPrimary }}
                          className={styles.footer_DownloadTitle}
                        >
                          {t("bottomNavBar.appTitle")}
                        </Typography>
                        <Stack className={styles.footer_appstoreResponsive}>
                          <StyledLink
                            href={config?.appStoreLink || ""}
                            target="_blank"
                            section="Download Apps"
                            aria-label={`${t("ariaLabels.open")} ${t(
                              "ariaLabels.downloadOnTheAppStore"
                            )} ${t("ariaLabels.inNewTab")}`}
                          >
                            <Image src={Apple} alt="Apple store" priority />
                          </StyledLink>
                          <StyledLink
                            href={config?.playStoreLink || ""}
                            target="_blank"
                            section="Download Apps"
                            aria-label={`${t("ariaLabels.open")} ${t(
                              "ariaLabels.getOnGooglePlay"
                            )} ${t("ariaLabels.inNewTab")}`}
                          >
                            <Image src={Google} alt="Google store" priority />
                          </StyledLink>
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={isTablet ? 12 : 6}
                    className={`${styles.footer_Right} ${styles.mobileNavright}`}
                  >
                    <Accordion
                      expanded={accExpanded === "panel1"}
                      onChange={handleChange("panel1")}
                      className={styles.footer_Accordian}
                    >
                      <AccordionSummary
                        expandIcon={
                          accExpanded === "panel1" ? (
                            <MinusIcon
                              fill={textPrimary}
                              width={24}
                              height={24}
                            />
                          ) : (
                            <PlusIcon
                              fill={textPrimary}
                              width={24}
                              height={24}
                            />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={styles.footer_accordionSummary}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: textPrimary }}
                          className={styles.footer_Linkh6}
                          aria-label={t("bottomNavBar.quickLink")}
                          tabIndex={0}
                        >
                          {t("bottomNavBar.quickLink")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={styles.footer_accordianDetails}
                      >
                        {quickLink}
                      </AccordionDetails>
                    </Accordion>
                    <Divider
                      sx={{
                        borderColor: footerBorder,
                        borderStyle: "dashed",
                        borderWidth: 0,
                        borderBottomWidth: "1px",
                        m: 0,
                      }}
                    />
                    <Accordion
                      expanded={accExpanded === "panel2"}
                      onChange={handleChange("panel2")}
                      className={styles.footer_Accordian}
                    >
                      <AccordionSummary
                        expandIcon={
                          accExpanded === "panel2" ? (
                            <MinusIcon
                              fill={textPrimary}
                              width={24}
                              height={24}
                            />
                          ) : (
                            <PlusIcon
                              fill={textPrimary}
                              width={24}
                              height={24}
                            />
                          )
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className={styles.footer_accordionSummary}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: textPrimary }}
                          className={styles.footer_Linkh6}
                          aria-label={t("bottomNavBar.policies")}
                          tabIndex={0}
                        >
                          {t("bottomNavBar.policies")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={styles.footer_accordianDetails}
                      >
                        {policies}
                      </AccordionDetails>
                    </Accordion>
                    <Divider
                      sx={{
                        borderColor: footerBorder,
                        borderStyle: "dashed",
                        borderWidth: 0,
                        borderBottomWidth: "1px",
                        m: 0,
                      }}
                    />
                    <Accordion
                      expanded={accExpanded === "panel3"}
                      onChange={handleChange("panel3")}
                      className={styles.footer_Accordian}
                    >
                      <AccordionSummary
                        expandIcon={
                          accExpanded === "panel3" ? (
                            <MinusIcon
                              fill={textPrimary}
                              width={24}
                              height={24}
                            />
                          ) : (
                            <PlusIcon
                              fill={textPrimary}
                              width={24}
                              height={24}
                            />
                          )
                        }
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        className={styles.footer_accordionSummary}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: textPrimary }}
                          className={styles.footer_Linkh6}
                          aria-label={t("bottomNavBar.companyInfo")}
                          tabIndex={0}
                        >
                          {t("bottomNavBar.companyInfo")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={styles.footer_accordianDetails}
                      >
                        {companyInfo}
                      </AccordionDetails>
                    </Accordion>
                    <Divider
                      sx={{
                        borderColor: footerBorder,
                        borderStyle: "dashed",
                        borderWidth: 0,
                        borderBottomWidth: "1px",
                        m: 0,
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            <>
              <Stack
                direction={isTablet ? "column" : "row"}
                className={styles.footer_box}
              >
                <Stack
                  direction="row"
                  flexGrow={1}
                  justifyContent="space-between"
                  spacing={20}
                  className={styles.footer_Right}
                >
                  <Box className={styles.marginBox}>
                    <Typography
                      variant="h5"
                      fontSize="16px"
                      fontWeight={600}
                      sx={{ color: textPrimary }}
                      textTransform="uppercase"
                      className={styles.footer_Linkh6}
                      aria-label={t("bottomNavBar.quickLink")}
                      tabIndex={0}
                    >
                      {t("bottomNavBar.quickLink")}
                    </Typography>
                    <Divider
                      className={styles.footer_divider}
                      sx={{
                        width: 40,
                        borderColor: borderColor,
                      }}
                    />
                    {quickLink}
                  </Box>
                  <Box
                    className={`${styles.marginBox} ${styles.marginDiff}`}
                    sx={{
                      borderLeft: isMobile
                        ? "none"
                        : `1px dashed ${footerBorder}`,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontSize="16px"
                      fontWeight={600}
                      sx={{ color: textPrimary }}
                      textTransform="uppercase"
                      className={styles.footer_Linkh6}
                      aria-label={t("bottomNavBar.policies")}
                      tabIndex={0}
                    >
                      {t("bottomNavBar.policies")}
                    </Typography>
                    <Divider
                      className={styles.footer_divider}
                      sx={{
                        width: 40,
                        borderColor: borderColor,
                      }}
                    />
                    {policies}
                  </Box>
                  <Box
                    className={`${styles.marginBox} ${styles.marginDiff} ${styles.lastDiffe}`}
                    sx={{
                      borderLeft: isMobile
                        ? "none"
                        : `1px dashed ${footerBorder}`,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontSize="16px"
                      fontWeight={600}
                      sx={{ color: textPrimary }}
                      className={styles.footer_Linkh6}
                      role="heading"
                      tabIndex={0}
                    >
                      {t("bottomNavBar.companyInfo")}
                    </Typography>
                    <Divider
                      className={styles.footer_divider}
                      sx={{
                        width: 40,
                        borderColor: borderColor,
                      }}
                    />
                    {companyInfo}
                  </Box>
                </Stack>
                <Stack
                  className={`${styles.footer_Left} ${styles.marginLeft}`}
                  sx={{
                    borderLeft: isMobile
                      ? "none"
                      : isTablet
                      ? "none"
                      : `1px dashed ${footerBorder}`,
                    borderTop: isMobile
                      ? "none"
                      : isTablet
                      ? `1px dashed ${footerBorder}`
                      : "none",
                  }}
                >
                  <Box
                    className={styles.iSection_justify}
                    sx={{
                      borderBottom: isTablet
                        ? `1px dashed ${footerBorder}`
                        : "none",
                    }}
                  >
                    <Image
                      className={styles.footer_logo}
                      src={config?.siteLogoLight}
                      alt={config?.siteName}
                      width={width}
                      height={50}
                      tabIndex={0}
                      priority
                    />
                    <Stack
                      direction="row"
                      className={styles.footer_socialIcons}
                    >
                      <StyledLink
                        href={config?.facebookUrl || ""}
                        target="_blank"
                        section="Social Handles"
                        aria-label={`${t("ariaLabels.open")} ${t(
                          "bottomNavBar.facebook"
                        )} ${t("ariaLabels.inNewTab")}`}
                      >
                        <Stack
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <FacebookIcon
                            color={borderColor}
                            style={{ width: 24, height: 24 }}
                          />
                        </Stack>
                      </StyledLink>
                      <StyledLink
                        href={config?.instagramUrl || ""}
                        target="_blank"
                        section="Social Handles"
                        aria-label={`${t("ariaLabels.open")} ${t(
                          "bottomNavBar.instagram"
                        )} ${t("ariaLabels.inNewTab")}`}
                      >
                        <Stack
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <InstagramIcon
                            color={borderColor}
                            style={{ width: 24, height: 24 }}
                          />
                        </Stack>
                      </StyledLink>
                    </Stack>
                  </Box>
                  <Box className={styles.DownloadApp}>
                    <Typography
                      variant="h6"
                      sx={{ color: textPrimary }}
                      className={styles.footer_DownloadTitle}
                    >
                      {t("bottomNavBar.appTitle")}
                    </Typography>
                    <StyledLink
                      href={config?.appStoreLink || ""}
                      target="_blank"
                      section="Download Apps"
                      aria-label={`${t("ariaLabels.open")} ${t(
                        "ariaLabels.downloadOnTheAppStore"
                      )} ${t("ariaLabels.inNewTab")}`}
                    >
                      <Image src={Apple} alt="Apple store" priority />
                    </StyledLink>
                    <StyledLink
                      href={config?.playStoreLink || ""}
                      target="_blank"
                      section="Download Apps"
                      aria-label={`${t("ariaLabels.open")} ${t(
                        "ariaLabels.getOnGooglePlay"
                      )} ${t("ariaLabels.inNewTab")}`}
                    >
                      <Image src={Google} alt="Google store" priority />
                    </StyledLink>
                  </Box>
                </Stack>
              </Stack>
            </>
          )}
          <Divider
            sx={{
              borderColor: footerBorder,
              borderStyle: "dashed",
              borderWidth: 0,
              borderBottomWidth: "1px",
            }}
            className={styles.footer_disclaimerbottomDivider}
          />
          <Stack
            className={styles.footer_allrightText}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              fontWeight={400}
              className={styles.footer_copyright}
              color={textPrimary}
            >
              {t("bottomNavBar.copyRights", {
                year: new Date().getFullYear(),
                siteName: config?.metaSiteName,
              })}
            </Typography>

            <Stack direction="row" alignItems="center">
              <GitRef
                sha={config?.appShaVersion as string}
                sx={{
                  ml: 1,
                  height: "auto",
                  ".MuiChip-label": { color: "bgPrimary.main" },
                }}
              />
              {config?.env !== "production" && (
                <Typography variant="info" color="textMuted.main">
                  {t("bottomNavBar.env", {
                    env: config?.env,
                  })}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
