import React from "react"
import { Box, useMediaQuery, useTheme } from "@mui/material"
import Image from "next/image"
import loader from "../../assets/post-loader.gif"

const AdsPlaceHolder = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(800))
  const height = isMobile ? "50%" : "25%"
  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: height,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Image
        src={loader}
        alt="loader"
        style={{
          position: "absolute",
          height: "200%",
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%)`,
        }}
      />
    </Box>
  )
}

export default AdsPlaceHolder
