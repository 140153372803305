import React, { CSSProperties, ReactNode, useState } from "react"
import Carousel from "nuka-carousel"
import {
  Box,
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Pause, PlayArrow } from "@mui/icons-material"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
export interface SwipeableTextMobileStepperProps {
  children: ReactNode[]
}

export default function SwipeableTextMobileStepper({
  children,
}: SwipeableTextMobileStepperProps) {
  const theme = useTheme()
  const [paused, setPaused] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down(700))
  const [controlNo, setControlNo] = useState(0)

  if (children.length === 0) return <></>

  return (
    <Box style={{ position: "relative", display: "flex" }}>
      <Carousel
        autoplay={!paused}
        autoplayInterval={5000}
        zoomScale={5}
        dragging={children.length > 1}
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        renderBottomCenterControls={({
          slideCount,
          goToSlide,
          currentSlide,
        }) => {
          return (
            <>
              <Box
                className="slider-control-bottomcenter"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "20px",
                  marginBottom: "20px",
                  padding: isMobile ? "0" : "4px",
                }}
              >
                {!isMobile && (
                  <IconButton
                    aria-label={`${paused ? "Play" : "Pause"} coupon carousel`}
                    onClick={() => setPaused(!paused)}
                    sx={{
                      p: "0 10px",
                      borderRight: `1px solid ${
                        !isMobile && theme.palette.dividerColor?.main
                      }`,
                      color: theme.palette.ctaPrimary.main,
                      display: "block",
                      borderRadius: "20px 0px 0px 20px",
                    }}
                    role="button"
                  >
                    {paused ? (
                      <PlayArrow sx={{ width: 18, height: 18 }} />
                    ) : (
                      <Pause sx={{ width: 18, height: 18 }} />
                    )}
                  </IconButton>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  padding="6px"
                  marginBottom={isMobile ? "calc(-64px)" : ""}
                >
                  {[...Array(slideCount === 1 ? 2 : slideCount)].map(
                    (sc, i) => (
                      <Box
                        style={{
                          listStyleType: "none",
                          display: "inline-block",
                        }}
                        key={i + 1}
                        onClick={(): void => {
                          if (slideCount === 1) {
                            setControlNo(i)
                          }
                          goToSlide(i)
                        }}
                      >
                        <Button
                          aria-label={`Slide number ${i + 1}`}
                          role="button"
                          sx={{
                            minWidth: "8px",
                            width: "8px",
                            height: "17px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            border: `1px ${theme.palette.ctaPrimary.main} solid`,
                            background:
                              (currentSlide === i && slideCount !== 1) ||
                              (slideCount === 1 && controlNo === i)
                                ? theme.palette.ctaPrimary.main
                                : "transparent",
                          }}
                        />
                      </Box>
                    )
                  )}
                </Stack>
              </Box>
            </>
          )
        }}
        wrapAround={true}
        style={
          {
            cursor: "grab",
            display: "flex",
            borderRadius: 10,
            alignItems: "stretch",
          } as CSSProperties
        }
        cellSpacing={10}
        defaultControlsConfig={{
          containerClassName:
            "SwipeableTextMobileStepper-container" +
            (isMobile ? " display-none" : ""),
          pagingDotsStyle: {
            display: children.length > 1 ? undefined : "none",
            "--color": theme.palette.ctaPrimary.main,
            fill: theme.palette.ctaPrimary.main,
            stroke: theme.palette.ctaPrimary.main,
          } as CSSProperties,
          pagingDotsClassName: "SwipeableTextMobileStepper-pagingDots",
        }}
      >
        {Carousel.defaultProps.slideIndex}
        {children}
      </Carousel>
    </Box>
  )
}
