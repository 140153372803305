import * as React from "react"
import { SVGProps } from "react"
const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.293 18.293 10.586 12 4.293 5.707a1 1 0 0 1 1.414-1.414L12 10.586l6.293-6.293a1 1 0 1 1 1.414 1.414L13.414 12l6.293 6.293a.998.998 0 0 1-.316 1.645.999.999 0 0 1-1.098-.23L12 13.413l-6.293 6.293a1 1 0 0 1-1.414-1.414Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCloseIcon
