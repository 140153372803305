import * as React from "react"
import { SVGProps } from "react"

const SvgNotificationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99999 20H11C11 20.5304 10.7893 21.0391 10.4142 21.4142C10.0391 21.7893 9.53043 22 8.99999 22C8.46956 22 7.96085 21.7893 7.58578 21.4142C7.21071 21.0391 6.99999 20.5304 6.99999 20ZM0.0759938 17.383C0.000289015 17.2002 -0.019502 16.9991 0.019125 16.8051C0.057752 16.6111 0.153061 16.4328 0.292994 16.293L1.99999 14.586V9C2.00321 7.3185 2.61109 5.69424 3.71263 4.42379C4.81418 3.15334 6.33592 2.32145 7.99999 2.08V1C7.99999 0.734784 8.10535 0.48043 8.29289 0.292893C8.48042 0.105357 8.73478 0 8.99999 0C9.26521 0 9.51956 0.105357 9.7071 0.292893C9.89464 0.48043 9.99999 0.734784 9.99999 1V2.08C11.6641 2.32145 13.1858 3.15334 14.2874 4.42379C15.3889 5.69424 15.9968 7.3185 16 9V14.586L17.707 16.293C17.8468 16.4329 17.942 16.611 17.9806 16.805C18.0191 16.9989 17.9993 17.2 17.9237 17.3827C17.848 17.5654 17.7199 17.7215 17.5555 17.8314C17.391 17.9413 17.1977 18 17 18H0.999994C0.802228 18 0.608888 17.9415 0.444419 17.8316C0.27995 17.7218 0.151738 17.5657 0.0759938 17.383ZM3.41399 16H14.586L14.293 15.707C14.1054 15.5195 14.0001 15.2652 14 15V9C14 7.67392 13.4732 6.40215 12.5355 5.46447C11.5978 4.52678 10.3261 4 8.99999 4C7.67391 4 6.40214 4.52678 5.46446 5.46447C4.52678 6.40215 3.99999 7.67392 3.99999 9V15C3.99994 15.2652 3.89454 15.5195 3.70699 15.707L3.41399 16Z"
      fill="black"
    />
  </svg>
)
export default SvgNotificationIcon
