import { QueryClient } from "@tanstack/react-query"

// React query
// In v4, by default the server-side cacheTime is now set to Infinity
// effectively disabling manual garbage collection (the NodeJS process will clear everything once a request is complete).
// https://dev.to/arianhamdi/react-query-v4-ssr-in-next-js-2ojj#dehydrate
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
})

// Use this on server as it fails faster
export const queryClientServer = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
})
