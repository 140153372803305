// Based on: https://dillonshook.com/testing-nextjs-with-cypress-and-msw/
import Cookies from "js-cookie"

async function initMocks() {
  if (typeof window === "undefined") {
    if (global.serverMsw) {
      return
    }
    console.log("Init server MSW")
    const { server } = await import("./server")

    global.serverMsw = {
      server,
    }
    // TODO fix me
    global.serverMsw.server.listen({
      onUnhandledRequest: (req: any) => {
        console.warn(
          "[MSW] un-mocked request:",
          `${req.method} ${req.url.pathname} ${req.body?.toString()}`
        )
      },
    })
  } else {
    if (window.msw === undefined || window.msw.worker === undefined) {
      const { worker } = await import("./browser")
      window.msw = { worker }
      const nextRequestRegex = /\/_next.*/
      const imageRequestRegex = /\.(gif|jpg|jpeg|tiff|png|svg)$/i
      const imagePath = /\/img/
      const setupRequestRegex = /\/api\/setup/
      await worker.start({
        onUnhandledRequest: (req: any) => {
          if (req.url.pathname.match(nextRequestRegex)) return
          if (req.url.pathname.match(imageRequestRegex)) return
          if (req.url.pathname.match(imagePath)) return
          if (req.url.pathname.match(setupRequestRegex)) return
          console.warn(
            "[MSW] un-mocked request:",
            // @ts-ignore
            `${req?.method} : ${req?.url?.pathname} : ${req?.body?.operationName}`
          )
        },
      })

      // Cypress add test specific mock file
      const mockFile = Cookies.get("cypress:mock-file")
      if (mockFile && window.Cypress) {
        const allMocks = await import("mocks/testOverrideHandlers/index")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handlers = (allMocks as any)[mockFile].handlers
        worker.use(...handlers)
        console.log("Added browser handlers for " + mockFile)
      }
    }
  }
}

initMocks()

export { initMocks }
