import styled from "@emotion/styled"
import { Box, CircularProgress, CircularProgressProps } from "@mui/material"

const LoaderBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = ({
  height = "100vh",
  width,
  size,
  color = "ctaPrimary",
}: {
  height?: string
  width?: string
  size?: CircularProgressProps["size"]
  color?: CircularProgressProps["color"]
}) => {
  return (
    <LoaderBox sx={{ height, width }}>
      <CircularProgress size={size} color={color} />
    </LoaderBox>
  )
}

export default Loader
