import * as React from "react"
import { SVGProps } from "react"
const SvgMyStoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.485 4.485a8.485 8.485 0 0 0-12 12l5.27 5.28a1 1 0 0 0 1.42 0l5.31-5.33a8.45 8.45 0 0 0 0-11.95Zm-1.43 10.52-4.57 4.59-4.57-4.59a6.46 6.46 0 1 1 9.14 0Zm-7.57-7.59a4.32 4.32 0 0 0 0 6.1 4.31 4.31 0 0 0 7.36-3 4.24 4.24 0 0 0-1.26-3.05 4.3 4.3 0 0 0-6.1-.05Zm4.69 4.68a2.33 2.33 0 1 1 .67-1.63 2.33 2.33 0 0 1-.72 1.63h.05Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMyStoreIcon
