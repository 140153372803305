export enum ItemTypes {
  freeForm = "freeForm",
  catalog = "catalog",
}

export type ItemType = keyof typeof ItemTypes

export enum ItemStates {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
}

export type ItemState = keyof typeof ItemStates

export type ShoppingListItemType = {
  key: string
  title: string
  attributes?: {
    productId: string
  }
  productAttributes?: {
    brand?: string
    imageUrl?: string
    basePrice?: string
    promoPrice?: string | null
  }
  status: ItemState
}
