import { CloseIcon, SearchIcon } from "@/icons"
import {
  Divider,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { AppContext } from "contexts/AppContext"
import { resetPageAttributes } from "contexts/actions/actions"

import { useRouter } from "next/router"
import { FormEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.dividerColor?.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.ctaPrimary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.textPrimary.main,
    },
  },
}))

export const styles = {
  form: {
    flex: 1,
  },
}
const SearchField = () => {
  const [value, setValue] = useState<string>("")
  const router = useRouter()
  const theme = useTheme()
  const { t } = useTranslation()
  const { q } = router.query
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    setValue((q as string) || "")
  }, [q])

  useEffect(() => {
    if (window.location.pathname !== "/search") {
      if (q !== "" && q !== undefined) {
        router.push("/search?q=" + q)
      }
    }
  })

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    dispatch(resetPageAttributes())
    if (e) {
      e.preventDefault()
    }
    if (value !== "") {
      router.push("/search?q=" + value)
    }
  }

  return (
    <form onSubmit={onSubmit} role="search" style={styles.form}>
      <TextField
        id="product-search-input-filed"
        size="small"
        placeholder={t("navBarMenu.search.placeholder")}
        inputProps={{
          "aria-label": t("navBarMenu.search.placeholder"),
        }}
        value={value || ""}
        data-testid="searchTextField"
        onChange={(e) => {
          setValue(e.currentTarget.value)
        }}
        inputRef={(input) => isMobile && input && input.focus()}
        InputProps={{
          startAdornment: isMobile ? (
            <InputAdornment position="start">
              <IconButton
                type="submit"
                onClick={() => {
                  onSubmit()
                }}
                aria-label={t("ariaLabels.search")}
              >
                <SearchIcon
                  color={theme.palette.ctaPrimary?.main}
                  fontSize="1.25rem"
                />
              </IconButton>
            </InputAdornment>
          ) : null,
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <>
                  <IconButton
                    onClick={() => setValue("")}
                    aria-label={t("ariaLabels.closeButton")}
                  >
                    {isMobile ? (
                      <CloseIcon fontSize="14px" />
                    ) : (
                      <CloseIcon fontSize="14px" />
                    )}
                  </IconButton>

                  {!isMobile && (
                    <Divider
                      orientation="vertical"
                      sx={{ height: "1.5rem", margin: "0 6px" }}
                    />
                  )}
                </>
              )}

              {!isMobile && (
                <IconButton
                  type="submit"
                  onClick={() => {
                    onSubmit()
                  }}
                  sx={{ p: "5px" }}
                  aria-label={t("ariaLabels.search")}
                >
                  <SearchIcon
                    color={theme.palette.ctaPrimary?.main}
                    fontSize="1.25rem"
                  />
                </IconButton>
              )}
            </InputAdornment>
          ),
          name: "q",
        }}
        variant="outlined"
        sx={{
          width: "100%",
          "& fieldset": {
            borderRadius: isMobile ? "10px" : "24px",
          },
          "& input": {
            paddingLeft: isMobile
              ? (theme) => theme.spacing(0)
              : (theme) => theme.spacing(1),
          },
          "& .MuiInputBase-root": { paddingX: theme.spacing(1), minHeight: 40 },
          "& .MuiOutlinedInput-root fieldset": {
            borderColor: "#DADADA",
          },
        }}
      />
    </form>
  )
}

export default SearchField
