import React, { useEffect, useMemo, useRef } from "react"
import {
  Box,
  Button,
  Divider,
  Drawer,
  Menu,
  Stack,
  useMediaQuery,
  useTheme,
  IconButton,
  List,
  ListItem,
  Modal,
} from "@mui/material"
import Popover from "@mui/material/Popover"
import { MenuProps } from "@mui/material/Menu/Menu"
import TaxonomyApi from "../../services/TaxonomyApi"
import { useQuery } from "@tanstack/react-query"
import { swiftly } from "../../client-data-bom"
import {
  ArrowForwardIos,
  ArrowOutward,
  ArrowBackIos,
} from "@mui/icons-material"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import generateConfig from "../../configs/config"
import { CloseIcon } from "@/icons"
import { DrawerHeader } from "./SideNavBar"
import styles from "../../styles/component.module.scss"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
const config = generateConfig()

const style = {
  button: {
    py: 2,
    minWidth: 250,
    justifyContent: "flex-start",
    borderRadius: 0,
    fontSize: 15,
    fontWeight: 600,
    px: 2,
    flexGrow: 1,
  },
}

interface DepartmentsProps {
  setIsSideNavBarOpen: (isOpen: boolean) => void
  anchorElement?: MenuProps["anchorEl"]
  onClose: () => void
  isOpen: boolean
  isMobile: boolean
  productDepartments: any
  taxonomies: any
  taxonomy: any
}

const Departments = ({
  setIsSideNavBarOpen,
  anchorElement,
  onClose,
  isOpen,
  isMobile,
  productDepartments,
  taxonomies,
  taxonomy,
}: DepartmentsProps) => {
  const router = useRouter()
  const theme = useTheme()
  const [selectedDepartment, setSelectedDepartment] =
    React.useState<swiftly.taxonomy.SwiftlyJsTaxonomyNode | null>(null)
  const [activeElement, setActiveElement] = React.useState<string | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (isOpen) {
      setSelectedDepartment(null)
      setActiveElement(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (selectedDepartment) {
      document.getElementById("backToAllDepartments-button")?.focus()
    }
  }, [selectedDepartment])

  const getFocus = () => {
    if (activeElement) {
      document.getElementById(activeElement)?.focus()
    }
  }

  const gotoDepartment = (taxonomy: swiftly.taxonomy.SwiftlyJsTaxonomyNode) => {
    const categoryIdSanitized = encodeURIComponent(taxonomy.id)
    gaLogEvent({
      eventName: EventName.navTop_click,
      parameters: {
        text_click: taxonomy.displayName,
        link_sendTo: `/categories/${categoryIdSanitized}`,
        item_location: "Primary",
      },
    })
    router
      .push({
        pathname: "/categories/[categoryId]",
        query: { categoryId: categoryIdSanitized },
      })
      .catch(console.error)
    onClose?.()
  }
  const gotoAllCategories = () => {
    const gotUrl = `/categories`
    router.push(gotUrl).catch(console.error)
    onClose?.()
  }
  const isDesktop = useMediaQuery("(min-width:1279px)")
  const isTablet = useMediaQuery("(min-width:767px) and (max-width:1279px)")
  return !isDesktop ? (
    <Drawer
      anchor="right"
      variant="temporary"
      open={isOpen}
      onClose={onClose}
      sx={{
        " .MuiPaper-root": {
          width: isTablet ? "335px" : "90%",
        },
      }}
    >
      <DrawerHeader
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "bgPrimary.main",
          zIndex: 9,
          minHeight: "56px !important",
          borderBottom: `1px solid ${theme.palette.dividerColor?.main}`,
        }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            onClick={onClose}
            endIcon={<CloseIcon color={theme.palette.ctaPrimary.main} />}
            color="ctaPrimary"
            aria-label={t("navBarMenu.closeMenu")}
          >
            {t("navBarMenu.closeMenu")}
          </Button>
        </Box>
      </DrawerHeader>
      <Stack>
        {selectedDepartment ? (
          <>
            <Stack direction="row" alignItems="center">
              <IconButton
                sx={{
                  mx: 1,
                  color: theme.palette.ctaPrimary.main,
                }}
                onClick={() => setSelectedDepartment(null)}
                aria-label={t("ariaLabels.closeSideBar")}
              >
                <ArrowOutward
                  sx={{
                    transform: "rotateY(180deg)",
                  }}
                />
              </IconButton>
              <Divider
                sx={{
                  height: 30,
                  margin: "auto 0",
                  borderLeft: `1px solid ${theme.palette.dividerColor?.main}`,
                }}
              />
              <Button
                onClick={() => gotoDepartment(selectedDepartment)}
                color="textPrimary"
                sx={style.button}
                aria-label={selectedDepartment.displayName}
              >
                {selectedDepartment.displayName}
              </Button>
            </Stack>
            <Divider
              sx={{
                borderStyle: "dashed",
                borderWidth: 0,
                borderBottomWidth: "1px",
                my: 1,
                mx: 2,
              }}
            />
            {selectedDepartment.children.map((taxonomyId, index) => {
              const taxonomy = taxonomies[taxonomyId]
              return (
                taxonomy && (
                  <Button
                    key={index}
                    onClick={() => gotoDepartment(taxonomy)}
                    color={
                      selectedDepartment?.id === taxonomy?.id
                        ? "ctaPrimary"
                        : "textPrimary"
                    }
                    sx={style.button}
                    aria-label={taxonomy.displayName}
                  >
                    {taxonomy.displayName}
                    <ArrowForwardIos
                      sx={{
                        height: 12,
                        width: 12,
                        ml: "auto",
                      }}
                    />
                  </Button>
                )
              )
            })}
          </>
        ) : (
          <>
            <Stack direction="row" alignItems="center">
              <IconButton
                sx={{
                  mx: 1,
                  color: theme.palette.ctaPrimary.main,
                }}
                onClick={() => {
                  setIsSideNavBarOpen(true)
                  onClose()
                }}
                aria-label={t("ariaLabels.openSideBar")}
              >
                <ArrowOutward
                  sx={{
                    transform: "rotateY(180deg)",
                  }}
                />
              </IconButton>
              <Divider
                sx={{
                  height: 30,
                  margin: "auto 0",
                  borderLeft: `1px solid ${theme.palette.dividerColor?.main}`,
                }}
              />
              <Button
                color="textPrimary"
                sx={style.button}
                onClick={() => gotoAllCategories()}
                aria-label={t("navBarMenu.products")}
              >
                {t("navBarMenu.products")}
              </Button>
            </Stack>
            <Divider
              sx={{
                borderStyle: "dashed",
                borderWidth: 0,
                borderBottomWidth: "1px",
                my: 1,
                mx: 2,
              }}
            />
            <Button
              onClick={() => gotoAllCategories()}
              data-cy="all-products"
              color="textPrimary"
              sx={style.button}
              aria-label={t("navBarMenu.allDepartments")}
              // tabIndex={0}
            >
              {t("navBarMenu.allDepartments")}
            </Button>
            {productDepartments.map(
              (
                taxonomy: swiftly.taxonomy.SwiftlyJsTaxonomyNode,
                index: number
              ) => {
                return (
                  taxonomy && (
                    <Button
                      key={index}
                      id={`${taxonomy.displayName}-${index}`}
                      onClick={() => {
                        setSelectedDepartment(taxonomy)
                        setActiveElement(`${taxonomy.displayName}-${index}`)
                        document
                          .getElementById("backToAllDepartments-button")
                          ?.focus()
                      }}
                      data-cy="product-item"
                      color="textPrimary"
                      sx={style.button}
                      aria-label={taxonomy.displayName}
                      // tabIndex={0}
                    >
                      {taxonomy.displayName}
                      <ArrowForwardIos
                        sx={{
                          height: 12,
                          ml: "auto",
                        }}
                      />
                    </Button>
                  )
                )
              }
            )}
          </>
        )}
      </Stack>
    </Drawer>
  ) : (
    <Popover
      open={isOpen}
      anchorEl={anchorElement}
      onClose={onClose}
      aria-label={t("navBarMenu.departments")}
      sx={{
        top: 35,
        left: -50,
        "> .MuiPopover-paper": {
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      }}
    >
      <Stack direction="row" px={2} py={1}>
        <Stack
          className={styles.customScroll}
          sx={{
            maxHeight: "70vh",
            overflow: "auto",
            pr: 2,
          }}
        >
          <Button
            onClick={() => {
              gotoAllCategories()
            }}
            role="button"
            data-cy="all-products"
            tabIndex={0}
            sx={{
              fontSize: 15,
              fontWeight: 500,
              color:
                selectedDepartment?.id === taxonomy?.id
                  ? "ctaPrimary.main"
                  : "textPrimary.main",
              py: "12px",
              px: 2,
              minWidth: 250,
              justifyContent: "flex-start",
              borderRadius: 2,
              "&:hover": { backgroundColor: "lightPrimary.main" },
            }}
            aria-label={t("navBarMenu.allDepartments")}
          >
            {t("navBarMenu.allDepartments")}
          </Button>
          {productDepartments.map(
            (
              taxonomy: swiftly.taxonomy.SwiftlyJsTaxonomyNode,
              index: number
            ) => {
              return (
                taxonomy && (
                  <Button
                    key={index}
                    role="button"
                    id={`${taxonomy.displayName}-${index}`}
                    data-cy="product-item"
                    tabIndex={selectedDepartment ? -1 : 0}
                    onClick={() => {
                      {
                        setActiveElement(`${taxonomy.displayName}-${index}`)
                        setSelectedDepartment(taxonomy)

                        gaLogEvent({
                          eventName: EventName.navProdCat_click,
                          parameters: {
                            item_nameCategory: taxonomy.displayName,
                            item_location: "top of page",
                          },
                        })
                      }
                    }}
                    sx={{
                      fontSize: 15,
                      fontWeight: 500,
                      color:
                        selectedDepartment?.id === taxonomy?.id
                          ? "ctaPrimary.main"
                          : "textPrimary.main",
                      py: "12px",
                      px: 2,
                      borderRadius: 2,
                      "&:hover": { backgroundColor: "lightPrimary.main" },
                    }}
                    aria-label={
                      selectedDepartment?.id !== taxonomy?.id
                        ? `${taxonomy.displayName}  ${t(
                            "ariaLabels.listOfItems",
                            { count: taxonomy.children.length }
                          )} ${t("ariaLabels.collapsed")}`
                        : t("ariaLabels.expanded")
                    }
                  >
                    {taxonomy.displayName}
                    <ArrowForwardIos
                      sx={{
                        height: 12,
                        width: 12,
                        ml: "auto",
                      }}
                    />
                  </Button>
                )
              )
            }
          )}
        </Stack>
        {selectedDepartment && (
          <Stack
            className={styles.customScroll}
            sx={{
              border: "1px solid",
              borderColor: "dividerColor.main",
              p: 2,
              ml: 2,
              backgroundColor: "bgSecondary.main",
              borderRadius: 2,
              maxHeight: "70vh",
              overflow: "auto",
              ":empty": {
                display: "none",
              },
            }}
          >
            <Button
              aria-label={`${t("navBarMenu.backToAllDepartments")}`}
              onClick={() => {
                setSelectedDepartment(null), getFocus()
              }}
              tabIndex={0}
              data-cy="product-category"
              id="backToAllDepartments-button"
              color={
                selectedDepartment?.id === taxonomy?.id
                  ? "ctaPrimary"
                  : "textPrimary"
              }
              sx={{
                fontSize: 15,
                fontWeight: 600,
                py: "12px",
                px: 2,
                minWidth: 250,
                justifyContent: "flex-start",
                borderRadius: 2,
              }}
            >
              <ArrowBackIos
                sx={{
                  height: 12,
                  width: 12,
                  mr: "12px",
                }}
              />
              {t("navBarMenu.back")}
            </Button>
            <Button
              onClick={() => gotoDepartment(selectedDepartment)}
              data-cy="product-category"
              // tabIndex={0}
              color={
                selectedDepartment?.id === taxonomy?.id
                  ? "ctaPrimary"
                  : "textPrimary"
              }
              sx={{
                fontSize: 15,
                fontWeight: 600,
                py: "12px",
                px: 2,
                minWidth: 250,
                justifyContent: "flex-start",
                borderRadius: 2,
              }}
              aria-label={`${selectedDepartment.displayName}`}
            >
              {selectedDepartment.displayName}
            </Button>
            <Divider
              sx={{
                borderStyle: "dashed",
                borderWidth: 0,
                borderBottomWidth: "1px",
                my: 2,
                mx: 2,
              }}
            />
            <List>
              {selectedDepartment.children.map((taxonomyId, index) => {
                const taxonomy = taxonomies[taxonomyId]
                return (
                  taxonomy && (
                    <ListItem>
                      <Button
                        key={index}
                        data-cy="product-subcategory"
                        // tabIndex={0}
                        onClick={() => {
                          gotoDepartment(taxonomy)
                          gaLogEvent({
                            eventName: EventName.navProdCat_click,
                            parameters: {
                              item_nameCategory: selectedDepartment.displayName,
                              item_nameCategorySub: taxonomy.displayName,
                              item_location: "top of page",
                            },
                          })
                        }}
                        color={
                          selectedDepartment?.id === taxonomy?.id
                            ? "ctaPrimary"
                            : "textPrimary"
                        }
                        sx={{
                          fontSize: 15,
                          fontWeight: 600,
                          py: "12px",
                          px: 2,
                          minWidth: 250,
                          justifyContent: "flex-start",
                          borderRadius: 2,
                        }}
                        aria-label={taxonomy.displayName}
                      >
                        {taxonomy.displayName}
                      </Button>
                    </ListItem>
                  )
                )
              })}
            </List>
          </Stack>
        )}
      </Stack>
    </Popover>
  )
}

export default Departments
