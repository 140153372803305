import { useTranslation } from "react-i18next"
import { useContext, useMemo } from "react"
import { AppContext } from "../../contexts/AppContext"
import {
  BusinessStatus,
  StoreEntry,
  StoreEntryDistance,
} from "../../generated/graphql"

export interface LatLng {
  lat: number
  lng: number
}

export function useDecorateStore(store?: StoreEntry) {
  const mapStoreStatus = useMapStoreToStatus()
  const { state } = useContext(AppContext)
  const { t } = useTranslation()
  const decorateStore = useMemo(
    () =>
      (
        store: StoreEntry,
        location?: LatLng,
        storeEntryDistance?: StoreEntryDistance
      ): DecoratedStore => {
        return Object.assign(
          {
            name: store.primaryDetails.name,
            distance: (() => {
              if (
                storeEntryDistance &&
                storeEntryDistance.currentLocationDistance
              ) {
                return (
                  Math.round(storeEntryDistance.currentLocationDistance * 100) /
                    100 +
                  " mi"
                )
              }
              return location
                ? "" +
                    Math.round(
                      distanceBetweenCoordinates(
                        [location.lat, location.lng],
                        [store.latitude, store.longitude]
                      ) * 100
                    ) /
                      100 +
                    " mi"
                : ""
            })(), // need to write code to calculate distance
            address: store.address1,
            location: `${store.city}, ${store.state}`,
            isCurrentStore: store.storeId === state?.currentStore?.id,
            timings: store.primaryDetails.hours[new Date().getDay()].hours
              ?.length
              ? t("stores.closesAt") +
                " " +
                getHours(
                  store.primaryDetails.hours[new Date().getDay()].hours[0].close
                )
              : "n/a",
            id: store.storeId,
            status: mapStoreStatus(store),
            phoneNumber: mapPhoneNumber(store),
          },
          store
        )
      },
    /* Getting the state from the AppContext. */
    [mapStoreStatus, state?.currentStore?.id, t]
  )
  return {
    decorateStore,
    store: store ? decorateStore(store) : null,
  }
}

function getHours(closingHour: StoreEntry) {
  // prettier-ignore
  // @ts-ignore
  const parts = closingHour.split(':');
  const hours = getHourPortion(parts[0])
  const meridian = parts[0] < 12 ? " AM" : " PM"
  return hours + ":" + parts[1] + meridian
}

function getHourPortion(hours: string): string {
  if (hours === "00") {
    return "12"
  }

  if (+hours < 12) {
    if (+hours < 10) {
      return hours[1]
    }
    return hours
  }

  return +hours - 12 + ""
}

function mapPhoneNumber(store: StoreEntry) {
  return store?.primaryDetails?.contactNumbers?.length
    ? {
        phone: getFormatedPhoneNum(
          store.primaryDetails.contactNumbers?.find(
            (c) => c.displayName === "Phone"
          )?.value as string
        ),
        pharmacy: store.primaryDetails.contactNumbers?.find(
          (c) => c.displayName === "Pharmacy"
        )?.value,
        fax: store.primaryDetails.contactNumbers?.find(
          (c) => c.displayName === "Fax"
        )?.value,
      }
    : {
        phone: getFormatedPhoneNum(
          store.primaryDetails.phoneNumbers[0].value as string
        ),
      }
}

const getFormatedPhoneNum = (value: string) => {
  return value
    ?.replaceAll("-", "")
    ?.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3")
}

export function useMapStoreToStatus() {
  const { t } = useTranslation()

  return useMemo(
    () => (store: StoreEntry) => {
      const futureStatus = store.primaryDetails.nextStatus?.status
      const currentStatus = store.primaryDetails.status?.status
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let toShowStatus: any = currentStatus
      const toShowStatusColor = "green"

      if (futureStatus == BusinessStatus.ClosedPermanently) {
        toShowStatus = t("stores.closed_permanently")
      } else if (currentStatus || store.isOpenNow) {
        toShowStatus = t("stores.open_now")
      } else if (
        currentStatus != BusinessStatus.Open &&
        futureStatus == BusinessStatus.Open
      ) {
        toShowStatus = t("stores.location_coming_soon")
      } else if (currentStatus === "OPEN" || store.isOpenNow) {
        toShowStatus = t("stores.open_now")
      }
      return {
        text: toShowStatus,
        color: toShowStatusColor,
      }
    },
    [t]
  )
}

export function distanceBetweenCoordinates(
  coord1: [number, number],
  coord2: [number, number]
): number {
  const earthRadius = 6371 // Radius of the earth in kilometers

  const [lat1, lng1] = coord1
  const [lat2, lng2] = coord2

  // Convert latitude and longitude to radians
  const lat1Rad = (lat1 * Math.PI) / 180
  const lat2Rad = (lat2 * Math.PI) / 180
  const lng1Rad = (lng1 * Math.PI) / 180
  const lng2Rad = (lng2 * Math.PI) / 180

  // Calculate the difference between the two sets of coordinates
  const latDiff = lat2Rad - lat1Rad
  const lngDiff = lng2Rad - lng1Rad

  // Use the Haversine formula to calculate the distance
  const a =
    Math.pow(Math.sin(latDiff / 2), 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.pow(Math.sin(lngDiff / 2), 2)
  const distanceInKilometers = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distanceInMiles = distanceInKilometers * 0.621371
  return earthRadius * distanceInMiles
}

export interface OpenStatus {
  text: string
  color: string
}

export interface DecoratedPhoneNumber {
  phone?: string
  pharmacy?: string
  fax?: string
}

export type DecoratedStore = {
  isCurrentStore: boolean
  id: string
  distance: string
  address: string
  name: string
  location: string
  timings: string
  status: OpenStatus
  phoneNumber: DecoratedPhoneNumber
} & StoreEntry
