import * as React from "react"
import { SVGProps } from "react"

const SvgSignOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path
      d="M14.293 7.707 16.586 10H5a1 1 0 0 0 0 2h11.586l-2.293 2.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 1 0-1.414 1.414ZM1 0h8a1 1 0 0 1 0 2H2v18h7a1 1 0 0 1 0 2H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgSignOutIcon
