export function convertToLosAngelesTime(time: string) {
  let timestamp = time
  if (!time) {
    // If fallback time when time is undefined
    timestamp = new Date().toLocaleString()
  }
  // Date object from the given timestamp
  const dateObj = new Date(timestamp)
  // Specify the America/Los_Angeles time zone
  const losAngelesTimeZone = "America/Los_Angeles"

  // Format the date in the desired time zone using Intl.DateTimeFormat
  const losAngelesTime = new Intl.DateTimeFormat("en-US", {
    timeZone: losAngelesTimeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(dateObj)

  // new Date object using the formatted Los Angeles time
  return new Date(losAngelesTime)
}
