import * as React from "react"
import { SVGProps } from "react"
const SvgDollarInCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.953 23.474a8.152 8.152 0 0 1-3.27-.664 8.668 8.668 0 0 1-2.699-1.835 8.668 8.668 0 0 1-1.834-2.698 8.15 8.15 0 0 1-.664-3.27c0-1.157.221-2.244.664-3.262a8.712 8.712 0 0 1 1.826-2.706 8.669 8.669 0 0 1 2.698-1.835 8.151 8.151 0 0 1 3.27-.664 8.15 8.15 0 0 1 3.27.664 8.641 8.641 0 0 1 2.707 1.835 8.641 8.641 0 0 1 1.834 2.706 8.093 8.093 0 0 1 .664 3.262 8.15 8.15 0 0 1-.664 3.27 8.668 8.668 0 0 1-1.834 2.698 8.64 8.64 0 0 1-2.706 1.835 8.092 8.092 0 0 1-3.262.664Zm0-3.578a.41.41 0 0 0 .29-.108.405.405 0 0 0 .116-.299v-.597c.664-.067 1.223-.285 1.677-.656.454-.377.68-.888.68-1.536 0-.58-.187-1.048-.564-1.403-.376-.354-.935-.597-1.676-.73l-.117-.025V12.16c.305.055.56.171.764.348.21.177.36.41.448.698.094.282.269.423.523.423.15 0 .271-.047.365-.141a.494.494 0 0 0 .142-.365.545.545 0 0 0-.017-.133 1.13 1.13 0 0 0-.025-.133 1.994 1.994 0 0 0-.78-1.137 2.797 2.797 0 0 0-1.42-.548v-.59a.377.377 0 0 0-.116-.29.394.394 0 0 0-.29-.116.394.394 0 0 0-.29.116.377.377 0 0 0-.117.29v.59c-.648.072-1.182.299-1.602.68-.415.377-.623.858-.623 1.445 0 .542.183.982.548 1.32.365.332.905.583 1.619.755l.058.017v2.523c-.382-.055-.686-.182-.913-.382-.221-.199-.368-.44-.44-.722a.646.646 0 0 0-.208-.324.497.497 0 0 0-.323-.108.484.484 0 0 0-.498.507c0 .044.002.088.008.133l.033.132c.122.498.399.897.83 1.196.432.299.935.476 1.51.531v.614c0 .122.04.222.117.3a.41.41 0 0 0 .29.107Zm-.407-5.57-.058-.016c-.382-.117-.662-.26-.839-.432a.854.854 0 0 1-.265-.64c0-.26.102-.489.307-.688.21-.2.495-.33.855-.39v2.166Zm.813 1.262.183.05c.415.11.703.257.863.44.166.182.25.41.25.68 0 .3-.109.554-.324.764-.21.21-.534.34-.972.39v-2.324Z"
      fill={props.fill ?? "#005E8E"}
    />
  </svg>
)
export default SvgDollarInCircle
