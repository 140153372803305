import { SVGProps } from "react"
const TicketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path d="M20.5 18.16V12.5C19.68 12.5 19 11.82 19 11V6.5H14.5C5.68 6.5 3 9.18 3 18V19C3 19.82 3.68 20.5 4.5 20.5C6.42 20.5 8 22.08 8 24C8 25.92 6.42 27.5 4.5 27.5C3.68 27.5 3 28.18 3 29V30C3 38.82 5.68 41.5 14.5 41.5H19V37C19 36.18 19.68 35.5 20.5 35.5V29.84C19.68 29.84 19 29.16 19 28.34V19.66C19 18.84 19.68 18.16 20.5 18.16Z" />
    <path
      opacity="0.4"
      d="M41 25C41 26.92 42.58 28.5 44.5 28.5C45.32 28.5 46 29.18 46 30C46 38.82 43.32 41.5 34.5 41.5H22V37C22 36.18 21.32 35.5 20.5 35.5V29.84C21.32 29.84 22 29.16 22 28.34V19.66C22 18.84 21.32 18.16 20.5 18.16V12.5C21.32 12.5 22 11.82 22 11V6.5H34.5C43.32 6.5 46 9.18 46 18V20C46 20.82 45.32 21.5 44.5 21.5C42.58 21.5 41 23.08 41 25Z"
    />
  </svg>
)

export default TicketIcon
