import * as React from "react"
import { SVGProps } from "react"
import { useTheme } from "@mui/material/styles"
const SvgChevronUpIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill={theme.palette.ctaPrimary.main}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.8 15.1c0 .6.4 1 1 1 .3 0 .5-.1.7-.3l3.5-3.5 3.5 3.5c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.2-4.2c-.4-.4-1-.4-1.4 0l-4.2 4.2c-.2.2-.3.5-.3.7Z" />
    </svg>
  )
}
export default SvgChevronUpIcon
