import * as React from "react"
import { SVGProps } from "react"
const SvgShoppingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.0001 8H7.0001C7.26531 8 7.51967 7.89464 7.7072 7.70711C7.89474 7.51957 8.0001 7.26522 8.0001 7V1C8.0001 0.734784 7.89474 0.48043 7.7072 0.292893C7.51967 0.105357 7.26531 0 7.0001 0H1.0001C0.73488 0 0.480526 0.105357 0.29299 0.292893C0.105453 0.48043 9.65977e-05 0.734784 9.65977e-05 1V7C9.65977e-05 7.26522 0.105453 7.51957 0.29299 7.70711C0.480526 7.89464 0.73488 8 1.0001 8ZM2.0001 2H6.0001V6H2.0001V2ZM22.0001 4C22.0001 4.26522 21.8947 4.51957 21.7072 4.70711C21.5197 4.89464 21.2653 5 21.0001 5H11.0001C10.7349 5 10.4805 4.89464 10.293 4.70711C10.1055 4.51957 10.0001 4.26522 10.0001 4C10.0001 3.73478 10.1055 3.48043 10.293 3.29289C10.4805 3.10536 10.7349 3 11.0001 3H21.0001C21.2653 3 21.5197 3.10536 21.7072 3.29289C21.8947 3.48043 22.0001 3.73478 22.0001 4ZM22.0001 14C22.0001 14.2652 21.8947 14.5196 21.7072 14.7071C21.5197 14.8946 21.2653 15 21.0001 15H11.0001C10.7349 15 10.4805 14.8946 10.293 14.7071C10.1055 14.5196 10.0001 14.2652 10.0001 14C10.0001 13.7348 10.1055 13.4804 10.293 13.2929C10.4805 13.1054 10.7349 13 11.0001 13H21.0001C21.2653 13 21.5197 13.1054 21.7072 13.2929C21.8947 13.4804 22.0001 13.7348 22.0001 14ZM2.2351 16.7L0.281097 14.673C0.0966385 14.4823 -0.00451305 14.2262 -0.000105939 13.9609C0.00430117 13.6956 0.113906 13.443 0.304597 13.2585C0.495287 13.074 0.751443 12.9729 1.01671 12.9773C1.28198 12.9817 1.53464 13.0913 1.7191 13.282L2.9711 14.582L6.3001 11.289C6.39104 11.1866 6.50206 11.104 6.62626 11.0462C6.75045 10.9885 6.88518 10.9569 7.0221 10.9534C7.15901 10.9499 7.29519 10.9745 7.42219 11.0258C7.54919 11.0771 7.6643 11.1539 7.76039 11.2515C7.85647 11.3491 7.93149 11.4654 7.98078 11.5931C8.03007 11.7209 8.05259 11.8575 8.04694 11.9943C8.04128 12.1312 8.00758 12.2654 7.94792 12.3887C7.88826 12.5119 7.80391 12.6217 7.7001 12.711L3.6541 16.711C3.46767 16.8954 3.21629 16.9992 2.9541 17H2.9421C2.8103 16.9991 2.67998 16.9721 2.55865 16.9206C2.43731 16.8691 2.32736 16.7941 2.2351 16.7Z"
      fill="#3D8129"
    />
  </svg>
)
export default SvgShoppingIcon
