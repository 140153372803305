import { swiftly, com } from "../client-data-bom"
import generateConfig from "../configs/config"
import getAPIBaseUrl from "../utils/getAPIBaseUrl"
import getTimeZone from "../utils/getTimeZone"
import isMobileBrowser from "../utils/isMobileBrowser"

const { ads } = swiftly
const config = generateConfig()
const url = "/ads"
// const url = config.apiServerBaseUrl + '/ads';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchAds,
  fireClick,
  fireImpression,
  fireAdView,
}

init()

export async function fetchAds(
  screenName: string,
  placementId: string,
  categoryId?: string
): Promise<swiftly.ads.SwiftlyJsResolvableAdWrapper> {
  const result = await ads.fetchAds(
    (requestBuilder: com.swiftly.framework.ads.data.AdRequestBuilder) => {
      requestBuilder.adScreenName = screenName
      requestBuilder.adPlacementId = placementId
      if (categoryId) {
        requestBuilder.categoryId = categoryId
      }
    }
  )

  if (!result) {
    throw Error("No resolvable ad found. Check configurations.")
  }

  return result
}

const alreadyCalledAdViewIds: string[] = [] as string[]

export async function fireAdView(
  gaId: string | undefined,
  userId: string,
  adImpressionId: string,
  element: HTMLImageElement
) {
  if (
    !adImpressionId ||
    adImpressionId === "00000000-0000-0000-0000-000000000000"
  ) {
    return Promise.resolve()
  }

  let intersectionEntry: IntersectionObserverEntry | null = null
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let timeoutId = null

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        let isViewable = true
        const isCarousel = element.closest(".slider-list")
        if (isCarousel) {
          isViewable = !!element.closest(".slide-current.slide-visible")
        }
        if (entry.target === element) {
          intersectionEntry = entry // Store the entry for later use
          if (
            entry.isIntersecting &&
            entry.intersectionRatio >= 0.5 &&
            !alreadyCalledAdViewIds.includes(adImpressionId)
          ) {
            // we only want to send the ad_view call if this element is the current slide visible to the user
            // or if it is an image ad viewable by the user
            if (!isViewable) {
              observer.observe(element) // Re-observe the element
              return
            }
            // console.log("The element is 50% visible in the viewport");
            timeoutId = setTimeout(async () => {
              if (!intersectionEntry?.isIntersecting) {
                // console.log("The element moved out of the viewport");
                observer.observe(element) // Re-observe the element
              } else {
                if (alreadyCalledAdViewIds.includes(adImpressionId)) {
                  return
                }
                alreadyCalledAdViewIds.push(adImpressionId)
                // console.log(
                //   `The element is still visible in the viewport after 1 second ${adImpressionId}`,
                //   element
                // );
                try {
                  // make a request
                  const body = JSON.stringify([
                    {
                      TenantId: config.adsSiteId,
                      Timestamp: new Date().toISOString(),
                      EventName: "ad_view",
                      SwiftlyUserId: userId,
                      AnonUserId: gaId,
                      Payload: {
                        AdImpressionId: adImpressionId,
                      },
                    },
                  ])
                  return await fetch(url, {
                    body,
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json; charset=utf-8",
                      "X-Forwarded-Host":
                        config.apiServerBaseUrl.split("://")[1],
                      "x-swiftly-user-agent": `${
                        config.uaSite
                      }/1.0.0 (com.swiftly.data.web; build:10000; ${
                        isMobileBrowser() ? "mobile-web" : "web"
                      } 1)`,
                    },
                  })
                } catch (e) {
                  console.log(`Unable to fire ad view: ${adImpressionId}`, e)
                  return Promise.resolve()
                } finally {
                  intersectionEntry = null // Reset the stored entry
                  timeoutId = null // Reset the timeout ID
                }
              }
            }, 1000)
          }
        }
      })
    },
    { threshold: 0.5 }
  )

  if (element) {
    observer.observe(element)
  }
}

const alreadyCalledImpressionIds: string[] = [] as string[]

export async function fireImpression(
  gaId: string | undefined,
  userId: string,
  adImpressionId: string
) {
  if (
    !adImpressionId ||
    adImpressionId === "00000000-0000-0000-0000-000000000000"
  ) {
    return Promise.resolve()
  }

  if (alreadyCalledImpressionIds.includes(adImpressionId)) {
    return Promise.resolve()
  }
  alreadyCalledImpressionIds.push(adImpressionId)

  // make a request
  try {
    const body = JSON.stringify([
      {
        TenantId: config.adsSiteId,
        Timestamp: new Date().toISOString(),
        EventName: "impression",
        SwiftlyUserId: userId,
        AnonUserId: gaId,
        Payload: {
          AdImpressionId: adImpressionId,
        },
      },
    ])
    return await fetch(url, {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Forwarded-Host": config.apiServerBaseUrl.split("://")[1],
        "x-swiftly-user-agent": `${
          config.uaSite
        }/1.0.0 (com.swiftly.data.web; build:10000; ${
          isMobileBrowser() ? "mobile-web" : "web"
        } 1)`,
      },
    })
  } catch (e) {
    console.log(`Unable to fire impression ${adImpressionId}`, e)
    return Promise.resolve()
  }
}

// I don't think this is applicable right now since each ad fetch is separate right now
// async function fireAdReceived(
//   userId: string,
//   installId: string,
//   adCount: number,
//   adImpressionId: Array<string>
// ) {
//   const body = JSON.stringify([
//     {
//       TenantId: config.adsSiteId,
//       Timestamp: new Date().toISOString(),
//       EventName: "ads_received",
//       SwiftlyUserId: userId,
//       Payload: {
//         AdsCount: adCount,
//         AdImpressionId: adImpressionId,
//       },
//     },
//   ]);
//   return await fetch(url, {
//     body,
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json; charset=utf-8",
//       "X-Forwarded-Host": config.apiServerBaseUrl.split("://")[1],
//     },
//   });
// }

export async function fireClick(
  gaId: string | undefined,
  userId: string,
  adImpressionId: string
) {
  try {
    const body = JSON.stringify([
      {
        TenantId: config.adsSiteId,
        Timestamp: new Date().toISOString(),
        EventName: "click",
        SwiftlyUserId: userId,
        AnonUserId: gaId,
        Payload: {
          AdImpressionId: adImpressionId,
        },
      },
    ])
    return await fetch(url, {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Forwarded-Host": config.apiServerBaseUrl.split("://")[1],
        "x-swiftly-user-agent": `${
          config.uaSite
        }/1.0.0 (com.swiftly.data.web; build:10000; ${
          isMobileBrowser() ? "mobile-web" : "web"
        } 1)`,
      },
    })
  } catch (e) {
    console.log(`unable to fire click for ${adImpressionId}`, e)
    Promise.resolve()
  }
}

// DO NOT EXPORT, THIS IS TO BE CALLED ONCE
function init() {
  ads.initializeAdsDataSDK((builder: swiftly.ads.SwiftlyAdsDataSDKBuilder) => {
    builder.setBaseUrl(getAPIBaseUrl())
    builder.setStaticDefaultAdRequestParameters(
      config.adsNetworkId,
      config.adsSiteId
    )
    builder.setDefaultHttpClient(
      config.uaSite,
      "1.0.0",
      "10000",
      "com.swiftly.data.web",
      isMobileBrowser() ? "mobile-web" : "web",
      "1",
      15000,
      15000,
      15000,
      "",
      "browser",
      "en-US",
      getTimeZone()
    )
  })
}
