import getConfig from "next/config"

const config = getConfig() || {
  publicRuntimeConfig: {
    flipp: {},
  },
  serverRuntimeConfig: {},
}
const { publicRuntimeConfig, serverRuntimeConfig } = config
// import { useAuth } from "contexts/AuthContext";
// leaving this as a function export
// so if there is additional handling for each parameter
// or if environment variables are later consumed
// there won't be a huge lift in the code usage
export default function generateConfig(): Config {
  return {
    chainId:
      publicRuntimeConfig.chainId || process.env.NEXT_PUBLIC_CHAIN_ID || "",
    siteName:
      publicRuntimeConfig.siteName || process.env.NEXT_PUBLIC_SITE_NAME || "",
    siteLogoDark:
      publicRuntimeConfig.siteLogoDark ||
      process.env.NEXT_PUBLIC_SITE_LOGO_DARK ||
      "",
    siteLogoLight:
      publicRuntimeConfig.siteLogoLight ||
      process.env.NEXT_PUBLIC_SITE_LOGO_LIGHT ||
      "",
    appShaVersion:
      publicRuntimeConfig.appShaVersion ||
      process.env.NEXT_PUBLIC_GIT_COMMIT_REF ||
      "",
    taxonomyId:
      publicRuntimeConfig.taxonomyId ||
      process.env.NEXT_PUBLIC_TAXONOMY_ID ||
      "",
    taxonomyNodeId:
      publicRuntimeConfig.taxonomyNodeId ||
      process.env.NEXT_PUBLIC_CATALOG_ROOT_NODE ||
      "",
    adsNetworkId:
      publicRuntimeConfig.adsNetworkId ||
      process.env.NEXT_PUBLIC_ADS_NETWORK_ID ||
      "",
    adsSiteId:
      publicRuntimeConfig.adsSiteId ||
      process.env.NEXT_PUBLIC_ADS_SITE_ID ||
      "",
    adsTelemetryUrl:
      publicRuntimeConfig.adsTelemetryUrl ||
      process.env.NEXT_PUBLIC_ADS_TELEMETRY_URL ||
      "",
    mapsApiKey:
      publicRuntimeConfig.mapsApiKey ||
      process.env.NEXT_PUBLIC_MAPS_API_KEY ||
      "",
    siteDomain:
      publicRuntimeConfig.siteDomain ||
      process.env.NEXT_PUBLIC_SITE_DOMAIN ||
      "",
    playStoreLink:
      publicRuntimeConfig.playStoreLink ||
      process.env.NEXT_PUBLIC_PLAY_STORE_URL ||
      "",
    appStoreLink:
      publicRuntimeConfig.appStoreLink ||
      process.env.NEXT_PUBLIC_APP_STORE_URL ||
      "",
    defaultCouponImage:
      publicRuntimeConfig.defaultCouponImage ||
      process.env.NEXT_PUBLIC_REWARD_IMAGE ||
      "",
    description:
      publicRuntimeConfig.description ||
      process.env.NEXT_PUBLIC_SITE_DESCRIPTION ||
      "",
    siteTitle:
      publicRuntimeConfig.siteTitle || process.env.NEXT_PUBLIC_SITE_TITLE || "",
    siteTagline:
      publicRuntimeConfig.siteTagline ||
      process.env.NEXT_PUBLIC_SITE_TAGLINE ||
      "",
    homeNode:
      publicRuntimeConfig.homeNode ||
      process.env.NEXT_PUBLIC_HOME_ROOT_NODE ||
      "",
    enableMocks:
      publicRuntimeConfig.enableMocks ||
      process.env.NEXT_PUBLIC_ENABLE_MOCKS ||
      "",
    env: publicRuntimeConfig.env || process.env.NODE_ENV || "",
    apiServerBaseUrl:
      publicRuntimeConfig.apiServerBaseUrl ||
      process.env.NEXT_PUBLIC_API_SERVER_BASE_URL ||
      "",
    apiClientBaseUrl:
      publicRuntimeConfig.apiClientBaseUrl ||
      process.env.NEXT_PUBLIC_API_CLIENT_BASE_URL ||
      "",
    loginBaseUrl:
      publicRuntimeConfig.loginBaseUrl ||
      process.env.NEXT_PUBLIC_LOGIN_BASE_URL ||
      "",
    registerBaseUrl:
      publicRuntimeConfig.registerBaseUrl ||
      process.env.NEXT_PUBLIC_TSMC_BASE_URL ||
      "",
    clientId:
      publicRuntimeConfig.clientId || process.env.NEXT_PUBLIC_CLIENT_ID || "",
    accessTokenHostName:
      serverRuntimeConfig.accessTokenHostName ||
      process.env.NEXT_PUBLIC_ACCESS_TOKEN_HOST_NAME ||
      "",
    accessTokenUserName:
      serverRuntimeConfig.accessTokenUserName ||
      process.env.NEXT_PUBLIC_ACCESS_TOKEN_USER_NAME ||
      "",
    accessTokenPassword:
      serverRuntimeConfig.accessTokenPassword ||
      process.env.NEXT_PUBLIC_ACCESS_TOKEN_PASSWORD ||
      "",
    scimHostApi:
      publicRuntimeConfig.scimHostApi ||
      process.env.NEXT_PUBLIC_SCIM_HOST_API ||
      "",
    wordPressAPIUrl:
      publicRuntimeConfig.wordPressAPIUrl ||
      process.env.NEXT_PUBLIC_WORDPRESS_API_URL ||
      "",
    nextPublicRedirectUrl:
      publicRuntimeConfig.nextPublicRedirectUrl ||
      process.env.NEXT_PUBLIC_REDIRECTION_URL ||
      "",
    flipp: {
      accessToken:
        publicRuntimeConfig.flipp.accessToken ||
        process.env.NEXT_PUBLIC_FLIPP_ACCESS_TOKEN ||
        "",
      merchantId:
        publicRuntimeConfig.flipp.merchantId ||
        process.env.NEXT_PUBLIC_FLIPP_MERCHANT_ID ||
        "",
      merchantName:
        publicRuntimeConfig.flipp.merchantName ||
        process.env.NEXT_PUBLIC_FLIPP_MERCHANT_NAME ||
        "",
      baseUrl:
        publicRuntimeConfig.flipp.baseUrl ||
        process.env.NEXT_PUBLIC_FLIPP_BASE_URL ||
        "",
    },
    facebookUrl:
      publicRuntimeConfig.facebookUrl ||
      process.env.NEXT_PUBLIC_FACEBOOK_URL ||
      "",
    instagramUrl:
      publicRuntimeConfig.instagramUrl ||
      process.env.NEXT_PUBLIC_INSTAGRAM_URL ||
      "",
    revalidate:
      publicRuntimeConfig.revalidate || process.env.NEXT_PUBLIC_REVALIDATE || 0,
    logging:
      publicRuntimeConfig.logging || process.env.NEXT_PUBLIC_USE_LOGGING || "",
    sentryDSN:
      publicRuntimeConfig.sentryDSN || process.env.NEXT_PUBLIC_SENTRY_DSN || "",
    storeUrl:
      publicRuntimeConfig.storeUrl || process.env.NEXT_PUBLIC_STORE_URL || "",
    uaSite: publicRuntimeConfig.uaSite || process.env.NEXT_PUBLIC_UA_SITE || "",
    useDefaultMapsIcon: process.env.NEXT_PUBLIC_USE_DEFAULT_MAPS_ICON || "",
    zendeskUrl:
      publicRuntimeConfig.zendeskUrl ||
      process.env.NEXT_PUBLIC_ZENDESK_BASE_URL ||
      "",
    accessZendeskUserName:
      publicRuntimeConfig.accessZendeskUserName ||
      process.env.NEXT_PUBLIC_ZENDESK_USERNAME ||
      "",
    accessZendeskPassword:
      publicRuntimeConfig.accessZendeskPassword ||
      process.env.NEXT_PUBLIC_ZENDESK_PASSWORD ||
      "",
    aboutUsUrl:
      publicRuntimeConfig.aboutUsUrl ||
      process.env.NEXT_PUBLIC_ABOUTUS_URL ||
      "",
    newReleasesUrl:
      publicRuntimeConfig.newReleasesUrl ||
      process.env.NEXT_PUBLIC_NEWRELEASES_URL ||
      "",
    supplierPortalUrl:
      publicRuntimeConfig.supplierPortalUrl ||
      process.env.NEXT_PUBLIC_SUPPLIERPORTAL_URL ||
      "",
    configBanner:
      publicRuntimeConfig.configBanner ||
      process.env.NEXT_PUBLIC_CONFIG_BANNER ||
      "",
    experimentalFeatures: process.env.NEXT_PUBLIC_EXPERIMENTAL_FEATURES || "",
    experimentalProductPricing:
      process.env.NEXT_PUBLIC_EXPERIMENTAL_PRODUCT_PRICING || "",
    isDevEnv:
      process.env.NODE_ENV === "development" ||
      process.env.NEXT_PUBLIC_IS_DEV === "true",
    isProdEnv:
      process.env.NODE_ENV === "production" ||
      process.env.NEXT_PUBLIC_IS_PROD === "true",
    chatScript:
      publicRuntimeConfig.chatScript ||
      process.env.NEXT_PUBLIC_CHAT_SCRIPT ||
      "",
    googleAnalytics:
      publicRuntimeConfig.googleAnalytics ||
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ||
      "",
    madeToOrder:
      publicRuntimeConfig.madeToOrder ||
      process.env.NEXT_PUBLIC_MADE_TO_ORDER_URL ||
      "",
    shopNow:
      publicRuntimeConfig.shopNow || process.env.NEXT_PUBLIC_SHOP_NOW || "",
    countriesBound: (process.env.NEXT_PUBLIC_COUNTRIES_BOUND || "").split(","),
    giftCardLink:
      publicRuntimeConfig.giftCardLink ||
      process.env.NEXT_PUBLIC_GIFT_CARD_LINK ||
      "",
    ga4: publicRuntimeConfig.ga4 || process.env.NEXT_PUBLIC_GA4 || "",
    rebatesHostName:
      publicRuntimeConfig.rebatesHostName ||
      process.env.NEXT_PUBLIC_REBATES_HOST_NAME ||
      "",
    enableRebatesFeature:
      publicRuntimeConfig.enableRebatesFeature ||
      process.env.NEXT_PUBLIC_ALCOHOL_REBATES ||
      "",
    ga4_client:
      publicRuntimeConfig.ga4_client ||
      process.env.NEXT_PUBLIC_GA4_CLIENT ||
      "",
    breinifyUrl:
      publicRuntimeConfig.breinifyUrl ||
      process.env.NEXT_PUBLIC_BREINIFY_URL ||
      "",
    faro_id: publicRuntimeConfig.faro_id || process.env.NEXT_PUBLIC_FARO_ID,
    pingCloud:
      publicRuntimeConfig.pingCloud || process.env.NEXT_PUBLIC_PING_CLOUD || "",
    loginApiVersion:
      publicRuntimeConfig.loginApiVersion ||
      process.env.NEXT_PUBLIC_LOGIN_API_VERSION ||
      "",
    enableRewards:
      publicRuntimeConfig.enableRewards === "true" ||
      process.env.NEXT_PUBLIC_REWARDS_ENABLED === "true",
    productDetailPageEnabled:
      process.env.NEXT_PUBLIC_PRODUCT_DETAILS_PAGE === "true" || false,
    metaSiteName:
      publicRuntimeConfig.metaSiteName ||
      process.env.NEXT_PUBLIC_META_SITE_NAME ||
      "",
    grafanaEnabled:
      publicRuntimeConfig.grafanaEnabled === "true" ||
      process.env.NEXT_PUBLIC_GRAFANA_ENABLED === "true",
    apiV2: publicRuntimeConfig.apiV2 || process.env.NEXT_PUBLIC_API_V2 || "",
    enableShoppingList:
      publicRuntimeConfig.enableShoppingList === "true" ||
      process.env.NEXT_PUBLIC_ENABLE_SHOPPING_LIST === "true",
    enableShimmedHomeStoreUpdate:
      publicRuntimeConfig.enableShimmedHomeStoreUpdate === "true" ||
      process.env.NEXT_PUBLIC_ENABLE_SHIMMED_HOMESTORE_UPDATE === "true",
    quickLinks:
      (publicRuntimeConfig.quickLinks &&
        JSON.parse(publicRuntimeConfig.quickLinks)) ||
      (process.env.NEXT_PUBLIC_QUICK_LINKS &&
        JSON.parse(process.env.NEXT_PUBLIC_QUICK_LINKS)) ||
      [],
    topAlert: (publicRuntimeConfig.topAlert &&
      JSON.parse(publicRuntimeConfig.topAlert)) ||
      (process.env.NEXT_PUBLIC_TOP_ALERT &&
        JSON.parse(process.env.NEXT_PUBLIC_TOP_ALERT)) || {
        title: "",
        url: "",
      },
    inStockActive: process.env.NEXT_PUBLIC_IN_STOCK_ACTIVE === "true" || false,
    adsAccountKey:
      publicRuntimeConfig.adsAccountKey ||
      process.env.NEXT_ADS_ACCOUNT_KEY ||
      "",
    webAdsSdkUrl:
      publicRuntimeConfig.webAdsSdkUrl ||
      process.env.NEXT_WEB_ADS_SDK_URL ||
      "",
    serviceNowUrl:
      publicRuntimeConfig.serviceNowUrl ||
      process.env.NEXT_PUBLIC_SERVICE_NOW_URL ||
      "",
    serviceNowToken:
      publicRuntimeConfig.serviceNowToken ||
      process.env.NEXT_PUBLIC_SERVICE_NOW_TOKEN ||
      "",
  }
}
type Config = {
  grafanaEnabled: boolean
  productDetailPageEnabled: boolean
  inStockActive: boolean
  chainId: string
  siteName: string
  siteLogoDark: string
  siteLogoLight: string
  appShaVersion: string
  taxonomyId: string
  taxonomyNodeId: string
  adsNetworkId: string
  adsSiteId: string
  adsTelemetryUrl: string
  mapsApiKey: string
  siteDomain: string
  playStoreLink: string
  appStoreLink: string
  defaultCouponImage: string
  description: string
  siteTitle: string
  siteTagline: string
  homeNode: string
  enableMocks: string
  env: string
  logging: string
  sentryDSN: string
  apiServerBaseUrl: string
  apiClientBaseUrl: string
  loginBaseUrl: string
  registerBaseUrl: string
  clientId: string
  accessTokenHostName: string
  accessTokenUserName: string
  accessTokenPassword: string
  scimHostApi: string
  wordPressAPIUrl: string
  nextPublicRedirectUrl: string
  revalidate: number
  flipp: {
    accessToken: string
    baseUrl: string
    merchantId: string
    merchantName: string
  }
  facebookUrl: string
  instagramUrl: string
  uaSite: string
  storeUrl: string
  useDefaultMapsIcon: string
  zendeskUrl: string
  accessZendeskUserName: string
  accessZendeskPassword?: string
  aboutUsUrl: string
  newReleasesUrl: string
  supplierPortalUrl: string
  configBanner: string
  experimentalFeatures: string
  experimentalProductPricing: string
  isDevEnv: boolean
  isProdEnv: boolean
  chatScript: string
  googleAnalytics: string
  madeToOrder: string
  shopNow: string
  giftCardLink: string
  countriesBound: string[]
  ga4: string
  rebatesHostName: string
  enableRebatesFeature: string
  ga4_client: string
  breinifyUrl: string
  faro_id: string
  pingCloud: string
  loginApiVersion: string
  enableRewards: boolean
  metaSiteName: string
  apiV2: string
  enableShoppingList: boolean
  enableShimmedHomeStoreUpdate: boolean
  quickLinks: QuickLink[]
  topAlert: QuickLink
  adsAccountKey: string
  webAdsSdkUrl: string
  serviceNowUrl: string
  serviceNowToken: string
}
type QuickLink = {
  title: string
  url: string
}
const modifiedConfig = generateConfig()
delete modifiedConfig.accessZendeskPassword
if (global) {
  // @ts-ignore
  global.swiftlyConfig = modifiedConfig
}
