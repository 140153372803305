import { useEffect, useState } from "react"
import { fetchAds } from "services/AdsApi"
import Ads from "components/ads/Ads"
import { swiftly } from "../../client-data-bom"

let pixelDensity = 1

if (typeof window !== "undefined") {
  pixelDensity = window.devicePixelRatio || 1
}

export default function CouponsAds() {
  const [ads, setAds] = useState<swiftly.ads.SwiftlyJsResolvableAdWrapper>()

  useEffect(() => {
    // TODO(@MikeSchober, can you help with this, this is the top-carousel ad, which is different than anything in the app so far?)
    fetchAds("OffersCategoryMenu", "HeroTop").then((adsFound) => {
      if (adsFound?.ad) setAds(adsFound)
    })
  }, [])

  return ads ? <Ads adWrapper={ads} devicePixelRatio={pixelDensity} /> : null
}
