import * as React from "react"
import { SVGProps } from "react"

const SvgAccountAccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="23"
    viewBox="0 0 16 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C6.67441 0.00158786 5.40356 0.528882 4.46622 1.46622C3.52888 2.40356 3.00159 3.67441 3 5V7.765C1.70615 8.80091 0.765992 10.2131 0.309459 11.8065C-0.147074 13.3998 -0.0974441 15.0956 0.451491 16.6595C1.00043 18.2234 2.02156 19.5783 3.37378 20.5367C4.726 21.4952 6.34254 22.01 8 22.01C9.65746 22.01 11.274 21.4952 12.6262 20.5367C13.9784 19.5783 14.9996 18.2234 15.5485 16.6595C16.0974 15.0956 16.1471 13.3998 15.6905 11.8065C15.234 10.2131 14.2939 8.80091 13 7.765V5C12.9984 3.67441 12.4711 2.40356 11.5338 1.46622C10.5964 0.528882 9.32559 0.00158786 8 0ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V6.589C9.07708 5.80371 6.92292 5.80371 5 6.589V5ZM14 14C14 15.1867 13.6481 16.3467 12.9888 17.3334C12.3295 18.3201 11.3925 19.0892 10.2961 19.5433C9.19974 19.9974 7.99334 20.1162 6.82946 19.8847C5.66557 19.6532 4.59647 19.0818 3.75736 18.2426C2.91824 17.4035 2.3468 16.3344 2.11529 15.1705C1.88378 14.0067 2.0026 12.8003 2.45672 11.7039C2.91085 10.6075 3.67988 9.67047 4.66658 9.01118C5.65327 8.35189 6.81331 8 8 8C9.59081 8.00159 11.116 8.63424 12.2409 9.75911C13.3658 10.884 13.9984 12.4092 14 14ZM10 13C9.99902 13.3497 9.90611 13.6929 9.73059 13.9953C9.55508 14.2978 9.30312 14.5487 9 14.723V17C9 17.2652 8.89464 17.5196 8.70711 17.7071C8.51957 17.8946 8.26522 18 8 18C7.73478 18 7.48043 17.8946 7.29289 17.7071C7.10536 17.5196 7 17.2652 7 17V14.723C6.69563 14.5473 6.44295 14.2944 6.2674 13.99C6.09184 13.6855 5.99962 13.3401 6.00002 12.9887C6.00042 12.6372 6.09343 12.2921 6.26967 11.988C6.44592 11.6839 6.69918 11.4317 7.00395 11.2566C7.30871 11.0816 7.65422 10.99 8.00568 10.991C8.35713 10.992 8.70212 11.0856 9.00589 11.2623C9.30966 11.4391 9.56149 11.6928 9.73601 11.9978C9.91054 12.3029 10.0016 12.6485 10 13Z"
      fill="black"
    />
  </svg>
)
export default SvgAccountAccessIcon
