import * as React from "react"
import { SVGProps } from "react"
const SvgCouponsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.799951 6.09961C0.799951 6.69961 1.19995 7.09961 1.79995 7.09961C2.09995 7.09961 2.29995 6.99961 2.49995 6.79961L5.99995 3.29961L9.49995 6.79961C9.89995 7.19961 10.5 7.19961 10.9 6.79961C11.3 6.39961 11.3 5.79961 10.9 5.39961L6.69995 1.19961C6.29995 0.799609 5.69995 0.799609 5.29995 1.19961L1.09995 5.39961C0.899952 5.59961 0.799951 5.89961 0.799951 6.09961Z"
      fill="#AC4717"
    />
  </svg>
)
export default SvgCouponsIcon
