import * as React from "react"
import { SVGProps } from "react"
const SvgMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    role="presentation"
    aria-hidden="true"
  >
    <path
      d="M4 7h16V5H4v2ZM4 13h16v-2H4v2ZM4 19h16v-2H4v2Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMenuIcon
