import { Alert, AlertProps, Slide, useTheme } from "@mui/material"
import Link from "next/link"
import { useEffect, useState } from "react"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"

export type FlashAlertProps = {
  message: string
  url?: string
  color?: AlertProps["color"]
  onClose?: () => void
}

export default function FlashAlert({ message, onClose, url }: FlashAlertProps) {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  useEffect(() => {
    setIsOpen(true)
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    onClose && onClose()
  }

  return (
    <Slide direction="down" in={isOpen} unmountOnExit>
      <Alert
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 0,
          textAlign: "center",
          color: `${theme.palette.bgPrimary?.main}`,
          height: 40,
          textDecoration: "underline",
          "& .MuiAlert-message": {
            width: "100%",
          },
          ".MuiAlert-action": { padding: 0 },
          backgroundColor: `${theme.palette.ctaPrimary?.main}`,
        }}
        icon={false}
        data-cy="flash-alert"
      >
        {url && (
          <Link
            href={url}
            style={{ color: "inherit", textDecoration: "none" }}
            onClick={() =>
              gaLogEvent({
                eventName: EventName.navTop_click,
                parameters: {
                  item_location: "Message",
                  link_sendTo: url,
                  text_click: message,
                },
              })
            }
          >
            {message}
          </Link>
        )}
        {!url && message}
      </Alert>
    </Slide>
  )
}
