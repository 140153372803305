import * as React from "react"
import { SVGProps } from "react"

const SvgArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.90002 17.2C9.30002 17.2 8.90002 16.8 8.90002 16.2C8.90002 15.9 9.00002 15.7 9.20002 15.5L12.7 12L9.20002 8.50005C8.80002 8.10005 8.80002 7.50005 9.20002 7.10005C9.60002 6.70005 10.2 6.70005 10.6 7.10005L14.8 11.3C15.2 11.7 15.2 12.3 14.8 12.7L10.6 16.9C10.4 17.1 10.1 17.2 9.90002 17.2Z" />
  </svg>
)
export default SvgArrowIcon
