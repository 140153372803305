import * as React from "react"
import { SVGProps } from "react"

const SvgFaqIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 0a11 11 0 1 0 11 11A11.013 11.013 0 0 0 11 0Zm0 20a9 9 0 1 1 9-9 9.011 9.011 0 0 1-9 9Zm1-4.5v2h-2v-2h2Zm3-7a3.982 3.982 0 0 1-1.5 3.122A3.862 3.862 0 0 0 12.063 14h-2.032a5.812 5.812 0 0 1 2.219-3.936 2 2 0 0 0-.15-3.232 2.057 2.057 0 0 0-2-.14A1.939 1.939 0 0 0 9 8.5a1 1 0 1 1-2 0 3.91 3.91 0 0 1 2.319-3.647 4.06 4.06 0 0 1 3.889.315A3.999 3.999 0 0 1 15 8.5Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgFaqIcon
