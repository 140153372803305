import * as React from "react"
import { SVGProps } from "react"
const SvgStoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 23h16a1 1 0 0 0 1-1V9.752a4.22 4.22 0 0 0 .3-.3 4 4 0 0 0 .731-3.456L20.97 1.758A1 1 0 0 0 20 1H4a1 1 0 0 0-.97.758L1.972 5.994A4 4 0 0 0 2.7 9.45c.095.105.195.205.3.3V22a1 1 0 0 0 1 1Zm9-2h-2v-5h2v5Zm6.219-18 .87 3.479A2.029 2.029 0 0 1 18.12 9a2.04 2.04 0 0 1-2.02-1.86L15.754 3h3.465Zm-8.966 0h3.494l.316 3.8A2.028 2.028 0 0 1 12.041 9h-.082a2.029 2.029 0 0 1-2.022-2.2l.316-3.8ZM8.994 9.7a4.04 4.04 0 0 0 2.965 1.3h.082a4.04 4.04 0 0 0 2.965-1.3c.031-.034.052-.076.082-.11A3.956 3.956 0 0 0 19 10.9V21h-4v-6a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v6H5V10.9a3.955 3.955 0 0 0 3.912-1.311c.03.034.051.076.082.111ZM3.911 6.479 4.781 3h3.465L7.9 7.14A2.041 2.041 0 0 1 5.88 9a2.03 2.03 0 0 1-1.969-2.521Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgStoreIcon
