import { SVGProps } from "react"
const ProfileCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path
      opacity="0.4"
      d="M24.5 44.0195C35.5457 44.0195 44.5 35.0652 44.5 24.0195C44.5 12.9738 35.5457 4.01953 24.5 4.01953C13.4543 4.01953 4.5 12.9738 4.5 24.0195C4.5 35.0652 13.4543 44.0195 24.5 44.0195Z"
    />
    <path d="M24.5 13.8809C20.36 13.8809 17 17.2409 17 21.3809C17 25.4409 20.18 28.7409 24.4 28.8609C24.46 28.8609 24.54 28.8609 24.58 28.8609C24.62 28.8609 24.68 28.8609 24.72 28.8609C24.74 28.8609 24.76 28.8609 24.76 28.8609C28.8 28.7209 31.98 25.4409 32 21.3809C32 17.2409 28.64 13.8809 24.5 13.8809Z" />
    <path d="M38.0599 38.7205C34.4999 42.0005 29.7399 44.0205 24.4999 44.0205C19.2599 44.0205 14.4999 42.0005 10.9399 38.7205C11.4199 36.9005 12.7199 35.2405 14.6199 33.9605C20.0799 30.3205 28.9599 30.3205 34.3799 33.9605C36.2999 35.2405 37.5799 36.9005 38.0599 38.7205Z" />
  </svg>
)

export default ProfileCircleIcon
