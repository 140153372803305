import axios, { AxiosPromise } from "axios"
import { ShoppingListItemType } from "components/shoppingList/types"
import generateConfig from "configs/config"
import { ProductIdFilterInput } from "generated/graphql"
import { faroPushErrors } from "utils/faroGrafanaLogs"

const config = generateConfig()

export const registerUser = (user: any): AxiosPromise<any> => {
  const url = `${config.registerBaseUrl}/v1-loyalty-signup/submit`
  const userResponse = axios.post(url, user, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
  return userResponse
}

export const loginAPI = (): AxiosPromise<any> => {
  const url = "/api/login"
  const user = axios.get(url)
  return user
}

export const loginAPI1 = async () => {
  try {
    await axios.get("/api/login").then(async (result: any) => {
      return result.data.LoginUrl
    })
  } catch (err: any) {
    const body = err?.response?.data ? err?.response?.data : "Failed to connect"
    faroPushErrors(
      err?.response?.data ? err?.response?.data : "Failed to connect"
    )
    return { status: body.status ? body.status : 500, body }
  }
}

//ItWillReturnFlowUrl
export const getRedirectUrl = async (url: string) => {
  try {
    await axios.get(url).then(async (result: any) => {
      return result
    })
  } catch (err: any) {
    const body = err?.response?.data ? err?.response?.data : "Failed to connect"
    faroPushErrors(
      err?.response?.data ? err?.response?.data : "Failed to connect"
    )
    return { status: body.status ? body.status : 500, body }
  }
}

export const loginPutAPI = async (
  url: string,
  email: string,
  password: string
) => {
  const request = {
    "urn:pingidentity:scim:api:messages:2.0:UsernamePasswordAuthenticationRequest":
      {
        password: password,
        username: email,
      },
  }
  try {
    await axios.put(url, request).then(async (result: any) => {
      return result
    })
  } catch (err: any) {
    const body = err?.response?.data ? err?.response?.data : "Failed to connect"
    faroPushErrors(
      err?.response?.data ? err?.response?.data : "Failed to connect"
    )
    return { status: body.status ? body.status : 500, body }
  }
}

//ItWillReturnContinueUrl
export const loginGetRedirectionUrl = async (url: string) => {
  try {
    await axios.get(url).then((result: any) => {
      return result

      // window.location.href = result.data.continue_redirect_uri;
      // setContinueUrl(result.data.continue_redirect_uri);
      // loading(false)
      // onUrlChange(result.data.continue_redirect_uri);
    })
  } catch (err: any) {
    const body = err?.response?.data ? err?.response?.data : "Failed to connect"
    faroPushErrors(
      err?.response?.data ? err?.response?.data : "Failed to connect"
    )
    return { status: body.status ? body.status : 500, body }
  }
}

export const getCardId = async (
  userId: string,
  token: string
): Promise<{ cid: string | null; message: any }> => {
  try {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const result = await axios.get(
      `${config.apiServerBaseUrl}/oauth/api/v4.0/users/${userId}/profile`,
      header
    )
    return {
      cid: result?.data?.data?.cid,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      cid: null,
      message: error,
    }
  }
}

export const getTSMCSwiftlyAccessToken = async (): Promise<{
  accessToken: string | null
  message: any
}> => {
  try {
    const codeString = `${config.accessTokenUserName}:${config.accessTokenPassword}`
    const decodedCode = Buffer.from(codeString).toString("base64")
    const body = {
      grant_type: "client_credentials",
    }
    const header = {
      headers: {
        Authorization: `Basic ${decodedCode}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }

    const result = await axios.post(
      `${config.accessTokenHostName}/oauth/token`,
      body,
      header
    )
    return {
      accessToken: result?.data?.access_token,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      accessToken: null,
      message: error,
    }
  }
}

export const getUserAccountDetails = async (
  tsmcToken: any,
  cardId: string
): Promise<{ getAccountDetailsData: string | null; message: any }> => {
  try {
    let longFormbanner
    switch (config.configBanner) {
      case "savemart":
        longFormbanner = "Save Mart Named Stores"
        break
      case "luckysupermarkets":
        longFormbanner = "Lucky Named Stores - no SM13"
        break
      case "foodmaxx":
        longFormbanner = "FoodMaxx"
        break
      default:
        longFormbanner = "Save Mart Named Stores"
    }
    const details = {
      CardID: cardId,
      CustomerTypeID: "0",
      Banner: longFormbanner,
    }
    const header = {
      headers: {
        Authorization: `${tsmcToken}`,
      },
    }
    // TODO(use Swiftly rather than going directly to TSMC, otherwise we can't get Swiftly features)

    const result = await axios.post(
      `${config.registerBaseUrl}/v2/Customer/GetAccountDetails`,
      details,
      header
    )
    return {
      getAccountDetailsData: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      getAccountDetailsData: null,
      message: error,
    }
  }
}

export const getProfileDetails = async (
  tsmcToken: any,
  swiftlyUserId: string
): Promise<{ getProfileDetailsData: string | null; message: any }> => {
  try {
    const header = {
      headers: {
        Authorization: `${tsmcToken}`,
      },
    }
    const result = await axios.get(
      `${config.apiServerBaseUrl}/oauth/api/v4.0/users/${swiftlyUserId}/profile`,
      header
    )
    return {
      getProfileDetailsData: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      getProfileDetailsData: null,
      message: error,
    }
  }
}

export const updateProfileDetails = async (
  tsmcToken: any,
  userData: any,
  swiftlyUserId: string
): Promise<{ getProfileDetailsData: string | null; message: any }> => {
  try {
    const header = {
      headers: {
        Authorization: `${tsmcToken}`,
      },
    }
    const updatedProfile = { ...userData }
    const result = await axios.put(
      `${config.apiServerBaseUrl}/oauth/api/v4.0/users/${swiftlyUserId}/profile`,
      updatedProfile,
      header
    )
    return {
      getProfileDetailsData: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      getProfileDetailsData: null,
      message: error,
    }
  }
}

export const updateEmailPreference = async (
  token: string,
  cid: string,
  emailOptIn: boolean
): Promise<{ updateEmailPreferenceData: any; message: any }> => {
  try {
    const details = {
      CardID: cid || "",
      CustomerTypeID: "0",
      MailPreference: emailOptIn ? "optout" : "optin",
    }
    const header = {
      headers: {
        Authorization: `${token}`,
      },
    }
    const result = await axios.post(
      `${config.registerBaseUrl}/v2/Customer/UpdateMailPreference`,
      details,
      header
    )
    return {
      updateEmailPreferenceData: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      updateEmailPreferenceData: null,
      message: error,
    }
  }
}

export const updateHomeStoreDetails = async (
  token: string,
  cid: string,
  storeNumber: string
): Promise<{ updateHomeStore: any; message: any }> => {
  try {
    const details = {
      CardID: cid || "",
      CustomerTypeID: "0",
      HomeStore: storeNumber || "",
    }
    const header = {
      headers: {
        Authorization: `${token}`,
      },
    }
    const result = await axios.post(
      `${config.registerBaseUrl}/v2/Customer/UpdateHomeStore`,
      details,
      header
    )
    return {
      updateHomeStore: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      updateHomeStore: null,
      message: error,
    }
  }
}

export const updateUserDetails = async (
  token: string,
  details: any
): Promise<{ updateUserData: any; message: any }> => {
  try {
    const header = {
      headers: {
        Authorization: `${token}`,
      },
    }
    const result = await axios.post(
      `${config.registerBaseUrl}/v2/Customer/UpdateAccount`,
      details,
      header
    )
    return {
      updateUserData: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      updateUserData: null,
      message: error,
    }
  }
}

export const isEmployee = async (
  token: string,
  details: any
): Promise<{ isEmployee: any; message: any }> => {
  try {
    const header = {
      headers: {
        Authorization: `${token}`,
      },
    }
    const result = await axios.post(
      `${config.registerBaseUrl}/v1-loyalty-signup/employee-exists`,
      details,
      header
    )
    return {
      isEmployee: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      isEmployee: null,
      message: error,
    }
  }
}

// no axios
export const getCMSData = async (
  query: any,
  variables?: any
): Promise<{ cmsData: any; message: any }> => {
  try {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      "User-Agent": "GenericFetcher"
    }
    const body = {
      query,
      variables,
    }

    const API_URL: any = config.wordPressAPIUrl
    const cmsData = await fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers
    })
    const result = await cmsData.json()

    return {
      cmsData: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      cmsData: null,
      message: error,
    }
  }
}


export const getProductsDetails = async (items: any[]): Promise<any[]> => {
  const resultItems: ShoppingListItemType[] = items || []

  // Fetch product details for the products from the shopping list
  if (items && items.length) {
    const productIds: ProductIdFilterInput[] = items
      .map((item: ShoppingListItemType) => ({
        plu: item.attributes?.productId || "",
      }))
      .filter((item: any) => item.plu)

    const payload = {
      query: `query fetchProducts($storeId: String!, $productIds: [ProductIdFilterInput!], $limit: Int!){
          products(
            match: {
              productIds: $productIds
            }
            storeId: $storeId
            offset: 0
            limit: $limit
          ) {
            products {
              id
              title
              brand
              images {
                image(desiredDensity: FourX) {
                  uri
                }
              }
              webPrice {
                success {
                  basePrice
                  promoPrice
                }
              }
            }
          }
        }`,
      variables: {
        storeId: JSON.parse(localStorage?.storeObject || null)?.number || "",
        productIds: productIds,
        limit: productIds.length,
      },
    }

    const productsData = await axios.post("/graphql", payload)

    if (productsData && productsData?.data?.data?.products?.products) {
      const products = [...productsData?.data?.data?.products?.products]
      products.forEach((p) => {
        const index: number = resultItems.findIndex(
          (item: ShoppingListItemType) => item.attributes?.productId === p.id
        )
        if (index > -1) {
          resultItems[index].productAttributes = {
            brand: p.brand || "",
            imageUrl: p.images[0]?.image?.uri || "",
            basePrice: p.webPrice?.success?.basePrice || "",
            promoPrice: p.webPrice?.success?.promoPrice,
          }
        }
      })
    }
  }

  return resultItems
}

export const getShoppingList = async (
  token: string
): Promise<{ metaData: any; items: any[]; message: any }> => {
  try {
    const result = await axios.get(
      `${config.apiV2}/shopping-list/api/legacy/shopping-lists/default`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      metaData: result?.data,
      items: result?.data?.items || [],
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      metaData: null,
      items: [],
      message: error,
    }
  }
}

export const updateShoppingList = async (
  token: string,
  items: any[],
  metaData: any
): Promise<{ items: any[]; message: any }> => {
  try {
    const payloadItems = items.map((item) => {
      const currentItem = { ...item }
      // remove unnecessary props from the payload
      delete currentItem.productAttributes
      return currentItem
    })

    // remove metaData id which is not required by the PUT API
    if (metaData && metaData?.id) {
      delete metaData.id
    }

    const result = await axios.put(
      `${config.apiV2}/shopping-list/api/legacy/shopping-lists/default`,
      {
        ...metaData,
        items: payloadItems,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      items: result?.data?.items,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      items: [],
      message: error,
    }
  }
}
export const getRecipesById = async (variables?: {
  slug: string
}): Promise<{ recipeData: any; message: any }> => {
  try {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    }
    const query = `query NewQuery ($slug: String){
      recipeBy(slug: $slug) {
        title
        recipe {
          description
          ingredients
          instructions
          recipe
          image {
            id
          }
        }
      }
    }`
    const body = {
      variables,
    }

    const API_URL: any = config.wordPressAPIUrl
    const cmsData = await fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers
    })
    const result = await cmsData.json()

    return {
      recipeData: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      recipeData: null,
      message: error,
    }
  }
}
export const getRecipesList = async (): Promise<{
  recipeList: any
  message: any
}> => {
  try {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    }
    const payload = {
      query: `query {
        recipes {
          nodes {
            slug
            recipeId
            title
            recipe {
              image {
                mediaItemUrl
              }
            }
          }
        }
      }`,
    }
    const API_URL: any = config.wordPressAPIUrl
    const cmsData = await fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(payload),
        headers
    })
    const result = await cmsData.json()

    return {
      recipeList: result?.data,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      recipeList: null,
      message: error,
    }
  }
}

export const getEligibilitiesList = async (): Promise<{
  eligibilitiesList: any
  message: any
}> => {
  try {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    }
    const payload = {
      query: `query chainConfiguration {
        chain {
          configuration {
            productEligibilities {
              accessibilityString
              displayString
              id
              imageIdentifier
            }
          }
        }
      }`,
    }
    const API_URL: any = `${config.apiServerBaseUrl}/graphql`
    const data = await axios.post(API_URL, payload, headers)

    return {
      eligibilitiesList:
        data?.data?.data?.chain?.configuration?.productEligibilities,
      message: "Success",
    }
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    return {
      eligibilitiesList: null,
      message: error,
    }
  }
}
