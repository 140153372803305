import * as React from "react"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import { SxProps, Theme } from "@mui/material/styles"
import { useMediaQuery } from "@mui/material"

const style: SxProps = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  width: 640,
  // height: 630,
  overflowY: "auto",
  overflowX: "hidden",
  transform: "translate(-50%, -50%)",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  backgroundColor: "bgPrimary.main",
  borderRadius: "1rem",
  ":focus-visible": {
    outline: "unset",
  },
}

const mobileStyle: SxProps = {
  position: "absolute",
  display: "flex",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  transform: "all 0.2s linear",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  backgroundColor: "bgPrimary.main",
  ":focus-visible": {
    outline: "unset",
  },
}

const innerStyle = {
  overflow: "hidden",
  width: "100%",
}

export type TransitionModalProps = {
  ariaLabelledBy?: string
  ariaDescribedBy?: string
  ariaLabel?: string
  ariaDescription?: string
  open: boolean
  onClose: () => void
  children: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[]
  sx?: SxProps<Theme>
}

export const TransitionsModal: React.FC<TransitionModalProps> = ({
  ariaLabel,
  ariaDescription,
  ariaLabelledBy,
  ariaDescribedBy,
  open,
  children,
  onClose,
  sx,
}) => {
  const isMobile = useMediaQuery("(max-width:767px)")
  let updatedStyles = {
    ...style,
  }

  if (isMobile) {
    updatedStyles = {
      ...mobileStyle,
    }
  }

  if (sx) Object.assign(updatedStyles, sx)
  return (
    <Modal
      role="dialog"
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel ?? "transition-modal-title"}
      // eslint-disable-next-line jsx-a11y/aria-props
      aria-description={ariaDescription ?? "transition-modal-description"}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={updatedStyles}>
          <Box sx={innerStyle}>{children}</Box>
        </Box>
      </Fade>
    </Modal>
  )
}
