import { SVGProps } from "react"
const SvgSproutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="45"
    height="63"
    viewBox="0 0 45 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.3188 34.7657C32.1678 34.6287 31.9841 34.5326 31.7852 34.4867C31.5862 34.4409 31.3788 34.4467 31.1828 34.5037C30.9754 34.5614 30.7438 34.6383 30.493 34.7297C26.2891 36.3786 18.0477 44.7461 13.6219 50.2387C12.7403 51.3018 11.9283 52.4202 11.1907 53.5871C12.8091 54.8298 14.5119 55.7264 15.877 56.0461C17.2873 56.4555 18.7495 56.6603 20.2184 56.6543C28.3199 56.6543 35.3939 50.1185 35.3312 42.0443C35.3168 39.3689 33.8745 36.2128 32.3188 34.7657Z"
      fill="white"
    />
    <path
      d="M14.6928 36.3402C12.7174 34.8018 9.59163 33.7874 7.30034 33.16C6.87103 33.0398 6.62743 33.2802 6.46342 33.6408C4.41332 38.2848 4.21795 42.1837 6.32112 47.6595C6.99666 49.1955 7.95457 50.5921 9.14543 51.7771C10.2549 50.5127 12.1048 48.4118 12.9731 47.4768C14.2538 46.085 15.9518 44.2509 17.8258 42.4001C17.9343 42.2943 18.3251 41.9313 18.8388 41.453C17.8543 39.4634 16.4381 37.7169 14.6928 36.3402Z"
      fill="white"
    />
    <path
      d="M2.60439 32.9317C-2.8947 42.5924 0.419226 56.0751 11.5549 60.9162C22.2829 65.5795 32.203 61.5436 36.8797 57.3418C42.3884 52.3877 43.7873 48.0994 43.9923 43.0803C44.1129 39.3253 43.2685 35.6019 41.5394 32.2634C41.3187 31.8555 41.0171 31.4966 40.6529 31.2085C40.2887 30.9203 39.8695 30.709 39.4208 30.5874C38.9721 30.4658 38.5032 30.4363 38.0428 30.5009C37.5823 30.5655 37.1398 30.7227 36.7422 30.963L36.7108 30.9822C36.0355 31.3909 35.3095 31.8284 34.5064 32.2851C37.0533 35.1335 39.5858 39.3906 38.3654 45.6956C37.0654 52.3925 30.177 60.7455 17.107 59.272C12.1144 58.7072 7.04226 55.426 4.50737 51.02C1.15726 45.2197 2.04966 38.018 3.94299 33.7249L3.95746 33.7081C4.16054 33.2612 4.38758 32.8255 4.63761 32.4029L4.58455 32.3812C3.89957 32.1072 2.98788 32.2538 2.6261 32.8932L2.60439 32.9317Z"
      fill="white"
    />
    <path
      d="M19.2946 39.0732C19.3809 39.0079 19.4616 38.9355 19.5358 38.8568C19.9965 38.3617 21.393 36.8761 21.393 36.8761C21.3336 36.9355 21.2691 36.9894 21.2 37.0372C21.067 37.1303 20.9064 37.176 20.7442 37.167C20.5846 37.1506 20.4347 37.0829 20.3172 36.974C20.1997 36.8652 20.121 36.7211 20.093 36.5637C19.8228 35.6622 19.7601 34.232 19.7842 33.0181C19.9386 31.3547 20.2666 27.6145 17.8113 24.4872C17.8113 24.4728 17.7872 24.4632 17.7776 24.4487C16.0892 22.2854 13.7159 21.2109 11.8612 20.6027C7.21592 19.0715 4.46396 17.7038 3.47991 16.1053C3.20737 15.675 1.92907 17.7447 1.75782 20.3503C0.959491 33.3498 13.3059 32.8499 16.1978 34.0517C16.8612 34.3228 17.465 34.7204 17.9753 35.2224C18.538 35.8993 18.9241 36.7044 19.0993 37.566C19.1737 37.8964 19.2268 38.2312 19.2585 38.5684C19.2585 38.5684 19.2729 38.7607 19.2753 38.828C19.2783 38.9084 19.2855 38.9887 19.297 39.0684L19.2946 39.0732Z"
      fill="white"
    />
    <path
      d="M34.2676 15.3098C33.9757 14.394 30.9802 6.21161 31.1731 3.00019C31.1918 2.66237 31.2312 2.32601 31.2913 1.99302C31.3599 1.62175 31.4616 1.25734 31.5952 0.90412C32.0607 -0.316987 30.9247 0.0195386 30.6859 0.115689C19.2512 4.68282 17.9898 14.8411 17.7558 16.9804C17.1649 22.3769 21.5907 27.5954 22.7557 31.6433C22.8688 32.2064 22.9013 32.7826 22.8521 33.3548C22.759 34.3239 22.45 35.2601 21.9477 36.0951C21.8103 36.3502 21.6488 36.5917 21.4653 36.8162C21.446 36.8378 21.4291 36.8595 21.4098 36.8787C21.3905 36.8979 20.0134 38.3642 19.5527 38.8594C19.4792 38.9396 19.3985 39.0128 19.3115 39.0781C19.3549 39.4387 19.8807 40.1574 20.0544 40.3473C20.5609 39.881 22.3119 38.2056 22.7195 37.8162C24.374 36.2417 26.3084 34.6456 27.7362 33.4654C28.537 32.8404 29.2075 32.1553 29.9069 31.588C35.1286 27.307 36.6602 22.7759 34.2676 15.3098Z"
      fill="white"
    />
  </svg>
)

export default SvgSproutIcon
