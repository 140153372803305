import * as React from "react"
import { SVGProps } from "react"
const SvgLightningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 8h-4.276l3.144-5.5A1.001 1.001 0 0 0 17 1h-7a1 1 0 0 0-.895.553l-5 10A1 1 0 0 0 5 13h4.656l-2.614 8.713a1 1 0 0 0 1.722.933l11-13A1 1 0 0 0 19 8Zm-8.663 9.689 1.621-5.4A1 1 0 0 0 11 11H6.618l4-8h4.658l-3.144 5.5A1 1 0 0 0 13 10h3.844l-6.507 7.689Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightningIcon
