import * as React from "react"
import { SVGProps } from "react"
const SvgChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.9 17.2c-.6 0-1-.4-1-1 0-.3.1-.5.3-.7L6.7 12 3.2 8.5c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l4.2 4.2c.4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgChevronRightIcon
