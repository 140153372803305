import * as React from "react"
import { SVGProps } from "react"

const SvgRewardsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2932 7.707L16.5862 10H5.00021C4.735 10 4.48064 10.1054 4.29311 10.2929C4.10557 10.4804 4.00021 10.7348 4.00021 11C4.00021 11.2652 4.10557 11.5196 4.29311 11.7071C4.48064 11.8946 4.735 12 5.00021 12H16.5862L14.2932 14.293C14.1977 14.3852 14.1215 14.4956 14.0691 14.6176C14.0167 14.7396 13.9891 14.8708 13.988 15.0036C13.9868 15.1364 14.0121 15.2681 14.0624 15.391C14.1127 15.5138 14.1869 15.6255 14.2808 15.7194C14.3747 15.8133 14.4864 15.8875 14.6093 15.9378C14.7322 15.9881 14.8638 16.0134 14.9966 16.0123C15.1294 16.0111 15.2606 15.9835 15.3826 15.9311C15.5046 15.8787 15.615 15.8025 15.7072 15.707L19.7072 11.707C19.8947 11.5195 20 11.2652 20 11C20 10.7348 19.8947 10.4805 19.7072 10.293L15.7072 6.293C15.615 6.19749 15.5046 6.12131 15.3826 6.0689C15.2606 6.01649 15.1294 5.9889 14.9966 5.98775C14.8638 5.9866 14.7322 6.0119 14.6093 6.06218C14.4864 6.11246 14.3747 6.18671 14.2808 6.2806C14.1869 6.3745 14.1127 6.48615 14.0624 6.60905C14.0121 6.73194 13.9868 6.86362 13.988 6.9964C13.9891 7.12918 14.0167 7.2604 14.0691 7.3824C14.1215 7.50441 14.1977 7.61475 14.2932 7.707ZM1.00021 0H9.00021C9.26543 0 9.51978 0.105357 9.70732 0.292893C9.89486 0.48043 10.0002 0.734784 10.0002 1C10.0002 1.26522 9.89486 1.51957 9.70732 1.70711C9.51978 1.89464 9.26543 2 9.00021 2H2.00021V20H9.00021C9.26543 20 9.51978 20.1054 9.70732 20.2929C9.89486 20.4804 10.0002 20.7348 10.0002 21C10.0002 21.2652 9.89486 21.5196 9.70732 21.7071C9.51978 21.8946 9.26543 22 9.00021 22H1.00021C0.734997 22 0.480642 21.8946 0.293106 21.7071C0.10557 21.5196 0.000213623 21.2652 0.000213623 21V1C0.000213623 0.734784 0.10557 0.48043 0.293106 0.292893C0.480642 0.105357 0.734997 0 1.00021 0Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRewardsIcon
