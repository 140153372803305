import { swiftly } from "../client-data-bom"
import generateConfig from "../configs/config"
import getAPIBaseUrl from "../utils/getAPIBaseUrl"
import getTimeZone from "../utils/getTimeZone"
import isMobileBrowser from "../utils/isMobileBrowser"

const config = generateConfig()

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init,
  reset,
}

function init(accessToken: string | null | undefined) {
  swiftly.data.initializeDataSDK((builder) => {
    accessToken !== null && accessToken !== undefined
      ? (builder.authorizedRequester = {
          // TODO(make sure this is still valid, different bom's may have different values)
          v5k: (
            contextualUserId: string | null | undefined,
            contextualBaseUrl: string | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            operation: any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            $cont: any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            $mask0: any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            $handler: any
          ) => {
            return (
              operation &&
              operation(
                contextualUserId,
                getAPIBaseUrl(),
                accessToken,
                $cont,
                $mask0,
                $handler
              )
            )
          },
        })
      : (builder.authorizedRequester = null)
    builder.setBaseUrl(getAPIBaseUrl())
    builder.setChainId(config.chainId)
    builder.setStaticDefaultAdRequestParameters(
      config.adsNetworkId,
      config.adsSiteId
    )
    builder.setDefaultHttpClient(
      config.uaSite,
      "1.0.0",
      "10000",
      "com.swiftly.data.web",
      isMobileBrowser() ? "mobile-web" : "web",
      "1",
      15000,
      15000,
      15000,
      "",
      "browser",
      "en-US",
      getTimeZone()
    )
  })
}

function reset() {
  init(null)
}
