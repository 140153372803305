import * as React from "react"
import { SVGProps } from "react"
const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path
      d="M12 1a11 11 0 1 0 11 11A11.013 11.013 0 0 0 12 1Zm0 20a9.641 9.641 0 0 1-5.209-1.674 6.999 6.999 0 0 1 10.418 0A9.167 9.167 0 0 1 12 21Zm6.694-3.006a8.98 8.98 0 0 0-13.388 0 9 9 0 1 1 13.388 0ZM12 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUserIcon
