/**
 * Get the swiftly token using our clients opaque token
 * @param clientToken - token provider by our client/partner when a user logins to their system
 */
import { faroPushErrors } from "utils/faroGrafanaLogs"
import { SwiftlyTokenType } from "../contexts/AuthContext"

export async function getSwiftlyAuthToken(clientToken: string) {
  try {
    const response = await fetch("/oauth", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "authorization_code",
        code: clientToken,
      }),
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error("Unable to login")
    }
    return data
  } catch (error: any) {
    faroPushErrors(JSON.stringify(error?.response?.data))
    console.log("Failed attempting to fetch the users swiftly token", error)
  }
}

const MAX_RETRY = 3
let RETRY = 1

/**
 * When the swiftly token is expired, use the refresh token to get a new swiftly token
 * @param refreshToken the refresh token provided by Swiftly getSwiftlyAuthToken function call
 */
export async function refreshToken(
  refreshToken: string
): Promise<SwiftlyTokenType | void> {
  if (RETRY >= MAX_RETRY) {
    return Promise.resolve()
  }
  const response = await fetch("/oauth", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    }),
  })
  RETRY = RETRY + 1
  if (!response.ok) {
    throw new Error("Unable to refresh the token")
  }
  const data = await response.json()
  return data
}
