import * as React from "react"
import { SVGProps } from "react"
const SvgChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.8 10.9c0-.6.4-1 1-1 .3 0 .5.1.7.3l3.5 3.5 3.5-3.5c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.4.4-1 .4-1.4 0l-4.2-4.2c-.2-.2-.3-.5-.3-.7Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgChevronDownIcon
