import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { TransitionsModal } from "components/transitionModal/TransitionModal"
import { styles } from "../../details/EditPersonalInfoModal"
import { useTranslation } from "react-i18next"
import { DollarIcon } from "@/icons"
import { TextInput } from "components/common/textInput/TextInput"
import { ZodError, z } from "zod"
import { useAuth } from "contexts/AuthContext"
import axios from "axios"
import { openSnackbar, setClosePayPalModal } from "contexts/actions/actions"
import { useAppContext } from "contexts/AppContext"
import { LoadingButton } from "@mui/lab"
import { useRouter } from "next/router"
import { faroPushErrors } from "utils/faroGrafanaLogs"

const emailSchema = z.object({
  paypalEmail: z.string().email(),
})

export default function AddPaypalModal() {
  const [paypalEmail, setPaypalEmail] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const { user, updateUserInfo, swiftlyToken, userId } = useAuth()
  const { dispatch, state } = useAppContext()
  const { isPayPalModalOpen } = state

  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(767))

  const submitHandler: React.FormEventHandler = async (e) => {
    e.preventDefault()
    const data = await emailSchema
      .parseAsync({
        paypalEmail: paypalEmail,
      })
      .catch((err: ZodError) => {
        err.errors.forEach((e) => {
          if (e.path?.[0]) {
            setError(t("coupons.rebates.paypalError"))
          }
        })
      })
    if (!data) return
    try {
      setIsLoading(true)
      const sToken = `${swiftlyToken?.access_token}`
      const body = {
        userData: { paypalEmail: data.paypalEmail },
        swiftlyUserId: userId,
      }
      const header = {
        headers: {
          Authorization: sToken,
        },
      }
      const result = await axios.post("/api/updateShimmedProfile", body, header)
      if (result.data.status === "Success") {
        const updatedUserInfo = {
          ...user,
          paypalEmail: data.paypalEmail,
        }
        updateUserInfo(updatedUserInfo)
        onClose()
      }
      if (result.data.status === "Failed") {
        dispatch(
          openSnackbar({
            variant: "alert",
            message: result.data?.message,
            alert: {
              color: "error",
            },
          })
        )
      }
    } catch (error: any) {
      faroPushErrors(
        error.response.data.message
          ? error.response.data.message
          : "Failed to connect"
      )
      dispatch(
        openSnackbar({
          variant: "alert",
          message: error.response.data.message,
          alert: {
            color: "error",
          },
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    dispatch(setClosePayPalModal())
  }

  useEffect(() => {
    if (!router?.isReady) return

    router.beforePopState(() => {
      onClose()
      return true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, isPayPalModalOpen])

  return (
    <TransitionsModal
      ariaLabelledBy="addPaypalEmailMessage"
      ariaDescription={t("ariaLabels.description.paypalModal")}
      open={isPayPalModalOpen}
      onClose={onClose}
      sx={{
        ...styles.mobileView,
        width: isMobile ? "90%" : "375px",
        backgroundColor: theme.palette.background.paper,
        padding: isMobile ? 2 : 3,
        pr: isMobile ? 1 : 2,
        pt: isMobile ? 1 : 2,
      }}
    >
      <form onSubmit={submitHandler}>
        <Stack alignItems="center" gap={3}>
          <DollarIcon color={theme.palette.ctaPrimary.main} />
          <Typography sx={{ textAlign: "center" }} id="addPaypalEmailMessage">
            {t("coupons.rebates.addPaypalEmailMsg", { email: paypalEmail })}
          </Typography>
          <Stack direction="row" sx={{ ...styles.header, width: "100%" }}>
            <TextInput
              label={t("coupons.rebates.yourPaypalEmail")}
              placeholder={t("coupons.rebates.yourPaypalEmail")}
              value={paypalEmail}
              sx={styles.textInput}
              id="paypal_email"
              labelSx={styles.inputLabel}
              onChange={(e) => {
                setPaypalEmail(e.target.value)
                error && setError("")
              }}
              error={!!error}
              helperText={!!error && error}
              disabled={isLoading}
            />
          </Stack>
          <Stack direction="row" gap={2} sx={{ width: "100%" }}>
            <Button
              disabled={isLoading}
              variant="outlined"
              color="ctaPrimary"
              sx={styles.cancelbutton}
              type="button"
              onClick={onClose}
              data-cy="cancel_btn"
              aria-label={t("coupons.rebates.later")}
            >
              {t("coupons.rebates.later")}
            </Button>
            <LoadingButton
              loading={isLoading}
              disableElevation
              variant="contained"
              color="ctaPrimary"
              sx={styles.button}
              type="submit"
              data-cy="save_btn"
            >
              {t("coupons.rebates.add")}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </TransitionsModal>
  )
}
