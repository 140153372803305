import * as React from "react"
import { SVGProps } from "react"
const SvgStateRadioIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8c0-3.771 0-5.657 1.172-6.828C2.343 0 4.229 0 8 0c3.771 0 5.657 0 6.828 1.172C16 2.343 16 4.229 16 8c0 3.771 0 5.657-1.172 6.828C13.657 16 11.771 16 8 16c-3.771 0-5.657 0-6.828-1.172C0 13.657 0 11.771 0 8Z"
      fill="#fff"
    />
    <g clipPath="url(#state_radio_icon_svg__a)">
      <rect
        x={0.5}
        y={0.5}
        width={15}
        height={15}
        rx={3.5}
        fill="#fff"
        stroke="currentColor"
      />
      <rect x={4} y={4} width={8} height={8} rx={2} fill="currentColor" />
    </g>
    <defs>
      <clipPath id="state_radio_icon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgStateRadioIcon
