import * as React from "react"
import { SVGProps } from "react"
import { useTheme } from "@mui/material/styles"

const SvgCartIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill={theme.palette.ctaPrimary.main}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 2H3V16C3 16.2652 3.10536 16.5196 3.29289 16.7071C3.48043 16.8946 3.73478 17 4 17H16C16.2151 17 16.4245 16.9307 16.5971 16.8022C16.7697 16.6738 16.8963 16.4931 16.958 16.287L19.958 6.287C20.0027 6.13769 20.0119 5.97999 19.9849 5.82649C19.9579 5.67298 19.8953 5.52793 19.8022 5.4029C19.7092 5.27786 19.5882 5.17632 19.4489 5.10637C19.3096 5.03641 19.1559 4.99999 19 5H5V1C5 0.734784 4.89464 0.48043 4.70711 0.292893C4.51957 0.105357 4.26522 0 4 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM5 7H17.656L15.256 15H5V7ZM4.5 18C4.79667 18 5.08668 18.088 5.33336 18.2528C5.58003 18.4176 5.77229 18.6519 5.88582 18.926C5.99935 19.2001 6.02906 19.5017 5.97118 19.7926C5.9133 20.0836 5.77044 20.3509 5.56066 20.5607C5.35088 20.7704 5.08361 20.9133 4.79264 20.9712C4.50166 21.0291 4.20006 20.9993 3.92597 20.8858C3.65189 20.7723 3.41762 20.58 3.2528 20.3334C3.08797 20.0867 3 19.7967 3 19.5C3 19.1022 3.15804 18.7206 3.43934 18.4393C3.72064 18.158 4.10218 18 4.5 18ZM14.5 18C14.7967 18 15.0867 18.088 15.3334 18.2528C15.58 18.4176 15.7723 18.6519 15.8858 18.926C15.9993 19.2001 16.0291 19.5017 15.9712 19.7926C15.9133 20.0836 15.7704 20.3509 15.5607 20.5607C15.3509 20.7704 15.0836 20.9133 14.7926 20.9712C14.5017 21.0291 14.2001 20.9993 13.926 20.8858C13.6519 20.7723 13.4176 20.58 13.2528 20.3334C13.088 20.0867 13 19.7967 13 19.5C13 19.1022 13.158 18.7206 13.4393 18.4393C13.7206 18.158 14.1022 18 14.5 18Z" />
    </svg>
  )
}
export default SvgCartIcon
