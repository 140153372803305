import { GraphQLError } from "./GraphqlError"
import generateConfig from "../configs/config"
import { useAuth } from "../contexts/AuthContext"
import i18next from "i18next"
import getSwiftyAuthHeader from "./getSwiftyAuthHeader"
import { useAppContext } from "contexts/AppContext"

const config = generateConfig()

// If you want you could replace this custom fetcher with graphql-request:
// https://dev.to/soom/how-to-use-graphql-and-react-query-with-graphql-code-generator-based-on-nextjs-23jj

// Use this export when doing calls server side for SSR
export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit["headers"]
): (() => Promise<TData>) => {
  return async () => {
    const res = await fetch(`${config.apiServerBaseUrl}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(headers ?? {}),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })
    if (res.status === 403) {
      throw new Error("Unauthorized")
    }
    const json = await res.json()
    if (json.errors) {
      if (!variables) {
        console.log("Did you forget to provide variables?")
      }
      // eslint-disable-next-line no-throw-literal
      throw json as GraphQLError
    }
    return json.data
  }
}

// Used by generated react query hooks by codegen
export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"]
): ((variables?: TVariables) => Promise<TData>) => {
  const { logout, swiftlyToken, refreshTheToken } = useAuth()
  const { currentStore } = useAppContext().state
  const storeId = currentStore?.storeId
  return async (variables?: TVariables) => {
    const authHeader = getSwiftyAuthHeader()
    const headers: any = {
      "Content-Type": "application/json",
      ...authHeader,
      ...(options ?? {}),
    }
    // Passing storeId only when My Store is set
    if (!!storeId) {
      headers["x-swiftly-selected-store"] = storeId
    }
    const res = await fetch("/graphql", {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    if (
      (res.status === 401 || res.status === 403) &&
      swiftlyToken?.refresh_token
    ) {
      await refreshTheToken()
    } else if (res.status === 401 || res.status === 403) {
      // logout({ message: i18next.t("common.unauthorized") as string });
      console.log("401s and no swiftly refresh token")
    }

    const json = await res.json()

    // When fetch throws errors like network errors,
    // these are not handled here but bubble up to the app and can be handled in an error boundary component
    if (json.errors) {
      if (!variables) {
        console.log("Did you forget to provide variables?")
      }
      // eslint-disable-next-line  no-throw-literal
      throw json as GraphQLError
    }

    return json.data
  }
}
