import * as React from "react"
import { SVGProps } from "react"
const SvgPinMiniIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="pin_mini_icon_svg__a"
      maskUnits="userSpaceOnUse"
      x={1}
      y={3}
      width={14}
      height={18}
      fill="currentColor"
    >
      <path fill="#fff" d="M1 3h14v18H1z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10.005A6.008 6.008 0 0 1 8 4c3.31 0 6 2.697 6 6.005 0 3.728-3.698 7.612-5.38 9.379-.221.232-.407.428-.546.582a.098.098 0 0 1-.148 0 31.79 31.79 0 0 0-.536-.57C5.713 17.635 2 13.74 2 10.005Zm6 3.166c1.657 0 3-1.369 3-3.057S9.657 7.057 8 7.057s-3 1.369-3 3.057 1.343 3.057 3 3.057Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10.005A6.008 6.008 0 0 1 8 4c3.31 0 6 2.697 6 6.005 0 3.728-3.698 7.612-5.38 9.379-.221.232-.407.428-.546.582a.098.098 0 0 1-.148 0 31.79 31.79 0 0 0-.536-.57C5.713 17.635 2 13.74 2 10.005Zm6 3.166c1.657 0 3-1.369 3-3.057S9.657 7.057 8 7.057s-3 1.369-3 3.057 1.343 3.057 3 3.057Z"
      fill="currentColor"
    />
    <path
      d="m8.62 19.384.725.69-.725-.69Zm-.546.582.744.669-.744-.669Zm-.148 0 .742-.67-.742.67Zm-.536-.57-.724.69.724-.69ZM8 3c-3.863 0-7 3.141-7 7.005h2A5.008 5.008 0 0 1 8 5V3Zm7 7.005C15 6.146 11.863 3 8 3v2c2.756 0 5 2.248 5 5.005h2ZM9.345 20.073c.838-.88 2.235-2.346 3.42-4.078 1.172-1.71 2.235-3.82 2.235-5.99h-2c0 1.559-.786 3.255-1.884 4.86-1.084 1.584-2.377 2.943-3.22 3.83l1.449 1.378Zm-.527.562c.126-.141.301-.325.527-.562l-1.449-1.379c-.217.229-.415.436-.565.603l1.487 1.338Zm-1.635 0a1.098 1.098 0 0 0 1.635 0L7.33 19.297a.902.902 0 0 1 1.337 0l-1.485 1.339Zm-.517-.55c.22.232.392.412.517.55l1.485-1.339c-.147-.163-.341-.367-.554-.59l-1.448 1.38ZM1 10.005c0 2.174 1.067 4.288 2.241 6.001 1.189 1.736 2.589 3.202 3.425 4.08l1.448-1.38c-.841-.883-2.136-2.244-3.223-3.83C3.789 13.268 3 11.567 3 10.006H1Zm9 .109c0 1.154-.913 2.057-2 2.057v2c2.227 0 4-1.834 4-4.057h-2ZM8 8.057c1.087 0 2 .903 2 2.057h2c0-2.223-1.773-4.057-4-4.057v2Zm-2 2.057c0-1.154.913-2.057 2-2.057v-2c-2.227 0-4 1.834-4 4.057h2Zm2 2.057c-1.087 0-2-.903-2-2.057H4c0 2.223 1.773 4.057 4 4.057v-2Z"
      fill="#fff"
      mask="url(#pin_mini_icon_svg__a)"
    />
  </svg>
)
export default SvgPinMiniIcon
