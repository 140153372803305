import * as React from "react"
import { SVGProps } from "react"
import { useTheme } from "@mui/material/styles"

const MadeToOrderIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill={theme.palette.ctaPrimary.main} stroke={theme.palette.ctaPrimary.main} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_203_4237)">
    <path d="M44.8914 39.9044C44.6842 35.2815 42.7806 30.9652 39.4825 27.6782C37.0373 25.2423 34.0281 23.5746 30.7495 22.7979C30.7523 22.7422 30.7551 22.6883 30.7551 22.6326C30.7551 20.2626 28.8273 18.3367 26.4592 18.3367C24.091 18.3367 22.1633 20.2644 22.1633 22.6326C22.1633 22.6883 22.1661 22.7422 22.1688 22.7979C18.8912 23.5756 15.8811 25.2423 13.4358 27.6782C10.1377 30.9652 8.23504 35.2815 8.02694 39.9044C6.47358 40.1451 5.30205 41.5563 5.44234 43.2035C5.57798 44.7996 6.95482 46.0055 8.55649 46.0055H44.4204C46.1122 46.0055 47.489 44.6286 47.489 42.9368C47.4881 41.4048 46.3602 40.132 44.8914 39.9044ZM26.4573 19.9625C27.8815 19.9625 29.0447 21.0829 29.1209 22.4876C28.2457 22.3613 27.3566 22.2981 26.4573 22.2981C25.558 22.2981 24.6689 22.3631 23.7937 22.4876C23.8699 21.0829 25.0331 19.9625 26.4573 19.9625ZM44.4185 44.3796H8.53605C7.79653 44.3796 7.14713 43.8399 7.06352 43.105C6.96411 42.2345 7.64603 41.4931 8.49703 41.4931H13.7108C14.1596 41.4931 14.5237 41.1289 14.5237 40.6802C14.5237 40.2315 14.1596 39.8673 13.7108 39.8673H9.65369C10.1154 31.0023 17.4883 23.9221 26.4573 23.9221C35.4263 23.9221 42.7992 31.0023 43.2609 39.8673H21.1135C20.6647 39.8673 20.3005 40.2315 20.3005 40.6802C20.3005 41.1289 20.6647 41.4931 21.1135 41.4931H44.4157C45.211 41.4931 45.8586 42.1406 45.8586 42.9359C45.8595 43.7312 45.2119 44.3787 44.4167 44.3787L44.4185 44.3796Z" stroke-width="1.02195" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.581 40.683C15.581 41.1317 15.2837 41.4959 14.9177 41.4959L4.15284 41.5888C3.7868 41.5888 3.4895 41.2246 3.4895 40.7759C3.4895 40.3271 3.7868 39.963 4.15284 39.963L14.9177 39.8701C15.2846 39.8701 15.581 40.2333 15.581 40.683Z" stroke-width="1.02195" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.9752 35.1979C14.9752 35.6466 14.678 36.0108 14.3119 36.0108H1.17429C0.80825 36.0108 0.510956 35.6466 0.510956 35.1979C0.510956 34.7491 0.80825 34.3849 1.17429 34.3849H14.3119C14.6789 34.3849 14.9752 34.7482 14.9752 35.1979Z" stroke-width="1.02195" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.2519 28.5209C21.2519 28.9696 20.9546 29.3338 20.5886 29.3338H7.45094C7.08489 29.3338 6.7876 28.9696 6.7876 28.5209C6.7876 28.0721 7.08489 27.7079 7.45094 27.7079H20.5886C20.9555 27.7079 21.2519 28.0712 21.2519 28.5209Z" stroke-width="1.02195" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.7425 12.7262C18.1442 13.3087 18.1312 14.2665 18.7137 14.8648C19.0101 15.1696 19.4031 15.3219 19.797 15.3219C20.1909 15.3219 20.5579 15.1798 20.8524 14.8927C24.7637 11.0846 22.4085 8.19894 21.002 6.47464C19.6158 4.77634 19.1485 4.20405 20.8357 2.61074C21.4433 2.03752 21.4711 1.08061 20.8979 0.47301C20.3256 -0.133656 19.3678 -0.162456 18.7602 0.411693C15.0254 3.93556 17.2997 6.72269 18.6589 8.38754C20.0804 10.1295 20.6694 10.8504 18.7435 12.7262H18.7425Z" />
    <path d="M24.9458 12.7271C24.3475 13.3096 24.3345 14.2674 24.917 14.8657C25.2133 15.1704 25.6063 15.3228 26.0002 15.3228C26.3941 15.3228 26.7611 15.1807 27.0556 14.8936C30.966 11.0854 28.6118 8.19983 27.2052 6.47552C25.8181 4.77723 25.3518 4.20494 27.0389 2.61163C27.6465 2.03841 27.6744 1.08149 27.1012 0.473895C26.5289 -0.132771 25.571 -0.161571 24.9634 0.412578C21.2287 3.93644 23.503 6.72358 24.8622 8.38842C26.2836 10.1304 26.8717 10.8513 24.9467 12.7271H24.9458Z" />
    <path d="M31.2744 12.7271C30.6761 13.3096 30.6631 14.2674 31.2456 14.8657C31.542 15.1704 31.9349 15.3228 32.3289 15.3228C32.7228 15.3228 33.0898 15.1807 33.3843 14.8936C37.2946 11.0854 34.9404 8.19983 33.5338 6.47552C32.1468 4.77723 31.6804 4.20494 33.3675 2.61163C33.9751 2.03841 34.003 1.08149 33.4298 0.473895C32.8575 -0.132771 31.8996 -0.161571 31.2921 0.412578C27.5573 3.93644 29.8316 6.72358 31.1908 8.38842C32.6122 10.1304 33.2003 10.8513 31.2753 12.7271H31.2744Z" />
    <path d="M44.7353 46.3919C44.7353 46.8528 44.4381 47.2272 44.072 47.2272H8.74882C8.38277 47.2272 8.08548 46.8528 8.08548 46.3919C8.08548 45.9311 8.38277 45.5567 8.74882 45.5567H44.072C44.439 45.5567 44.7353 45.9302 44.7353 46.3919Z" stroke-width="1.02195" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_203_4237">
    <rect width="48" height="47.738" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}
export default MadeToOrderIcon
