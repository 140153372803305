import { TabContext, TabPanel } from "@mui/lab"
import {
  Button,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Box from "@mui/material/Box"
import DialogActions from "@mui/material/DialogActions"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import axios from "axios"
import { getClippedURL } from "components/accounts/coupons/rebateHttpConfig"
import Ads from "components/ads/Ads"
import Loader from "components/loader/Loader"
import { useAuth } from "contexts/AuthContext"
import { SortDimensionId } from "generated/graphql"
import { useUserAgent } from "next-useragent"
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import AdsApi from "services/AdsApi"
import TaxonomyApi from "services/TaxonomyApi"
import { CouponCard, CouponGridItem } from "swiftlykit"
import {
  findBestDecoration,
  findBestDecorationRebates,
  findOfferState,
  findOfferStateRebate,
  sortOffers,
} from "utils/offers"
import { swiftly } from "../../client-data-bom"
import generateConfig from "../../configs/config"
import { AppContext, useAppContext } from "../../contexts/AppContext"
import {
  closeSnackbar,
  openLoginRequestModal,
  openSnackbar,
  setOpenCouponId,
  setSelectedCouponCategoryId,
} from "../../contexts/actions/actions"
import CouponsApi, { Rebate } from "../../services/CouponsApi"
import AdsPlaceHolder from "../ads/AdsPlaceHolder"
import DialogModal from "../common/DialogModal"
import CouponCategories from "./CouponCategories"
import CouponsAds from "./CouponsAds"
import CouponsLoginMessage from "./CouponsLoginMessage"
import CouponsMobileRefine from "./CouponsMobileRefine"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { useQuery } from "@tanstack/react-query"
import styles from "../../styles/component.module.scss"
import { useRouter } from "next/router"
import { convertToLosAngelesTime } from "utils/getLosAngelesTime"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import Cookies from "js-cookie"

const config = generateConfig()

const sxStyles = {
  tabTitle: {
    fontSize: "15px",
    fontWeight: 500,
  },
}

const SPECIAL_OFFERS = "special offers"
export const useClaimCoupon = () => {
  const { t } = useTranslation()
  const [isClaiming, setIsClaiming] = useState<boolean>(false)
  const { isAuthenticated, userId, swiftlyToken } = useAuth()

  const { dispatch } = useContext(AppContext)

  const claimCoupon = (
    couponId: string,
    isRebate: boolean,
    onClaim?: (coupon?: swiftly.offers.SwiftlyJsSwiftlyOffer | null) => void,
    onClaimError?: (error?: Error) => void
  ) => {
    if (!isAuthenticated) {
      setIsClaiming(false)
      dispatch(openLoginRequestModal())
      onClaimError?.(new Error("Not authenticated"))
      return
    }
    if (couponId) {
      setIsClaiming(true)
      new Promise((resolve, reject) => {
        if (isRebate) {
          if (!userId || !swiftlyToken?.access_token) return reject()

          const redeemedUrl = getClippedURL(config.chainId, userId)
          const token = swiftlyToken.access_token
          resolve(
            axios.post(redeemedUrl, [couponId], {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
          )
        } else {
          resolve(CouponsApi.claimCoupon(couponId, []))
        }
      })
        .then(() => {
          dispatch(
            openSnackbar({
              message: t("coupons.claimSuccess"),
              alert: {
                color: "success",
              },
              close: true,
            })
          )
          setTimeout(() => {
            dispatch(closeSnackbar())
          }, 2000)
          return CouponsApi.fetchCouponsById(couponId)
        })
        .then((coupon) => {
          // since sometimes fetchCouponsById don't fetch claimed coupons
          // if (!coupon) return;
          onClaim?.(coupon)
        })
        .catch((e) => {
          dispatch(
            openSnackbar({
              message: t("coupons.claimError"),
              alert: {
                color: "error",
              },
              close: true,
            })
          )
          onClaimError?.(e)
          setTimeout(() => {
            dispatch(closeSnackbar())
          }, 2000)
        })
        .finally(() => {
          setIsClaiming(false)
        })
    }
  }
  return { isClaiming, claimCoupon }
}

interface IProps {
  couponsId?: string
}

export default function Coupons({ couponsId }: IProps) {
  const {
    state: { currentStore },
  } = useAppContext()
  const { isAuthenticated, userId, swiftlyToken, isLoading } = useAuth()
  const theme = useTheme()
  const { t } = useTranslation()
  const { query } = useRouter()
  const { claimCoupon, isClaiming } = useClaimCoupon()
  const isMobile = useMediaQuery(theme.breakpoints.down(800))
  const [showGAandGTM, setShowGAandGTM] = useState(false)

  const [topAd, setTopAd] = useState<
    swiftly.ads.SwiftlyJsResolvableAdWrapper | null | undefined
  >(undefined)
  const [bottomAd, setBottomAd] =
    useState<swiftly.ads.SwiftlyJsResolvableAdWrapper | null>(null)

  const [openDialog, setOpenDialog] = useState(false)
  const [sortBy, setSortBy] = useState<SortDimensionId>(
    SortDimensionId.ExpiresSoon
  )
  const [couponId, setCouponId] = useState<string>("")
  const TABS_CONFIG = [
    t("coupons.tabLabels.availableCoupons"),
    t("coupons.tabLabels.clippedCoupons"),
    t("coupons.tabLabels.couponsHistory"),
  ]
  const [isRebatesCollapsed, setIsRebatesCollapsed] = useState(false)
  const [selectedTab, setSelectedTab] = useState("0")

  const claimCouponRef = useRef<string | null>()

  const {
    state: { selectedCouponCategoryId: selectedCategoryId },
    dispatch,
  } = useContext(AppContext)

  const getSelectedCategory = (id: string) => {
    if (query.couponId) {
      const catergorySelect =
        (categoriesForFilter &&
          categoriesForFilter?.find(
            (item: any) => item.name?.toLowerCase() === query.couponId
          )?.id) ||
        null
      if (catergorySelect) {
        dispatch(setSelectedCouponCategoryId(catergorySelect))
      }
    } else {
      dispatch(setSelectedCouponCategoryId(id))
    }
  }

  // Fetch taxonomies on init
  const { data: taxonomy, isSuccess: isTaxonomySuccess } = useQuery(
    ["taxonomies"],
    () => {
      return TaxonomyApi.fetchTaxonomy()
    }
  )

  const taxonomies = useMemo(() => {
    return taxonomy?.graph ?? []
  }, [taxonomy])

  // Fetches coupons
  const {
    data: coupons,
    isLoading: isCouponsLoading,
    refetch: fetchCoupons,
  } = useQuery(["coupons"], () => CouponsApi.fetchCoupons(), {
    enabled: isTaxonomySuccess,
  })

  // Fetches claimed and redeemed coupons
  const {
    data: claimedAndRedeemed,
    isLoading: isClaimedCouponsLoading,
    refetch: fetchClaimedAndRedeemedCoupons,
  } = useQuery(
    ["claimed-coupons"],
    () => {
      return CouponsApi.fetchClaimedAndRedeemedOffers()
    },
    {
      enabled: isTaxonomySuccess,
      select(data) {
        return {
          claimed: data.claimedOffers,
          redeemed: data.redeemedOffers,
        }
      },
    }
  )

  // fetch rebate coupons
  const { data: rebateCoupons, refetch: fetchRebateCoupons } = useQuery(
    ["rebate-coupons"],
    () => {
      if (isAuthenticated) {
        if (currentStore?.state)
          return CouponsApi.fetchRebateListCustomerWithState(
            config.chainId,
            currentStore?.state ?? "",
            userId ?? ""
          )
        return CouponsApi.fetchRebateListCustomer(config.chainId, userId ?? "")
      }

      if (currentStore?.state)
        return CouponsApi.fetchRebateListWithState(
          config.chainId,
          currentStore?.state ?? ""
        )
      return CouponsApi.fetchRebateList(config.chainId)
    },
    {
      enabled: isTaxonomySuccess && config.enableRebatesFeature === "true",
    }
  )

  const onCouponClaimed = useCallback(
    (isRebate = false) => {
      if (isRebate)
        fetchRebateCoupons()
          .then()
          .catch((e) => console.log(e))
      else {
        fetchCoupons()
          .then()
          .catch((e) => console.log(e))
        fetchClaimedAndRedeemedCoupons()
          .then()
          .catch((e) => console.log(e))
      }
    },
    [fetchRebateCoupons, fetchCoupons, fetchClaimedAndRedeemedCoupons]
  )

  const onOpenCouponDetails = useCallback(
    (id: string, isRebate = false) => {
      dispatch(setOpenCouponId(id, isRebate, selectedTab === "1"))
    },
    [dispatch, selectedTab]
  )
  let pixelDensity = 1

  if (typeof window !== "undefined") {
    pixelDensity = window.devicePixelRatio || 1
  }

  const ua = useUserAgent("")
  const storeURL = ua.isIos ? config.appStoreLink : config.playStoreLink

  const availableCoupons = useMemo(
    /**
     * NOTE: By default we want to display all the coupons regardless it is claimed or not
     * If we decide to show only available(unclaimed) coupons then comment below line
     * and uncomment the next to below line to filter out claimed coupons.
     */
    //
    () => coupons?.filter?.((c) => !c.shopperOffer?.claimed) ?? [],
    [coupons]
  )

  const availableRebates: Rebate[] = useMemo(
    () =>
      (rebateCoupons?.activatedRebates ?? []).filter(
        (r: any) => r?.status === "Available"
      ),
    [rebateCoupons]
  )

  const clippedRebates: Rebate[] = useMemo(
    () =>
      (rebateCoupons?.activatedRebates ?? []).filter(
        (r: any) => r?.status === "Clipped"
      ),
    [rebateCoupons]
  )

  const redeemedRebates: Rebate[] = useMemo(
    () =>
      (rebateCoupons?.activatedRebates ?? []).filter(
        (r: any) => r?.status === "Redeemed"
      ),
    [rebateCoupons]
  )

  const categoriesForFilter = useMemo(() => {
    const filters =
      taxonomies?.filter(
        (category) =>
          availableCoupons?.some((coupon) => {
            return coupon.retailerDisplayCategoryIds?.some(
              (id: string) => category.id === id
            )
          }) ||
          availableRebates?.some(
            (rebate: any) => rebate.category === category.id
          )
      ) || []

    const newCategories: {
      name: string
      id: string
      count: number
    }[] = []

    for (const data of filters) {
      if (data.id !== "*") {
        const index = newCategories.findIndex((cat) => data.id === cat.id)
        if (index === -1) {
          const filteredCoupons =
            availableCoupons?.filter((coupon) => {
              if (data.id !== "*") {
                return coupon.retailerDisplayCategoryIds?.some(
                  (id: string) => data.id === id
                )
              }
              return true
            }) || []
          const filteredRebates = availableRebates.filter((rebate) => {
            if (data.id !== "*") {
              return rebate.category === data.id
            } else {
              return
            }
          })
          newCategories.push({
            id: data.id,
            name: data.displayName,
            count: filteredCoupons.length + filteredRebates.length,
          })
        }
      }
    }

    // this is to move special offers from wherever it is to the top
    newCategories.forEach((category) => {
      // figure out if we have special offers at all
      if (category.name.trim().toLowerCase() === SPECIAL_OFFERS) {
        // find the specific position that special offers is in usually last but you never know?
        const specialOffersPosition = newCategories.findIndex(
          (d) => d.name.trim().toLowerCase() === SPECIAL_OFFERS
        )
        // if we have special offers at a position other than first
        if (specialOffersPosition && specialOffersPosition !== 0) {
          // copy special offers and remove it
          const specialOffers = newCategories.splice(specialOffersPosition, 1)
          // take the copy put it in the front
          newCategories.splice(0, 0, specialOffers[0])
        }
      }
    })
    return newCategories
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId, availableCoupons, taxonomies, availableRebates])

  // on initial load only, get the category from the query params

  useEffect(() => {
    const catergorySelect =
      (categoriesForFilter &&
        categoriesForFilter?.find(
          (item: any) => item.name?.toLowerCase() === couponsId
        )?.id) ||
      null

    catergorySelect === null &&
      categoriesForFilter.length > 1 &&
      router.replace("/coupons", undefined, { shallow: true })
    const filter: string = (catergorySelect ?? "*") as string
    getSelectedCategory(filter)

    !isMobile && dispatch(setSelectedCouponCategoryId(filter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesForFilter, couponsId])

  useEffect(() => {
    // refetch the rebates with the customer id once logged in
    config.enableRebatesFeature === "true" && fetchRebateCoupons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    async function fetchTopAd() {
      const topAd = await AdsApi.fetchAds(
        "CategoryDetails",
        "HeroTop",
        selectedCategoryId === "*" ? "all-categories" : selectedCategoryId
      )
      setTopAd(
        topAd?.ad?.constructor?.name === "SwiftlyJsEmptyAdValue" ? null : topAd
      )
    }

    async function fetchBottomAd() {
      const bottomAd = await AdsApi.fetchAds(
        "CategoryDetails",
        "HeroBottom",
        selectedCategoryId === "*" ? "all-categories" : selectedCategoryId
      )
      setBottomAd(
        bottomAd?.ad?.constructor?.name === "SwiftlyJsEmptyAdValue"
          ? null
          : bottomAd
      )
    }

    fetchTopAd()
      .then()
      .catch((e) => console.log(e))
    fetchBottomAd()
      .then()
      .catch((e) => console.log(e))
  }, [selectedCategoryId, userId])

  const handleTabChange = (e: SyntheticEvent<Element, Event>, value: any) => {
    e.preventDefault()
    setSelectedTab(value)
  }

  function filterAndSortCouponsToShow(): swiftly.offers.SwiftlyJsSwiftlyOffer[] {
    // evaluate coupons to show based on selected tab and category filter
    let currentCoupons: swiftly.offers.SwiftlyJsSwiftlyOffer[] = []
    switch (selectedTab) {
      case "0":
        currentCoupons = availableCoupons
        if (selectedCategoryId !== "*") {
          currentCoupons = availableCoupons.filter((c) => {
            return c.retailerDisplayCategoryIds?.some(
              (id: string) => selectedCategoryId === id
            )
          })
        }
        break
      case "1":
        currentCoupons = claimedAndRedeemed?.claimed ?? []
        break
      case "2":
        currentCoupons = claimedAndRedeemed?.redeemed ?? []
        break
    }

    // later sort them based on the selected sort dimension
    return sortOffers(currentCoupons, sortBy)
  }

  useEffect(() => {
    if (couponId) {
      const couponItem: any = couponCards.find(
        (item: any) => item.id === couponId
      )
      let categoryName =
        selectedCategoryId === "*"
          ? "All Categories"
          : categoriesForFilter &&
            categoriesForFilter.find(
              (item: any) => item.id === selectedCategoryId
            )?.name

      if (typeof categoryName !== "string") {
        categoryName = "" // Assign an empty string if categoryName is not a string
      }

      couponItem &&
        gaLogEvent({
          eventName: EventName.coupon_view,
          parameters: {
            item_nameCategory: categoryName,
            item_brandName: couponItem.brand,
            item_description: couponItem.shortDescription,
            item_savings: couponItem.valueText,
            item_location: window.location.pathname,
            item_message1: couponItem && findBestDecoration(couponItem),
            item_expirationDate:
              couponItem?.expDate &&
              `${new Date(couponItem?.expDate)
                .toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                })
                .replace(/\//g, "/")}`,
          },
        })
    }
  }, [couponId])

  useEffect(() => {
    const cookieValue = Cookies.get("cmapi_cookie_privacy")
    /**
     * cookieValue = 2 means Functional Cookies are set to true
     */
    if (cookieValue && !cookieValue.includes("2")) {
      setShowGAandGTM(false)
    } else {
      setShowGAandGTM(true)
    }
  }, [])

  const couponCards: CouponGridItem[] = useMemo(() => {
    const sortedCoupons: swiftly.offers.SwiftlyJsSwiftlyOffer[] =
      filterAndSortCouponsToShow().sort((a, b) => {
        const dateA = new Date(a.expirationDateStr)
        const dateB = new Date(b.expirationDateStr)
        return dateA.getTime() - dateB.getTime()
      })

    return sortedCoupons.map((c) => ({
      type: "coupon",
      id: c.id,
      brand: c.brand,
      valueText: c.valueText,
      shortDescription: c.shortDescription,
      imageUrl: showGAandGTM
        ? c?.images[0]?.imageInfo?.[0]?.url
        : c?.images[0]?.imageInfo?.[0]?.url.includes("cloudfront")
        ? ""
        : c?.images[0]?.imageInfo?.[0]?.url,
      expDate: c.expirationDateStr
        ? convertToLosAngelesTime(c.expirationDateStr)
        : c.expirationDate,
      onCouponClick: () => {
        onOpenCouponDetails(c?.id)
        gaLogEvent({
          eventName: EventName.coupon_view,
          parameters: {
            item_nameCategory: "my coupons",
            item_brandName: c?.brand,
            imageUrl: showGAandGTM
              ? c?.images[0]?.imageInfo?.[0]?.url
              : c?.images[0]?.imageInfo?.[0]?.url.includes("cloudfront")
              ? ""
              : c?.images[0]?.imageInfo?.[0]?.url,
            item_description: c?.shortDescription,
            item_savings: c?.valueText,
            item_location: window.location.pathname,
            item_message1: c && findBestDecoration(c),
            item_expirationDate: c?.expirationDateStr
              ? convertToLosAngelesTime(c?.expirationDateStr)
                  .toLocaleDateString()
                  .slice(0, 5)
              : new Date(c?.expirationDate)
                  .toLocaleDateString()
                  .replace(/(\b\d\b)/g, "0$1")
                  .slice(0, 5),
          },
        })
      },
      topBar: findBestDecoration(c),
      state:
        isClaiming && claimCouponRef.current === c.id
          ? "claiming"
          : findOfferState(c),
      onClipClick: () => {
        gaLogEvent({
          eventName: EventName.coupon_clip,
          parameters: {
            item_nameCategory:
              typeof (selectedCategoryId === "*"
                ? "All Categories"
                : categoriesForFilter &&
                  categoriesForFilter.find(
                    (item: any) => item.id === selectedCategoryId
                  )?.name) === "string"
                ? selectedCategoryId === "*"
                  ? "All Categories"
                  : categoriesForFilter &&
                    categoriesForFilter.find(
                      (item: any) => item.id === selectedCategoryId
                    )?.name
                : "",
            item_nameCategorySub: "",
            item_brandName: c.brand,
            item_description: c.shortDescription,
            imageUrl: showGAandGTM
              ? c?.images[0]?.imageInfo?.[0]?.url
              : c?.images[0]?.imageInfo?.[0]?.url.includes("cloudfront")
              ? ""
              : c?.images[0]?.imageInfo?.[0]?.url,
            item_savings: c?.valueText,
            item_location: "Card",
            item_message1: findOfferState(c),
            item_expirationDate: c?.expirationDateStr
              ? convertToLosAngelesTime(c?.expirationDateStr)
                  .toLocaleDateString()
                  .slice(0, 5)
              : new Date(c.expirationDate)
                  .toLocaleDateString()
                  .replace(/(\b\d\b)/g, "0$1")
                  .slice(0, 5),
          },
        })
        claimCouponRef.current = c.id
        if (findOfferState(c) === "active") {
          claimCoupon(
            c.id,
            false,
            () => {
              if (typeof onCouponClaimed === "function") onCouponClaimed()

              claimCouponRef.current = null
            },
            () => {
              claimCouponRef.current = null
            }
          )
        }
      },
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claimCoupon,
    onOpenCouponDetails,
    sortBy,
    onCouponClaimed,
    isClaiming,
    claimCouponRef,
    selectedCategoryId,
    availableCoupons,
    claimedAndRedeemed,
    selectedTab,
  ])

  const rebateCouponCards: CouponGridItem[] = useMemo(() => {
    let rebates = availableRebates
    if (selectedTab === "0") {
      if (selectedCategoryId !== "*")
        rebates = availableRebates.filter(
          (r) => r.category === selectedCategoryId
        )
    } else if (selectedTab === "1") rebates = clippedRebates
    else rebates = redeemedRebates

    return rebates
      .sort((a, b) => {
        const dateA = new Date(a.endDate)
        const dateB = new Date(b.endDate)
        return dateA.getTime() - dateB.getTime()
      })
      .map((c: Rebate) => ({
        type: "coupon",
        id: c.rebateId,
        brand: c.brand,
        valueText: c.valueDisplay,
        shortDescription: c.shortDescription,
        topBar: findBestDecorationRebates(c),
        imageUrl: showGAandGTM
          ? c.imageDetailsUrl
          : c.imageDetailsUrl?.includes("cloudfront")
          ? ""
          : c.imageDetailsUrl,
        expDate: convertToLosAngelesTime(c.endDate),
        onCouponClick: (id: string) => {
          !isClaiming && onOpenCouponDetails(id, true)
          gaLogEvent({
            eventName: EventName.coupon_view,
            parameters: {
              item_nameCategory: "my coupons",
              item_brandName: c?.brand,
              imageUrl: showGAandGTM
                ? c.imageDetailsUrl
                : c.imageDetailsUrl?.includes("cloudfront")
                ? ""
                : c.imageDetailsUrl,
              item_description: c?.shortDescription,
              item_savings: c?.valueDisplay,
              item_location: window.location.pathname,
              item_message1: c && findBestDecorationRebates(c),
              item_expirationDate: c?.endDate
                ? convertToLosAngelesTime(c?.endDate)
                    .toLocaleDateString()
                    .slice(0, 5)
                : new Date(c?.expirationDate)
                    .toLocaleDateString()
                    .replace(/(\b\d\b)/g, "0$1")
                    .slice(0, 5),
            },
          })
        },
        state:
          isClaiming && claimCouponRef.current === c.rebateId
            ? "claiming"
            : // we only get active rebates from the API
              findOfferStateRebate(c),
        onClipClick: () => {
          claimCouponRef.current = c.rebateId
          gaLogEvent({
            eventName: EventName.coupon_clip,
            parameters: {
              item_nameCategory:
                typeof (selectedCategoryId === "*"
                  ? "All Categories"
                  : categoriesForFilter &&
                    categoriesForFilter.find(
                      (item: any) => item.id === selectedCategoryId
                    )?.name) === "string"
                  ? selectedCategoryId === "*"
                    ? "All Categories"
                    : categoriesForFilter &&
                      categoriesForFilter.find(
                        (item: any) => item.id === selectedCategoryId
                      )?.name
                  : "undefined",
              item_nameCategorySub: "",
              item_brandName: c?.brand,
              item_description: c?.shortDescription,
              imageUrl: showGAandGTM
                ? c.imageDetailsUrl
                : c.imageDetailsUrl?.includes("cloudfront")
                ? ""
                : c.imageDetailsUrl,
              item_savings: c?.valueDisplay,
              item_location: "Card",
              item_message1: c && findBestDecorationRebates(c),
              item_expirationDate: c?.endDate
                ? convertToLosAngelesTime(c?.endDate)
                    .toLocaleDateString()
                    .slice(0, 5)
                : new Date(c.expirationDate)
                    .toLocaleDateString()
                    .replace(/(\b\d\b)/g, "0$1")
                    .slice(0, 5),
            },
          })
          claimCoupon(
            c.rebateId,
            true,
            () => {
              if (typeof onCouponClaimed === "function") onCouponClaimed(true)
              claimCouponRef.current = null
            },
            () => {
              claimCouponRef.current = null
            }
          )
        },
      }))
  }, [
    availableRebates,
    selectedTab,
    clippedRebates,
    redeemedRebates,
    selectedCategoryId,
    isClaiming,
    onOpenCouponDetails,
    claimCoupon,
    onCouponClaimed,
  ])

  const router = useRouter()
  const openCouponId = router.query.openCouponId
  const isRebateCoupon = router.query.isRebate

  useEffect(() => {
    if (openCouponId || (openCouponId && isRebateCoupon)) {
      const isRebate =
        rebateCouponCards.some((c) => c.id === openCouponId) ||
        isRebateCoupon === "true"
      dispatch(setOpenCouponId(openCouponId.toString(), isRebate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCouponId, isRebateCoupon])
  const focusableTextCountRebates = useRef<HTMLElement>(null)
  const focusableTextCountCoupons = useRef<HTMLElement>(null)

  useEffect(() => {
    if (
      focusableTextCountRebates.current &&
      selectedCategoryId !== "*" &&
      rebateCouponCards.length > 0
    ) {
      focusableTextCountRebates.current.focus()
    } else if (
      focusableTextCountCoupons.current &&
      selectedCategoryId !== "*" &&
      couponCards.length > 0
    ) {
      focusableTextCountCoupons.current.focus()
    }
  }, [rebateCouponCards, selectedCategoryId, couponCards])
  return (
    <>
      <CouponsAds />
      <Typography
        variant={isMobile ? "h3" : "h1"}
        sx={{ fontWeight: 600 }}
        color={theme.palette.textPrimary.main}
        alignSelf="start"
      >
        {t("coupons.title")}
      </Typography>
      <Stack
        spacing={2}
        width="fill-available"
        sx={{
          ":focus": {
            outline: "none",
          },
        }}
      >
        <TabContext value={selectedTab}>
          {isAuthenticated && (
            <Tabs
              sx={{
                mt: 3,
                minHeight: 0,
                width: "100%",
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.ctaPrimary.main,
                },
                "& .Mui-selected": {
                  color: `${theme.palette.textPrimary.main} !important`,
                  fontWeight: 600,
                  border: "transparent !important",
                },
              }}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={false}
              value={selectedTab}
            >
              {TABS_CONFIG.map((label, index) => {
                let length = 0
                switch (index) {
                  case 0:
                    length =
                      (availableCoupons?.length ?? 0) + availableRebates.length
                    break
                  case 1:
                    length =
                      (claimedAndRedeemed?.claimed?.length ?? 0) +
                      clippedRebates.length
                    break
                  case 2:
                    length =
                      (claimedAndRedeemed?.redeemed?.length ?? 0) +
                      redeemedRebates.length
                    break
                }
                return (
                  <Tab
                    disableRipple
                    data-cy={label}
                    label={`${label} (${length})`}
                    key={label}
                    tabIndex={0}
                    value={index.toString()}
                    sx={{
                      px: 0,
                      mr: 3,
                      pt: 0,
                      pb: 1,
                      minHeight: 0,
                      textTransform: "capitalize",
                      fontSize: 15,
                      "&:focus":
                        index !== Number(selectedTab)
                          ? {
                              border: `1px solid ${theme.palette.ctaPrimary.main}`,
                              borderRadius: "3px",
                              p: 0.2,
                            }
                          : {},
                    }}
                  />
                )
              })}
            </Tabs>
          )}
          {isAuthenticated && (
            <Divider sx={{ borderStyle: "solid", marginTop: "0 !important" }} />
          )}

          {!isAuthenticated && <CouponsLoginMessage />}

          {TABS_CONFIG.map((label, index) => {
            return (
              <TabPanel
                sx={{
                  width: "100%",
                  px: 0,
                  pb: 0,
                  mt: "0 !important",
                  paddingTop: isMobile ? 2 : 3,
                }}
                key={`${label}-panel`}
                value={index.toString()}
              >
                <Grid container columnSpacing={3}>
                  {index === 0 ? (
                    isMobile ? null : (
                      <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                        <CouponCategories
                          categoryList={categoriesForFilter}
                          totalCount={availableCoupons.length}
                          couponsId={couponsId}
                        />
                      </Grid>
                    )
                  ) : null}
                  <Grid
                    item
                    xs={index === 0 && !isMobile ? 8 : 12}
                    sm={index === 0 && !isMobile ? 8 : 12}
                    md={index === 0 && !isMobile ? 9 : 12}
                    lg={index === 0 && !isMobile ? 10 : 12}
                    xl={index === 0 && !isMobile ? 10 : 12}
                    justifyContent="space-between"
                  >
                    {isMobile ? (
                      <CouponsMobileRefine
                        coupanType={selectedTab}
                        categoryList={categoriesForFilter}
                        totalCount={availableCoupons.length}
                        couponsLength={couponCards.length}
                        sortBy={sortBy}
                        onSortChange={(val) => setSortBy(val)}
                        couponsId={couponsId}
                      />
                    ) : (
                      <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="inherit"
                        pb={3}
                      >
                        {/* <Typography color="textSecondary" fontWeight={400}>
                          {t("common.results", {
                            count:
                              couponCards.length + rebateCouponCards.length,
                          })}
                        </Typography> */}
                        {/* <CouponsSortByMenu
                          value={sortBy}
                          onChange={(val) => setSortBy(val)}
                        /> */}
                      </Stack>
                    )}

                    {isCouponsLoading || isClaimedCouponsLoading ? (
                      <Loader width="100%" height="300px" />
                    ) : (
                      <Box className={styles.couponsC}>
                        {topAd ? (
                          <Grid item key="hero-top" width="100%">
                            <Ads
                              adWrapper={topAd}
                              devicePixelRatio={pixelDensity}
                              userId={userId}
                              overrideSx={{
                                width: "100%",
                              }}
                            />
                          </Grid>
                        ) : (
                          topAd === undefined && (
                            <Grid item key="hero-top" width="100%">
                              <AdsPlaceHolder />
                            </Grid>
                          )
                        )}
                        {rebateCouponCards.length > 0 &&
                          config.enableRebatesFeature === "true" && (
                            <Stack
                              px={3}
                              py={1}
                              my={3}
                              borderRadius={1}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              bgcolor="lightPrimary.main"
                            >
                              <Typography
                                tabIndex={selectedCategoryId !== "*" ? -1 : 0}
                                ref={focusableTextCountRebates}
                              >
                                {t("coupons.rebates.rebateCount", {
                                  count: rebateCouponCards.length,
                                })}
                              </Typography>
                              <IconButton
                                onClick={() =>
                                  setIsRebatesCollapsed(!isRebatesCollapsed)
                                }
                                aria-label={t("ariaLabels.expandMoreLess")}
                              >
                                {isRebatesCollapsed ? (
                                  <ExpandMore />
                                ) : (
                                  <ExpandLess />
                                )}
                              </IconButton>
                            </Stack>
                          )}
                        <Grid
                          container
                          display={
                            isRebatesCollapsed ||
                            config.enableRebatesFeature !== "true"
                              ? "none"
                              : "flex"
                          }
                          rowSpacing={isMobile ? 2 : 3}
                          columnSpacing={isMobile ? 2 : 3}
                        >
                          {rebateCouponCards.map((card, index) => (
                            <Grid
                              item
                              key={`${card.id}-${index}`}
                              sx={{
                                ".sk-CouponCard .sk-CouponCard__card-container .MuiTypography-h3":
                                  {
                                    color: `${theme.palette.ctaPrimary.main}`,
                                  },
                              }}
                            >
                              <CouponCard {...card} />
                            </Grid>
                          ))}
                        </Grid>
                        {couponCards.length > 0 && (
                          <Typography
                            my={3}
                            py={2}
                            px={3}
                            borderRadius={1}
                            bgcolor="lightPrimary.main"
                            tabIndex={selectedCategoryId !== "*" ? -1 : 0}
                            ref={focusableTextCountCoupons}
                            // eslint-disable-next-line jsx-a11y/aria-props
                            aria-label={t("profile.coupons.coupons", {
                              count: couponCards.length,
                            })}
                            variant="h3"
                            sx={sxStyles.tabTitle}
                          >
                            {t("profile.coupons.coupons", {
                              count: couponCards.length,
                            })}
                          </Typography>
                        )}
                        <Grid
                          container
                          rowSpacing={isMobile ? 2 : 3}
                          columnSpacing={isMobile ? 2 : 3}
                        >
                          {couponCards.map((card, index) => (
                            <Grid
                              item
                              key={`${card.id}-${index}`}
                              sx={{
                                ".sk-CouponCard .sk-CouponCard__card-container .MuiTypography-h3":
                                  {
                                    color: `${theme.palette.ctaPrimary.main}`,
                                  },
                              }}
                            >
                              <CouponCard {...card} />
                            </Grid>
                          ))}
                        </Grid>
                        {/* prettier-ignore */}
                        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                        {bottomAd && (
                          <Grid item key="hero-bottom" width="100%">
                            <Ads
                              adWrapper={bottomAd}
                              devicePixelRatio={pixelDensity}
                              userId={userId}
                              overrideSx={{
                                width: "100%",
                              }}
                            />
                          </Grid>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            )
          })}

          <DialogModal
            onClose={() => {
              setOpenDialog(false)
            }}
            open={openDialog}
            width="md"
          >
            <DialogContent>{t("app.couponDialogText")}</DialogContent>
            <DialogActions>
              <Link
                target="_blank"
                href={storeURL ?? ""}
                sx={{ textDecoration: "none" }}
                aria-label={`${t("ariaLabels.open")} ${storeURL ?? ""} ${t(
                  "ariaLabels.inNewTab"
                )}`}
              >
                <Button
                  variant="contained"
                  color="ctaPrimary"
                  aria-label={t("app.openText")}
                >
                  {t("app.openText")}
                </Button>
              </Link>
            </DialogActions>
          </DialogModal>
        </TabContext>
      </Stack>
    </>
  )
}

function getSwiftlyUserId(token?: string): string | undefined {
  if (!token) {
    return undefined
  }

  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)?.sub || undefined
}
