import * as React from "react"
import { SVGProps } from "react"
const SvgCategoriesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 21V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1Zm-11-1H4v-7h7v7Zm0-9H4V4h7v7Zm9 9h-7v-7h7v7Zm0-9h-7V4h7v7Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCategoriesIcon
