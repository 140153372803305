import {
  Box,
  Link as MuiLink,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import Link from "next/link"
import { Trans } from "react-i18next"
import generateConfig from "configs/config"
import { useAuth } from "contexts/AuthContext"

const config = generateConfig()
function CouponsMessage() {
  let lightBackground
  switch (config.configBanner) {
    case "savemart":
      lightBackground =
        "linear-gradient(269.69deg, #FDFEFD 28.3%, rgba(242, 255, 240, 0.32) 80.9%)"
      break
    case "luckysupermarkets":
      lightBackground =
        "linear-gradient(269.69deg, #FEFDFD 28.3%, rgba(255, 240, 240, 0.32) 80.9%)"
      break
    case "foodmaxx":
      lightBackground =
        "linear-gradient(269.69deg, #FDFDFE 28.3%, rgba(240, 246, 255, 0.32) 80.9%)"
      break
    default:
      lightBackground =
        "linear-gradient(269.69deg, #FDFEFD 28.3%, rgba(242, 255, 240, 0.32) 80.9%)"
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(800))
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.ctaPrimary.main}`,
        borderRadius: "12px",
        padding: isMobile ? 1 : 2,
        mt: 2,
        width: "fill-available",
        background: lightBackground,
      }}
    >
      <Typography textAlign="center">
        <Trans
          i18nKey="couponsMessage.notLoggedInFoodmaxx"
          components={[
            <MuiLink
              color={theme.palette.ctaPrimary?.main}
              component={Link}
              key="coupons_login"
              href="/login"
              onClick={() => {
                localStorage.setItem("from", "coupons")
              }}
            />,
            <MuiLink
              color={theme.palette.ctaPrimary?.main}
              component={Link}
              key="coupons_register"
              href="/register"
              onClick={() => {
                localStorage.setItem("from", "coupons")
              }}
            />,
          ]}
        />
      </Typography>
    </Box>
  )
}

export default CouponsMessage
