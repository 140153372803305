import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.612 12.265c.47 0 .83-.185 1.08-.554l6.365-9.741c.086-.137.15-.27.191-.397.041-.127.062-.25.062-.369 0-.319-.107-.583-.322-.793a1.115 1.115 0 0 0-.813-.314c-.224 0-.413.045-.568.136-.15.087-.296.237-.437.452L5.585 9.51 2.741 5.99a1.126 1.126 0 0 0-.909-.445c-.328 0-.602.107-.82.321-.214.21-.322.476-.322.8 0 .141.025.278.076.41.05.132.136.269.26.41l3.527 4.273c.282.337.635.506 1.06.506Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgComponent
