import { faro } from "@grafana/faro-web-sdk"
import generateConfig from "configs/config"

const config = generateConfig()

export const faroPushLogs = (response: any) => {
  if (config.grafanaEnabled) {
    faro.api.pushLog([response])
  }
}

export const faroPushErrors = (response: any) => {
  if (config.grafanaEnabled) {
    faro.api.pushError(new Error(response))
  }
}

export const faroPushEvent = (response: any) => {
  if (config.grafanaEnabled) {
    faro.api.pushEvent(response)
  }
}
