import { Wrapper } from "@googlemaps/react-wrapper"
import { LoadingButton, TabContext, TabPanel } from "@mui/lab"
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useRouter } from "next/router"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { fetchData } from "utils/fetcher"
import { queryClient } from "utils/queryClient"
import { ZipCode } from "zipcodes"
import generateConfig from "../../configs/config"
import { useAuth } from "../../contexts/AuthContext"
import {
  NearbyStoresDocument,
  NearbyStoresQuery,
  StoreEntryDistance,
  useNearbyStoresQuery,
  useAllStoresQuery,
  AllStoresDocument,
} from "../../generated/graphql"
import * as API from "../../index.service"
import styless from "../../styles/component.module.scss"
import { styles } from "../auth/Register"
import { SearchPlaceInput } from "./SearchPlaceInput"
import { StoresList } from "./StoresList"
import StoresMap from "./StoresMap"
import StoreToggleSwitch from "./StoreToggleSwitch"
import { LatLng, useDecorateStore } from "./utils"
import { AppContext } from "../../contexts/AppContext"
import axios from "axios"
import { fetchAds } from "../../services/AdsApi"
import Ads from "../ads/Ads"
import { swiftly } from "client-data-bom"
import Loader from "../loader/Loader"
import { faroPushErrors } from "utils/faroGrafanaLogs"

const config = generateConfig()

type LatLong = Pick<ZipCode, "latitude" | "longitude">

export type StoreProps = {
  isModal?: boolean
  closeModal?: () => void
  defaultLocation?: LatLong
}

export const Stores: React.FC<StoreProps> = ({
  isModal = false,
  closeModal,
}: StoreProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery("(max-width:767px)")
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1199px)")
  const { t } = useTranslation()
  const [storesFound, setStoresFound] = useState<StoreEntryDistance[]>()
  const [defaultStoresFound, setDefaultStoresFound] =
    useState<StoreEntryDistance[]>()
  const [latLong, setLatLong] = useState<LatLong>()
  const [currentLocation, setCurrentLocation] = useState<LatLong>()
  const [value, setValue] = useState<"1" | "2">("1")
  const [bottomAds, setBottomAds] =
    useState<swiftly.ads.SwiftlyJsResolvableAdWrapper | null>(null)
  const router = useRouter()
  const {
    isFromRegister,
    updateIsFromRegister,
    getRegisterInfo,
    initialState,
    updateRegistrationPopUp,
    registrationPopUp,
    updateStartSavingLoading,
    isStartSavingloading,
    setInitialState,
    updateRegisterInfo,
    setSwiftlyAccessToken,
  } = useAuth()
  const [openError, setErrorOpen] = useState(false)
  const [isloading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState()
  // const [continueUrl, setContinueUrl] = useState();
  const {
    state: { currentStore },
  } = useContext(AppContext)
  // constt { email, password }: any = getRegisterInfo || null;
  const { decorateStore } = useDecorateStore()
  let email = ""
  let password = ""

  let pixelDensity = 1

  if (typeof window !== "undefined") {
    pixelDensity = window.devicePixelRatio || 1
  }

  let stepValue

  if (typeof window !== "undefined" && window.localStorage) {
    stepValue = localStorage.getItem("step")
  }

  useEffect(() => {
    if (localStorage.hasOwnProperty("step")) {
      const stepVal = localStorage.getItem("step")
      if (stepVal === "1") {
        const userData: any = localStorage.getItem("userInfo")
        updateRegisterInfo(JSON.parse(userData))
      }
    }
  }, [])

  useEffect(() => {
    const storeId = localStorage.getItem("store")

    if (currentStore) {
      setLatLong({
        latitude: currentStore.latitude,
        longitude: currentStore.longitude,
      })
    } else if (storeId === null) {
      // default location if store id not provided
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatLong({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
          setCurrentLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        },
        () => {
          console.error("Error Getting Location")
          setLatLong({
            latitude: 37.33874,
            longitude: -121.8852525,
          })
        }
      )
    }
  }, [currentStore])

  // once at start-up, query for all stores as default list
  useEffect(() => {
    const allStoresListVariable = {
      chainId: config.chainId,
    }
    const getStoreData = async () => {
      try {
        const storeListData: any = await queryClient.fetchQuery(
          useAllStoresQuery.getKey(allStoresListVariable),
          fetchData(AllStoresDocument, allStoresListVariable)
        )
        const defaultStores = storeListData.stores.all.map((store: any) => ({
          store,
          searchDistance: 0,
          isInStore: false,
          distanceUnit: "",
        }))
        setDefaultStoresFound(defaultStores)
      } catch (e) {
        faroPushErrors(e)
        setStoresFound([])
        console.error(e)
      }
    }
    getStoreData()
  }, [])

  // while latLong changes, update location
  // render the store markers
  useEffect(() => {
    if (!latLong) {
      setStoresFound(defaultStoresFound)
      return
    }

    const fetch = async () => {
      const position = {
        lat: latLong.latitude,
        lng: latLong.longitude,
      }
      const stores: NearbyStoresQuery = await queryClient.fetchQuery(
        useNearbyStoresQuery.getKey({
          ...position,
          currentLat: currentLocation?.latitude || null,
          currentLng: currentLocation?.longitude || null,
        }),
        fetchData(NearbyStoresDocument, {
          ...position,
          currentLat: currentLocation?.latitude || null,
          currentLng: currentLocation?.longitude || null,
        })
      )

      if (stores?.stores?.nearby?.length) {
        setStoresFound(
          stores.stores.nearby.map((store) => ({
            ...store,
          }))
        )
      } else {
        setStoresFound(defaultStoresFound)
      }
    }

    fetch()
  }, [currentLocation, latLong, defaultStoresFound])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newValue: "1" | "2") => {
    setValue(newValue)
    router.query.tabView = newValue
    router.push(router)
  }

  const decoratedStores = useMemo(
    () =>
      (storesFound &&
        storesFound.map((store) =>
          decorateStore(store.store, undefined, store)
        )) ??
      [],
    [storesFound, decorateStore]
  )

  useEffect(() => {
    if (router.query.tabView) {
      setValue(router.query.tabView as "1" | "2")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const panTo = useCallback(({ lat, lng }: LatLng) => {
    setLatLong({
      latitude: lat,
      longitude: lng,
    })
  }, [])

  useEffect(() => {
    fetchAds("StoreLocator", "HeroBottom").then((adsFound) => {
      if (adsFound?.ad) setBottomAds(adsFound)
    })
  }, [])

  /*---------regsiter user--------------*/
  const registerUserAPI = async (registerUser: any) => {
    if (registerUser.preferred_store === "") {
      if (currentStore?.number) {
        registerUser.preferred_store = currentStore?.number
      }
    }
    setLoading(true)
    setInitialState((prevState: any) => ({
      ...prevState,
      actionInprogress: true,
    }))
    if (registerUser.is_email_optin) {
      await API.registerUser(registerUser)
        .then(async (res: any) => {
          if (res.data.return_code === "1") {
            setLoading(false)
            setInitialState((prevState: any) => ({
              ...prevState,
              actionInprogress: false,
            }))
            faroPushErrors(res.data.user_message)
            setMessage(res.data.user_message)
            setErrorOpen(true)
          } else if (res.data.return_code === "0") {
            setLoading(false)
            setInitialState((prevState: any) => ({
              ...prevState,
              actionInprogress: false,
            }))
            updateRegistrationPopUp(true)
          } else {
            setLoading(false)
            setInitialState((prevState: any) => ({
              ...prevState,
              actionInprogress: false,
            }))
            faroPushErrors(res.data.user_message)
            setMessage(res.data.errorMessage)
            setErrorOpen(true)
          }
        })
        .catch((err: any) => {
          faroPushErrors(err)
          setLoading(false)
          setInitialState((prevState: any) => ({
            ...prevState,
            actionInprogress: false,
          }))
        })
    }
  }

  //getLoginUrl
  const getLoginUrlAPI = async (registerUser: any) => {
    updateStartSavingLoading(true)
    try {
      if (config.pingCloud == "true") {
        await axios
          .post("/pingcloud", {
            grant_type: "password",
            username: registerUser?.email,
            password: registerUser?.password,
          }, {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          })
          .then((result: any) => {
            if (result.status === 200) {
              setSwiftlyAccessToken(result.data)
              router.push({ pathname: "/" })
              updateStartSavingLoading(false)
              updateRegistrationPopUp(false)
            }
          })
      } else {
        await axios
          .get("/api/login", { withCredentials: true })
          .then(async (result: any) => {
            email = registerUser?.email
            password = registerUser?.password
            await getRedirectUrl(result.data.LoginUrl.split("flow=")[1])
          })
      }
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data ? err?.response?.data : "Failed to connect"
      )
      updateStartSavingLoading(false)
      updateRegistrationPopUp(false)
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  //ItWillReturnContinueUrl
  const getRedirectUrl = async (url: string) => {
    try {
      await axios
        .get(url, { withCredentials: true })
        .then(async (result: any) => {
          await loginPutAPI(result.data.meta.location)
          // setContinueUrl(result.data.continue_redirect_uri);
        })
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data ? err?.response?.data : "Failed to connect"
      )
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  const loginPutAPI = async (url: string) => {
    const request = {
      "urn:pingidentity:scim:api:messages:2.0:UsernamePasswordAuthenticationRequest":
        {
          password: password,
          username: email,
        },
    }

    try {
      await axios
        .put(url, request, { withCredentials: true })
        .then(async (result: any) => {
          await loginGetRedirectionUrl(result.data.followUp.$ref)
        })
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data ? err?.response?.data : "Failed to connect"
      )
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  //ItWillReturnContinueUrl
  const loginGetRedirectionUrl = async (url: string) => {
    try {
      await axios.get(url, { withCredentials: true }).then((result: any) => {
        router.push(result.data.continue_redirect_uri)
        updateStartSavingLoading(false)
        updateRegistrationPopUp(false)
        // setContinueUrl(result.data.continue_redirect_uri);
      })
    } catch (err: any) {
      faroPushErrors(
        err?.response?.data ? err?.response?.data : "Failed to connect"
      )
      const body = err?.response?.data
        ? err?.response?.data
        : "Failed to connect"
      return { status: body.status ? body.status : 500, body }
    }
  }

  return (
    <Container
      maxWidth="xl"
      sx={{ overflowY: isMobile ? "auto" : isModal ? "auto" : "auto" }}
    >
      <Wrapper apiKey={config.mapsApiKey || ""} libraries={["places"]}>
        {!isMobile && (
          <>
            {stepValue === "1" ? (
              <>
                <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  alignItems="center"
                  mb={3}
                >
                  <Button
                    color="ctaPrimary"
                    sx={{
                      padding: 0,
                      marginRight: 3,
                      fontSize: 14,
                      "&:hover": {
                        backgroundColor: "unset",
                      },
                    }}
                    onClick={() => {
                      updateIsFromRegister(false)
                      router.push("/login")
                      localStorage.removeItem("step")
                    }}
                    aria-label={t("register.cancelSignUp")}
                  >
                    {t("register.cancelSignUp")}
                  </Button>
                  {currentStore ? (
                    <LoadingButton
                      data-cy="btn-finish-sign-up"
                      variant="contained"
                      color="bgPrimary"
                      loading={isloading}
                      sx={{
                        ...styles.button,
                        ...styles.finishB,
                        maxWidth: "192px",
                      }}
                      onClick={async () => {
                        await registerUserAPI(getRegisterInfo)
                      }}
                    >
                      {t("stores.finishSignUp")}
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      data-cy="btn-finish-sign-up"
                      variant="contained"
                      color="ctaPrimary"
                      loading={isloading}
                      sx={{
                        ...styles.finishDisablebutton,
                        ...styles.finishB,
                        maxWidth: "192px",
                      }}
                    >
                      {t("stores.finishSignUp")}
                    </LoadingButton>
                  )}
                </Stack>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <Stack
          sx={{
            width: "100%",
            height: isMobile ? "auto" : isModal ? "510px" : "100%",
            backgroundColor: isModal ? `${theme.palette.bgPrimary}` : undefined,
            overflow: "none",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: isModal ? 600 : 600,
              fontSize: isMobile ? "1.25rem" : isModal ? "1.125rem" : "1.5rem",
              lineHeight: isModal ? "1.5rem" : "2rem",
              paddingBottom: isMobile ? "1.25rem" : isModal ? "1rem" : "1.5rem",
              mt: isModal ? 3 : 0,
            }}
          >
            {isModal
              ? !currentStore
                ? t("stores.select_a_store")
                : t("stores.select_another_store")
              : t("stores.locateNearbyStore")}
          </Typography>
          {(isModal || isTablet) && !isMobile ? (
            <Stack flexDirection="row" mb={2} alignItems="flex-start">
              <SearchPlaceInput
                panTo={panTo}
                addToUrlParams
                handleInputClear={() => {
                  setLatLong(undefined)
                }}
                isModal={isModal}
              />
              <Box display="flex" justifyContent="center">
                <StoreToggleSwitch
                  handleChange={handleChange}
                  value={value}
                  isModal={isModal}
                  currentStore={currentStore}
                />
              </Box>
            </Stack>
          ) : (isModal || isTablet) && isMobile ? (
            <Stack>
              <SearchPlaceInput
                panTo={panTo}
                addToUrlParams
                handleInputClear={() => {
                  setLatLong(undefined)
                }}
                isModal={isModal}
              />
              <Box display="flex" justifyContent="center">
                <StoreToggleSwitch
                  handleChange={handleChange}
                  value={value}
                  isModal={isModal}
                  currentStore={currentStore}
                />
              </Box>
            </Stack>
          ) : (!isModal || !isTablet) && isMobile ? (
            <Stack>
              <SearchPlaceInput
                panTo={panTo}
                addToUrlParams
                handleInputClear={() => {
                  setLatLong(undefined)
                }}
                isModal={isModal}
              />
              <Box display="flex" justifyContent="center">
                <StoreToggleSwitch
                  handleChange={handleChange}
                  value={value}
                  isModal={isModal}
                  currentStore={currentStore}
                />
              </Box>
            </Stack>
          ) : (
            <></>
          )}
          <Box
            sx={{
              width: "100%",
              text: "body1",
              padding: 0,
              margin: 0,
            }}
          >
            {isTablet || isMobile || isModal ? (
              <TabContext value={value}>
                {/* <Box display="flex" justifyContent="center">
                  <StoreToggleSwitch
                    handleChange={handleChange}
                    value={value}
                  />
                </Box> */}
                <TabPanel value="1" sx={{ padding: 0, margin: 0 }}>
                  {storesFound && storesFound.length ? (
                    <StoresList
                      stores={decoratedStores}
                      closeModal={closeModal}
                      isModal={isModal}
                    />
                  ) : storesFound?.length ? (
                    t("stores.noStoresFound")
                  ) : (
                    <Loader height="100%" size={20} />
                  )}
                </TabPanel>
                <TabPanel
                  value="2"
                  data-cy="stores-no-found"
                  sx={{ padding: 0, margin: 0 }}
                >
                  <Box>
                    <StoresMap
                      isMobile={isMobile}
                      isModal={isModal}
                      stores={decoratedStores}
                      closeModal={closeModal}
                      currentLocation={currentLocation}
                      position={
                        latLong?.latitude && latLong?.longitude
                          ? { lat: latLong.latitude, lng: latLong.longitude }
                          : null
                      }
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            ) : (
              <Stack flexDirection="row" gap={3} spacing={3}>
                <Stack flex={1}>
                  <SearchPlaceInput
                    panTo={panTo}
                    addToUrlParams
                    handleInputClear={() => {
                      setLatLong(undefined)
                    }}
                    isModal={isModal}
                  />
                  {storesFound && (
                    <Stack flex={1} mb={2}>
                      {storesFound.length ? (
                        <Box
                          className={styless.customScroll}
                          mt={3}
                          sx={{
                            pr: "10px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            height: 600,
                          }}
                        >
                          <StoresList
                            stores={decoratedStores}
                            closeModal={closeModal}
                            isModal={isModal}
                          />
                        </Box>
                      ) : (
                        <Typography mt={3}>
                          {t("stores.noStoresFound")}
                        </Typography>
                      )}
                    </Stack>
                  )}
                </Stack>
                <Stack sx={{ height: 500 }} flex={1.5} mt="0 !important">
                  <StoresMap
                    isMobile={isMobile}
                    isModal={isModal}
                    stores={decoratedStores}
                    closeModal={closeModal}
                    currentLocation={currentLocation}
                    position={
                      latLong?.latitude && latLong?.longitude
                        ? { lat: latLong.latitude, lng: latLong.longitude }
                        : null
                    }
                  />
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
        {isMobile && stepValue === "1" && (
          <Stack textAlign="center" py={3}>
            {currentStore ? (
              <LoadingButton
                data-cy="btn-finish-sign-up"
                fullWidth
                variant="contained"
                color="ctaPrimary"
                loading={isloading}
                sx={styles.button}
                // type="submit"
                onClick={async () => {
                  updateIsFromRegister(false)
                  await registerUserAPI(getRegisterInfo)
                  // router.push("/login");
                }}
              >
                {t("stores.finishSignUp")}
              </LoadingButton>
            ) : (
              <Button
                data-cy="btn-finish-sign-up"
                fullWidth
                variant="contained"
                color="ctaPrimary"
                sx={styles.finishDisablebutton}
                aria-label={t("stores.finishSignUp")}
                aria-disabled={true}
                // type="submit"
              >
                {t("stores.finishSignUp")}
              </Button>
            )}
            <Typography variant="body1" color="#5A5A5A" my={3}>
              {t("register.or")}
            </Typography>

            <Button
              variant="contained"
              color="ctaPrimary"
              type="submit"
              sx={{
                border: "none",
                padding: 0,
                "&:hover": { background: "none", color: "ctaPrimary.main" },
              }}
              onClick={() => {
                updateIsFromRegister(false)
                router.push("/login")
              }}
              aria-label={t("register.cancelSignUp")}
            >
              {t("register.cancelSignUp")}
            </Button>
          </Stack>
        )}
        <Dialog
          aria-labelledby="select store modal"
          open={registrationPopUp}
          sx={styles.dialogContent}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">{t("register.accountCreated")}</Typography>
          </Stack>
          <DialogContent
            sx={{
              marginTop: 1,
            }}
          >
            <Box>
              <Typography
                variant="body"
                sx={styles.pageMsgText}
                color={theme.palette.textSecondary.main}
              >
                {t("register.registrationSuccess")}
              </Typography>
              <LoadingButton
                variant="contained"
                color="ctaPrimary"
                sx={{ ...styles.button, marginTop: 3 }}
                // type="submit"
                loading={isStartSavingloading}
                onClick={async () => {
                  // router.push("/home");
                  getLoginUrlAPI(getRegisterInfo)
                  localStorage.setItem("step", "0")
                }}
              >
                {t("register.startSaving")}
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          aria-labelledby="select store modal"
          open={openError}
          sx={styles.dialogContent}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              textAlign="center"
              color="ctaFailed.main"
              mb={1}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                router.push("/login")
              }}
            >
              {t("register.accountNotCreated")}
            </Typography>
          </Stack>
          <DialogContent
            sx={{
              marginTop: 1,
            }}
          >
            <Box>
              <Typography variant="paragraph" color="#5B5653" mb={3}>
                {message}
              </Typography>

              <LoadingButton
                variant="contained"
                sx={styles.disableBtn}
                loading={isloading}
                onClick={async () => {
                  setErrorOpen(false)
                  localStorage.setItem("step", "0")
                  router.push("/register")
                }}
              >
                {t("register.reviewData")}
              </LoadingButton>

              <Typography
                variant="body1"
                textAlign="center"
                color="ctaPrimary.main"
                mt={3}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  router.push("/login")
                }}
              >
                {t("forgotPassword.backToLogin")}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
        {bottomAds && (
          <Ads adWrapper={bottomAds} devicePixelRatio={pixelDensity} />
        )}
      </Wrapper>
    </Container>
  )
}
