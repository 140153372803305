import { ChevronRight, CloseOutlined } from "@mui/icons-material"
import { TreeItem, TreeView } from "@mui/lab"
import { Alert, Button, Fade, Grow, Slide } from "@mui/material"
import MuiSnackbar from "@mui/material/Snackbar"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../../contexts/AppContext"
import { closeSnackbar } from "../../../contexts/actions/actions"

// animation function

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TransitionSlideLeft(props: any) {
  return <Slide {...props} direction="left" />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TransitionSlideUp(props: any) {
  return <Slide {...props} direction="up" />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TransitionSlideRight(props: any) {
  return <Slide {...props} direction="right" />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TransitionSlideDown(props: any) {
  return <Slide {...props} direction="down" />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function GrowTransition(props: any) {
  return <Grow {...props} />
}

// animation options
const animation = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade,
}

const Snackbar = () => {
  const {
    state: { snackbar },
    dispatch,
  } = React.useContext(AppContext)
  const { open, message, variant, alert, close, errors } = snackbar
  const { t } = useTranslation()

  const snackbarRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (snackbarRef && open) {
      snackbarRef && snackbarRef?.current?.focus()
    }
  }, [open])

  const closeSnackbarFunc = () => {
    const getActiveElement = localStorage.getItem("activeElement")
    if (getActiveElement) {
      document.getElementById(getActiveElement)?.focus()
    }
    dispatch(closeSnackbar())
    localStorage.removeItem("activeElement")
  }

  return (
    <>
      {variant === "alert" && (
        <MuiSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={20000}
          TransitionComponent={animation["SlideDown"]}
          onClose={() => closeSnackbarFunc()}
        >
          <Alert
            variant={alert.variant}
            severity={alert.color}
            color={alert.color}
            action={
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  marginTop: "-4px",
                }}
              >
                {close && (
                  <Button
                    color="inherit"
                    onClick={() => closeSnackbarFunc()}
                    aria-label={`${t("ariaLabels.alert")}, ${message}, ${t(
                      "ariaLabels.closeButton"
                    )}`}
                    tabIndex={0}
                    ref={snackbarRef}
                  >
                    <CloseOutlined />
                  </Button>
                )}
              </div>
            }
          >
            {message}
            {Boolean(errors.length) && (
              <TreeView>
                <TreeItem nodeId="0" label="Errors:" icon={<ChevronRight />}>
                  <ul>
                    {errors?.map((error) => (
                      <li key={error.message}>{error.message}</li>
                    ))}
                  </ul>
                </TreeItem>
              </TreeView>
            )}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  )
}

export default Snackbar
