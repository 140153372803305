import { useContext } from "react"
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material"
import { AppContext } from "../../../contexts/AppContext"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import { useRouter } from "next/router"
import { TransitionsModal } from "components/transitionModal/TransitionModal"
import { Stores } from "components/stores/Stores"
import { CloseIcon } from "@/icons"
import PinMiniIcon from "@/icons/PinMiniIcon"
import { useAuth } from "contexts/AuthContext"
import { gaLogEvent, EventName } from "utils/googleAnalyticsEvents"

export type StoreSelectModalProps = {
  onClose: () => void
  open: boolean
}

export default function StoreSelectModal({
  onClose,
  open,
}: StoreSelectModalProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery("(max-width:767px)")
  const {
    state: { currentStore },
  } = useContext(AppContext)
  const { t } = useTranslation()
  const router = useRouter()
  const { handleStoreRoute } = useAuth()

  return (
    <TransitionsModal
      ariaLabelledBy="selectStore"
      ariaDescription={t("ariaLabels.description.selectStoreModal")}
      open={open}
      onClose={() => {
        onClose()
        delete router.query.locationSearch
        router.push(router)
      }}
    >
      <Stack flex={1} height="100%">
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems={currentStore ? "start" : "center"}
          sx={{
            backgroundColor: `${theme.palette.bgPrimary?.main}`,
            py: isMobile ? 1 : 2,
            pr: isMobile ? 1 : 2,
            pl: isMobile ? 2 : 3,
            scrollPaddingLeft: 3,
            boxShadow: "0px 1px 1px 0px #00000014",
            zIndex: "10",
            position: "sticky",
            top: 0,
          }}
        >
          <Stack flex={1}>
            <Typography
              variant="body"
              sx={{
                color: `${theme.palette.textSecondary.main}`,
              }}
              id="selectStore"
            >
              {currentStore
                ? t("subNavBar.myStore")
                : t("subNavBar.noStoreSelected")}
            </Typography>
            {currentStore && (
              <>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",
                    color: `${theme.palette.ctaPrimary?.main}`,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleStoreRoute(
                      currentStore.id,
                      currentStore.name,
                      currentStore.number,
                      currentStore.city
                    )
                    gaLogEvent({
                      eventName: EventName.navStoreLocator_closeModal,
                      parameters: {
                        item_location: "Modal",
                        item_message1: currentStore?.name && currentStore?.name,
                      },
                    })
                    onClose()
                  }}
                >
                  {currentStore?.name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    color: "textPrimary.main",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PinMiniIcon
                    color={theme.palette.ctaPrimary.main}
                    fontSize="1.5rem"
                    style={{ marginLeft: "-0.5rem" }}
                  />
                  {currentStore?.address1}
                </Typography>
                <Stack direction="row" gap={1} sx={{ marginTop: 0.5 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    color={
                      currentStore?.status.text === "Open Now"
                        ? "ctaSuccess.main"
                        : "ctaSecondary.main"
                    }
                  >
                    {currentStore?.status?.text}
                  </Typography>
                  <Typography variant="caption">
                    {t("common.bulletPoint")}
                  </Typography>
                  <Typography variant="caption">
                    {currentStore?.timings}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <IconButton
            aria-label={t("ariaLabels.closeButton")}
            sx={{ color: "ctaPrimary" }}
            onClick={() => {
              onClose()
              gaLogEvent({
                eventName: EventName.navStoreLocator_closeModal,
                parameters: {
                  item_location: "Modal",
                  item_message1: currentStore?.name && currentStore?.name,
                },
              })
              delete router.query.locationSearch
              router.push(router)
            }}
          >
            <CloseIcon color={theme.palette.ctaPrimary?.main} />
          </IconButton>
        </Stack>
        <Stores isModal closeModal={onClose} />
      </Stack>
    </TransitionsModal>
  )
}
