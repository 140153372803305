/* eslint-disable i18next/no-literal-string */
import {
  Box,
  Button,
  Collapse,
  Container,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import StoreSelectModal from "components/ui/storeSelectModal/StoreSelectModal"
import { useAuth } from "contexts/AuthContext"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../../contexts/AppContext"
import { NavBarItemType } from "../../navBar/SideNavBar"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import generateConfig from "configs/config"

const config = generateConfig()

export default function SubNavBar() {
  const {
    state: { currentStore },
  } = useContext(AppContext)
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery("(max-width:767px)")
  const isTablet = useMediaQuery("(min-width:767px) and (max-width:1279px)")
  const [isSelectStoreModalOpen, setIsSelectStoreModalOpen] =
    useState<boolean>(false)
  const router = useRouter()
  const { isAuthenticated, initialState } = useAuth()

  useEffect(() => {
    if (!router.isReady) {
      return
    }
    router.beforePopState(() => {
      setIsSelectStoreModalOpen(false)
      return true
    })
  }, [router.isReady])

  const navItemGroups: NavBarItemType[] = [
    {
      label: t("navBarMenu.weeklyAd"),
      href: "/flyers" + (currentStore ? `/${currentStore.number}` : ""),
      props: {
        "data-cy": "weekly-ad",
        role: "link",
      },
    },
    {
      label: t("navBarMenu.locations"),
      href: "/stores",
      props: {
        "data-cy": "locations",
        role: "link",
      },
    },
  ]

  // make gift card links the first item in the list if found in ENV
  if (config?.giftCardLink) {
    navItemGroups.unshift({
      label: t("navBarMenu.giftCards"),
      href: config?.giftCardLink || "",
      props: {
        "data-cy": "gift-cards",
        role: "link",
      },
      isExternal: true,
    })
  }

  if (initialState?.envVariables?.configBanner === "foodmaxx") {
    navItemGroups.splice(2, 0, {
      label: t("navBarMenu.maxxSavings"),
      href: "/maxx-savings",
      props: {
        "data-cy": "my-rewards",
        role: "link",
      },
    })
  } else {
    navItemGroups.splice(
      2,
      0,
      {
        label: t("navBarMenu.myRewards"),
        href: isAuthenticated ? "/my-rewards" : "/rewards-login",
        props: {
          "data-cy": "my-rewards",
          role: "link",
        },
      }
      // {
      //   label: t("navBarMenu.madeToOrder"),
      //   href: `${initialState?.envVariables?.madeToOrder}`,
      // }
    )
  }

  if (config.enableShoppingList) {
    navItemGroups.push({
      label: t("navBarMenu.shoppingList"),
      href: "/shopping-list",
      props: {
        "data-cy": "shopping-list",
        role: "link",
      },
    })
  }

  const handleSelectStore = (e: any) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      setIsSelectStoreModalOpen(true)
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: `${theme.palette.bgPrimary?.main}`,
        borderTop: `1px solid ${theme.palette.dividerColor?.main}`,
      }}
    >
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={isMobile ? "center" : "space-between"}
          minHeight={40}
          flex={1}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" mr={theme.spacing(1)}>
              {t("subNavBar.myStore")}
            </Typography>
            <Link
              data-testid="setStoreLink"
              onClick={handleSelectStore}
              onKeyDown={handleSelectStore}
              sx={{
                cursor: "pointer",
                color: `${theme.palette.ctaPrimary?.main}`,
                textDecoration: "none",
              }}
              role="button"
              tabIndex={0}
              aria-label={
                currentStore ? currentStore.name : t("subNavBar.selectStore")
              }
            >
              {currentStore ? currentStore.name : t("subNavBar.selectStore")}
            </Link>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end" mr={-1}>
            {!isMobile &&
              navItemGroups?.map(
                (
                  {
                    label,
                    icon,
                    href,
                    onClick,
                    props,
                    isExternal,
                  }: NavBarItemType,
                  i: number
                ) => (
                  <Button
                    sx={{
                      py: "6px",
                      px: 1,
                      color: theme.palette.textPrimary?.main,
                    }}
                    key={`nav-mobile-${i}`}
                    startIcon={icon}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      const target = e.target as HTMLButtonElement
                      gaLogEvent({
                        eventName: EventName.navTop_click,
                        parameters: {
                          text_click: target.textContent,
                          link_sendTo: href,
                          item_location: "Secondary",
                        },
                      }),
                        e.preventDefault()
                      if (href) {
                        Boolean(isExternal) && window.open(href, "_blank")
                        !Boolean(isExternal) && router.push({ pathname: href })
                      } else if (onClick && typeof onClick === "function") {
                        onClick(e)
                      }
                    }}
                    {...props}
                    variant="label"
                    aria-label={label}
                  >
                    {label}
                  </Button>
                )
              )}
          </Stack>
        </Stack>
        <StoreSelectModal
          open={isSelectStoreModalOpen}
          onClose={() => setIsSelectStoreModalOpen(false)}
        />
      </Container>
    </Box>
  )
}
