import * as React from "react"
import { SVGProps } from "react"
const SvgGiftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 7h-1.106c.075-.328.11-.664.106-1a3.718 3.718 0 0 0-4-4c-2.164 0-3.969 2.27-5 3.928C10.969 4.27 9.164 2 7 2a3.718 3.718 0 0 0-4 4c-.005.336.03.672.106 1H2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1v7a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-7h1a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM7 4c1.113 0 2.419 1.583 3.308 3H7.556C5 7 5 6.6 5 6c0-1.792 1.142-2 2-2Zm4 16H5v-6h6v6Zm0-8H3V9h8v3Zm6-8c.858 0 2 .208 2 2 0 .6 0 1-2.556 1h-2.752c.889-1.417 2.195-3 3.308-3Zm2 16h-6v-6h6v6Zm2-8h-8V9h8v3Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgGiftIcon
