import { Breakpoint, Dialog, DialogProps } from "@mui/material"

export type DialogModalProps = {
  open?: boolean
  onClose?: () => void
  width?: Breakpoint
  children?: React.ReactNode
} & DialogProps

export default function DialogModal({
  open = false,
  width = "md",
  onClose,
  children,
  ...rest
}: DialogModalProps) {
  return (
    <Dialog
      PaperProps={{
        elevation: 1,
        sx: {
          border: "none",
          borderRadius: "16px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
        id: "dialogModal",
        tabIndex: 0,
      }}
      open={open}
      maxWidth={width}
      onClose={onClose}
      {...rest}
    >
      {children}
    </Dialog>
  )
}
