function getPixelDensity(pixelDensity?: number): string {
  if (!pixelDensity) {
    if (typeof window !== "undefined")
      pixelDensity = window.devicePixelRatio || 1
    else pixelDensity = 1
  }
  if (pixelDensity > 3) return "FourX"
  if (pixelDensity > 2) return "ThreeX"
  if (pixelDensity > 1.5) return "TwoX"
  if (pixelDensity > 1) return "OnePointFiveX"
  return "OneX"
}

export default getPixelDensity
