import { com, swiftly } from "../client-data-bom"
import toJson from "../utils/toJson"
import generateConfig from "../configs/config"
import refreshApiSetupInitWithNewToken from "../utils/refreshApiSetupInitWithNewToken"
import { getImage } from "../components/ads/Ads"

const config = generateConfig()
const { taxonomy } = swiftly

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchTaxonomy,
  fetchMerchandisedCategory,
  fetchHome,
  fetchOffersMerchandisedCategory,
}

async function fetchTaxonomy(): Promise<swiftly.taxonomy.SwiftlyJsTaxonomy> {
  return await taxonomy.fetchTaxonomy(config.taxonomyId)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchHome(storeNumber: number | null | undefined): Promise<any> {
  await refreshApiSetupInitWithNewToken()
  const home =
    await swiftly.offersproducts.fetchOffersProductsMerchandisedCategory(
      (
        modifyRequest: com.swiftly.bridge.offersproducts.data.OffersProductsMerchandisedCategoryInfoRequestBuilder
      ) => {
        modifyRequest.taxonomyId = config.taxonomyId
        modifyRequest.taxonomyNodeId = config.homeNode
        modifyRequest.forcedRenderingTemplate = "merchandised"
        modifyRequest.storeNumber = storeNumber
      }
    )

  return parseMerchandisedCategory(home)
}

async function fetchOffersMerchandisedCategory(
  taxonomyNodeId: string,
  adsScreenName: string,
  storeNumber: number | null | undefined,
  forcedRenderingTemplate: string | null | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const merchandisedCategory =
    await swiftly.offersproducts.fetchOffersProductsMerchandisedCategory(
      (modifyRequest) => {
        // TODO(Get storenumber working)
        modifyRequest.storeNumber = storeNumber
        modifyRequest.storeId = storeNumber?.toString()
        // storeNumber isn't working?

        modifyRequest.taxonomyId = config.taxonomyId
        modifyRequest.taxonomyNodeId = taxonomyNodeId
        if (adsScreenName) {
          modifyRequest.adsScreenName = adsScreenName
        }
        if (forcedRenderingTemplate) {
          modifyRequest.forcedRenderingTemplate = forcedRenderingTemplate
        }
      }
    )
  return merchandisedCategory
}

async function fetchMerchandisedCategory(
  taxonomyNodeId: string,
  adsScreenName: string,
  storeNumber: number | null | undefined,
  forcedRenderingTemplate: string | null | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const merchandisedCategory =
    await swiftly.products.fetchProductsMerchandisedCategory(
      //     await swiftly.offersproducts.fetchOffersProductsMerchandisedCategory(
      (modifyRequest) => {
        // TODO(Get storenumber working)
        modifyRequest.storeNumber = storeNumber
        modifyRequest.storeId = storeNumber?.toString()
        // storeNumber isn't working?

        modifyRequest.taxonomyId = config.taxonomyId
        modifyRequest.taxonomyNodeId = taxonomyNodeId
        if (adsScreenName) {
          modifyRequest.adsScreenName = adsScreenName
        }
        if (forcedRenderingTemplate) {
          modifyRequest.forcedRenderingTemplate = forcedRenderingTemplate
        }
      }
    )
  return parseMerchandisedCategory(merchandisedCategory)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseMerchandisedCategory(merchandisedCategory: any): any {
  const offersProducts = merchandisedCategory.reify(
    swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any

  const displayableObjects = (offersProducts.displayableObjects || []).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (item: swiftly.types.SwiftlyJsSwiftlyDisplayableObject, index: number) => {
      let constructorName = "SwiftlyJsSwiftlyDisplayableObjectValue"
      let items = undefined

      const displayableAd = item.reify(
        swiftly.offers.JsSwiftlyOffersReificationContext
      ) as swiftly.ads.SwiftlyJsSwiftlyDisplayableAd
      let ad = {}

      if (displayableAd.ad) {
        const resolvedAd = swiftly.ads
          .resolveAdRefList(displayableAd.ad)
          .reify(
            swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
          )
        ad = toJson(resolvedAd)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(ad as any).serverBestFit = toJson(getImage(resolvedAd.ad as any))
        constructorName = "SwiftlyJsSwiftlyDisplayableAdValue"
      }

      const categoryPreview = item.reify(
        swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
      )
      const offersPreview =
        categoryPreview as swiftly.offers.SwiftlyJsOffersMerchandisedCategoryPreview
      let offers: swiftly.offers.SwiftlyJsSwiftlyOffer[] = []

      if (offersPreview.offers) {
        offers = toJson(offersPreview.offers)
        constructorName = "SwiftlyJsOffersMerchandisedCategoryPreviewValue"
      }

      const productPreviews =
        categoryPreview as swiftly.products.SwiftlyJsProductsMerchandisedCategoryPreview

      if (productPreviews.items) {
        const products = productPreviews.items.map(
          (baseProduct: swiftly.products.SwiftlyJsSwiftlyProductSummary) => {
            const product = baseProduct.reify(
              swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
            )

            const price = (product.price &&
              product.price.success && {
                success: {
                  base:
                    (product.price.success.base && {
                      activeThrough:
                        (product.price.success.base.activeThrough && {
                          start: {
                            date: product.price.success.base.activeThrough.start
                              .date,
                            time: product.price.success.base.activeThrough.start
                              .time,
                          },
                          end: {
                            date: product.price.success.base.activeThrough.end
                              .date,
                            time: product.price.success.base.activeThrough.end
                              .time,
                          },
                        }) ||
                        null,
                      amountOff: getPrice(product.price.success.base.amountOff),
                      model: getEnum(product.price.success.base.model),
                      buyQuantity: product.price.success.base.buyQuantity,
                      getQuantity: product.price.success.base.getQuantity,
                      price: getPrice(product.price.success.base.price),
                      type: getEnum(product.price.success.base.type),
                    }) ||
                    null,
                  cost:
                    (product.price.success.cost && {
                      purchaseQuantity:
                        product.price.success.cost.purchaseQuantity,
                      totalCost: getPrice(product.price.success.cost.totalCost),
                      type: getEnum(product.price.success.cost.type),
                    }) ||
                    null,
                  savings: product.price.success.savings,
                  info: product.price.success.info,
                  mixAndMatchId: product.price.success.mixAndMatchId,
                  promotion: product.price.success.promotion,
                  scenario: product.price.success.scenario,
                  soldBy: getEnum(product.price.success.soldBy),
                  unit: getEnum(product.price.success.unit),
                },
              }) || {
              failure: product.price.failure && {
                message: product.price.failure.message,
                type: getEnum(product.price.failure.type),
              },
            }

            return {
              brand: product.brand,
              categories: product.categories,
              id: product.id,
              images: getImages(product.images),
              productImages: getImages(product.productImages),
              eligibilities: product.eligibilities,
              legacyPriceTag: product.legacyPriceTag,
              ordinal: product.ordinal,
              pointsAway: price,
              shortDescription: product.shortDescription,
              tags: product.tags.map((tag) => {
                const {
                  code,
                  description,
                  id,
                  images,
                  tagImages,
                  title,
                  type,
                  value,
                } = tag
                return {
                  code,
                  description,
                  id,
                  images: getImages(images),
                  tagImages: getImages(tagImages),
                  title,
                  type,
                  value,
                }
              }),
              title: product.title,
              type: product.type,
              constructorName: "SwiftlyJsSwiftlyProductSummaryValue",
            }
          }
        )
        items = products

        constructorName = "SwiftlyJsProductsMerchandisedCategoryPreviewValue"
      }

      const ads = (productPreviews.ads && toJson(productPreviews?.ads)) || []
      const adList =
        (productPreviews.adList && toJson(productPreviews?.adList)) || []

      // this is to handle mutation support for server-side image generation
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const adListImages: any = []

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(productPreviews?.ads || []).forEach((ad: any, i: number) => {
        // this is to handle mutation support for server-side image generation
        if (ad.ad) {
          adListImages[i] = getImage(ad.ad)
        }
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ads.forEach((ad: any, i: number) => {
        if (ad.ad) {
          ad.serverBestFit = toJson(adListImages[i])
        }
      })

      return {
        ...item,
        ...ad,
        ads,
        adList,
        items,
        offers,
        taxonomyNodeId: productPreviews?.taxonomyNodeId,
        prefixItems: productPreviews?.prefixItems,
        constructorName,
      }
    }
  )

  // const heroTop = offersProducts.resolveAd(offersProducts.heroTopAdRef);
  // const heroBottom = offersProducts.resolveAd(offersProducts.heroTopAdRef);

  const offerProductsParsed = toJson(offersProducts)
  // this is to handle mutation support for server-side image generation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const adListImages: any = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  offersProducts.adList.forEach((ad: any, i: number) => {
    // this is to handle mutation support for server-side image generation
    adListImages[i] = []
    if (ad.ad) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ad.containedAds.forEach((innerAd: any, innerI: number) => {
        adListImages[i][innerI] = getImage(innerAd.ad)
      })
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  offerProductsParsed.adList.forEach((ad: any, i: number) => {
    if (ad.ad) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ad.containedAds.forEach((_: any, innerI: number) => {
        ad.containedAds[innerI].serverBestFit = toJson(adListImages[i][innerI])
      })
    }
  })

  if (
    offerProductsParsed.products &&
    offerProductsParsed.products.fieldFacets
  ) {
    offerProductsParsed.products.fieldFacets.forEach(
      (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fieldFacet: any,
        fieldI: number
      ) => {
        const reifiedFieldFacet = offersProducts.products.fieldFacets[
          fieldI
        ].reify(
          swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
        ) as swiftly.products.SwiftlyJsSwiftlyFieldFacet
        fieldFacet.entries.forEach(
          (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            entry: any,
            i: number
          ) => {
            const { count } = reifiedFieldFacet.entries[i].reify(
              swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
            ) as swiftly.products.SwiftlyJsSwiftlyFieldFacetEntry
            entry.count = parseInt(count.toString())
          }
        )
      }
    )
  }

  const ads = offersProducts.adList

  return {
    ...offerProductsParsed,
    ads,
    displayableObjects: toJson(displayableObjects),
  }
}

// private functions below

function getImages(
  images: Array<swiftly.types.SwiftlyJsSwiftlyMultiDensityImage>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return images.map((baseImage: any) => {
    const { caption, image, imageInfo, images, type, use, altText } =
      baseImage.reify(
        swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
      )
    const reifiedImage = image.reify(
      swiftly.offersproducts.JsSwiftlyOffersproductsReificationContext
    )

    return {
      caption,
      image: {
        density: getEnum(reifiedImage.density),
        deviceClass: getEnum(reifiedImage.deviceClass),
        url: reifiedImage.url,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      imageInfo: imageInfo.map((val: any) => ({
        density: getEnum(val.density.name),
        deviceClass: getEnum(val.deviceClass),
        url: val.url,
      })),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      images: images.map((val: any) => ({
        density: getEnum(val.density || {}),
        deviceClass: getEnum(val.deviceClass || {}),
        url: val.url,
      })),
      type: {
        description: type.description,
        height: type.height,
        name: type.name,
        width: type.width,
      },
      use: getEnum(use),
      altText,
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getEnum(e: any): any {
  return {
    name: e.name,
    ordinal: e.ordinal,
  }
}

function getPrice(
  price: swiftly.products.SwiftlyJsSwiftlyProductMoney | null | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  if (!price) {
    return null
  }
  return {
    currency: getEnum(price.currency),
    denomination: getEnum(price.denomination),
    value: price.value,
  }
}
