import { Container, Stack, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import SideBar from "./SideBar"

export const styles = {
  card: {
    borderRadius: 4,
    padding: 3,
    outline: "1px solid",
    outlineColor: "#E3E7E2",
    alignSelf: "strech",
    flex: 1,
    backgroundColor: "bgPrimary.main",
  },
  border: {
    borderRadius: "0.8rem",
  },
  text: {
    borderRadius: "0.8rem",
  },

  button: {
    marginTop: 2,
    fontSize: 15,
    py: 0,
    px: 1,
    cursor: "pointer",
  },
  verticalRule: {
    width: 1,
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "ctaPrimary.light",
    },
    "& .MuiTab-root": {
      color: "tabsButton.main",
      textTransform: "none",
      fontSize: 15,
      fontWeight: 500,
      p: 0,
      mr: 3,
      minHeight: 0,
      pb: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "textPrimary.main",
    },
    minHeight: 0,
    borderBottom: "1px solid",
    borderColor: "grey.300",
    marginBottom: 3,
  },
}

interface AccountsWrapperProps {
  children: React.ReactNode
}

const AccountsLayout = ({ children }: AccountsWrapperProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery("(max-width:767px)")
  const isDesktop = useMediaQuery(theme.breakpoints.up(1200))
  const isTablet = !isMobile && !isDesktop

  return (
    <Container maxWidth="xl">
      <Stack direction="row" gap={isTablet ? 2 : 3}>
        {!isMobile && <SideBar isDesktop={isDesktop} isTablet={isTablet} />}
        <Stack
          sx={{
            ...styles.border,
            flexGrow: 1,
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Container>
  )
}

export default AccountsLayout
