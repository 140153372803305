import * as React from "react"
import { SVGProps } from "react"
const SvgCouponsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path
      d="M2 11.308a1 1 0 0 0 .293.707l9.692 9.692a1 1 0 0 0 1.414 0l8.308-8.307a1 1 0 0 0 0-1.414l-9.692-9.693A1 1 0 0 0 11.308 2H3a1 1 0 0 0-1 1v8.308ZM4 4h6.894l8.692 8.692-6.894 6.894L4 10.894V4Zm5.923 3.154a1.959 1.959 0 1 1-2.77 2.77 1.959 1.959 0 0 1 2.77-2.77Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCouponsIcon
