import * as React from "react"
import { SVGProps } from "react"

const SvgZapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 7h-4.276l3.144-5.5A1.001 1.001 0 0 0 13 0H6a1 1 0 0 0-.895.553l-5 10A1 1 0 0 0 1 12h4.656l-2.614 8.713a1 1 0 0 0 1.722.933l11-13A1 1 0 0 0 15 7Zm-8.663 9.689 1.621-5.4A1 1 0 0 0 7 10H2.618l4-8h4.658L8.132 7.5A1 1 0 0 0 9 9h3.844l-6.507 7.689Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgZapIcon
