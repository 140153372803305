import { CloseIcon } from "@/icons"
import SvgCloseIcon from "@/icons/ClipIcon"
import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { AppContext } from "../../../contexts/AppContext"
import { closeLoginRequestModal } from "../../../contexts/actions/actions"
import { TransitionsModal } from "../../transitionModal/TransitionModal"

const LoginRequestModal = () => {
  const { state, dispatch } = React.useContext(AppContext)
  const { isLoginRequestPopupOpen } = state
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(700))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1200))
  const { t } = useTranslation()

  const router = useRouter()
  const goToLogin = () => {
    dispatch(closeLoginRequestModal())
    router.push("/login").catch(console.error)
  }

  return (
    <TransitionsModal
      ariaLabelledBy="loginModal"
      ariaDescribedBy="loginMessage"
      open={isLoginRequestPopupOpen}
      onClose={() => dispatch(closeLoginRequestModal())}
      sx={{
        width: isMobile ? "90%" : "460px",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack flex={1} data-cy="coupon-details-modal">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            p: isMobile ? 2 : isDesktop ? 3 : 3,
            paddingBottom: 0,
            pr: isMobile ? 1 : 2,
            pt: isMobile ? 1 : 2,
          }}
        >
          <Typography
            variant="h3"
            aria-label="login required dialog"
            fontWeight="bold"
            id="loginModal"
          >
            {t("loginRequestModal.title")}
          </Typography>
          <IconButton
            onClick={() => dispatch(closeLoginRequestModal())}
            aria-label={t("ariaLabels.closeButton")}
          >
            <CloseIcon color={theme.palette.ctaPrimary.main} />
          </IconButton>
        </Stack>
        <Stack flex={1} sx={{ p: isMobile ? 2 : isDesktop ? 3 : 3 }}>
          <Typography variant="body1" id="loginMessage">
            {t("loginRequestModal.message")}
          </Typography>
          <Button
            disableElevation
            sx={{
              mt: 2,
              justifyContent: "space-between",
              fontSize: 15,
              px: 2,
              py: 1,
              "&:hover": {
                backgroundColor: "transparent",
                color: `${theme.palette.ctaPrimary?.main}`,
              },
            }}
            fullWidth
            variant="contained"
            color="ctaPrimary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              const target = e.target as HTMLButtonElement
              goToLogin()
              localStorage.setItem("from", window.location.pathname)
              gaLogEvent({
                eventName: EventName.login_click,
                parameters: {
                  text_click: target.textContent,
                  item_location: window.location.pathname,
                },
              })
            }}
            aria-label={t("login.login")}
          >
            {t("login.login")} <SvgCloseIcon />
          </Button>
        </Stack>
      </Stack>
    </TransitionsModal>
  )
}

export default LoginRequestModal
