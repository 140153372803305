import { SwiftlyTokenType } from "../contexts/AuthContext"
import Cookies from "js-cookie"

function getAuthTokenFromCookie(): SwiftlyTokenType | undefined {
  let token
  const authToken: string | undefined = Cookies.get("authToken") as
    | string
    | undefined
  if (authToken) {
    token = JSON.parse(
      Buffer.from(authToken, "base64").toString("ascii")
    ) as SwiftlyTokenType
  }
  return token
}

export default getAuthTokenFromCookie
