import { SVGProps } from "react"
const SvgRewardsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.4"
      d="M48 54.6672H45.3333V54.0005C45.3333 51.0672 42.9333 48.6672 40 48.6672H34V42.5605C33.3333 42.6405 32.6667 42.6672 32 42.6672C31.3333 42.6672 30.6667 42.6405 30 42.5605V48.6672H24C21.0667 48.6672 18.6667 51.0672 18.6667 54.0005V54.6672H16C14.9067 54.6672 14 55.5739 14 56.6672C14 57.7605 14.9067 58.6672 16 58.6672H48C49.0933 58.6672 50 57.7605 50 56.6672C50 55.5739 49.0933 54.6672 48 54.6672Z"
    />
    <path
      opacity="0.4"
      d="M14.7201 31.0407C12.9601 30.374 11.4134 29.2807 10.1867 28.054C7.70674 25.3073 6.08008 22.0273 6.08008 18.1873C6.08008 14.3473 9.09341 11.334 12.9334 11.334H14.4267C13.7334 12.7473 13.3334 14.3207 13.3334 16.0007V24.0007C13.3334 26.5073 13.8134 28.8807 14.7201 31.0407Z"
    />
    <path
      opacity="0.4"
      d="M57.92 18.1873C57.92 22.0273 56.2934 25.3073 53.8134 28.054C52.5867 29.2807 51.04 30.374 49.28 31.0407C50.1867 28.8807 50.6667 26.5073 50.6667 24.0007V16.0007C50.6667 14.3207 50.2667 12.7473 49.5734 11.334H51.0667C54.9067 11.334 57.92 14.3473 57.92 18.1873Z"
    />
    <path d="M39.9999 5.33398H23.9999C18.1066 5.33398 13.3333 10.1073 13.3333 16.0007V24.0007C13.3333 34.3207 21.6799 42.6673 31.9999 42.6673C42.3199 42.6673 50.6666 34.3207 50.6666 24.0007V16.0007C50.6666 10.1073 45.8933 5.33398 39.9999 5.33398ZM39.5733 22.534L37.9199 24.5607C37.6533 24.854 37.4666 25.4407 37.4933 25.8407L37.6533 28.454C37.7599 30.054 36.6133 30.8807 35.1199 30.294L32.6933 29.334C32.3199 29.2007 31.6799 29.2007 31.3066 29.334L28.8799 30.294C27.3866 30.8807 26.2399 30.054 26.3466 28.454L26.5066 25.8407C26.5333 25.4407 26.3466 24.854 26.0799 24.5607L24.4266 22.534C23.3866 21.3073 23.8399 19.9473 25.3866 19.5473L27.9199 18.9073C28.3199 18.8007 28.7999 18.4273 29.0133 18.0807L30.4266 15.894C31.3066 14.534 32.6933 14.534 33.5733 15.894L34.9866 18.0807C35.1999 18.4273 35.6799 18.8007 36.0799 18.9073L38.6133 19.5473C40.1599 19.9473 40.6133 21.3073 39.5733 22.534Z" />
    <path
      opacity="0.4"
      d="M39.5733 22.533L37.92 24.5597C37.6533 24.853 37.4666 25.4397 37.4933 25.8397L37.6533 28.453C37.76 30.053 36.6133 30.8797 35.12 30.293L32.6933 29.333C32.32 29.1997 31.68 29.1997 31.3066 29.333L28.88 30.293C27.3866 30.8797 26.24 30.053 26.3466 28.453L26.5066 25.8397C26.5333 25.4397 26.3466 24.853 26.08 24.5597L24.4266 22.533C23.3866 21.3064 23.84 19.9464 25.3866 19.5464L27.92 18.9064C28.32 18.7997 28.8 18.4264 29.0133 18.0797L30.4266 15.893C31.3066 14.533 32.6933 14.533 33.5733 15.893L34.9866 18.0797C35.2 18.4264 35.68 18.7997 36.08 18.9064L38.6133 19.5464C40.16 19.9464 40.6133 21.3064 39.5733 22.533Z"
    />
  </svg>
)
export default SvgRewardsIcon
