export const DESKTOP_START_BREAKPOINT = 768

const isMobileBrowser = () => {
  if (typeof window === "undefined") {
    return false
  }
  return document.documentElement.clientWidth <= DESKTOP_START_BREAKPOINT
}

export default isMobileBrowser
