// ref (https://www.30secondsofcode.org/js/s/stringify-circular-json)
const stringifyCircularJSON = (obj) => {
  const seen = new WeakSet()
  return JSON.stringify(obj, (k, v) => {
    if (v !== null && typeof v === "object") {
      if (seen.has(v)) return
      seen.add(v)
    }
    return v
  })
}

export default function toJson(data) {
  if (!data) {
    return data
  }

  if (typeof data !== "object") {
    return data
  }

  if (Array.isArray(data)) {
    return data.map((value) => toJson(value))
  }
  return JSON.parse(
    stringifyCircularJSON(
      Object.getOwnPropertyNames(Object.getPrototypeOf(data) || {}).reduce(
        (acc, propertyName) => {
          if (Array.isArray(data[propertyName])) {
            acc[propertyName] = data[propertyName].map((dataProp) =>
              toJson(dataProp)
            )
            return acc
          }

          if (typeof data[propertyName] === "object") {
            acc[propertyName] = toJson(data[propertyName])
            return acc
          }

          acc[propertyName] = data[propertyName]
          return acc
        },
        {
          ...data,
          constructorName: data.constructorName || data.constructor.name,
        }
      )
    )
  )
}
