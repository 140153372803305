import * as React from "react"
import { SVGProps } from "react"
import { useTheme } from "@mui/material/styles"

const SvgLocationIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        d="M31.9997 35.8133C36.5947 35.8133 40.3197 32.0884 40.3197 27.4933C40.3197 22.8983 36.5947 19.1733 31.9997 19.1733C27.4047 19.1733 23.6797 22.8983 23.6797 27.4933C23.6797 32.0884 27.4047 35.8133 31.9997 35.8133Z"
        strokeWidth="1.5"
        stroke={theme.palette.ctaPrimary.main}
      />
      <path
        d="M9.65321 22.6399C14.9065 -0.453409 49.1199 -0.426741 54.3465 22.6666C57.4132 36.2133 48.9865 47.6799 41.5999 54.7733C36.2399 59.9466 27.7599 59.9466 22.3732 54.7733C15.0132 47.6799 6.58654 36.1866 9.65321 22.6399Z"
        strokeWidth="1.5"
        stroke={theme.palette.ctaPrimary.main}
      />
    </svg>
  )
}
export default SvgLocationIcon
