import * as React from "react"
import { SVGProps } from "react"
import generateConfig from "configs/config"

const config = generateConfig()
const footerBg =
  config?.configBanner === "luckysupermarkets" ? "#E0201F" : "#fff"
const SvgExternalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.58325 6.41615L12.3666 1.63281"
      stroke={footerBg}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8334 3.96602V1.16602H10.0334"
      stroke={footerBg}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.41675 1.16602H5.25008C2.33341 1.16602 1.16675 2.33268 1.16675 5.24935V8.74935C1.16675 11.666 2.33341 12.8327 5.25008 12.8327H8.75008C11.6667 12.8327 12.8334 11.666 12.8334 8.74935V7.58268"
      stroke={footerBg}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgExternalIcon
