import { TextFieldProps } from "@mui/material/TextField/TextField"
import Box from "@mui/material/Box"
import { TextField } from "@mui/material"
import React from "react"
import { SxProps } from "@mui/system"
import { Theme, useTheme } from "@mui/material/styles"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"

export type TextInputProps = TextFieldProps & {
  labelSx?: SxProps<Theme>
}

const styles = {
  textfields: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "ctaPrimary.main",
      },
    },
    "& .MuiInputLabel-root": {
      "&.Mui-focused": {
        color: "ctaPrimary.main",
      },
    },
    "& label": {
      top: "-18px",
      fontSize: "17px",
      fontWeight: 600,
    },
    "& legend > span": {
      display: "none",
    },
    "& fieldset": {
      borderRadius: "8px",
    },
    margin: "22px 0",
    width: "100%",
  },
}

export const TextInput = ({ ...props }: TextInputProps) => {
  const modifiedProps = {
    ...props,
    sx: {
      marginTop: 1,
      width: "100%",
      ...props.sx,
    },
  }
  delete modifiedProps.labelSx
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TextField
        label={props.label}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={props?.placeholder}
        {...modifiedProps}
        sx={{
          ...styles.textfields,
          ...props.sx,
          "& .MuiOutlinedInput-root fieldset": {
            borderColor: "#DADADA",
          },
        }}
        SelectProps={{
          IconComponent: (props: any) => (
            <>
              <ExpandMoreOutlinedIcon
                {...props}
                sx={{ color: `${theme.palette.ctaPrimary.main} !important` }}
              />
            </>
          ),
        }}
      />
    </Box>
  )
}
