import { refreshToken } from "../services/OAuthApi"
import Cookies from "js-cookie"
import ApiSetup from "../services/ApiSetup"
import getAuthTokenFromCookie from "./getAuthTokenFromCookie"

async function refreshApiSetupInitWithNewToken(): Promise<void> {
  // if we are on the server lets abort
  if (typeof window === "undefined") {
    return
  }
  const currentToken = getAuthTokenFromCookie()
  if (currentToken?.refresh_token) {
    const token = await refreshToken(currentToken.refresh_token)
    if (token?.access_token) {
      ApiSetup.init(token.access_token)
      Cookies.set(
        "authToken",
        Buffer.from(JSON.stringify(token)).toString("base64"),
        {
          secure: true,
          sameSite: "strict",
        }
      )
    }
  }
}

export default refreshApiSetupInitWithNewToken
