import * as React from "react"
import { SVGProps } from "react"
const SvgStateOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8c0-3.771 0-5.657 1.172-6.828C2.343 0 4.229 0 8 0c3.771 0 5.657 0 6.828 1.172C16 2.343 16 4.229 16 8c0 3.771 0 5.657-1.172 6.828C13.657 16 11.771 16 8 16c-3.771 0-5.657 0-6.828-1.172C0 13.657 0 11.771 0 8Z"
      fill="#fff"
    />
    <rect
      x={0.5}
      y={0.5}
      width={15}
      height={15}
      rx={3.5}
      fill="#F5F5F5"
      stroke="currentColor"
    />
  </svg>
)
export default SvgStateOffIcon
