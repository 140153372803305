import axios from "axios"
import { useDecorateStore } from "components/stores/utils"
import {
  StoreByNumberDocument,
  StoreDetailsDocument,
  useStoreByNumberQuery,
  useStoreDetailsQuery,
} from "generated/graphql"
import Cookies from "js-cookie"
import Router from "next/router"
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { faroPushErrors } from "utils/faroGrafanaLogs"
import { fetchData } from "utils/fetcher"
import { queryClient } from "utils/queryClient"
import generateConfig from "../configs/config"
import ApiSetup from "../services/ApiSetup"
import { getSwiftlyAuthToken, refreshToken } from "../services/OAuthApi"
import { useAppContext } from "./AppContext"
import {
  openSnackbar,
  setStore,
  resetShoppingListItems,
} from "./actions/actions"

type logOutParams = { redirectLocation?: string; message?: string }

// TODO - update as needed with the user we get from our client auth api
export type UserType = {
  firstName?: string
  lastName?: string
  email?: string
  emailVerified?: boolean
  emailOptIn?: boolean
  smsOptIn?: boolean
  paypalEmail?: string
  birthday?: string
  phone?: string
  zipCode?: string
  accessToken?: string
  phoneNumber?: string
  homeStore?: string
  cid?: string
}

export type SwiftlyTokenType = {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: string
}

const AuthContext = React.createContext(
  {} as {
    user: UserType | undefined
    /**
     * Authenticate is a function that takes a user object which contains an access token.
     * It uses this client access token to call the swiftly auth api to get a swiftly token
     * This token is saved to state and localstorage along with the user object
     * @param {UserType} user - {
     */
    userId: string | undefined
    cardId: string | undefined
    storeId: string | undefined
    authenticate: (tsmcPingAccessToken: { accessToken: string }) => void
    authenticateWithPingCloud: (SWIFTLYAccessToken: {
      accessToken: string
    }) => void
    refreshTheToken: () => Promise<SwiftlyTokenType | void>
    updateUserInfo: (_user: UserType) => void
    updateLoading: (_user: boolean) => void
    updatePassword: () => void
    getPassword: () => string
    logout: (_params?: logOutParams) => void
    isLoading: boolean
    showSubNav: boolean
    isAuthenticated: boolean
    isFromRegister: boolean
    registrationPopUp: boolean
    isStartSavingloading: boolean
    updateIsFromRegister: (status: boolean) => void
    updateRegistrationPopUp: (status: boolean) => void
    updateStartSavingLoading: (status: boolean) => void
    updateRegisterInfo: (user: any) => void
    getRegisterInfo: any
    // Swiftly token
    swiftlyToken: SwiftlyTokenType | null
    updateSaubNavBar: (status: boolean) => void
    setIsLoading: (value: boolean) => void
    handleStoreRoute: (
      id: string,
      name: string,
      number: number,
      city: string
    ) => void
    setSeoResponseAuth: (user: any) => void
    seoResponse: any
    setInitialState: (val: any) => void
    initialState: any
    swiftlyAccessToken: any
    setSwiftlyAccessToken: (value: any) => void
  }
)
type Nullable<T> = T | null

const AuthProvider = ({
  children,
  redirectUnauthenticatedTo = "/",
  requiresAuth = false,
  redirectAuthenticatedTo,
}: {
  children: ReactElement
  redirectUnauthenticatedTo?: string
  requiresAuth?: boolean
  redirectAuthenticatedTo?: string
}) => {
  const [pingAccessToken, setPingAccessToken] = useState<string | null>(null)
  const [swiftlyAccessToken, setSwiftlyAccessToken] = useState<any | null>(null)

  const [swiftlyToken, setSwiftlyToken] = useState<SwiftlyTokenType | null>(
    null
  )
  const [user, setUser] = useState<UserType | undefined>()
  const [userId, setUserId] = useState<string | undefined>()
  const [storeId, setStoreId] = useState<string | undefined>()
  const [cardId, setCardId] = useState<string | undefined>()
  // The Swiftly token
  // initially true i.e. when page is loading and saved token is not retrieved yet
  const [showSubNav, setShowSubNav] = useState(true)
  const [isFromRegister, setIsFromRegister] = useState(false)
  const [getRegisterInfo, setRegisterInfo] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [registrationPopUp, setRegistrationPopUp] = useState(false)
  const [isStartSavingloading, setStartSavingLoading] = React.useState(false)
  // wait until page is loaded properly and saved token is retrieved
  const [, setIsInitialized] = useState(false)
  const [seoResponse, setSeoResponseAuth] = useState<any>(null)
  const [initialState, setInitialState] = useState<any>({
    loading: true,
    envVariables: null,
    actionInprogress: false,
  })
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  // IS the user logged in to our client and our system

  const { dispatch } = useAppContext()
  const config = generateConfig()
  const { decorateStore } = useDecorateStore()

  // On initial load
  useEffect(() => {
    // to do -- move this object to somewhere re-usable and setup variables for the key names

    setIsLoading(true)
    const userFromStorage = localStorage.getItem("localUser") as string
    const userIdFromStorage = localStorage.getItem("userId") as string
    const swiftlyCookieAuthToken = Cookies.get("authToken") as string
    const swiftlyUserId = localStorage.getItem("userId") as string
    const storeObject = localStorage.getItem("storeObject") as string
    // This will keep users logged in when they refresh the page
    // we dont want this to run if we already have user or token in state, and only when we have them in cookies/localstorage
    if (userFromStorage && swiftlyCookieAuthToken && !user && !swiftlyToken) {
      const parsedUser = JSON.parse(userFromStorage)
      const parsedSwiftlyToken = JSON.parse(
        Buffer.from(swiftlyCookieAuthToken, "base64").toString("ascii")
      ) as SwiftlyTokenType
      setUser(parsedUser)
      setUserId(userIdFromStorage)
      const parsedStoreObject = JSON.parse(storeObject)
      localStorage.setItem("storeName", parsedStoreObject?.name)
      setSwiftlyToken(parsedSwiftlyToken)
      ApiSetup.init(parsedSwiftlyToken?.access_token)
      setIsInitialized(true)
      setIsAuthenticated(true)
    } else {
      ApiSetup.init(null)
    }
    setIsLoading(false)
  }, [])

  const logout = (options?: logOutParams) => {
    setIsLoading(true)
    setIsAuthenticated(false)
    Cookies.set("authToken", "", { secure: true, sameSite: "strict" })
    localStorage.setItem("localUser", "")
    localStorage.setItem("shoppingListItems", JSON.stringify([]))
    localStorage.setItem("storeObject", "")
    localStorage.setItem("userIsLoggedOut", "true")
    ApiSetup.reset()
    dispatch(resetShoppingListItems())
    setIsLoading(false)
    if (options?.message?.trim()) {
      dispatch(
        openSnackbar({
          variant: "alert",
          message: options.message,
          alert: {
            color: "error",
          },
        })
      )
    }
    if (options?.redirectLocation) {
      Router.push(options.redirectLocation).catch(console.error)
    }
  }

  const authenticate = (tsmcPingAccessToken: { accessToken: string }) => {
    setIsLoading(true)
    setPingAccessToken(tsmcPingAccessToken.accessToken)
  }
  //FOR P14C
  const authenticateWithPingCloud = (SWIFTLYAccessToken: {
    accessToken: string
  }) => {
    // setIsLoading(true)
    setSwiftlyAccessToken(SWIFTLYAccessToken)
    dispatch(resetShoppingListItems())
  }

  useEffect(() => {
    // save user to local storage (remove only on invalid token or logout)
    async function getSwiftlyToken() {
      localStorage.setItem("userIsLoggedOut", "false")
      let swiftlyToken: any = null
      if (config.pingCloud == "true" && swiftlyAccessToken) {
        swiftlyToken = swiftlyAccessToken
      } else {
        swiftlyToken = await getSwiftlyAuthToken(pingAccessToken as string)
      }

      const tokenPayload = swiftlyToken?.access_token?.split(".")[1]
      if (tokenPayload) {
        const decodePayload = Buffer.from(tokenPayload, "base64").toString()
        const userId = JSON.parse(decodePayload).sub
        const cardId = JSON.parse(decodePayload).tsmc_card_id
        ApiSetup.init(swiftlyToken?.access_token)
        Cookies.set(
          "authToken",
          Buffer.from(JSON.stringify(swiftlyToken)).toString("base64"),
          { secure: true, sameSite: "strict" }
        )
        setSwiftlyToken(swiftlyToken)
        setUserId(userId)
        setCardId(cardId)
        localStorage.setItem("userId", userId)
        localStorage.setItem("cardId", cardId)
      }
    }
    if (pingAccessToken || swiftlyAccessToken) {
      getSwiftlyToken()
        .then()
        .catch((e) => console.log(e))
      setIsInitialized(true)
    }
  }, [pingAccessToken, swiftlyAccessToken])

  // get them account details because we should be ready for it
  useEffect(() => {
    async function getAccountDetails() {
      const swiftlyUserId = localStorage.getItem("userId") as string
      try {
        const swiftlyUser = await axios.get(
          `/api/getShimmedProfile?swiftlyUserId=${swiftlyUserId}&cardId=${cardId}&tsmcToken=${pingAccessToken}`,
          {
            headers: {
              Authorization: swiftlyToken?.access_token || "",
            },
          }
        )
        if (swiftlyUser.data.status === "Failed") {
          faroPushErrors(
            swiftlyUser.data?.message
              ? swiftlyUser.data?.message
              : "Failed to connect"
          )
          dispatch(
            openSnackbar({
              variant: "alert",
              message: swiftlyUser.data?.message,
              alert: {
                color: "error",
              },
            })
          )
          return
        }
        const userDetails = swiftlyUser.data.data
        setUser({ ...userDetails, zipCode: swiftlyUser.data.message.Zip })
        setIsAuthenticated(true)
        setIsLoading(false)
        setInitialState((prevState: any) => ({
          ...prevState,
          loading: false,
          actionInprogress: false,
        }))
      } catch (error: any) {
        faroPushErrors(
          error.response.data.message
            ? error.response.data.message
            : "Failed to connect"
        )
        dispatch(
          openSnackbar({
            variant: "alert",
            message: error.response.data.message,
            alert: {
              color: "error",
            },
          })
        )
      }
    }

    if (cardId && swiftlyToken?.access_token) {
      getAccountDetails()
        .then()
        .catch((e) => console.log(e))
    }
  }, [cardId, swiftlyToken])

  const getStoreId = async (storeNumber: number) => {
    const variable = { storeNumber: storeNumber }
    try {
      const storeResponse: any = await queryClient.fetchQuery(
        useStoreByNumberQuery.getKey(variable),
        fetchData(StoreByNumberDocument, variable, {
          authorization: `Bearer ${swiftlyToken?.access_token}`,
        })
      )
      const storeObject = storeResponse?.storeByNumber

      const storeDetail = { storeId: storeObject?.storeId }

      const data: any = await queryClient.fetchQuery(
        useStoreDetailsQuery.getKey(storeDetail),
        fetchData(StoreDetailsDocument, storeDetail)
      )

      const store = decorateStore(data.store)
      dispatch(setStore(store))

      setStoreId(storeObject?.storeId)
      localStorage.setItem("storeId", storeObject?.storeId)
      localStorage.setItem("storeObject", JSON.stringify(store))
      localStorage.setItem("storeName", store?.name)
    } catch (error: any) {
      faroPushErrors(JSON.stringify(error?.response?.data))
      console.log(error)
    }
  }

  useEffect(() => {
    let homeStore = ""
    // Initializing store value from localStorage
    const localUser = localStorage.getItem("localUser")
    const userInfo = localStorage.getItem("userInfo")
    if (localUser && !homeStore) {
      const user = JSON.parse(localUser)
      homeStore = user?.homeStore || ""
    }
    if (userInfo && !homeStore) {
      const preferredStore = JSON.parse(userInfo)
      homeStore = preferredStore?.preferred_store || ""
    }

    const storeNumber = homeStore
    if (storeNumber && swiftlyToken?.access_token) {
      getStoreId(+storeNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiftlyToken?.access_token])

  useEffect(() => {
    // save user to local storage (remove only on invalid token or logout)
    if (user) {
      localStorage.setItem("localUser", JSON.stringify(user))
    }

    if (user?.homeStore) {
      getStoreId(+user.homeStore)
        .then()
        .catch((e) => console.log(e))
      const redirectUrlAfterSignUp = localStorage.getItem("from")
      if (redirectUrlAfterSignUp) {
        localStorage.setItem("from", "")
        //TODO: Happens for /coupons -> signin/signup and /rewards -> signin/signup
        Router.replace(`/${redirectUrlAfterSignUp}`).catch(console.error)
      }
    }
  }, [user])

  const refreshTheToken = async (): Promise<SwiftlyTokenType | void> => {
    setIsLoading(true)
    try {
      if (swiftlyToken?.refresh_token) {
        const refreshedSwiftlyToken = await refreshToken(
          swiftlyToken?.refresh_token
        )
        if (refreshedSwiftlyToken) {
          setSwiftlyToken(refreshedSwiftlyToken)
          ApiSetup.init(swiftlyToken?.access_token)
        }
      } else {
        console.error("We do not have a refresh token")
      }
      setIsLoading(false)
    } catch (e) {
      faroPushErrors(e)
      setIsLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    const getStore = localStorage.getItem("storeObject")
    const store = getStore && JSON.parse(getStore)
    if (store) dispatch(setStore(store))
  }, [])

  // Check if user has permission to view the page
  useEffect(() => {
    if (!requiresAuth && isAuthenticated && redirectAuthenticatedTo) {
      Router.replace(redirectAuthenticatedTo).catch(console.error)
    }
    // If it doesn't require auth, everything's good.
    if (!requiresAuth) return

    // If we're already authenticated, everything's good.
    if (isAuthenticated) return

    const userFromStorage = localStorage.getItem("localUser") as string
    const swiftlyCookieAuthToken = Cookies.get("authToken") as string
    const isUserLoggedOut = localStorage.getItem("userIsLoggedOut") as string
    if (userFromStorage && swiftlyCookieAuthToken) return

    if (!userFromStorage || !swiftlyCookieAuthToken) {
      if (isUserLoggedOut === "true") {
        localStorage.setItem("from", "")
      } else {
        localStorage.setItem("from", Router.pathname)
        Router.replace(redirectUnauthenticatedTo || "/login").catch(
          console.error
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    requiresAuth,
    redirectUnauthenticatedTo,
    redirectAuthenticatedTo,
  ])

  const updateUserInfo = (user: UserType) => {
    // TODO: implement update user api call
    setUser(user)
  }

  const updateLoading = (user: boolean) => {
    // TODO: implement update user api call
    setIsLoading(user)
  }

  const updateSaubNavBar = (status: boolean) => {
    setShowSubNav(status)
  }

  const updateIsFromRegister = (status: boolean) => {
    setIsFromRegister(status)
  }

  const updateRegistrationPopUp = (status: boolean) => {
    setRegistrationPopUp(status)
  }

  const updateStartSavingLoading = (status: boolean) => {
    setStartSavingLoading(status)
  }

  const updatePassword = () => {
    // TODO: implement update password api call
    console.log("Password Change Request")
  }

  const getPassword = () => {
    // TODO: remove mock password
    return localStorage.getItem("mock-password") ?? "password"
  }

  const updateRegisterInfo = (user: any) => {
    setRegisterInfo(user)
  }
  const showLoadingBar =
    isLoading ||
    (requiresAuth && !isAuthenticated && redirectUnauthenticatedTo) ||
    (!requiresAuth && isAuthenticated && redirectAuthenticatedTo)

  const handleStoreRoute = (
    id: string,
    name: string,
    number: number,
    city: string
  ) => {
    const url = config?.storeUrl
      .replace("store_id", id)
      .replace("store_name", name)
      .replace("store_number", String(number))
      .replace("store_city", city)
    Router.replace(url).catch((e) => console.log(e))
  }

  const authValue = useMemo(
    () => ({
      user,
      userId,
      cardId,
      storeId,
      authenticate,
      authenticateWithPingCloud,
      refreshTheToken,
      logout,
      isLoading,
      isAuthenticated,
      swiftlyToken,
      updateUserInfo,
      updatePassword,
      getPassword,
      updateLoading,
      updateSaubNavBar,
      updateIsFromRegister,
      updateRegistrationPopUp,
      updateStartSavingLoading,
      isFromRegister,
      showSubNav,
      updateRegisterInfo,
      getRegisterInfo,
      setIsLoading,
      handleStoreRoute,
      setSeoResponseAuth,
      seoResponse,
      registrationPopUp,
      isStartSavingloading,
      setInitialState,
      initialState,
      swiftlyAccessToken,
      setSwiftlyAccessToken,
    }),
    [
      cardId,
      getRegisterInfo,
      handleStoreRoute,
      initialState,
      isAuthenticated,
      isFromRegister,
      isLoading,
      isStartSavingloading,
      logout,
      refreshTheToken,
      registrationPopUp,
      seoResponse,
      showSubNav,
      storeId,
      swiftlyToken,
      user,
      userId,
    ]
  )

  return (
    <AuthContext.Provider value={authValue}>
      {showLoadingBar ? null : children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
export default AuthProvider
