import * as React from "react"
import { SVGProps } from "react"

import { useTheme } from "@mui/material/styles"
const SvgPlusIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill={theme.palette.ctaPrimary.main}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 17v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0Z" />
    </svg>
  )
}
export default SvgPlusIcon
