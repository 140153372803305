import { BreadcrumbProp } from "components/breadcrumb/Breadcrumb"
import { ItemStates, ShoppingListItemType } from "components/shoppingList/types"
import { ProductSortDimension } from "generated/graphql"
import React, { ReducerState, useContext, useMemo } from "react"
import { DecoratedStore } from "../components/stores/utils"

export type PageAttributesType = {
  pageNumber: number
  totalItems: number
  itemsPerPage: number
  sortDirection: ProductSortDimension
}

export const initialState = {
  snackbar: {
    action: false,
    open: false,
    message: "Success",
    errors: [] as Error[],
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    variant: "alert",
    alert: {
      color: "success" as "success" | "error" | "warning" | "info",
      variant: "filled" as "filled" | "outlined" | "standard",
    },
    transition: "Fade",
    close: true,
    actionButton: false,
  },
  currentStore: null as DecoratedStore | null,
  flashAlert: {
    isOpen: false,
    message: "",
    color: "",
  },
  openCouponId: undefined as string | undefined,
  isClippedCoupon: false,
  isOpenCouponRebate: false,
  openProductId: undefined as string | undefined,
  isPayPalModalOpen: false,
  isLoginRequestPopupOpen: false,
  selectedCouponCategoryId: "*" as string,
  reFetchedCoupons: [],
  categoryList: [],
  eligibilitiesList: [],
  breadcrumbs: [] as BreadcrumbProp[],
  carousalList: [],
  pageAttributes: {
    pageNumber: 1,
    totalItems: 50,
    itemsPerPage: 50,
    sortDirection: ProductSortDimension.Featured,
  } as PageAttributesType,
  shoppingList: {
    searchText: null,
    isShoppingListDrawerOpen: false,
    shoppingListItems: [] as ShoppingListItemType[],
    isInitialSynced: false,
    metaData: {},
    isLoading: false,
  },
}

type Validate<T extends Record<string, string>> = {
  [Name in keyof T]: Name extends T[Name] ? T[Name] : never
}

const validator = <
  Key extends string,
  Value extends string,
  Data extends Record<Key, Value>
>(
  data: Validate<Data>
) => data

export const actionTypes = Object.freeze(
  validator({
    OPEN_SNACKBAR: "OPEN_SNACKBAR",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
    SET_STORE: "SET_STORE",
    SHOW_FLASH_ALERT: "SHOW_FLASH_ALERT",
    HIDE_FLASH_ALERT: "HIDE_FLASH_ALERT",
    SET_OPEN_COUPON_ID: "SET_OPEN_COUPON_ID",
    CLEAR_OPEN_COUPON_ID: "CLEAR_OPEN_COUPON_ID",
    SET_OPEN_PRODUCT_ID: "SET_OPEN_PRODUCT_ID",
    CLEAR_OPEN_PRODUCT_ID: "CLEAR_OPEN_PRODUCT_ID",
    SET_OPEN_PAYPAL_MODAL: "SET_OPEN_PAYPAL_MODAL",
    SET_CLOSE_PAYPAL_MODAL: "SET_CLOSE_PAYPAL_MODAL",
    OPEN_COUPON_ID_LOGIN_REQUEST_MODAL: "OPEN_COUPON_ID_LOGIN_REQUEST_MODAL",
    CLOSE_COUPON_ID_LOGIN_REQUEST_MODAL: "CLOSE_COUPON_ID_LOGIN_REQUEST_MODAL",
    SET_SELECTED_COUPON_CATEGORY_ID: "SET_SELECTED_COUPON_CATEGORY_ID",
    SET_REFETCHED_COUPON: "SET_REFETCHED_COUPON",
    SET_CATEGORY_LIST: "SET_CATEGORY_LIST",
    SET_BREADCRUMBS: "SET_BREADCRUMBS",
    SET_CAROUSAL_LIST: "SET_CAROUSAL_LIST",
    SET_PAGE_ATTRIBUTES: "SET_PAGE_ATTRIBUTES",
    RESET_PAGE_ATTRIBUTES: "RESET_PAGE_ATTRIBUTES",
    OPEN_SHOPPING_LIST_DRAWER: "OPEN_SHOPPING_LIST_DRAWER",
    CLOSE_SHOPPING_LIST_DRAWER: "CLOSE_SHOPPING_LIST_DRAWER",
    ADD_SHOPPING_LIST_ITEM: "ADD_SHOPPING_LIST_ITEM",
    UPDATE_SHOPPING_LIST_ITEM: "UPDATE_SHOPPING_LIST_ITEM",
    DELETE_SHOPPING_LIST_ITEM: "DELETE_SHOPPING_LIST_ITEM",
    SET_SHOPPING_LIST_META_DATA: "SET_SHOPPING_LIST_META_DATA",
    SET_SHOPPING_LIST_ITEMS: "SET_SHOPPING_LIST_ITEMS",
    RESET_SHOPPING_LIST_ITEMS: "RESET_SHOPPING_LIST_ITEMS",
    SET_SHOPPING_LIST_LOADER: "SET_SHOPPING_LIST_LOADER",
    UNCHECK_ALL_SHOPPING_LIST_ITEMS: "UNCHECK_ALL_SHOPPING_LIST_ITEMS",
    SET_ELIGIBILITES_LIST: "SET_ELIGIBILITES_LIST",
  })
)

export type ActionType = keyof typeof actionTypes

type IAction = {
  type: ActionType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
}

//? UPDATE `initialState` with proper STRUCTURE and TYPE before using this context
export const AppContext = React.createContext({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  dispatch: (() => {}) as React.Dispatch<any>,
})

const AppContextProvider = ({ children }: { children: JSX.Element }) => {
  const reducer = (state: typeof initialState, action: IAction) => {
    switch (action.type) {
      case actionTypes.OPEN_SNACKBAR:
        return {
          ...state,
          snackbar: {
            open: true,
            message: action.payload.message || initialState.snackbar.message,
            variant: action.payload.variant || initialState.snackbar.variant,
            errors: action.payload.errors || initialState.snackbar.errors,
            alert: {
              color:
                action.payload.alert.color || initialState.snackbar.alert.color,
              variant:
                action.payload.alert.variant ||
                initialState.snackbar.alert.variant,
            },
            actionButton:
              action.payload.actionButton || initialState.snackbar.actionButton,
            close: action.payload.close || initialState.snackbar.close,
          },
        }
      case actionTypes.CLOSE_SNACKBAR:
        return {
          ...state,
          snackbar: {
            open: false,
            message: "",
            variant: "default",
            alert: {
              variant: "filled",
              color: "success",
            },
            actionButton: true,
            close: true,
          },
        }
      case actionTypes.SET_STORE:
        if (!action.payload.store.id) return state
        return {
          ...state,
          currentStore: action.payload.store,
        }
      case actionTypes.SHOW_FLASH_ALERT:
        return {
          ...state,
          flashAlert: {
            isOpen: true,
            message: action.payload.message,
            color: action.payload.color,
          },
        }
      case actionTypes.HIDE_FLASH_ALERT:
        return {
          ...state,
          flashAlert: { ...initialState.flashAlert },
        }
      case actionTypes.SET_OPEN_COUPON_ID:
        return {
          ...state,
          openCouponId: action.payload.couponId,
          isOpenCouponRebate: action.payload.isOpenCouponRebate,
          isClippedCoupon: action.payload.isClippedCoupon,
        }
      case actionTypes.CLEAR_OPEN_COUPON_ID:
        return {
          ...state,
          openCouponId: undefined,
          isOpenCouponRebate: false,
          isClippedCoupon: false,
        }
      case actionTypes.SET_OPEN_PAYPAL_MODAL:
        return {
          ...state,
          isPayPalModalOpen: action.payload,
        }
      case actionTypes.SET_CLOSE_PAYPAL_MODAL:
        return {
          ...state,
          isPayPalModalOpen: action.payload,
        }
      case actionTypes.SET_OPEN_PRODUCT_ID:
        return {
          ...state,
          openProductId: action.payload,
        }
      case actionTypes.CLEAR_OPEN_PRODUCT_ID:
        return {
          ...state,
          openProductId: undefined,
        }
      case actionTypes.OPEN_COUPON_ID_LOGIN_REQUEST_MODAL:
        return {
          ...state,
          isLoginRequestPopupOpen: true,
        }
      case actionTypes.CLOSE_COUPON_ID_LOGIN_REQUEST_MODAL:
        return {
          ...state,
          isLoginRequestPopupOpen: false,
        }
      case actionTypes.SET_SELECTED_COUPON_CATEGORY_ID:
        return {
          ...state,
          selectedCouponCategoryId: action.payload,
        }
      case actionTypes.SET_REFETCHED_COUPON:
        return {
          ...state,
          reFetchedCoupons: action.payload,
        }
      case actionTypes.SET_PAGE_ATTRIBUTES:
        return {
          ...state,
          pageAttributes: { ...state.pageAttributes, ...action.payload },
        }
      case actionTypes.RESET_PAGE_ATTRIBUTES:
        return {
          ...state,
          pageAttributes: initialState.pageAttributes,
        }
      case actionTypes.SET_CATEGORY_LIST:
        return {
          ...state,
          categoryList: action.payload,
        }
      case actionTypes.SET_BREADCRUMBS:
        return {
          ...state,
          breadcrumbs: action.payload,
        }
      case actionTypes.SET_CAROUSAL_LIST:
        return {
          ...state,
          carousalList: action.payload,
        }
      case actionTypes.OPEN_SHOPPING_LIST_DRAWER:
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            searchText: action.payload,
            isShoppingListDrawerOpen: true,
          },
        }
      case actionTypes.CLOSE_SHOPPING_LIST_DRAWER:
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            searchText: null,
            isShoppingListDrawerOpen: false,
          },
        }
      case actionTypes.SET_SHOPPING_LIST_META_DATA:
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            metaData: action.payload,
          },
        }
      case actionTypes.SET_SHOPPING_LIST_ITEMS:
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            shoppingListItems: action.payload,
            isInitialSynced: true,
          },
        }
      case actionTypes.RESET_SHOPPING_LIST_ITEMS:
        return {
          ...state,
          shoppingList: { ...initialState.shoppingList },
        }
      case actionTypes.ADD_SHOPPING_LIST_ITEM:
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            shoppingListItems: [
              ...(state.shoppingList?.shoppingListItems || []),
              action.payload,
            ],
          },
        }
      case actionTypes.UPDATE_SHOPPING_LIST_ITEM:
        const itemIndex = state.shoppingList.shoppingListItems?.findIndex(
          (item: any) => item.key === action.payload.key
        )
        if (itemIndex !== -1) {
          const updatedShoppingListItems: any[] = [
            ...state.shoppingList.shoppingListItems,
          ]
          updatedShoppingListItems[itemIndex] = {
            ...updatedShoppingListItems[itemIndex],
            ...action.payload,
          }

          return {
            ...state,
            shoppingList: {
              ...state.shoppingList,
              shoppingListItems: updatedShoppingListItems,
            },
          }
        }
        return state
      case actionTypes.DELETE_SHOPPING_LIST_ITEM:
        const filteredList = state.shoppingList.shoppingListItems?.filter(
          (item: any) => item.key != action.payload.key
        )
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            shoppingListItems: filteredList,
          },
        }
      case actionTypes.UNCHECK_ALL_SHOPPING_LIST_ITEMS:
        const updatedList = state.shoppingList.shoppingListItems?.map(
          (item: ShoppingListItemType) => ({
            ...item,
            status: ItemStates.ACTIVE,
          })
        )
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            shoppingListItems: updatedList,
          },
        }
      case actionTypes.SET_SHOPPING_LIST_LOADER:
        return {
          ...state,
          shoppingList: {
            ...state.shoppingList,
            isLoading: action.payload,
          },
        }
      case actionTypes.SET_ELIGIBILITES_LIST:
        return {
          ...state,
          eligibilitiesList: action.payload,
        }
      default:
        return state
    }
  }

  const [state, dispatch] = React.useReducer(
    reducer,
    initialState as ReducerState<typeof reducer>
  )
  const contextState = useMemo(() => ({ state, dispatch }), [state])
  return (
    <AppContext.Provider value={contextState}>{children}</AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
export default AppContextProvider
