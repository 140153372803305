import { CloseIcon, RewardsIconLarge } from "@/icons"
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { useAuth } from "contexts/AuthContext"
import {
  RewardsDocument,
  RewardsRedeemDocument,
  usePurchaseRewardMutation,
  useRewardsQuery,
  useRewardsRedeemQuery,
} from "generated/graphql"
import Image from "next/image"
import { useRouter } from "next/router"
import React, { useEffect, useRef } from "react"
import Barcode from "react-barcode"
import { useTranslation } from "react-i18next"
import { faroPushErrors } from "utils/faroGrafanaLogs"
import { fetchData } from "utils/fetcher"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { queryClient } from "utils/queryClient"
import { useAppContext } from "../../../contexts/AppContext"
import { openSnackbar } from "../../../contexts/actions/actions"
import FoodmaxLogo from "../../../public/images/logos/foodmaxx-logo-dark.svg"
import LuckyLogo from "../../../public/images/logos/lucky-logo-dark.svg"
import SavemartLogo from "../../../public/images/logos/savemart-logo-dark.svg"
import styless from "../../../styles/component.module.scss"
import DialogModal from "../../common/DialogModal"
import { RewardCardProps } from "../../common/RewardCard/RewardCard"
import RewardGrid from "../../common/RewardCard/RewardGrid"
import AccountsLayout, { styles as accountsStyles } from "../AccountsLayout"

export const styles = {
  ...accountsStyles,
  header: {
    borderBottom: "1px solid",
    borderColor: "grey.300",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "bgPrimary.main",
    borderRadius: "0.8rem 0.8rem 0 0",
  },
  body: {
    backgroundColor: "bgPrimary.main",
    px: 3,
    py: 3,
    borderRadius: "0 0 0.8rem 0.8rem ",
  },
  headerButton: {
    fontSize: 15,
    color: "ctaPrimary.main",
    p: 0,
    minWidth: 0,
  },
  subHeader: {
    borderBottom: "2px dotted",
    borderColor: grey[300],
  },
  smartRewards: {
    ...accountsStyles.text,
    whiteSpace: "nowrap",
    paddingLeft: 0,
  },
  loyaltyId: {
    fontSize: 18,
    whiteSpace: "nowrap",
  },
  text: {
    ...accountsStyles.text,
    padding: 0,
  },
}

const MyRewards = () => {
  const myRewardsRef = useRef<HTMLDivElement | null>(null)
  const [selectedTab, setSelectedTab] = React.useState(1)
  const [showBarcode, setShowBarcode] = React.useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(800))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1200))
  const isTablet = !isMobile && !isDesktop
  const router = useRouter()
  const { swiftlyToken, initialState, user, userId, storeId } = useAuth()
  const { t } = useTranslation()
  const { dispatch } = useAppContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rewardDetails, setRewardDetails] = React.useState<any>()
  const [rewardDetailsLoading, setRewardDetailsLoading] = React.useState(false)

  const cardId =
    typeof window !== "undefined" ? localStorage.getItem("cardId") : ""
  const storageStoreId =
    typeof window !== "undefined" ? localStorage.getItem("storeId") : ""
  const storageUserId =
    typeof window !== "undefined" ? localStorage.getItem("userId") : ""

  let Logo
  let width
  switch (initialState?.envVariables?.configBanner) {
    case "luckysupermarkets":
      Logo = LuckyLogo
      width = 190
      break
    case "savemart":
      Logo = SavemartLogo
      width = 142
      break
    case "foodmaxx":
      Logo = FoodmaxLogo
      width = 142
      break
    default:
      // get a real default theme and put it here...sprys??
      Logo = SavemartLogo
      width = 142
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const focusElement = () => {
    const element = document.getElementById("dialogModal")
    if (element) {
      element.focus()
    } else {
      requestAnimationFrame(() => focusElement())
    }
  }

  useEffect(() => {
    if (showBarcode) {
      requestAnimationFrame(() => focusElement())
    }
  }, [showBarcode])

  const { mutateAsync } = usePurchaseRewardMutation({
    onSuccess: async () => {
      getRewardsRedeem()
      dispatch(
        openSnackbar({
          variant: "alert",
          message: t("rewards.rewardsAddedSuccessfully"),
          alert: {
            color: "success",
          },
        })
      )
    },
    onError(error) {
      setRewardDetailsLoading(false)
      dispatch(
        openSnackbar({
          variant: "alert",
          message: t("common.somethingWentWrong"),
          alert: {
            color: "error",
          },
        })
      )
    },
  })

  const header = {
    authorization: `Bearer ${swiftlyToken?.access_token}`,
  }

  const getRewards = async () => {
    try {
      setRewardDetailsLoading(true)
      const rewardVar = {
        storeId: storeId || storageStoreId,
      }
      const data: any = await queryClient.fetchQuery(
        useRewardsQuery.getKey(rewardVar),
        fetchData(RewardsDocument, rewardVar, header)
      )
      setRewardDetails(data)
      localStorage.setItem(
        "loyaltyPoints",
        data?.loyaltySummary?.availablePoints.toString()
      )
      setRewardDetailsLoading(false)
    } catch (error: any) {
      faroPushErrors(JSON.stringify(error?.response?.data))
    }
  }

  const getRewardsRedeem = async () => {
    try {
      setRewardDetailsLoading(true)
      const rewardVar = {
        storeId: storeId || storageStoreId,
      }
      const data: any = await queryClient.fetchQuery(
        useRewardsRedeemQuery.getKey(rewardVar),
        fetchData(RewardsRedeemDocument, rewardVar, header)
      )
      if (
        data?.loyaltySummary?.issuedRewards?.length !==
          rewardDetails?.loyaltySummary?.issuedRewards?.length &&
        data?.loyaltySummary?.availablePoints !==
          rewardDetails?.loyaltySummary?.availablePoints
      ) {
        setRewardDetails(data)
        setRewardDetailsLoading(false)
      } else {
        setTimeout(getRewardsRedeem, 2000)
      }
    } catch (error: any) {
      faroPushErrors(JSON.stringify(error?.response?.data))
    }
  }

  React.useEffect(() => {
    getRewards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickRedeem = async (rewardId: string) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    const PurchaseRewardVariable = {
      storeId: storeId || storageStoreId,
      userId: userId || storageUserId,
      rewardCanonicalId: rewardId,
    }
    try {
      setRewardDetailsLoading(true)
      await mutateAsync(PurchaseRewardVariable)
      // setRewardDetailsLoading(false)
    } catch (error: any) {
      faroPushErrors(JSON.stringify(error?.response?.data))
    }
  }

  const paddings = {
    px: isMobile ? 2 : 3,
    py: isMobile ? 1 : 2,
  }

  useEffect(() => {
    if (router.pathname === "/my-rewards" && rewardDetails) {
      myRewardsRef && myRewardsRef?.current?.focus()
    }
  }, [router.pathname])

  return (
    <AccountsLayout>
      {rewardDetailsLoading ? (
        <Stack
          sx={{
            height: "60%",
            minHeight: "50vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : rewardDetails ? (
        <Box
          className={styless.AccountMain_section}
          sx={{
            ...styles.card,
            padding: 0,
            boxShadow: "unset",
            outline: isMobile ? "unset" : "1px solid",
            borderRadius: isMobile ? "unset" : 4,
          }}
        >
          <DialogModal
            onClose={() => {
              setShowBarcode(false)
              gaLogEvent({
                eventName: EventName.navRewards_closeMyBarcodeModal,
              })
            }}
            // open={true}
            open={showBarcode}
            width="md"
            data-cy="store-dialog"
            aria-label="Bar Code Dialog Active"
          >
            <DialogActions
              sx={{
                p: 3,
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Image
                src={Logo}
                alt={initialState?.envVariables?.siteName}
                width={width}
                height={32}
                priority
              />
              <IconButton
                sx={{
                  color: "ctaPrimary",
                  position: "absolute",
                  right: "1rem",
                }}
                aria-label={t("ariaLabels.closeBarcode")}
                onClick={() => {
                  setShowBarcode(false)
                  gaLogEvent({
                    eventName: EventName.navRewards_closeMyBarcodeModal,
                  })
                }}
                data-cy="barcode-close-button"
              >
                <CloseIcon color={theme.palette.ctaPrimary.main} />
              </IconButton>
            </DialogActions>
            <DialogContent sx={{ p: 3, pt: 0 }}>
              <Typography
                variant="body"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  py: 2,
                  textAlign: "center",
                }}
              >
                {t("profile.rewards.barcodeText")}
              </Typography>
              <Box
                sx={{
                  backgroundColor: "bgSecondary.main",
                  borderRadius: 2,
                  p: 2,
                  mt: 1,
                  ".svg": { width: "100%" },
                }}
              >
                {cardId && <Barcode value={cardId} height={50} />}
              </Box>
            </DialogContent>
          </DialogModal>
          <Stack
            direction="row"
            className={styless.mySection}
            sx={{
              ...styles.header,
              ...paddings,
              borderBottom: isMobile ? "1px dashed" : "1px solid",
            }}
          >
            <Typography
              variant="h1"
              my={0}
              sx={{ fontSize: "18px", fontWeight: 600 }}
              tabIndex={-1}
              id="pageTitle"
              ref={myRewardsRef}
              aria-live="assertive"
              aria-atomic="true"
            >
              {t("profile.rewards.pageTitle")}
            </Typography>
            <Button
              aria-label={t("profile.rewards.showMyBarcode")}
              color="ctaPrimary"
              sx={{
                padding: 0,
                fontSize: 15,
                "&:hover": {
                  backgroundColor: "unset",
                },
              }}
              onClick={() => {
                setShowBarcode(true)
                gaLogEvent({
                  eventName: EventName.navRewards_showMyBarcodeClick,
                })
              }}
              data-cy="open-barcode-button"
            >
              {t("profile.rewards.showMyBarcode")}
            </Button>
          </Stack>

          <Box
            sx={{
              ...styles.body,
              p: 3,
            }}
            className={styless.mycouponsBody}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              gap={2}
              sx={{
                ...styles.subHeader,
                paddingBottom: paddings.px,
                marginBottom: 2,
              }}
            >
              <Stack
                direction={isMobile ? "column" : isTablet ? "column" : "row"}
                width="100%"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center">
                  <RewardsIconLarge color={theme.palette.ctaPrimary.main} />
                  <Box ml={2}>
                    <Typography variant="h2" fontSize="20px" fontWeight={600}>
                      {initialState?.envVariables?.configBanner === "savemart"
                        ? t("profile.rewards.saveSmartRewards")
                        : t("profile.rewards.luckyRewards")}
                    </Typography>
                    <Typography variant="body">
                      {t("profile.rewards.loyaltyId", {
                        id: cardId,
                      })}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row" mt={isMobile ? 2 : isTablet ? 2 : 0}>
                  <Divider
                    sx={{
                      borderLeft: `1px solid ${theme.palette.dividerColor?.main}`,
                      mx: isMobile ? 2 : 4,
                      display: isMobile ? "none" : isTablet ? "none" : "block",
                    }}
                  />
                  <Box>
                    <Typography
                      variant="paragraph"
                      fontSize="32px"
                      fontWeight={600}
                      lineHeight="40px"
                    >
                      {rewardDetails.loyaltySummary.availablePoints}
                    </Typography>
                    <Typography variant="h3" fontSize="14px" fontWeight={500}>
                      {t("profile.rewards.pointsBalance")}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      borderStyle: "solid",
                      mx: isMobile ? 2 : 4,
                      borderRight: 1,
                      borderColor: "dividerColor.main",
                    }}
                  />

                  <Box>
                    {/* TODO:: Need to Integrate */}

                    <Typography
                      variant="paragraph"
                      fontSize="32px"
                      fontWeight={600}
                      lineHeight="40px"
                    >
                      {rewardDetails?.loyaltySummary?.summaryPoints.length > 0
                        ? rewardDetails?.loyaltySummary?.summaryPoints[0]
                            ?.points
                        : 0}
                    </Typography>
                    <Typography variant="h3" fontSize="14px" fontWeight={500}>
                      {t("profile.rewards.expiresOn")}{" "}
                      <span
                        style={{
                          color: theme.palette.ctaSecondary.light,
                        }}
                      >
                        {rewardDetails?.loyaltySummary?.summaryPoints.length > 0
                          ? rewardDetails?.loyaltySummary?.summaryPoints[0]
                              ?.expiresOn
                          : null}
                      </span>
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
            <Typography variant="body1" mb={2} sx={{ fontWeight: 400 }}>
              {initialState?.envVariables?.configBanner === "savemart"
                ? t("profile.rewards.description")
                : t("profile.rewards.luckydescription")}
            </Typography>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              allowScrollButtonsMobile
              indicatorColor="ctaSecondary"
              sx={styles.tabs}
              className={styless.tabsSection}
            >
              <Tab
                value={1}
                tabIndex={0}
                disableRipple
                label={t("profile.rewards.availableRewards", {
                  count: rewardDetails.availableLoyaltyRewards.length,
                })}
                sx={{
                  "&:focus":
                    Number(selectedTab) !== 1
                      ? {
                          border: `1px solid ${theme.palette.ctaPrimary.main}`,
                          borderRadius: "3px",
                          p: 0.2,
                        }
                      : {},
                }}
              />
              <Tab
                value={2}
                tabIndex={0}
                disableRipple
                label={t("profile.rewards.myAddedRewards", {
                  count: rewardDetails.loyaltySummary.issuedRewards.length,
                })}
                sx={{
                  "&:focus":
                    Number(selectedTab) !== 2
                      ? {
                          border: `1px solid ${theme.palette.ctaPrimary.main}`,
                          borderRadius: "3px",
                          p: 0.2,
                        }
                      : {},
                }}
              />
            </Tabs>
            {selectedTab === 1 ? (
              rewardDetails.availableLoyaltyRewards.length ? (
                <RewardGrid
                  isMobile={isMobile}
                  rewards={
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    rewardDetails.availableLoyaltyRewards.map((reward: any) => {
                      return {
                        ...reward,
                        pointBalance:
                          rewardDetails.loyaltySummary.availablePoints,
                        // onClick: onClickReward,
                        onRedeem: onClickRedeem,
                      }
                    }) as unknown as RewardCardProps[]
                  }
                />
              ) : (
                <Typography variant="body1" sx={styles.text}>
                  {t("profile.rewards.noRewards")}
                </Typography>
              )
            ) : rewardDetails.loyaltySummary?.issuedRewards.length ? (
              <RewardGrid
                isMobile={isMobile}
                rewards={
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  rewardDetails?.loyaltySummary?.issuedRewards.map(
                    (reward: any) => {
                      return {
                        ...reward.reward,
                        expiryDateTime: reward?.expiryDateTime,
                        pointBalance:
                          rewardDetails?.loyaltySummary?.availablePoints,
                        // onClick: onClickReward,
                      }
                    }
                  ) as unknown as RewardCardProps[]
                }
              />
            ) : (
              <Typography variant="body1" sx={styles.text}>
                {t("profile.rewards.noHistory")}
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        "Error"
      )}
    </AccountsLayout>
  )
}

export default MyRewards
