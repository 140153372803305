import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { MouseEvent, useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../contexts/AppContext"
import {
  setCategoriesList,
  setSelectedCouponCategoryId,
} from "../../contexts/actions/actions"
import styles from "../../styles/component.module.scss"
import { CouponCategoriesProps } from "./types"
import { useRouter } from "next/router"
import useWebAdsSdkRefresh from "../useWebAdsSdkRefresh"
export default function CouponCategories({
  categoryList,
  totalCount,
  onClick,
  couponsId,
}: CouponCategoriesProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    state: { selectedCouponCategoryId: selectedCategoryId },
    dispatch,
  } = useContext(AppContext)
  const router = useRouter()
  const { query } = useRouter()

  const setSelectedCategoryId = useCallback(
    (index: string) => {
      dispatch(setSelectedCouponCategoryId(index))
    },
    [dispatch]
  )

  const [categories, setCategories] = useState([
    { name: "All Categories", id: "*", count: totalCount },
  ])

  useEffect(() => {
    if (query.couponId && categories.length > 1) {
      const catergorySelect =
        (categories &&
          categories?.find(
            (item: any) => item.name?.toLowerCase() === couponsId
          )?.id) ||
        null
      if (catergorySelect) {
        setSelectedCategoryId(catergorySelect)
      }
      //  else {
      //   router.replace('/coupons', undefined, { shallow: true });

      // }
      !isMobile && onClick && onClick()
    }
  }, [categories, query.couponId])
  // evaluate coupons and categories
  useEffect(() => {
    setCategories([
      { name: "All Categories", id: "*", count: totalCount },
      ...(categoryList ?? []),
    ])
    dispatch(
      setCategoriesList([
        { name: "All Categories", id: "*", count: totalCount },
        ...(categoryList ?? []),
      ])
    )
  }, [categoryList, totalCount])

  // handle category change on click of category item
  const onCategoryItemClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    category: any
  ) => {
    e.preventDefault()
    router.push(`/coupons/${category.name.toLowerCase()}`)
    // query.couponId ?? setSelectedCategoryId(category?.id)
    onClick && onClick()
  }

  const isMobile = useMediaQuery("(max-width:767px)")
  useWebAdsSdkRefresh()
  return (
    <>
      <Typography
        variant={isMobile ? "h6" : "h2"}
        color={theme.palette.textPrimary?.main}
      >
        {t("coupons.byCategories")}
      </Typography>
      <List
        className={styles.couponsCategory}
        sx={{ marginTop: 2, padding: 0 }}
      >
        {categories.map((category) => {
          return (
            <ListItem key={category.id}>
              <ListItemButton
                data-cy="coupons-category"
                selected={category.id === selectedCategoryId}
                onClick={(e) => onCategoryItemClick(e, category)}
                sx={{
                  color: theme.palette.textSecondary?.main,
                  px: 1.5,
                  py: 1,
                  marginBottom: 1,
                  borderRadius: 2,
                  width: "fit-content",
                  "&.Mui-selected": {
                    backgroundColor: "lightPrimary.main",
                    color: "ctaPrimary.main",
                    "&:hover": { backgroundColor: "lightPrimary.main" },
                  },
                }}
              >
                <ListItemText
                  className={styles.categoryName}
                  aria-label={` ${
                    category.id === selectedCategoryId
                      ? "selected category,"
                      : ""
                  } ${category.name}, ${category.count}`}
                  sx={{
                    m: 0,
                    ".MuiListItemText-primary": {
                      color: "ctaPrimary.main",
                      fontSize: 15,
                      fontWeight: 500,
                    },
                  }}
                  primary={`${category.name} (${category.count})`}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
        <ListItem sx={{ justifyContent: "center" }}>
          <swiftly-ad
            data-placement-id="Skyscraper"
            data-screen-name="CouponCategoryMenu"
          ></swiftly-ad>
        </ListItem>
      </List>
    </>
  )
}
