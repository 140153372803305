import * as React from "react"
import { SVGProps } from "react"
const SvgMailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 3H2a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1Zm-1 16H3V9.477l8.628 3.452c.239.095.505.095.744 0L21 9.477V19Zm0-11.677-9 3.6-9-3.6V5h18v2.323Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMailIcon
