import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { AppContext, useAppContext } from "../../contexts/AppContext"
import getPixelDensity from "../../utils/getPixelDensity"
import { TransitionsModal } from "../transitionModal/TransitionModal"
import {
  ProductSummary,
  SwiftlyImageDensity,
  WebPriceResult,
  useProductQuery,
} from "../../generated/graphql"
import {
  addShoppingListItem,
  clearOpenProductId,
  deleteShoppingListItem,
  openSnackbar,
} from "../../contexts/actions/actions"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { CloseIcon } from "@/icons"
import generateConfig from "../../configs/config"
import StoreSelectModal from "components/ui/storeSelectModal/StoreSelectModal"
import { useAuth } from "../../contexts/AuthContext"
import DoneIcon from "@mui/icons-material/Done"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import {
  MAX_ITEMS_COUNT,
  generateNewShoppingListItem,
  isItemPresentInShoppingList,
} from "components/shoppingList/utils"
import { ShoppingListItemType } from "components/shoppingList/types"
import { id } from "date-fns/locale"
import { updateShoppingList } from "index.service"
import styles from "../../styles/component.module.scss"
const config = generateConfig()

const ProductsDetailsModal = () => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [isSelectStoreModalOpen, setIsSelectStoreModalOpen] =
    useState<boolean>(false)
  const router = useRouter()
  const { state, dispatch } = useContext(AppContext)
  const { user, swiftlyToken } = useAuth()

  const {
    state: { shoppingList: shoppingListState },
  } = useAppContext()

  const { data: fetchedProduct, isLoading: isFetchingProduct } =
    useProductQuery(
      {
        productId: state?.openProductId as string,
        desiredDensities: [
          SwiftlyImageDensity.FourX,
          SwiftlyImageDensity.ThreeX,
          SwiftlyImageDensity.TwoX,
          SwiftlyImageDensity.OnePointFiveX,
          SwiftlyImageDensity.OneX,
        ],
        storeId:
          user?.homeStore || String(state.currentStore?.number || "") || null,
      },
      {
        enabled: !!state?.openProductId,
      }
    )

  useEffect(() => {
    if (!router?.isReady) {
      return
    }

    router.beforePopState(() => {
      dispatch(clearOpenProductId())
      return true
    })

    if (state.openProductId) {
      const updatedQueries = {
        ...router.query,
        openProductId: state.openProductId,
      }
      router
        .replace(
          {
            pathname: router.pathname,
            query: updatedQueries,
          },
          undefined,
          { shallow: true }
        )
        .catch(console.error)
    } else {
      const updatedQueries = { ...router.query }
      updatedQueries.openProductId = undefined
      delete updatedQueries.openProductId
      router
        .replace(
          {
            pathname: router.pathname,
            query: updatedQueries,
          },
          undefined,
          { shallow: true }
        )
        .catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.isReady, state.openProductId, dispatch, clearOpenProductId])

  const closeProductDetailsModal = () => {
    setShowDetails(false)
    dispatch(clearOpenProductId())
  }

  const isProductDetailsModalOpen = !!state.openProductId

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(767))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1200))

  const { t } = useTranslation()

  let pixelDensity = SwiftlyImageDensity.OneX as string

  if (typeof window !== "undefined") {
    pixelDensity = getPixelDensity(window.devicePixelRatio || 1)
  }

  const bestFitImage = fetchedProduct?.product?.images[0].images.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (img: any) => {
      return img.density === pixelDensity
    }
  )

  const eligibilities = state.eligibilitiesList.filter((item: any) =>
    fetchedProduct?.product?.eligibilities?.includes(item.id)
  )

  const isAddedToShoppingList = useMemo(() => {
    if (fetchedProduct?.product?.id) {
      return isItemPresentInShoppingList(
        shoppingListState.shoppingListItems,
        fetchedProduct?.product?.id
      )
    } else {
      return false
    }
  }, [shoppingListState.shoppingListItems, fetchedProduct])

  /**
   * Handle add an item to the shopping list
   * @param {ProductSummary} item
   */
  const handleAddItemToShoppingList = async () => {
    const item = fetchedProduct?.product
    if (item) {
      const newItem: ShoppingListItemType = generateNewShoppingListItem({
        title: item?.name || "",
        id: item?.id || "",
        brand: item?.brand,
        images: item?.images ? [...item.images] : [],
        webPrice: item?.webPrice ? item?.webPrice : ({} as WebPriceResult),
        type: "",
      } as ProductSummary)

      dispatch(addShoppingListItem(newItem))

      if (swiftlyToken?.access_token) {
        const items: ShoppingListItemType[] = [
          ...shoppingListState.shoppingListItems,
        ]

        await updateShoppingList(
          swiftlyToken.access_token,
          [...items, newItem],
          shoppingListState.metaData
        )
      }

      // Show notification
      dispatch(
        openSnackbar({
          variant: "alert",
          message: t("shoppingList.addItemSuccessAlert", {
            productName: item?.brand
              ? `${item.brand}, ${item?.name || ""}`
              : item?.name || "",
          }),
          alert: {
            color: "success",
          },
        })
      )
    }
  }

  /**
   * Remove an item from the shopping list
   * @param {ProductSummary} item
   */
  const handleRemoveItemFromShoppingList = async () => {
    const item = fetchedProduct?.product
    if (item) {
      const itemKey = shoppingListState.shoppingListItems.find(
        (slItem) => slItem?.attributes?.productId === item.id
      )?.key

      if (itemKey) {
        dispatch(deleteShoppingListItem({ key: itemKey }))

        if (swiftlyToken?.access_token) {
          const filteredList = shoppingListState.shoppingListItems?.filter(
            (i: any) => i.key != item.id
          )

          await updateShoppingList(
            swiftlyToken.access_token,
            filteredList,
            shoppingListState.metaData
          )
        }

        dispatch(
          openSnackbar({
            variant: "alert",
            message: t("shoppingList.removeItemSuccessAlert", {
              productName: item.name,
            }),
            alert: {
              color: "success",
            },
          })
        )
      }
    }
  }

  return (
    <TransitionsModal
      ariaLabelledBy="productDetails"
      ariaDescribedBy="productDetails"
      open={isProductDetailsModalOpen}
      onClose={closeProductDetailsModal}
      sx={{
        width: isMobile ? "100%" : isDesktop ? "460px" : "460px",
        backgroundColor: theme.palette.bgPrimary.main,
        borderRadius: isMobile ? 0 : 3,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          py: isMobile ? 1 : 2,
          pr: isMobile ? 1 : 2,
          pl: isMobile ? 2 : 3,
          borderBottom: isMobile
            ? `1px solid ${theme.palette.dividerColor?.main}`
            : "unset",
        }}
      >
        <Typography variant={isMobile ? "h4" : "h5"} id="productDetails">
          {" "}
          {t("products.details")}
        </Typography>
        <IconButton
          aria-label={t("ariaLabels.close")}
          onClick={closeProductDetailsModal}
        >
          <CloseIcon color={theme.palette.ctaPrimary.main} />
        </IconButton>
      </Stack>
      {isFetchingProduct ? (
        <Stack
          flex={1}
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, minHeight: 200 }}
        >
          <CircularProgress color="ctaPrimary" />
        </Stack>
      ) : (
        <Box
          sx={{ p: isMobile ? 2 : isDesktop ? 3 : 3, pt: 0 }}
          style={{ height: "calc(100% - 56px)", overflowY: "auto" }}
          className={styles.productModal}
        >
          <Box
            sx={{
              flexShrink: 1,
              flexGrow: 1,
              textAlign: "center",
              position: "relative",
            }}
          >
            {bestFitImage && (
              <Box position="relative">
                <Box
                  component="img"
                  src={bestFitImage.uri}
                  alt={fetchedProduct?.product?.name || ""}
                  height={200}
                />

                {config.enableShoppingList && (
                  <Box
                    position="absolute"
                    bottom={0}
                    right={0}
                    sx={{
                      width: "40px !important",
                      height: "40px !important",
                    }}
                  >
                    {isAddedToShoppingList ? (
                      <Tooltip
                        title={t("products.modal.removeFromShoppingList")}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          color="ctaPrimary"
                          disableElevation
                          sx={{
                            minWidth: "auto",
                            width: "100%",
                            height: "100%",
                            border: "transparent !important",
                            bgcolor: theme.palette?.successSurface?.main,
                          }}
                          role="button"
                          onClick={handleRemoveItemFromShoppingList}
                        >
                          <DoneIcon
                            sx={{
                              color: "ctaPrimary.main",
                            }}
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("products.modal.addToShoppingList")}>
                        <Button
                          size="small"
                          variant="contained"
                          color="ctaPrimary"
                          disableElevation
                          sx={{
                            minWidth: "auto",
                            width: "100%",
                            height: "100%",
                          }}
                          role="button"
                          onClick={handleAddItemToShoppingList}
                          disabled={
                            shoppingListState.shoppingListItems.length >=
                            MAX_ITEMS_COUNT
                          }
                        >
                          <PlaylistAddIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Divider
            sx={{
              borderStyle: "dashed",
              borderWidth: 0,
              borderBottomWidth: "1px",
              mt: 2,
              mb: 4,
            }}
          />

          <Typography
            variant="h1"
            color="textPrimary.main"
            fontSize="1rem"
            sx={{
              mb: 1,
            }}
          >
            {fetchedProduct?.product?.brand || ""}
          </Typography>

          <Typography
            variant="h2"
            color="textPrimary.main"
            fontWeight={600}
            sx={{
              mb: 1,
            }}
          >
            {fetchedProduct?.product?.name || ""}
          </Typography>
          {eligibilities.length ? (
            <>
              <Stack flexDirection="row">
                <Typography variant="body" color="textPrimary.main">
                  {t("products.eligibleFor")}:
                </Typography>
                <Typography
                  variant="body"
                  color="textPrimary.main"
                  fontWeight={600}
                  ml={1}
                >
                  {eligibilities.map((item: any) => item?.id).join(", ")}
                </Typography>
              </Stack>
            </>
          ) : null}

          {config.experimentalProductPricing === "true" && (
            <Box
              sx={{
                ".sale": {
                  display: "inline-block",
                },
                ".swiftly-price-override": {
                  textDecoration: "line-through",
                  color: "textSecondary.main",
                  fontWeight: 400,
                  fontSize: 15,
                },
                ".promotion": {
                  color: "ctaPrimary.main",
                },
                ".swiftly-price-with-sale-tag": {
                  m: 0,
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "16px",
                  py: 1 / 8,
                  px: 2,
                  backgroundColor: "ctaPrimary.main",
                  color: "bgPrimary.main",
                  borderRadius: 1,
                  ml: 2,
                },
              }}
            >
              <Typography variant={isMobile ? "h4" : "h3"}>
                {fetchedProduct?.product?.webPrice?.success?.basePrice && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        fetchedProduct?.product?.webPrice?.success?.basePrice ||
                        "",
                    }}
                  />
                )}
                {fetchedProduct?.product?.webPrice?.success?.isSale && (
                  <Typography
                    variant="h6"
                    color="textSecondary.main"
                    sx={{
                      py: 0.5,
                      px: 2,
                      backgroundColor: "ctaPrimary.main",
                      color: "bgPrimary.main",
                      borderRadius: 1,
                      ml: 2,
                    }}
                    className="sale"
                  >
                    {t("products.sale")}
                  </Typography>
                )}
              </Typography>

              {fetchedProduct?.product?.webPrice?.success?.promoPrice && (
                <Typography variant={isMobile ? "h4" : "h3"}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        fetchedProduct?.product?.webPrice?.success
                          ?.promoPrice || "",
                    }}
                  />
                </Typography>
              )}

              {fetchedProduct?.product?.webPrice?.success?.promoText?.map(
                (promoText: string, index: number) => (
                  <Typography
                    variant="body"
                    color="textSecondary.main"
                    sx={{
                      mb: index == 0 ? 0 : 4,
                      display: "flex",
                      alignItems: "center",
                      mt: index == 0 ? 2 : 0,
                    }}
                    key={index}
                  >
                    {promoText}
                  </Typography>
                )
              )}
            </Box>
          )}

          {config.experimentalProductPricing === "true" &&
          fetchedProduct?.product?.webPrice?.failure?.displayMessage ? (
            fetchedProduct?.product?.webPrice?.failure?.type ===
            "StoreNotSelected" ? (
              <Button
                style={{
                  width: "100%",
                  borderWidth: "2px",
                  fontWeight: "bold",
                }}
                variant="outlined"
                color="success"
                onClick={() => {
                  setIsSelectStoreModalOpen(true)
                }}
                aria-label={t("products.modal.StoreNotSelected")}
              >
                {t("products.modal.StoreNotSelected")}
              </Button>
            ) : (
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "17px",
                  lineHeight: "22px",
                  color: theme.palette.textSecondary?.main,
                }}
              >
                {fetchedProduct?.product?.webPrice?.failure?.displayMessage}
              </Typography>
            )
          ) : null}

          <Divider
            sx={{
              borderStyle: "dashed",
              borderWidth: 0,
              borderBottomWidth: "1px",
              mt: 4,
              mb: 2,
            }}
          />
          <Stack
            role="button"
            tabIndex={0}
            aria-expanded={!showDetails}
            aria-label={t("products.productDetails")}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              setShowDetails(!showDetails)
            }}
          >
            <Typography
              variant="body"
              fontWeight={600}
              id="productDetails"
              color={theme.palette.ctaPrimary.main}
            >
              {t("products.productDetails")}
            </Typography>
            {showDetails ? (
              <ExpandLess sx={{ color: theme.palette.ctaPrimary.main }} />
            ) : (
              <ExpandMore />
            )}
          </Stack>
          <Collapse
            in={!showDetails}
            timeout="auto"
            unmountOnExit
            sx={{
              ".MuiCollapse-wrapperInner": {
                height: "100%",
                minHeight: "auto",
                maxHeight: isMobile ? "auto" : 125,
                overflowY: "auto",
                mt: 2,
              },
            }}
          >
            <Typography
              variant="caption"
              color={theme.palette.textSecondary.main}
              mt={1}
              fontWeight={400}
            >
              {fetchedProduct?.product?.description || ""}
            </Typography>
          </Collapse>
          <Divider
            sx={{
              borderStyle: "dashed",
              borderWidth: 0,
              borderBottomWidth: "1px",
              mt: 2,
            }}
          />
        </Box>
      )}
      <StoreSelectModal
        open={isSelectStoreModalOpen}
        onClose={() => setIsSelectStoreModalOpen(false)}
      />
    </TransitionsModal>
  )
}

export default ProductsDetailsModal
