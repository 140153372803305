import * as React from "react"
import { SVGProps } from "react"

import { useTheme } from "@mui/material/styles"
const SvgMinusIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill={theme.palette.ctaPrimary.main}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 12a1 1 0 0 1 1-1h10a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1Z" />
    </svg>
  )
}
export default SvgMinusIcon
