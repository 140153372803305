import PinMiniIcon from "@/icons/PinMiniIcon"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import axios from "axios"
import { openSnackbar, setStore } from "contexts/actions/actions"
import { AppContext } from "contexts/AppContext"
import { useAuth } from "contexts/AuthContext"
import { useRouter } from "next/router.js"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { DecoratedStore, useDecorateStore } from "../stores/utils"
import { MouseEventHandler } from "react"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { faroPushErrors } from "utils/faroGrafanaLogs"
import generateConfig from "configs/config"

export type StoreCardType = {
  store: DecoratedStore
  isSearchResult?: boolean
  isCurrentStore?: boolean
  onClick?: () => void
  isNearByList?: boolean
  isModal?: boolean
}
export const styles = {
  pinIcon: {
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
    paddingRight: 8,
  },
}

export const StoreCard: React.FC<StoreCardType> = React.memo(
  ({
    store,
    isSearchResult = false,
    isCurrentStore = false,
    onClick,
    isNearByList = false,
    isModal,
  }: StoreCardType) => {
    const { t } = useTranslation()
    const config = generateConfig()
    const router = useRouter()
    const theme = useTheme()
    const isMobile = useMediaQuery("(max-width:767px)")
    const flyer = router.query
    const { decorateStore } = useDecorateStore(store)
    const { dispatch } = useContext(AppContext)
    const {
      user,
      getRegisterInfo,
      updateRegisterInfo,
      updateUserInfo,
      swiftlyToken,
      isAuthenticated,
      userId,
    } = useAuth()
    const setAsMyStore = async (e: React.MouseEvent<HTMLElement>) => {
      gaLogEvent({
        eventName: EventName.storeLocator_setAsMyStore,
        parameters: {
          item_description: store.name,
          item_location: isModal ? "Modal" : "Page",
        },
      }),
        e.preventDefault()
      e.stopPropagation()
      !!window &&
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })

      if (isAuthenticated) {
        try {
          const sToken = `${swiftlyToken?.access_token}`
          const cardId = localStorage.getItem("cardId") || ""
          const body = {
            ...user,
            storeNumber: store?.number.toString(),
            cardId: cardId,
            swiftlyUserId: userId,
          }
          const header = {
            headers: {
              Authorization: sToken,
            },
          }
          const endpoint = config.enableShimmedHomeStoreUpdate
            ? "/api/updateShimmedHomeStore"
            : "/api/updateHomeStore"

          const result = await axios.post(endpoint, body, header)
          if ((await result.data.status) === "Success") {
            const updatedUser = { ...user, homeStore: store?.number.toString() }
            localStorage.setItem("localUser", JSON.stringify(updatedUser))
            updateRegisterInfo(updatedUser)
            updateUserInfo(updatedUser)
            dispatch(setStore(decorateStore(store)))
          } else {
            faroPushErrors(result.data)
            throw new Error(result.data)
          }
        } catch (error: any) {
          faroPushErrors(JSON.stringify(error?.response?.data))
          dispatch(
            openSnackbar({
              variant: "alert",
              message: "Something went wrong!",
              alert: {
                color: "error",
              },
            })
          )
        }
      } else {
        // this is for unauthenticated users to set a home store
        // this is used to view flyers for unauthenticated users
        // this is used for registration
        const updatedUser = {
          ...getRegisterInfo,
          preferred_store: store?.number.toString(),
        }
        localStorage.setItem("userInfo", JSON.stringify(updatedUser))
        dispatch(setStore(decorateStore(store)))
        updateRegisterInfo(updatedUser)
        flyer &&
          Object.keys(flyer)[0] === "flyer" &&
          router.push(`/flyers/${store.number.toString()}`)
      }
    }
    const handleRoute = (store: any) => {
      gaLogEvent({
        eventName: EventName.storeLocator_storeCardClick,
        parameters: {
          item_message1: store.name,
          item_location: isModal ? "Modal" : "Page",
          item_message2: store.address,
        },
      }),
        !!window &&
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
    }

    const constHandleSetAsMyStore = (e: any) => {
      if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
        setAsMyStore(e)
        gaLogEvent({
          eventName: EventName.storeLocator_setAsMyStore,
          parameters: {
            item_description: store.name,
            item_location: isModal ? "Modal" : "Page",
          },
        })
      }
    }
    return (
      <>
        {isNearByList && isCurrentStore ? null : (
          <Card
            role="button"
            aria-label={t("ariaLabels.openDetailsForStore", {
              storeName: store.name,
            })}
            tabIndex={0}
            sx={{
              backgroundColor: isNearByList ? "bgPrimary.main" : "unset",
              boxShadow: "none",
              borderRadius: isNearByList ? 4 : 0,
              cursor: "pointer",
              border: isNearByList
                ? `1px solid ${theme.palette.dividerColor?.main}`
                : "1px solid transparent",
              padding: isNearByList ? 3 : 2,
              py: isNearByList ? 3 : 2,
              "&:hover": {
                borderRadius: "12px",
                border: `1px solid ${theme.palette.ctaPrimary?.main}`,
              },
            }}
            onClick={() => {
              handleRoute(store)
              router.push(
                `/stores/${store.id}/${store.name}/${store.number}/${store.name}`,
                undefined,
                {
                  shallow: true,
                }
              )
              onClick && onClick()
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                handleRoute(store)
                router.push(
                  `/stores/${store.id}/${store.name}/${store.number}/${store.name}`,
                  undefined,
                  {
                    shallow: true,
                  }
                )
                onClick && onClick()
              }
            }}
          >
            <CardContent
              style={{
                padding: 0,
                width: "100%",
                display: "flex",
                alignItems: "start",
              }}
            >
              <Stack
                direction={isMobile ? "column" : "row"}
                alignItems={isMobile ? "start" : "unset"}
                sx={{ width: "100%" }}
              >
                <div
                  style={{
                    flex: 1,
                    cursor: "pointer",
                    width: "100%",
                  }}
                  // onClick={onClick}
                >
                  <Stack flex={1} width="100%">
                    <Grid
                      container
                      direction="row"
                      justifyContent={
                        isNearByList ? "space-between" : "flex-start"
                      }
                      alignItems="center"
                      flexWrap="nowrap"
                      width="100%"
                      gap={1}
                      mb={1}
                    >
                      <Grid item mr={1}>
                        <Typography
                          variant={isNearByList ? "h3" : "h2"}
                          color="ctaPrimary.main"
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                          aria-label={`${config?.metaSiteName} ${store.name}`}
                        >
                          {store.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="caption"
                          color={`${theme.palette.placeholder}`}
                        >
                          {store.distance}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <PinMiniIcon
                        color={theme.palette.ctaPrimary.main}
                        style={styles.pinIcon}
                        aria-label={t("ariaLabels.storeAddress")}
                      />
                      <Typography
                        variant={isNearByList ? "body" : "body"}
                        fontWeight={600}
                        noWrap
                      >
                        {store.address}
                      </Typography>
                    </Stack>
                    <Typography
                      pb={1}
                      variant="body"
                      fontWeight={600}
                      sx={{
                        width: "100%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`${store.city}, ${store.state} ${store.postalCode}`}
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item mr={1}>
                        <Typography
                          color={theme.palette.ctaSuccess.main}
                          variant="info"
                          sx={{ fontWeight: 600 }}
                        >
                          {store.status.text}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="info"
                          sx={{ fontWeight: 600 }}
                        >{`• ${store.timings}`}</Typography>
                      </Grid>
                    </Grid>
                    {!isSearchResult && (
                      <>
                        <Button
                          sx={{
                            py: isNearByList ? 0 : 0,
                            my: isNearByList ? 2 : 0,
                            px: 0,
                            justifyContent: "flex-start",
                            ":hover": { backgroundColor: "unset" },
                            width: isNearByList ? "fit-content" : "auto",
                          }}
                          onClick={(e: React.SyntheticEvent) => {
                            e.stopPropagation()
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`,
                              "_blank"
                            )
                          }}
                          aria-label={t("ariaLabels.getDirectionsOfStore", {
                            name: store.name,
                          })}
                        >
                          <Typography
                            color={theme.palette.ctaPrimary.main}
                            variant={isNearByList ? "label" : "h6"}
                            mb={isNearByList ? 0 : 2}
                          >
                            {t("common.getDirections")}
                          </Typography>
                        </Button>
                        <Typography mb={2} color={theme.palette.text.primary}>
                          {store.phoneNumber.phone || ""}
                        </Typography>
                        <Stack>
                          {isCurrentStore ? (
                            <Button
                              variant="contained"
                              sx={{
                                fontWeight: 600,
                                padding: 1,
                                width: "100%",
                                backgroundColor: "lightPrimary.main",
                                color: "ctaPrimary.main",
                                border: "unset",
                                ":hover": {
                                  backgroundColor: "lightPrimary.main",
                                  color: "ctaPrimary.main",
                                },
                              }}
                              aria-label={t("stores.storeSeleted", {
                                name: store.name,
                              })}
                              disabled
                            >
                              {t("stores.myStore")}
                            </Button>
                          ) : (
                            <Button
                              data-cy="btn-set-as-my-store"
                              variant="contained"
                              color="secondary"
                              aria-label={t("ariaLabels.setStore", {
                                name: store.name,
                              })}
                              sx={{ fontWeight: 600 }}
                              onClick={constHandleSetAsMyStore}
                              onKeyDown={constHandleSetAsMyStore}
                            >
                              {t("stores.setAsMyStore")}
                            </Button>
                          )}
                        </Stack>
                      </>
                    )}
                  </Stack>
                </div>
                {!isNearByList && (
                  <>
                    {isSearchResult && (
                      <Stack
                        flex={1}
                        alignItems={isMobile ? "start" : "end"}
                        sx={{ mt: isMobile ? 1 : 0, width: "100%" }}
                      >
                        {isCurrentStore ? (
                          <Button
                            aria-label={t("stores.storeSeleted", {
                              name: store.name,
                            })}
                            variant="contained"
                            sx={{
                              fontWeight: 600,
                              padding: 1,
                              width: "100%",
                              maxWidth: "132px",
                              backgroundColor: "lightPrimary.main",
                              color: "ctaPrimary.main",
                              border: "unset",
                              ":hover": {
                                backgroundColor: "lightPrimary.main",
                                color: "ctaPrimary.main",
                              },
                            }}
                            disabled
                          >
                            {t("stores.myStore")}
                          </Button>
                        ) : (
                          <Button
                            aria-label={t("ariaLabels.setStore", {
                              name: store.name,
                            })}
                            data-cy="btn-set-as-my-store"
                            sx={{ padding: 1, px: 2, fontWeight: 600 }}
                            variant="contained"
                            onClick={(e) => {
                              setAsMyStore(e)
                              gaLogEvent({
                                eventName: EventName.storeLocator_setAsMyStore,
                                parameters: {
                                  item_description: store.name,
                                  item_location: isModal ? "Modal" : "Page",
                                },
                              })
                            }}
                          >
                            {t("stores.setAsMyStore")}
                          </Button>
                        )}
                      </Stack>
                    )}

                    {!isSearchResult && (
                      <>
                        <Button
                          aria-label={t("ariaLabels.getDirectionsOfStore", {
                            name: store.name,
                          })}
                          onClick={() => {
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`,
                              "_blank"
                            )
                          }}
                        >
                          <Typography
                            mb={2}
                            color={theme.palette.ctaPrimary.main}
                            variant={isNearByList ? "label" : "h6"}
                          >
                            {t("common.getDirections")}
                          </Typography>
                        </Button>
                        <Button
                          aria-label={t("common.learnMore")}
                          fullWidth
                          variant="contained"
                          color="secondary"
                          sx={{ fontWeight: 600 }}
                          onClick={() => router.push(`${store.id}`)}
                        >
                          {t("common.learnMore")}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Stack>
              <IconButton
                sx={{
                  display: isNearByList ? "none" : isMobile ? "flex" : "none",
                }}
                onClick={onClick}
                aria-label={t("ariaLabels.closeButton")}
              >
                <ChevronRightIcon sx={{ color: "ctaPrimary.main" }} />
              </IconButton>
            </CardContent>
          </Card>
        )}
      </>
    )
  }
)

StoreCard.displayName = "StoreCard"
