import * as React from "react"
import { SVGProps } from "react"

const SvgRewardsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path
      d="M22 3H19V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H6C5.73478 1 5.48043 1.10536 5.29289 1.29289C5.10536 1.48043 5 1.73478 5 2V3H2C1.73478 3 1.48043 3.10536 1.29289 3.29289C1.10536 3.48043 1 3.73478 1 4V6C1.00363 7.19749 1.43742 8.3538 2.22231 9.2582C3.00721 10.1626 4.09095 10.7548 5.276 10.927C5.64939 12.2213 6.38786 13.3806 7.40301 14.2661C8.41816 15.1516 9.66698 15.7258 11 15.92V18H7C6.79004 17.9999 6.58538 18.066 6.41505 18.1888C6.24472 18.3115 6.11735 18.4848 6.051 18.684L5.051 21.684C5.00093 21.8343 4.9873 21.9944 5.01123 22.151C5.03517 22.3077 5.09598 22.4564 5.18866 22.5849C5.28134 22.7134 5.40323 22.8181 5.54429 22.8903C5.68536 22.9624 5.84155 23 6 23H18C18.1584 22.9999 18.3144 22.9621 18.4554 22.8899C18.5963 22.8177 18.7181 22.713 18.8106 22.5845C18.9032 22.456 18.9639 22.3074 18.9878 22.1508C19.0117 21.9942 18.998 21.8343 18.948 21.684L17.948 18.684C17.8817 18.485 17.7545 18.3118 17.5844 18.1891C17.4142 18.0663 17.2098 18.0002 17 18H13V15.92C14.333 15.7258 15.5818 15.1516 16.597 14.2661C17.6121 13.3806 18.3506 12.2213 18.724 10.927C19.9091 10.7548 20.9928 10.1626 21.7777 9.2582C22.5626 8.3538 22.9964 7.19749 23 6V4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652 3 22 3ZM5 8.829C4.41549 8.62142 3.90951 8.2381 3.55144 7.73161C3.19338 7.22513 3.00076 6.62027 3 6V5H5V8.829ZM16.279 20L16.612 21H7.387L7.721 20H16.279ZM17 9C17 10.3261 16.4732 11.5979 15.5355 12.5355C14.5979 13.4732 13.3261 14 12 14C10.6739 14 9.40215 13.4732 8.46447 12.5355C7.52678 11.5979 7 10.3261 7 9V3H17V9ZM21 6C20.9992 6.62027 20.8066 7.22513 20.4486 7.73161C20.0905 8.2381 19.5845 8.62142 19 8.829V5H21V6ZM10.667 8.667L9 7.292L11 7L12 5L13 7L15 7.292L13.333 8.667L13.854 11L12 9.667L10.146 11L10.667 8.667Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRewardsIcon
