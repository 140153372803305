import { useEffect, useRef } from 'react'

export default function useWebAdsSdkRefresh() {
  const isRefreshed = useRef(false)
  useEffect(() => {
    if (!isRefreshed.current) {
      isRefreshed.current = true
      window?.Swiftly?.ads?.refresh()
    }
  }, [])
  return { isRefreshed: isRefreshed.current }
}