import * as React from "react"
import { SVGProps } from "react"
const ShopIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-hidden="true"
    role="presentation"
  >
    <path
      opacity="0.4"
      d="M39.6783 20.8825V31.8641C39.6783 36.9241 35.5717 41.0308 30.5117 41.0308H14.4883C9.42832 41.0308 5.32166 36.9241 5.32166 31.8641V21.0108C6.71499 22.5141 8.69499 23.3758 10.84 23.3758C13.15 23.3758 15.3683 22.2208 16.7617 20.3691C18.0083 22.2208 20.135 23.3758 22.5 23.3758C24.8467 23.3758 26.9367 22.2758 28.2017 20.4425C29.6133 22.2575 31.795 23.3758 34.0683 23.3758C36.2867 23.3758 38.3033 22.4775 39.6783 20.8825Z"
    />
    <path d="M27.9816 2.29102H16.9816L15.6249 15.7843C15.5149 17.031 15.6983 18.2043 16.1566 19.2677C17.2199 21.761 19.7133 23.3743 22.4999 23.3743C25.3233 23.3743 27.7616 21.7977 28.8616 19.286C29.1916 18.4977 29.3933 17.581 29.4116 16.646V16.2977L27.9816 2.29102Z" />
    <path
      opacity="0.6"
      d="M41.4933 15.161L40.9616 10.0827C40.1916 4.54602 37.68 2.29102 32.3083 2.29102H25.2683L26.625 16.041C26.6433 16.2243 26.6616 16.426 26.6616 16.7743C26.7716 17.7277 27.065 18.6077 27.505 19.396C28.825 21.816 31.3916 23.3743 34.0683 23.3743C36.5066 23.3743 38.7066 22.2927 40.0816 20.386C41.1816 18.9193 41.6766 17.0677 41.4933 15.161Z"
    />
    <path
      opacity="0.6"
      d="M12.5818 2.29102C7.19176 2.29102 4.69842 4.54602 3.91009 10.1377L3.41509 15.1793C3.23176 17.141 3.76342 19.0477 4.91842 20.5327C6.31176 22.3477 8.45676 23.3743 10.8401 23.3743C13.5168 23.3743 16.0834 21.816 17.3851 19.4327C17.8618 18.6077 18.1734 17.6543 18.2651 16.6643L19.6951 2.30935H12.5818V2.29102Z"
    />
    <path d="M21.3083 30.5431C18.98 30.7815 17.22 32.7615 17.22 35.1081V41.0298H27.7616V35.7498C27.78 31.9181 25.525 30.1031 21.3083 30.5431Z" />
  </svg>
)
export default ShopIcon
