import * as React from "react"
import { SVGProps } from "react"
const SvgStateDoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={16} height={16} rx={2} fill="#fff" />
    <g clipPath="url(#state_done_icon_svg__a)">
      <rect width={16} height={16} rx={4} fill="currentColor" />
      <path
        d="m6.605 12.222-.017.018-3.4-3.4L4.62 7.407l1.984 1.985 4.774-4.774 1.433 1.433-6.189 6.189-.018-.018Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="state_done_icon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgStateDoneIcon
