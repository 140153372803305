import * as React from "react"
import { SVGProps } from "react"

const SvgCallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.277 8.858c1.329 5.28 8.586 12.542 13.865 13.865.713.18 1.445.273 2.18.274a8.54 8.54 0 0 0 4.006-1 3.109 3.109 0 0 0 .764-4.951l-2.086-2.086a3.112 3.112 0 0 0-3.444-.651 4.86 4.86 0 0 0-1.471.987c-.178.177-.506.205-.977.077a9.98 9.98 0 0 1-5.488-5.487c-.126-.471-.1-.8.078-.977.417-.426.752-.926.988-1.473a3.112 3.112 0 0 0-.651-3.442L6.955 1.909A3.065 3.065 0 0 0 4.3 1.035 3.1 3.1 0 0 0 2 2.672a8.58 8.58 0 0 0-.723 6.186ZM3.773 3.6a1.115 1.115 0 0 1 1.413-.516c.133.055.254.137.355.239l2.085 2.085a1.1 1.1 0 0 1 .239 1.213 2.9 2.9 0 0 1-.575.879 2.817 2.817 0 0 0-.59 2.9c.722 2.7 4.205 6.179 6.9 6.9a2.822 2.822 0 0 0 2.907-.6c.254-.243.55-.438.874-.576a1.1 1.1 0 0 1 1.214.239l2.085 2.085a1.09 1.09 0 0 1 .31.942 1.113 1.113 0 0 1-.591.826 6.517 6.517 0 0 1-4.766.556C11.089 19.641 4.36 12.912 3.216 8.37a6.53 6.53 0 0 1 .557-4.77Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgCallIcon
