import React, { useContext } from "react"
import { Divider, Stack, useTheme } from "@mui/material"
import { StoreCard } from "../common/StoreCard"
import { DecoratedStore } from "./utils"
import { AppContext } from "contexts/AppContext"
import { useRouter } from "next/router"
import { useAuth } from "contexts/AuthContext"

type NearbyStoreList = {
  stores: DecoratedStore[]
  closeModal?: () => void
  isModal?: boolean
}

export const StoresList: React.FC<NearbyStoreList> = ({
  stores,
  closeModal,
  isModal,
}: NearbyStoreList) => {
  const theme = useTheme()
  const router = useRouter()

  const {
    state: { currentStore },
  } = useContext(AppContext)

  const { handleStoreRoute } = useAuth()

  return (
    <>
      {/* {closeModal ? (
        <Divider
          sx={{
            borderWidth: "1px",
            borderStyle: "dashed",
            borderColor: theme.palette.border.main,
          }}
        />
      ) : null} */}
      <Stack>
        {stores?.map((store, index) => (
          <React.Fragment key={index}>
            <StoreCard
              store={store}
              isModal={isModal}
              isSearchResult
              isCurrentStore={store.id === currentStore?.id}
              onClick={() => {
                closeModal && closeModal()
                handleStoreRoute(store.id, store.name, store.number, store.city)
              }}
            />
            <Divider
              sx={{
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: theme.palette.border.main,
                my: 1,
              }}
            />
          </React.Fragment>
        ))}
      </Stack>
    </>
  )
}
