import * as React from "react"
import { SVGProps } from "react"

const FailedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.125 43.9062L32.5 13.9062H40.5L39.9062 43.9062H33.125ZM36.5 59.3438C35.25 59.3438 34.1771 58.9167 33.2812 58.0625C32.4062 57.2083 31.9688 56.1667 31.9688 54.9375C31.9688 53.6875 32.4062 52.6458 33.2812 51.8125C34.1771 50.9583 35.25 50.5312 36.5 50.5312C37.7708 50.5312 38.8438 50.9583 39.7188 51.8125C40.5938 52.6458 41.0312 53.6875 41.0312 54.9375C41.0312 56.1667 40.5938 57.2083 39.7188 58.0625C38.8438 58.9167 37.7708 59.3438 36.5 59.3438Z"
      fill="currentColor"
    />
    <rect
      x="2.5"
      y="2"
      width="68"
      height="68"
      rx="34"
      stroke="currentColor"
      stroke-width="4"
    />
  </svg>
)

export default FailedIcon
