import React, { FormEventHandler, useEffect } from "react"
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { styles as rewardsStyles } from "../rewards/MyRewards"
import { useTranslation } from "react-i18next"
import { TextInput } from "../../common/textInput/TextInput"
import { styles as registerStyles } from "../../auth/Register"
import { useAuth } from "../../../contexts/AuthContext"
import { z, ZodError } from "zod"
import { TransitionsModal } from "../../transitionModal/TransitionModal"
import CloseIcon from "@/icons/CloseIcon"
import { MobileKeyboardInputView } from "@mui/x-date-pickers/internals/components/CalendarOrClockPicker/CalendarOrClockPicker"
import { LoadingButton } from "@mui/lab"

export const styles = {
  ...rewardsStyles,
  ...registerStyles,
  text: {
    ...rewardsStyles.text,
    ml: 1,
    mt: 1,
  },
  text2: {
    ...rewardsStyles.text,
    ml: 1,
  },
  button: {
    ...registerStyles.button,
    borderRadius: 2,
    borderWidth: 2,
    mt: 0,
    "&:hover": {
      borderWidth: 2,
    },
  },
  cancelbutton: {
    ...registerStyles.button,
    borderRadius: 2,
    borderWidth: 2,
    backgroundColor: "bgPrimary.main",
    borderColor: "ctaPrimary.main",
    color: "ctaPrimary.main",
    mt: 0,
  },
  textInput: {
    ".MuiOutlinedInput-root": {
      borderRadius: 2,
      fontSize: 13,
      backgroundColor: "#FAFAFA",
      ".MuiInputBase-input": {
        p: 2,
        boxShadow: "0 0 0 30px #fafafa inset !important",
      },
    },
  },
  mobileView: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "1rem",
    height: "auto",
  },
}

export let editProfileZod = z.object({
  firstName: z
    .string()
    .min(1, { message: "This field is required." })
    .regex(/^[a-zA-Z- ]*$/, {
      message:
        "Name must start with a letter and contain letters, spaces and dashes only.",
    }),
  lastName: z
    .string()
    .min(1, { message: "This field is required." })
    .regex(/^[a-zA-Z- ]*$/, {
      message:
        "Name must start with a letter and contain letters, spaces and dashes only.",
    }),
  email: z.string().email({
    message: "Email address is not in correct format, please check your input.",
  }),
  emailOptIn: z.boolean(),
  homeStore: z.string(),
  zipCode: z
    .string()
    .min(5, { message: "Zipcode should be 5 digits" })
    .max(5, { message: "Zipcode should be 5 digits" })
    .optional(),
})

export interface EditProfileFormType {
  firstName: {
    value: string
    error?: string
  }
  lastName: {
    value: string
    error?: string
  }
  email: {
    value: string
    error?: string
  }
  zipCode?: {
    value: string
    error?: string
  }
  phone?: {
    value: string
    error?: string
  }
  phoneNumber?: {
    value: string
    error?: string
  }
  emailOptIn?: {
    value: boolean
    error?: string
  }
  homeStore?: {
    value: string
    error?: string
  }
}

interface EditPersonalInfoModalProps {
  onSubmit: (data: z.infer<typeof editProfileZod>) => void
  onClose: () => void
  open: boolean
  isLoading: boolean
}

const EditPersonalInfoModal = ({
  onSubmit,
  onClose,
  open,
  isLoading,
}: EditPersonalInfoModalProps) => {
  const { user } = useAuth()
  const [profileFormData, setProfileFormData] =
    React.useState<EditProfileFormType>({
      firstName: {
        value: user?.firstName || "",
        error: "",
      },
      lastName: {
        value: user?.lastName || "",
        error: "",
      },
      email: {
        value: user?.email || "",
        error: "",
      },
      zipCode: {
        value: user?.zipCode || "",
        error: "",
      },
      phone: {
        value: user?.phone || "",
        error: "",
      },
      phoneNumber: {
        value: user?.phoneNumber || "",
        error: "",
      },
      emailOptIn: {
        value: user?.emailOptIn || false,
        error: "",
      },
      homeStore: {
        value: user?.homeStore || "",
        error: "",
      },
    })

  useEffect(() => {
    setProfileFormData({
      firstName: {
        value: user?.firstName || "",
        error: "",
      },
      lastName: {
        value: user?.lastName || "",
        error: "",
      },
      email: {
        value: user?.email || "",
        error: "",
      },
      zipCode: {
        value: user?.zipCode || "",
        error: "",
      },
      phone: {
        value: user?.phone || "",
        error: "",
      },
      phoneNumber: {
        value: user?.phoneNumber || "",
        error: "",
      },
    })
  }, [user])

  useEffect(() => {
    if (user?.phoneNumber)
      editProfileZod = editProfileZod.extend({
        phoneNumber: z.string().regex(/^\d{10}$/),
      })
    else
      editProfileZod = editProfileZod.extend({
        phoneNumber: z
          .string()
          .regex(/^\d{10}$/)
          .optional(),
      })
  }, [user?.phone])

  const { t } = useTranslation()

  const onSaveProfile: FormEventHandler = (e) => {
    e.preventDefault()
    editProfileZod
      .parseAsync({
        firstName: profileFormData.firstName.value,
        lastName: profileFormData.lastName.value,
        email: profileFormData.email.value,
        zipCode: user?.zipCode ? profileFormData.zipCode?.value : undefined,
        phoneNumber: user?.phoneNumber
          ? profileFormData.phoneNumber?.value
          : undefined,
        emailOptIn: user?.emailOptIn,
        homeStore: user?.homeStore,
      })
      .then((a) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof EditProfileFormType] = {
              ...prev[key as keyof EditProfileFormType],
              error: undefined,
            }
            return acc
          }, {} as EditProfileFormType)
        })
        for (const aKey in a) {
          if (a[aKey as keyof typeof a] === undefined)
            delete a[aKey as keyof typeof a]
        }
        onSubmit(a)
      })
      .catch((err: ZodError) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const x = Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof EditProfileFormType] = {
              ...prev[key as keyof EditProfileFormType],
              error: err.issues?.find((issue) => issue.path[0] === key)
                ?.message,
            }
            return acc
          }, {} as EditProfileFormType)
          return x
        })
      })
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(767))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1280))

  const normalizeInput = (value: any) => {
    if (!value) return value

    const currentValue = value.replace(/[^\d]/g, "")
    const cvLength = currentValue.length

    if (value.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    }
  }

  return (
    <TransitionsModal
      ariaLabelledBy="updatePersonalInfo"
      ariaDescription={t("ariaLabels.description.editPersonalInfoModal")}
      open={open}
      onClose={onClose}
      sx={{
        ...styles.mobileView,
        width: isMobile ? "90%" : isDesktop ? "40%" : "480px",
        backgroundColor: theme.palette.background.paper,
        padding: isMobile ? 2 : 3,
        pr: isMobile ? 1 : 2,
        pt: isMobile ? 1 : 2,
      }}
    >
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            id="updatePersonalInfo"
            sx={{ ...styles.text, color: "textPrimary" }}
          >
            {t("profile.details.editModalTitle")}
          </Typography>
        </Box>
        <IconButton
          sx={{ color: "ctaPrimary.main" }}
          data-cy="barcode-close-button"
          onClick={onClose}
          aria-label={t("ariaLabels.closeBarcode")}
        >
          <CloseIcon color={theme.palette.ctaPrimary.main} />
        </IconButton>
      </Stack>
      <Typography variant="body1" sx={{ ...styles.text2 }}>
        {t("profile.details.allFieldsRequired")}
      </Typography>
      <form onSubmit={onSaveProfile} style={{ width: "100%" }}>
        <FormControl sx={{ width: "100%" }}>
          <Stack direction="row" gap={2} width="100%">
            <TextInput
              label={t("profile.edit.firstName")}
              placeholder={t("profile.edit.firstName")}
              value={profileFormData.firstName.value}
              sx={{ ...styles.textInput, flex: 1 }}
              labelSx={styles.inputLabel}
              onChange={(e) => {
                setProfileFormData((prev) => ({
                  ...prev,
                  firstName: {
                    ...prev.firstName,
                    value: e.target.value,
                    error: "",
                  },
                }))
              }}
              error={!!profileFormData.firstName.error}
              helperText={profileFormData.firstName.error}
            />
            <TextInput
              label={t("profile.edit.lastName")}
              placeholder={t("profile.edit.lastName")}
              value={profileFormData.lastName.value}
              sx={styles.textInput}
              labelSx={styles.inputLabel}
              onChange={(e) => {
                setProfileFormData((prev) => ({
                  ...prev,
                  lastName: {
                    ...prev.lastName,
                    value: e.target.value,
                    error: "",
                  },
                }))
              }}
              error={!!profileFormData.lastName.error}
              helperText={profileFormData.lastName.error}
            />
          </Stack>
          <TextInput
            label={t("profile.edit.email")}
            placeholder={t("profile.edit.email")}
            value={profileFormData.email.value}
            sx={styles.textInput}
            labelSx={styles.inputLabel}
            disabled
            onChange={(e) => {
              setProfileFormData((prev) => ({
                ...prev,
                email: {
                  ...prev.email,
                  value: e.target.value,
                },
              }))
            }}
            error={!!profileFormData.email.error}
            helperText={profileFormData.email.error}
          />
          {user?.phoneNumber ? (
            <TextInput
              label={t("profile.edit.phoneNumber")}
              placeholder={t("profile.edit.phoneNumber")}
              value={normalizeInput(profileFormData.phoneNumber?.value)}
              sx={styles.textInput}
              labelSx={styles.inputLabel}
              disabled
              onChange={(e) => {
                setProfileFormData((prev) => ({
                  ...prev,
                  phoneNumber: {
                    ...prev.phoneNumber,
                    value: e.target.value,
                  },
                }))
              }}
              error={!!profileFormData.phoneNumber?.error}
              helperText={profileFormData.phoneNumber?.error}
            />
          ) : null}
          {user?.zipCode ? (
            <TextInput
              label={t("profile.edit.zipCode")}
              placeholder={t("profile.edit.zipCode")}
              value={profileFormData.zipCode?.value}
              sx={styles.textInput}
              labelSx={styles.inputLabel}
              onChange={(e) => {
                setProfileFormData((prev) => ({
                  ...prev,
                  zipCode: {
                    ...prev.zipCode,
                    value: e.target.value.replace(/[^0-9]/g, ""),
                    error: "",
                  },
                }))
              }}
              inputProps={{
                maxLength: 5,
              }}
              error={!!profileFormData.zipCode?.error}
              helperText={profileFormData.zipCode?.error}
            />
          ) : null}
          <Divider sx={{ borderStyle: "dashed", my: isMobile ? 2 : 3 }} />
          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              color="ctaPrimary"
              sx={styles.cancelbutton}
              type="button"
              onClick={onClose}
              aria-label={t("profile.edit.cancel")}
            >
              {t("profile.edit.cancel")}
            </Button>
            <LoadingButton
              disableElevation
              variant="contained"
              color="ctaPrimary"
              sx={styles.button}
              type="submit"
              loading={isLoading}
              aria-label={t("profile.edit.save")}
            >
              {t("profile.edit.save")}
            </LoadingButton>
          </Stack>
        </FormControl>
      </form>
    </TransitionsModal>
  )
}

export default EditPersonalInfoModal
