import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

const Container = styled("div")(({ theme }) => ({
  "> .hidden": {
    padding: "8px",
    position: "absolute",
    background: theme.palette.bgPrimary?.main,
    left: "calc(50% - 100px)",
    height: "30px",
    transform: "translateY(-100%)",
    transition: "transform 0.3s",
  },
  ".link": {
    color: theme.palette.ctaSecondaryText?.main,
    textDecoration: "none",
    zIndex: 1000,
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".main-content": {
    "&:focus": {
      transform: "translateY(0%)",
    },
  },
  ".footer": {
    "&:focus": {
      transform: "translateY(0%)",
    },
  },
}))

const AdaSkipTo = () => {
  const { t } = useTranslation()
  return (<Container>
    <a className="link main-content hidden" href="#main-content">
      {t("ada.skipToMainContent")}
    </a>
    <a className="link footer hidden" href="#footer">
      {t("ada.skipToFooter")}
    </a>
  </Container>
  )
}

export default AdaSkipTo