import * as React from "react"
import { SVGProps } from "react"
const SvgCouponsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.799951 1.90039C0.799951 1.30039 1.19995 0.900391 1.79995 0.900391C2.09995 0.900391 2.29995 1.00039 2.49995 1.20039L5.99995 4.70039L9.49995 1.20039C9.89995 0.800391 10.5 0.800391 10.9 1.20039C11.3 1.60039 11.3 2.20039 10.9 2.60039L6.69995 6.80039C6.29995 7.20039 5.69995 7.20039 5.29995 6.80039L1.09995 2.60039C0.899952 2.40039 0.799951 2.10039 0.799951 1.90039Z" />
  </svg>
)
export default SvgCouponsIcon
