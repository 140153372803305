import { AppBar, Box, Toolbar, useMediaQuery } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { useTheme } from "@mui/material/styles"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import PropTypes from "prop-types"
import { cloneElement, useContext, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import generateConfig from "../../configs/config"
import { AppContext } from "../../contexts/AppContext"
import { useAuth } from "../../contexts/AuthContext"
import { flashAlert } from "../../contexts/actions/actions"
import styles from "../../styles/component.module.scss"
import BottomNavBar from "../navBar/BottomNavBar"
import TopNavBar from "../navBar/TopNavBar"
import FlashAlert from "../ui/flashAlert/FlashAlert"
import SubNavBar from "../ui/subNavBar/SubNavBar"
import AdaSkipTo from "../ui/adaSkipTo/AdaSkipTo"
import {
  getProductsDetails,
  getShoppingList,
  updateShoppingList,
} from "index.service"
import { useAppContext } from "contexts/AppContext"
import {
  setShoppingListItems,
  setShoppingListMetaData,
  setShoppingListLoader,
} from "contexts/actions/actions"
import { useRouter } from "next/router"

const config = generateConfig()

function ElevationScroll(props) {
  const theme = useTheme()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return cloneElement(props.children, {
    elevation: trigger ? 4 : 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.grey["300"]}`,
      boxShadow:
        "0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px 1px 0px rgba(0, 0, 0, 0.04)",
    },
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

// Get distinct items by item title
export function getDistinctItems(items) {
  return Array.from(
    new Map(items.map((item) => [item["title"], item])).values()
  )
}

export default function PrimaryLayout(props) {
  const isMobile = useMediaQuery("(max-width:767px)")
  const { dispatch, state } = useContext(AppContext)
  const { t } = useTranslation()
  const { setInitialState, initialState, swiftlyToken, isAuthenticated } =
    useAuth()
  const {
    state: {
      currentStore,
      shoppingList: { isInitialSynced, shoppingListItems },
    },
  } = useAppContext()

  useEffect(() => {
    setInitialState((prevState) => ({
      ...prevState,
      envVariables: config,
      loading: false,
    }))
  }, [])

  // removed because it was causing stores to get glitchy and also caused a lot of extra api calls when changing stores
  // useEffect(() => {
  //   // code to load data of current store when app gets loaded
  //   (async () => {
  //     // const storeId = localStorage.getItem("storeId")
  //     if (storeId) {
  //       const storeDetail = { storeId }

  //       /**
  //        * @type {import("../../generated/graphql").StoreDetailsQuery}
  //        */
  //       const data = await queryClient.fetchQuery(
  //         useStoreDetailsQuery.getKey(storeDetail),
  //         fetchData(StoreDetailsDocument, storeDetail)
  //       )

  //       const store = decorateStore(data.store)
  //       dispatch(setStore(store))
  //     }
  //   })()
  // }, [decorateStore, dispatch])

  //TODO: THIS BREAKS LOGIN! - is it needed?
  // if (initialState.loading) {
  //   return <></>;
  // }

  useEffect(() => {
    if (!config?.topAlert?.title) {
      return
    }

    dispatch(
      flashAlert.show({
        message: config.topAlert?.title,
      })
    )
  }, [dispatch, t, config?.topAlert?.title])

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setShoppingListLoader(true))
      const items = []
      let remoteShoppingList

      // If user is logged in then get the saved shopping list from API
      if (swiftlyToken?.access_token && isAuthenticated) {
        remoteShoppingList = await getShoppingList(swiftlyToken.access_token)
        if (remoteShoppingList.items && remoteShoppingList.items.length) {
          items.push(...remoteShoppingList.items)
        }

        // Update shopping list meta data
        dispatch(setShoppingListMetaData(remoteShoppingList.metaData))
      }

      // Get items from local storage and append to the shopping list
      const localItems =
        JSON.parse(localStorage.getItem("shoppingListItems")) || []
      items.push(...localItems)

      const itemsWithDetails = await getProductsDetails(items)

      // Update context store with items
      dispatch(setShoppingListItems(getDistinctItems(itemsWithDetails)))

      if (swiftlyToken?.access_token && isAuthenticated) {
        await updateShoppingList(
          swiftlyToken.access_token,
          getDistinctItems(items),
          remoteShoppingList?.metaData
        )
        localStorage.setItem("shoppingListItems", JSON.stringify([]))
      }

      dispatch(setShoppingListLoader(false))
    }

    fetchData()
  }, [swiftlyToken, isAuthenticated, currentStore, dispatch])

  /**
   * Get updated shopping list from context and store it in local storage
   */
  useEffect(() => {
    if (isInitialSynced) {
      // select distinct items by item name
      const distinctItems = getDistinctItems(shoppingListItems)

      // Set items in local storage
      localStorage.setItem("shoppingListItems", JSON.stringify(distinctItems))
    }
  }, [isInitialSynced, shoppingListItems])

  const params = useRouter()
  const mainContainerRef = useRef(null)
  useEffect(() => {
    if (mainContainerRef.current && params.pathname !== "/") {
      mainContainerRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.pathname])

  return (
    <Box
      sx={{
        pointerEvents: initialState?.actionInprogress ? "none" : "auto",
      }}
    >
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          component="nav"
          color="bgPrimary"
          className={styles.headerWrapper}
          sx={{
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.08)",
          }}
        >
          <AdaSkipTo />
          {/* {!isMobile && ( */}

          {config.topAlert?.title?.length > 0 && (
            <FlashAlert
              message={config.topAlert?.title || undefined}
              onClose={() => dispatch(flashAlert.hide())}
              url={config.topAlert?.url || undefined}
            />
          )}
          {/* )} */}

          <Toolbar disableGutters p={0} className={styles.toolbar}>
            <TopNavBar />
          </Toolbar>
          <SubNavBar />
        </AppBar>
      </ElevationScroll>
      <Box
        sx={{
          pt: (() => {
            if (isMobile) {
              return state.flashAlert?.isOpen ? "137px" : "97px"
            }
            return state.flashAlert?.isOpen ? "145px" : "105px"
          })(),
        }}
        component="main"
        mb={3}
        id="main-content"
        ref={mainContainerRef}
        tabIndex={params.pathname !== "/" ? -1 : 0}
      >
        {props.children}
      </Box>
      <BottomNavBar />
    </Box>
  )
}
